(function () {
    'use strict';

    angular.module('app').factory('pixelService', ['appCache', 'api', '$q', 'hub', pixelServiceFactory]);

    function pixelServiceFactory(appCache, api, $q, hub) {
        const pixelStoreKey = 'pixelTemplates';
        const WEB_AUDIENCE_TYPE = 'Web';
        const CONVERSION_AUDIENCE_TYPE = 'Conversion';
        const TRACER_HOST = hub.settings.environment === 'production' ? 'https://trc.lhmos.com' : 'https://trc-tst.lhmos.com';
        let fetchingPixel = false;
        function getPixelData() {
            const data = appCache.get(pixelStoreKey);
            if (data) {
                return JSON.parse(data);
            }
            return null;
        }
        function setPixelData(data) {
            appCache.put(pixelStoreKey, JSON.stringify(data));
            return getPixelData();
        }

        function getPixelTemplates() {

            var deferred = $q.defer();
            if (!fetchingPixel && !getPixelData()) {
                fetchingPixel = true;
                api.get('api/audience/pixels').then(function (data) {
                    const pData = setPixelData(data);
                    deferred.resolve(pData);
                    fetchingPixel = false;
                }, function (err) {
                    deferred.resolve(null);
                });
            } else if (fetchingPixel && !getPixelData()) {
                setTimeout(function () {
                    getPixelTemplates().then((r) => {
                        deferred.resolve(r);
                    });
                }, 500);
            } else {
                deferred.resolve(getPixelData());
            }
            return deferred.promise;
        }
        // type: enum{ js, img }
        function getPixelUrl(audience, type, imgType) {
            const { id, apnSegmentId } = audience;
            var deferred = $q.defer();
            if (id && apnSegmentId) {
                getPixelTemplates().then((data) => {
                    deferred.resolve(data[type]
                        .replace('\{LASSO_AUDIENCE_ID\}', id)
                        .replace('\{TYPE\}', imgType)
                        .replace('\{APN_SEGMENT_ID\}', apnSegmentId));
                });
            } else {
                deferred.reject(null);
            }
            return deferred.promise;
        }
        function isWeb(audience) {
            return audience.audienceType === WEB_AUDIENCE_TYPE;
        }
        function isConversion(audience) {
            return audience.audienceType === CONVERSION_AUDIENCE_TYPE;
        }
        function setJsPixel(audience) {
            if (isWeb(audience) || isConversion(audience)) {
                getPixelUrl(audience, (isWeb(audience) ? "web" : "conversion")).then(url => {
                    audience.tag = `
                    <!-- Lasso Tag - ${audience.name} - ${audience.id} DO NOT MODIFY  -->
                    <script type="text/javascript" id="lassosegment" src="https://res.lassomarketing.io/scripts/lassotag.js"></script>
                    <script async type="text/javascript" src="${url}"></script>
                    <!-- End of Lasso Tag -->
                    `;
                }, (error) => { });
            }
        }

        function setImgPixel(audience) {
            if (isWeb(audience) || isConversion(audience)) {
                audience.imgTag = `
                <!-- Lasso Pixel - ${audience.name} - ${audience.id} DO NOT MODIFY -->
                <img height="1" width="1" src="${TRACER_HOST + '/conv/px?id=' + audience.id}"/>
                <img height="1" width="1" src="${TRACER_HOST + '/web/px?id=' + audience.id}"/>
                <!-- End of Lasso Pixel -->
                `;
            }
        }

        function downloadPixel(name, pixel, type) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(pixel));
            element.setAttribute('download', `${name}_${type}.txt`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }

        return {
            setImgPixel: setImgPixel,
            setJsPixel: setJsPixel,
            downloadPixel: downloadPixel
        };
    }
})();