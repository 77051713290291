(function () {
    'use strict';

    const moneyFormat = function (_this, n, s) {
      var x = 3;
      var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
      return (
        (s ? s : "") +
        _this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,")
      );
    }

    angular.module('app').factory('page', ['$location', '$filter', function ($location, $filter) {
        var title = 'Dashboard';
        var sidebar_mode = '';
        var toNumber = function (value) {
            var number;
            var reg = /^(\-)?\d+(\.\d{0,2})?$/;
            if (typeof (value) == 'number') {
                number = value;
            }
            else if (typeof (value) == 'string' && reg.test(value)) {
                number = parseFloat(value);
            } else {
                number = 0;
            }
            return number;
        }

        return {
            title: function () { return title; },
            setTitle: function (newTitle) {
                title = newTitle;
                document.title = newTitle;
            },
            setSidebarMode: function (mode) {
                sidebar_mode = mode;
            },
            sidebarMode: function () {
                return sidebar_mode === 'AdvertisersWithCampaigns' ? 'Default' : sidebar_mode;
            },

            getTotal: function (collection, field) {
                var total = 0;
                if (!collection)
                    return 0;
                for (var i = 0; i < collection.length; i++) {
                    if (collection[i][field]) {
                        if (typeof collection[i][field] == 'function')
                            total += collection[i][field]();
                        else
                            total += collection[i][field];
                    }
                }
                return total;
            },
            getROAS: function (collection, mode) {
                var total = 0;

                if (!collection)
                    return 0;
                if (mode == 'totals') {
                    for (var i = 0; i < collection.length; i++) {
                        if (collection[i].spend > 0) {

                            return this.getTotal(collection, 'spend', 0) > 0 ?
                                ((this.getTotal(collection, 'onlinePurchaseAmount', 0) + this.getTotal(collection, 'offlinePurchaseAmount', 0)) / this.getTotal(collection, 'spend', 0)) : 0;
                        }
                    }
                }
                else {
                    for (var i = 0; i < collection.length; i++) {
                        if (collection[i].spend > 0) {

                            total += (collection[i].onlinePurchaseAmount + collection[i].offlinePurchaseAmount) / collection[i].spend;
                        }
                    }
                }
                return total;

            },
            formatValue: function (value, format, defaultValue, defaultOnzero) {
                if (value == null)
                    return defaultValue;
                if (defaultOnzero && !value)
                    return defaultValue;
                var f = format ? format.split(':')[0] : format;
                var number = toNumber(value);
                var isNegative = number < 0;

                switch (f) {
                    case 'name': {
                        const reg = new RegExp('"', 'g');
                        return `"${value.replace(reg, '""')}"`;
                    }
                    case 'text':
                        return value;
                    case 'text%':
                        return number.toFixed(2) + '%';
                    case '100%': {
                        return value > 1 ? 'N/A' : ((number * 100).toFixed(2) + '%');
                    }
                    case '%':
                        return (value * 100).toFixed(2) + '%';
                    case 'short%':
                        return number.toFixed(0) + '%';
                    case '$':
                        return (isNegative ? '-$' : '$') + moneyFormat(Math.abs(number), 2);
                    case '$3':
                        return (isNegative ? '-$' : '$') + moneyFormat(Math.abs(number), 3);
                    case '$6':
                        return value ? '$' + moneyFormat(number, 6) : "$0";
                    case '$0':
                        return value ? $filter('currency')(value, '$', 0) : "$0";
                    case '$0.00':
                        return value ? '$' + moneyFormat(value, 2) : "$0";
                    case 'date':
                        return $filter('date')(value);
                    case 'date2':
                        if (!value)
                            return "";
                        if (value.indexOf('2037') >= 0)
                            return 'No end date';
                        var date = moment(value).toDate();
                        return $filter('date')(date);
                    case 'estdate':
                            if (!value)
                                return "";
                            if (value.indexOf('2037') >= 0)
                                return 'No end date';
                            var date = moment.tz(value, "America/New_York").format("MMM DD, YYYY");
                            return date;
                    case 'fixed':

                        var limit = format.split(':')[1];
                        limit = limit || 5;

                        return !value ? value : (value.toString().length > limit ? value.toString().substr(0, limit) + '...' : value);
                    case '0':
                        return $filter('number')(value, '0');
                    case '0.0':
                        return $filter('number')(value, '1');
                    case '0.00':
                        return $filter('number')(value, '2');
                    case '0.000':
                        return $filter('number')(value, '3');
                    default:
                        return $filter('number')(value, '2');
                }


            },
            fixDate: function (dateValue, format) {
                if (!dateValue)
                    return null;

                if (dateValue.indexOf('T') > 0) {
                    var date = dateValue.substring(0, 10).split('-');
                    var dateObj = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]));
                    if (format)
                        return $filter('date')(dateObj);
                    else
                        return dateObj;
                }
                return dateValue;
            },
            getValue: function (value) {
                if (!value)
                    return 0;
                return value;
            },
            getOrSetValue: function (obj, name, defaultValue) {
                if (!obj[name]) {
                    obj[name] = defaultValue;
                }
                return obj[name];
            },
            getAverage: function (collection, field) {

                if (!collection || collection.length === 0)
                    return 0;

                var total = this.getTotal(collection, field);
                return total ? total / collection.length : 0;
            },

            getRandomInRange: function (min, max) {
                return Math.random() * (max - min) + min;
            },
            getPageId: function () {
                return $location.path().split(/[\s/]+/).pop();
            },
            divide: function (a, b) {
                if (b > 0)
                    return a / b;
                return 0;
            },
            divideTotals: function (collection, field1, field2) {
                return this.divide(this.getTotal(collection, field1), this.getTotal(collection, field2));
            },
            csvToArray: function (text) {
                var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
                var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
                // Return NULL if input string is not well formed CSV string.
                if (!re_valid.test(text)) return null;
                var a = [];                     // Initialize array to receive values.
                text.replace(re_value, // "Walk" the string using replace with callback.
                    function (m0, m1, m2, m3) {
                        // Remove backslash from \' in single quoted values.
                        if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                        // Remove backslash from \" in double quoted values.
                        else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                        else if (m3 !== undefined) a.push(m3);
                        return ''; // Return empty string.
                    });
                // Handle special case of empty last value.
                if (/,\s*$/.test(text)) a.push('');
                return a;
            }

        };
    }]);

})();
