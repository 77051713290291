import toastr from "toastr"

(function () {
    'use strict';

    angular.module('app').factory('logger', ['$log', '$injector', logger]);

    function logger($log, $injector) {
        var service = {
            getLogFn: getLogFn,
            log: log,
            logError: logError,
            logFixedError: logFixedError,
            logSuccess: logSuccess,
            logWarning: logWarning,
            logSystem: logSystem,
            logMessages: logMessages,
            showToastPermanent: showToastPermanent,
            logPreview: logPreview,
            stopPreview: stopPreview,
            init: init,
            save: save
        };

        var _logMessages = [];
        function logMessages() {
            return _logMessages;
        }

        toastr.options = {
            "closeButton": true,
            "closeDuration": 100,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": true,
            "timeOut": "5000",
            "tapToDismiss": false,
            target: "#angular-modals"
        };

        return service;

        function getLogFn(moduleId, fnName) {
            fnName = fnName || 'log';
            switch (fnName.toLowerCase()) { // convert aliases
                case 'success':
                    fnName = 'logSuccess'; break;
                case 'error':
                    fnName = 'logError'; break;
                case 'fixederror':
                    fnName = 'logFixedError'; break;
                case 'warn':
                    fnName = 'logWarning'; break;
                case 'warning':
                    fnName = 'logWarning'; break;
                case 'system':
                    fnName = 'logSystem'; break;
                case 'preview':
                    fnName = 'logPreview'; break;
                case 'stop':
                    fnName = 'stopPreview'; break;
            }

            var logFn = service[fnName] || service.log;
            return function (msg, data, showToast, noLog, timeOut, isPermanent) {
                logFn(msg, data, moduleId, (showToast === undefined) ? true : showToast, noLog, timeOut, isPermanent);
            };
        }

        //show permanent toastr popup
        function showToastPermanent(message) {
            toastr.options.timeOut = 0;
            toastr.options.extendedTimeOut = 0;
            _logMessages.push({ date: new Date(), data: null, source: 'System Notification', message: message, type: 'warning', isHidden: false });
            save();
            toastr.warning(message);
        }

        function log(message, data, source, showToast, noLog, timeOut) {
            logIt(message, data, source, showToast, 'info', noLog, timeOut);
        }

        function logWarning(message, data, source, showToast, noLog) {
            logIt(message, data, source, showToast, 'warning', noLog);
        }


        function logSuccess(message, data, source, showToast, noLog) {
            logIt(message, data, source, showToast, 'success', noLog);
        }
        function logSystem(message, data, source, showToast, noLog) {
            logIt(message, data, source, false, 'system', noLog);
        }

        function logError(message, data, source, showToast, noLog, timeOut) {
            logIt(message, data, source, showToast, 'error', noLog, timeOut);
        }

        function logFixedError(message, data, source, showToast, noLog, timeOut) {
            logIt(message, data, source, showToast, 'error', noLog, timeOut, true);
        }

        function logPreview(message) {
            toastr.options.timeOut = 0;
            toastr.options.extendedTimeOut = 0;
            toastr.options.tapToDismiss = false;
            toastr.options.closeOnHover = false;
            toastr.options.hideDuration = 100;
            toastr.info(message);
        }

        function stopPreview() {
            toastr.clear();
        }

        function init() {
            var store = $injector.get('localStorageService');
            var events = store.get('eventlog');
            if (events && (events instanceof Array)) {
                events.forEach(function (item) {
                    item.date = new Date(item.date);
                    if (item.isHidden == null)
                        item.isHidden = false;
                });

                _logMessages = events;
            }
        }
        function save() {
            var store = $injector.get('localStorageService');
            store.set('eventlog', _logMessages);
        }



        function logIt(message, data, source, showToast, toastType, noLog, timeOut, isPermanent) {
            if (!message)
                return;

            toastr.options.timeOut = timeOut ? timeOut : 5000;
            toastr.options.extendedTimeOut = 5000;
            if(isPermanent){
                toastr.options.timeOut = 0;
                toastr.options.extendedTimeOut = 0;
                toastr.options.tapToDismiss = false;
                toastr.options.closeOnHover = false;
            }
            var write = (toastType === 'error') ? $log.error : $log.log;
            var store = $injector.get('localStorageService');
            var adminMode = store.get('adminMode');
            if (!(noLog))
                _logMessages.push({ date: new Date(), data: (adminMode ? data : null), source: source, message: message, type: toastType, isHidden: false });
            save();

            write(source, message, data);
            if (showToast) {
                if (toastType === 'error') {
                    toastr.error(message);
                } else if (toastType === 'warning') {
                    toastr.warning(message);
                } else if (toastType === 'success') {
                    toastr.success(message);
                } else {
                    toastr.info(message);
                }
            }
        }
    }
})();
