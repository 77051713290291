(function () {
    'use strict';
    angular.module('app').constant('Enum', {
        AccountTypes: [
            { id: 1, name: 'Advertiser', authorize: 'Advertiser', page: 'advertisers' },
            { id: 2, name: 'Marketer', authorize: 'Marketer', page: 'marketers' },
            { id: 3, name: 'Advertiser Group', authorize: 'Advertiser', page: 'advertisergroups' },
            //{ id: 4, name: 'Marketer Holding Company', authorize: 'HoldingCompany', page: 'holding-companies' }
            { id: 5, name: 'Publisher', authorize: 'Publisher', page: 'publishers'},
        ],
        Values: {
            AccountOptions: { ThirdPartyAdServing: 1, Offer: 2, CoAdvertiseredCreative : 64}
        },
        EntityStatuses: [
           { id: 0, name: 'Active' },
           { id: 1, name: 'Removed' },
             { id: 2, name: 'Disabled' },
           { id: 3, name: 'Paused' },
           { id: 4, name: 'Draft' }
        ],
        MarketerTagStates:
            [{ id: 2, name: 'Paused' }, { id: 1, name: 'Active' }],
        LocationTypes:
            [{ id: 1, name: 'Point of Care' }, { id: 2, name: 'Point of Sale' }, { id: 3, name: 'Conference' }, { id: 4, name: 'Other' }],
        BannerSizes: [
            { name: '728x90', id: 1 },
            { name: '300x250',id: 2 },
            { name: '160x600', id: 3 },
            { name: '320x50', id: 4 },
            { name: '300x600', id: 5 },
            { name: '300x50', id: 6 },
            { name: '970x90', id: 7 },
            { name: '970x250', id: 8 },
            { name: '1x1', id: 9 },
            { name: '460x120', id: 10 },
            { name: '570x70', id: 11 },
            { name: '120x240', id: 12 },
            { name: '650x136', id: 13 },
            { name: '1200x628', id: 14 },
            { name: '320x480', id: 15 },
            { name: '640x480', id: 16 },
            { name: '640x640', id: 17 },
            { name: '120x600', id: 18 },
            { name: '1920x1080', id: 19 },
            { name: '768x1024', id: 20 },
            { name: '1024x768', id: 21 },
            { name: '320x100', id: 22 },
            { name: '300x1050', id: 23 },
            { name: '250x250', id: 24 },
            { name: '336x280', id: 25 },
            { name: '480x320', id: 26 },
            { name: '320x250', id: 27 },
            { name: '970x550', id: 28 },
            { name: '468x60', id: 29 },
        ],
       
        getId: function (type, enumValue) {
            var e = this[type];
            var id = null;
            if (e) {
                e.forEach(function (item, idx) {

                    if (item.name == enumValue) {
                        id = item.id;
                        return id;
                    }

                });
            }
            return id;
        },

        getName: function (type, enumValue) {
            var e = this[type];
            var name = null;
            if (e) {
                e.forEach(function (item, idx) {

                    if (item.id == enumValue) {
                        name = item.name;
                        return name;
                    }

                });
            }
            return name;
        }
    });
})();