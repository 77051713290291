(function () {
  'use strict';

  angular.module('app').constant('authSettings', {
    get apiServiceBaseUri() {
      return `${process.env.API}/`;
    },
    returnUrl: '',
    get clientId() {
      return 'TDX';
    },
    security: {
      //enable authorization
      get authorize() {
        return true
      },
      get routePrefix() {
        return 'activation';
      },
      //do not check permissions for these urls
      get exclude() {
        return ['/auth/:key', '/pages/403', '/pages/404', '/pages/500'];
      },
      //apply permissions to site sections (sites) /[site]/page/.../
      get sites() {
        return { 'activation': ['Advertiser', 'Retail'], 'retail': ['Retail'] };
      }, //, 'admin': ['Admin Access']
      //per page permissions
      get paths() {

        return {
          'activation/creative': ['creatives'],
          'activation/creative/:creativeId/:mode': ['creatives'],
          'activation/creative/new': ['creatives'],
          'activation/creative/bulk': ['creatives'],
          'activation/creative-request': ['creativerequests'],
          'activation/creative-request/:requestId': ['creativerequests'],
          'reporting': ['reports'],
          'reporting/custom': ['reports'],
          'reporting/custom/:id': ['reports'],
          'reporting/site': ['reports'],
          'reporting/site/:id': ['reports'],
          'reporting/sell': ['reports'],
          'reporting/sell/:id': ['reports'],
          'reporting/match': ['reports'],
          'reporting/match/:id': ['reports'],
          'reporting/campaign-summary/:id': ['reports'],
          'reporting/campaign-summary': ['reports'],
          'activation/campaign': ['campaigns'],
          'activation/campaign/:campaignId': ['campaigns'],
          'activation/campaign/:campaignId/edit': ['campaigns'],
          'activation/campaign/campaign-wizard': ['campaigns'],
          'activation/campaign/campaign-wizard/:campaignId': ['campaigns'],
          'activation/campaign/:campaignId/new': ['campaigns'],
          'activation/campaign/adgroup/:adGroupId': ['campaigns'],
          'activation/campaign/adgroup/:adGroupId/:mode': ['campaigns'],
          'activation/adgroup-history/:adGroupId/:fromV2?': ['campaigns'],
          'activation/admin-only': ['audiences'],
          'activation/admin-only/new': ['audiences'],
          'activation/retargeting-audience/:audienceId/edit': ['audiences'],
          'activation/pixel/:audienceId/edit': ['audiences'],
          'activation/admin-only/:audienceId/edit': ['audiences'],
          'admin/account-management': ['accounts'],
          'admin/accounts/advertisers/:accountId/edit': ['accounts'],
          'admin/accounts/advertisers/new': ['accounts'],
          'admin/accounts/advertisers': ['accounts'],
          'admin/accounts/advertisergroups/:accountId/edit': ['accounts'],
          'admin/accounts/advertisergroups/new': ['accounts'],
          'admin/accounts/advertisergroups': ['accounts'],
          'admin/accounts/marketers/:accountId/edit': ['accounts'],
          'admin/accounts/marketers/new': ['accounts'],
          'admin/accounts/marketers': ['accounts'],
          'admin/accounts/publishers/:accountId/edit': ['accounts'],
          'admin/accounts/publishers/new': ['accounts'],
          'admin/accounts/publishers': ['accounts'],
          'admin/accounts': ['accounts'],
          'admin/users': ['users'],
          'admin/users/new': ['users'],
          'admin/users/:userId/edit': ['users'],
          'admin/settings': ['settings'],
          'activation/brand-safety': ['inventorylists'],
          'activation/brand-safety/:inventoryListId/:mode': ['inventorylists'],
          'activation/brand-safety/:keywordListId/:mode': ['inventorylists'],
          'activation/brand-safety/inventory-list': ['inventorylists'],
          'activation/brand-safety/inventory-list/new': ['inventorylists'],
          'activation/brand-safety/inventory-list/:inventoryListId/:mode': ['inventorylists'],
          'activation/brand-safety/keyword-list': ['inventorylists'],
          'activation/brand-safety/keyword-list/new': ['inventorylists'],
          'activation/brand-safety/keyword-list/:keywordListId/:mode': ['inventorylists'],
          'activation/brand-safety/blocking-profile': ['inventorylists'],
          'activation/brand-safety/blocking-profile/new': ['inventorylists'],
          'activation/brand-safety/blocking-profile/:blockingProfileId/:mode': ['inventorylists'],
          'activation/location': ['locations'],
          'activation/location/:locationId/edit': ['locations'],
          'activation/location/new': ['locations'],
          'activation/web-events': ['audiences'],
          'activation/web-events/pixel': ['audiences'],
          'activation/web-events/pixel/new': ['audiences'],
          'activation/web-events/pixel/:audienceId/edit': ['audiences'],
          'activation/web-events/retargeting-audience': ['audiences'],
          'activation/web-events/retargeting-audience/new': ['audiences'],
          'activation/web-events/retargeting-audience/:audienceId/edit': ['audiences'],
          'app/activation/campaign/new': ['campaigns'],
          'app/activation/campaign/:id/edit': ['campaigns'],
          'app/activation/campaign/:id/overview': ['campaigns'],
          'admin/features': ['features'],
        };
      }
    }

  });

  angular.module('app').constant('appSettings', {
    appName: 'Lasso Portal',
    version: process.env.TAG || process.env.VERSION,
    base: process.env.BASE_URL,
    environment: process.env.ENVIRONMENT,
    build: process.env.BUILD,
    buildDate: process.env.DATE * 1000,
    ticks: process.env.TICKS,
    returnUrl: 'https://rwsweb.dev.one.market/',
    contactEmail: 'user@prodatarx.com',
    apiUrl: `${process.env.API}/`,
    cdnUrl: process.env.CDN_PATH,
    storageUrl: process.env.STORAGE_API,
    blobUrl: process.env.BLOB_URL,
    get imagesUrl() {
      return `${this.blobUrl}${process.env.IMAGES_BUCKET}/`;
    },
    get creativeImagesUrl() {
      return `${this.blobUrl}${process.env.CREATIVES_BUCKET}/`;
    },
    get audienceDataUrl() {
      return `${this.blobUrl}${process.env.AUDIENCES_DATA_BUCKET}/`;
    },
    get locationDataUrl() {
      return `${this.blobUrl}${process.env.LOCATION_GROUPS_DATA_BUCKET}/`;
    },
    get reportsUrl() {
      return `${this.blobUrl}${process.env.UPLOADS_BUCKET}/`;
    },
    get keywordsUrl() {
      return `${this.blobUrl}${process.env.KEYWORDS_BUCKET}/`;
    },
    serverHostName: process.env.SRV,
    pageSize: 25,
    menuMap: [{ paths: ['activation', 'settings', 'admin'], menu: 'manufacturer' }],
    get pathPrefix() {
      return 'activation';
    },
    menus: {
      manufacturer: {
        paths: ['activation', 'settings/account-settings'], values: [
          {
            title: 'Audiences',
            url: 'app/audience',
            authorize: "audiences",
            feature: 'AUDIENCE',
            pathPrefixes: ['app/audience']
          },
          {
            title: 'Activation', url: '', authorize: "activation", pathPrefixes: [''], feature: 'ACTIVATION ',
            submenu: [
              {
                title: 'Campaigns',
                url: 'activation/campaign',
                authorize: "campaigns",
                feature: 'CAMPAIGN',
                pathPrefixes: ['/activation/campaign'],
                iconclass: 'icon-programmatic-cpm'
              },
              {
                title: 'Reporting',
                url: 'reporting',
                feature: 'REPORTING',
                authorize: "reports",
                pathPrefixes: ['/reporting'],
                iconclass: 'ti-blackboard'
              },
              {
                title: 'Creatives',
                url: 'activation/creative',
                authorize: "creatives",
                feature: 'CREATIVES',
                pathPrefixes: ['/activation/creative'],
                iconclass: 'icon-banner'
              },
              {
                title: 'Digital Events',
                url: 'activation/web-events',
                authorize: "webevents",
                feature: 'AUDIENCE',
                pathPrefixes: ['/activation/web-events/pixel', '/activation/web-events/retargeting-audience'],
                iconclass: 'ti-desktop'
              },
              {
                title: 'Locations',
                url: 'activation/location',
                authorize: "locations",
                pathPrefixes: ['/activation/location'],
                iconclass: 'ti-location-pin'
              },
              {
                title: 'Brand Safety',
                url: 'activation/brand-safety',
                authorize: 'inventorylists',
                pathPrefixes: ['/activation/brand-safety/inventory-list', '/activation/brand-safety', '/activation/brand-safety/keyword-list', '/activation/brand-safety/blocking-profile'],
                feature: 'INVENTORYLISTS',
                iconclass: 'ti-shield'
              },
            ]
          },
          {
            title: 'Settings', url: 'admin/settings', authorize: 'settings',
            submenu: [
              { title: 'Accounts', url: 'admin/account-management', authorize: 'accounts', iconclass: 'icon-accounts' },
              { title: 'Users', url: 'admin/users', authorize: 'users', iconclass: 'icon-user' },
              { title: 'Feature Preview', url: 'admin/features', authorize: 'SuperAdmin', iconclass: 'icon-preview' },
              { title: 'Admin Only', url: 'activation/admin-only', authorize: 'SuperAdmin', iconclass: 'ti-medall-alt' }
            ]
          }
        ]
      },
      newcampaign: {
        paths: ['activation/creative', 'activation/creative-request'],
        values: [{
          title: 'RETURN TO CAMPAIGN WIZARD',
          url: 'activation/campaign/new-campaign',
          clickAction: 'show-campaign-menu'
        }, { title: 'CREATIVES', url: 'activation/creative' }, {
          title: 'CANCEL CAMPAIGN SETUP',
          url: 'activation/campaign/new-campaign',
          clickAction: 'cancel-campaign'
        }, {
          title: 'RETURN TO CAMPAIGN WIZARD',
          url: 'activation/campaign/edit-campaign',
          clickAction: 'show-campaign-menu'
        }, { title: 'CANCEL CAMPAIGN SETUP', url: 'activation/campaign/edit-campaign', clickAction: 'cancel-campaign' }]
      },
      admin: {
        paths: ['admin', 'settings/account-settings'], values: [
          {
            title: 'CAMPAIGNS', url: 'activation/campaign', authorize: "campaigns"
          },
          { title: 'CREATIVESS', url: 'activation/creative', authorize: "creatives" },
          { title: 'REPORTING', url: 'reporting', authorize: "reports", feature: 'REPORTING' },
          {
            title: 'Settings', url: 'admin/settings', authorize: 'Account,User,System',

            submenu: [
              { title: 'ACCOUNTS', url: 'admin/account-management', authorize: 'Admin Access' },
              { title: 'USERS', url: 'admin/users', authorize: 'Admin Access' },
              { title: 'Deal IDs', url: 'admin/settings', authorize: 'Admin Access' },
            ]

          }

        ]
      }
    }
  });
})();
