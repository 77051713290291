(function () {
    'use strict';

    var serviceId = 'storagecontext';
    angular.module('app').factory(serviceId, ['$q', '$http', 'appSettings', storagecontext]);


    function storagecontext($q, $http, appSettings) {

        var service = {
            reload: reload,
            getProfile: getProfile,
            saveProfile: saveProfile,
            getCached: getCached, 
            API_SERVER_URL: appSettings.storageUrl,
            PROFILE_KEY: 'profileData',
            saveSession: saveInSession
        };

        return service;
        function reload()
        {
            this.getProfile(true);
        }

        function getProfile(reload) {

            var deferred = $q.defer();
            var KEY = this.PROFILE_KEY;

            if (!reload && sessionStorage[this.PROFILE_KEY]) {
                var data = JSON.parse(sessionStorage[this.PROFILE_KEY]);
                deferred.resolve(data || {});
            }
            else {
                $http.get(this.API_SERVER_URL + 'api/profile/').then(function (data) {
                    var p = data.data || {};
                    sessionStorage[KEY] = JSON.stringify(p);
                    deferred.resolve(p);
                },
                (error) => {
                    deferred.reject(error);
                });
            }

            return  deferred.promise;
        }

        function saveInSession(profile)
        {
            var p = profile || {};

            sessionStorage[this.PROFILE_KEY] = JSON.stringify(p);
        }

        function getCached(section, value, defaultValues)
        {
            var settings = {};
            if (sessionStorage[this.PROFILE_KEY]) {
                settings= JSON.parse(sessionStorage[this.PROFILE_KEY]);
            }
            
            if(section)
            {
                return settings[section] ? ((value && settings[section][value]) ? settings[section][value] : defaultValues) : defaultValues;
            }
            else
            {
                return settings ? settings:  defaultValues;
            }

            
        }
       
        function saveProfile(section, data) {
           var KEY = this.PROFILE_KEY;
           var deferred = $q.defer();
            $http.post(this.API_SERVER_URL + 'api/profile?section=' + section, data).then((res) =>{
                sessionStorage[KEY] = res.data.valueJson;
                deferred.resolve(res.data);
            }, (error) => {
                deferred.reject(error);
            });

            return deferred.promise;

         }
    }

})();