import urlJoinBase from 'url-join'

/** Joins url parts, automatically collapsing multiple consecutive forward slashes */
export const urlJoin = (...parts) => {
  return urlJoinBase(...parts.map(part => part || '/')).replace(/(^|[^:])\/\/+/g, '$1/')
}

/** Creates a url that accounts for base url */
export const urlApp = (url) => {
  return urlJoin(location.origin, process.env.BASE_URL, url)
}
