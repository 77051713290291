(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:infoPanel
 * @infoPanel directive
 * # <info-panel  />
 * informational panel
 */

    angular.module('app')
      .directive('infoPanel', function () {
          return {
              template: require('@views/app/info-panel.html').default,
              restrict: 'E',
              scope: { model: '=' },
              controller: ['$scope', function ($scope) {
                  var vm = this;
                  vm.model = $scope.model;


                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });

})();
