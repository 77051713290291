(function () {
    'use strict';
    angular.module('app')
        .controller('brandSafetyController', ['$scope', '$rootScope', '$uibModal', '$location', '$routeParams', '$filter', 'hub', 'campaignService', 'tableService', 'dialogService', 'authService', 'logger', '$route', 'timezoneService', 'accountService', function ($scope, $rootScope, $modal, $location, $routeParams, $filter, hub, campaignService, tableService, dialogService, authService, logger, $route, timezoneService, accountService) {
            var rootVM = $rootScope.rootVM;
            var vm = $scope.vm;
            vm.rootVM = rootVM;

            hub.page.setTitle('Brand Safety');
            vm.page = hub.page;
            vm.config = hub.config;
            vm.ts = tableService;
            var log = hub.getLog('BrandSafety');
            vm.log = log;
            vm.inventoryLists = [];
            vm.hasAccess = authService.hasAccess;
            vm.isAdmin = authService.userAccountType() == "Admin";
            vm.sort = {
                inventoryLists: { pageNumber: 1, column: 'createdDate', sortDesc: true, keyword: '' },
                keywordLists: { pageNumber: 1, column: 'createdDate', sortDesc: true, keyword: '' },
                blockingProfiles: { pageNumber: 1, column: 'createdDate', sortDesc: true, keyword: '' }
            };

            vm.currentEastTimeAbbreviation = timezoneService.getInfo().currentEastTimeAbbreviation;

            vm.tabs = [{ active: true, title: 'Inventory List' }, { active: false, title: 'Keyword List' }, { active: false, title: 'Blocking Profile' }];
            vm.activeTab = 0;
            vm.setActiveTab = function (tab) {
                if (tab == 0){
                    $location.path('activation/brand-safety/inventory-list', false);
                    vm.sort.inventoryLists.keyword = "";
                }
                else if (tab == 1){
                    $location.path('activation/brand-safety/keyword-list', false);
                    vm.sort.keywordLists.keyword = "";
                }
                else{
                    $location.path('activation/brand-safety/blocking-profile', false);
                    vm.sort.blockingProfiles.keyword = "";
                }
                $scope.updatePageData();
            };

            if ($location.$$path.indexOf('/activation/brand-safety/keyword-list') > -1) {
                vm.tabs = [{ active: false, title: 'Inventory List' }, { active: true, title: 'Keyword List' }, { active: false, title: 'Blocking Profile' }];
                vm.activeTab = 1;
            }

            if ($location.$$path.indexOf('/activation/brand-safety/blocking-profile') > -1) {
                vm.tabs = [{ active: false, title: 'Inventory List' }, { active: false, title: 'Keyword List' }, { active: true, title: 'Blocking Profile' }];
                vm.activeTab = 2;
            }

            $scope.updatePageData = function () {
                if (vm.activeTab == 0) {
                    if(vm.inventoryListTypeFilter){
                      let tempList = $filter('filter')(vm.inventoryLists, {inventoryListType: vm.inventoryListTypeFilter});
                      vm.total = $filter('filter')(tempList, vm.sort.inventoryLists.keyword).length;
                    }else{
                      vm.total = $filter('filter')(vm.inventoryLists, vm.sort.inventoryLists.keyword).length;
                    }
                } else if (vm.activeTab == 1) {
                    vm.total = $filter('filter')(vm.keywordLists, vm.sort.keywordLists.keyword).length;
                } else if (vm.activeTab == 2) {
                    vm.total = $filter('filter')(vm.blockingProfiles, vm.sort.blockingProfiles.keyword).length;
                }
                vm.pageNumber = 1;
            };

            $rootScope.$on('inventoryListUpdate', function(name, notification) {                
                const message = JSON.parse(notification.message);
                if(vm.ts.data && vm.ts.data.inventoryLists) {
                    const { inventoryLists } = vm.ts.data;
                    const { list } = inventoryLists;
                    const messageItem = list.find(x => x.id === notification.value);
                    if(messageItem) {                        
                        loadInventoryLists();
                        logger.showToastPermanent('Lasso Notification: Inventory List has been updated.');
                    }
                }
             });

            const loadInventoryLists = function() {
                return hub.webapicontext.getInventoryLists().then(function (d) {
                    vm.inventoryLists = d;
                    vm.ts.setData(vm.inventoryLists, 'inventoryLists', vm.sort['inventoryLists']);
                    vm.ts.search('inventoryLists');
                    $scope.updatePageData();
                });
            }

            campaignService.getAccounts(1).then(function () {
                loadInventoryLists();
            });

            campaignService.getAccounts(2).then(function () {
                hub.webapicontext.getKeywordLists().then(function (d) {
                    vm.keywordLists = d;
                    vm.ts.setData(vm.keywordLists, 'keywordLists', vm.sort['keywordLists']);
                    vm.ts.search('keywordLists');
                });
            });

            campaignService.getAccounts(2).then(function () {
                hub.webapicontext.getBlockingProfiles().then(function (d) {
                    vm.blockingProfiles = d;
                    vm.ts.setData(vm.blockingProfiles, 'blockingProfiles', vm.sort['blockingProfiles']);
                    vm.ts.search('blockingProfiles');
                });
            });

            vm.getAdvertiserName = function (advertiserId) {
                campaignService.getAccounts(1).then(function () {
                    var advertiser = campaignService.advertisers.find(function (b) { return b.id === advertiserId; });
                    if (advertiser)
                        return advertiser.name;
                    else
                        return "not available";
                });
            };

            vm.getMarketerName = function (marketerId) {
                campaignService.getAccounts(2).then(function () {
                    var marketer = campaignService.marketers.find(function (b) { return b.id === marketerId; });
                    if (marketer)
                        return marketer.name;
                    else
                        return "not available";
                });
            };

            vm.download = function (url) {

                hub.downloadFile(url).then(function (r) {

                });

            };
            vm.shouldOpenModal = function () {
                return document.querySelector('.modal-container') === null;
            }
            vm.newInventoryList = function () {
                if (!vm.shouldOpenModal()) {
                    return;
                }
                var modalInstance;
                var marketerId = '';
                var advertiserId = '';
                if (vm.rootVM.accountId) {
                    var advertiser = campaignService.advertisers.find(function (a) { return a.id == vm.rootVM.accountId; });
                    if (advertiser)
                        advertiserId = advertiser.id;
                    var marketer = campaignService.marketers.find(function (a) { return a.id == vm.rootVM.accountId || advertiser && a.id == advertiser.marketerId; });
                    if (marketer)
                        marketerId = marketer.id;
                }

                $location.path('activation/brand-safety/inventory-list/new', false);
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/brand-safety/editor/inventory-list.html',
                        animation: false,
                        controller: 'editInventoryListController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return {
                                    inventoryList: {
                                        id: 0, name: '', advertiserId: advertiserId, marketerId: marketerId, internal: false
                                    },
                                    advertisers: campaignService.advertisers,
                                    marketers: campaignService.marketers
                                };
                            }
                        }
                    });

                    modalInstance.result.then((function (data) {
                        var newInventoryList = data.inventoryList;

                        vm.log(newInventoryList.name + " Created");
                        loadInventoryLists().then(() => {
                            $location.path('activation/brand-safety/inventory-list', false);
                        }, () => {
                            $location.path('activation/brand-safety/inventory-list', false);
                        });
                    }), (function () {
                        $location.path('activation/brand-safety/inventory-list', false);
                    }));
                });
            };

            vm.editInventoryList = function (inventoryList) {
                if (!vm.shouldOpenModal()) {
                    return;
                }
                $location.path('activation/brand-safety/inventory-list/' + inventoryList.id + '/edit', false);
                var modalInstance;
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/brand-safety/editor/inventory-list.html',
                        animation: false,
                        controller: 'editInventoryListController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return { marketers: campaignService.marketers, advertisers: campaignService.advertisers, inventoryList: inventoryList };
                            },
                            creativeForm: function () {
                                return $scope.creativeForm;
                            }
                        }
                    });
                    modalInstance.result.then((function (data) {
                        var editedInventoryList = data.inventoryList;
                        vm.log(editedInventoryList.name + " Updated");
                        loadInventoryLists().then(() => {
                            $location.path('activation/brand-safety/inventory-list', false);
                        });
                    }), (function () {
                        $location.path('activation/brand-safety/inventory-list', false);
                    }));
                });
            };

            vm.copyInventoryList = function (inventoryList) {
                hub.webapicontext.copyInventoryList(inventoryList.id).then(function () {
                    vm.log(inventoryList.name + " Copied");
                    loadInventoryLists().then(() => {
                        $scope.updatePageData();
                    });                    
                });
            };

            vm.removeInventoryList = function (inventoryList) {

                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Remove',
                    message: 'Are you sure you want to remove inventoryList ' + inventoryList.name + '?'
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                    hub.webapicontext.removeInventoryList(inventoryList.id).then(function () {
                        vm.log(inventoryList.name + " Removed");
                        loadInventoryLists().then(() => {                            
                            $scope.updatePageData();
                        });
                    });
                });

            };

            vm.canRemove = function (inventoryList) {
                return vm.isAdmin;
            }

            vm.newKeywordList = function () {
                if(!vm.shouldOpenModal()) {
                    return;
                }
                var modalInstance;
                var marketerId = '';
                var advertiserId = '';
                if (vm.rootVM.accountId) {
                    var advertiser = campaignService.advertisers.find(function (a) { return a.id == vm.rootVM.accountId; });
                    if (advertiser)
                        advertiserId = advertiser.id;
                    var marketer = campaignService.marketers.find(function (a) { return a.id == vm.rootVM.accountId || advertiser && a.id == advertiser.marketerId; });
                    if (marketer)
                        marketerId = marketer.id;
                }
                $location.path('activation/brand-safety/keyword-list/new', false);
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/brand-safety/editor/keyword-list.html',
                        animation: false,
                        controller: 'editKeywordListController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return {
                                    keywordList: {
                                        id: 0, marketerId: marketerId, advertiserId: advertiserId
                                    },
                                    marketers: campaignService.marketers,
                                    advertisers: campaignService.advertisers
                                };
                            }
                        }
                    });

                    modalInstance.result.then((function (data) {
                        var newKeywordList = data.keywordList;

                        vm.log(newKeywordList.name + " Created");
                        hub.webapicontext.getKeywordLists().then(function (d) {
                            vm.keywordLists = d;
                            vm.ts.setData(vm.keywordLists, 'keywordLists', vm.sort['keywordLists']);
                            vm.ts.search('keywordLists');
                            $location.path('activation/brand-safety/keyword-list', false);
                        });

                    }), (function () {
                        $location.path('activation/brand-safety/keyword-list', false);
                    }));
                });
            };

            vm.editKeywordList = function (keywordList) {
                if (!vm.shouldOpenModal()) {
                    return;
                }
                $location.path('activation/brand-safety/keyword-list/' + keywordList.id + '/edit', false);
                var modalInstance;
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/brand-safety/editor/keyword-list.html',
                        animation: false,
                        controller: 'editKeywordListController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return { marketers: campaignService.marketers, keywordList: keywordList, advertisers: campaignService.advertisers };
                            },
                            creativeForm: function () {
                                return $scope.creativeForm;
                            }
                        }
                    });
                    modalInstance.result.then((function (data) {
                        var editedKeywordList = data.keywordList;
                        vm.log(editedKeywordList.name + " Updated");
                        hub.webapicontext.getKeywordLists().then(function (d) {
                            vm.keywordLists = d;
                            vm.ts.setData(vm.keywordLists, 'keywordLists', vm.sort['keywordLists']);
                            vm.ts.search('keywordLists');
                            $location.path('activation/brand-safety/keyword-list', false);
                        });
                    }), (function () {
                        $location.path('activation/brand-safety/keyword-list', false);
                    }));
                });
            };

            vm.copyKeywordList = function (keywordList) {
                hub.webapicontext.copyKeywordList(keywordList.id).then(function () {
                    vm.log(keywordList.name + " Copied");
                    hub.webapicontext.getKeywordLists().then(function (d) {
                        vm.keywordLists = d;
                        vm.ts.setData(vm.keywordLists, 'keywordLists', vm.sort['keywordLists']);
                        vm.ts.search('keywordLists');
                        $scope.updatePageData();
                    });
                });
            };

            vm.removeKeywordList = function (keywordList) {

                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Remove',
                    message: 'Are you sure you want to remove keywordList ' + keywordList.name + '?'
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                    hub.webapicontext.removeKeywordList(keywordList.id).then(function () {
                        vm.log(keywordList.name + " Removed");
                        hub.webapicontext.getKeywordLists().then(function (d) {
                            vm.keywordLists = d;
                            vm.ts.setData(vm.keywordLists, 'keywordLists', vm.sort['keywordLists']);
                            vm.ts.search('keywordLists');
                            $scope.updatePageData();
                        });
                    });
                });

            };


            vm.newBlockingProfile = function () {
                if(!vm.shouldOpenModal()) {
                    return;
                }
                var modalInstance;
                var marketerId = '';
                var advertiserId = '';
                if (vm.rootVM.accountId) {
                    var advertiser = campaignService.advertisers.find(function (a) { return a.id == vm.rootVM.accountId; });
                    if (advertiser)
                        advertiserId = advertiser.id;
                    var marketer = campaignService.marketers.find(function (a) { return a.id == vm.rootVM.accountId || advertiser && a.id == advertiser.marketerId; });
                    if (marketer)
                        marketerId = marketer.id;
                }

                $location.path('activation/brand-safety/blocking-profile/new', false);
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/brand-safety/editor/blocking-profile.html',
                        animation: false,
                        controller: 'editBlockingProfileController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return {
                                    blockingProfile: {
                                        id: 0, marketerId: marketerId, advertiserId: advertiserId
                                    },
                                    marketers: campaignService.marketers,
                                    advertisers: campaignService.advertisers
                                };
                            }
                        }
                    });

                    modalInstance.result.then((function (data) {
                        var newBlockingProfile = data.blockingProfile;

                        vm.log(newBlockingProfile.name + " Created");
                        hub.webapicontext.getBlockingProfiles().then(function (d) {
                            vm.blockingProfiles = d;
                            vm.ts.setData(vm.blockingProfiles, 'blockingProfiles', vm.sort['blockingProfiles']);
                            vm.ts.search('blockingProfiles');
                            $location.path('activation/brand-safety/blocking-profile', false);
                        });

                    }), (function () {
                        $location.path('activation/brand-safety/blocking-profile', false);
                    }));
                });
            };

            vm.editBlockingProfile = function (blockingProfile) {
                if (!vm.shouldOpenModal()) {
                    return;
                }
                $location.path('activation/brand-safety/blocking-profile/' + blockingProfile.id + '/edit', false);
                var modalInstance;
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/brand-safety/editor/blocking-profile.html',
                        animation: false,
                        controller: 'editBlockingProfileController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return { marketers: campaignService.marketers, blockingProfile: blockingProfile, advertisers: campaignService.advertisers };
                            },
                            creativeForm: function () {
                                return $scope.creativeForm;
                            }
                        }
                    });
                    modalInstance.result.then((function (data) {
                        var editedBlockingProfile = data.blockingProfile;
                        vm.log(editedBlockingProfile.name + " Updated");
                        hub.webapicontext.getBlockingProfiles().then(function (d) {
                            vm.blockingProfiles = d;
                            vm.ts.setData(vm.blockingProfiles, 'blockingProfiles', vm.sort['blockingProfiles']);
                            vm.ts.search('blockingProfiles');
                            $location.path('activation/brand-safety/blocking-profile', false);
                        });
                    }), (function () {
                        $location.path('activation/brand-safety/blocking-profile', false);
                    }));
                });
            };

            vm.copyBlockingProfile = function (blockingProfile) {
                hub.webapicontext.copyBlockingProfile(blockingProfile.id).then(function () {
                    vm.log(blockingProfile.name + " Copied");
                    hub.webapicontext.getBlockingProfiles().then(function (d) {
                        vm.blockingProfiles = d;
                        vm.ts.setData(vm.blockingProfiles, 'blockingProfiles', vm.sort['blockingProfiles']);
                        vm.ts.search('blockingProfiles');
                        $scope.updatePageData();
                    });
                });
            };

            vm.removeBlockingProfile = function (blockingProfile) {

                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Remove',
                    message: 'Are you sure you want to remove blockingProfile ' + blockingProfile.name + '?'
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                    hub.webapicontext.removeBlockingProfile(blockingProfile.id).then(function () {
                        vm.log(blockingProfile.name + " Removed");
                        hub.webapicontext.getBlockingProfiles().then(function (d) {
                            vm.blockingProfiles = d;
                            vm.ts.setData(vm.blockingProfiles, 'blockingProfiles', vm.sort['blockingProfiles']);
                            vm.ts.search('blockingProfiles');
                            $scope.updatePageData();
                        });
                    });
                });

            };

            vm.sortFn = function (column, list) {
                vm.ts.sortFn(column, list);
                vm.sort[list].column = vm.ts.sort[list].column;
                vm.sort[list].sortDesc = vm.ts.sort[list].sortDesc;
                vm.sort[list].keyword = vm.ts.sort[list].keyword;
            };

            $scope.search = function (item) {
                if (!vm.sort.inventoryLists.keyword || (item.name.toLowerCase().indexOf(vm.sort.inventoryLists.keyword.toLowerCase()) != -1) || (item.marketerName.toLowerCase().indexOf(vm.sort.inventoryLists.keyword.toLowerCase()) != -1)) {
                    return true;
                }
                return false;
            };

            vm.processUrl = function () {
                var mode;
                if ($location.$$path.indexOf('/activation/brand-safety/inventory-list') > -1) {
                    var inventoryListId = $routeParams.inventoryListId;
                    mode = $routeParams.mode;

                    if (inventoryListId) {
                        hub.webapicontext.getInventoryList(inventoryListId).then(function (d) {
                            if (mode == 'edit')
                                vm.editInventoryList(d);
                        });
                    }
                    else if ($location.$$path.indexOf('/activation/brand-safety/inventory-list/new') > -1) {
                        vm.newInventoryList();
                    }
                    else
                        $rootScope.$broadcast('modal.force.close', null);
                } else if ($location.$$path.indexOf('/activation/brand-safety/keyword-list') > -1) {
                    var keywordListId = $routeParams.keywordListId;
                    mode = $routeParams.mode;

                    if (keywordListId) {
                        hub.webapicontext.getKeywordList(keywordListId).then(function (d) {
                            if (mode == 'edit')
                                vm.editKeywordList(d);
                        });
                    }
                    else if ($location.$$path.indexOf('/activation/brand-safety/keyword-list/new') > -1) {
                        vm.newKeywordList();
                    }
                    else
                        $rootScope.$broadcast('modal.force.close', null);
                } else if ($location.$$path.indexOf('/activation/brand-safety/blocking-profile') > -1) {
                    var blockingProfileId = $routeParams.blockingProfileId;
                    mode = $routeParams.mode;

                    if (blockingProfileId) {
                        hub.webapicontext.getBlockingProfile(blockingProfileId).then(function (d) {
                            if (mode == 'edit')
                                vm.editBlockingProfile(d);
                        });
                    }
                    else if ($location.$$path.indexOf('/activation/brand-safety/blocking-profile/new') > -1) {
                        vm.newBlockingProfile();
                    } else
                        $rootScope.$broadcast('modal.force.close', null);
                }
            };

            vm.processUrl();

            var original = $location.path;
            $location.path = function (path, reload) {
                if (reload === false) {
                    var lastRoute = $route.current;
                    var un = $rootScope.$on('$locationChangeSuccess', function () {
                        if (IsCurrentPage($route.current.$$route.originalPath))
                            $route.current = lastRoute;

                        un();
                    });
                }
                return original.apply($location, [path]);
            };

            function IsCurrentPage(path) {
                if (path.indexOf('activation/brand-safety') > -1 || path.indexOf('activation/brand-safety/inventory-list') > -1 || path.indexOf('activation/brand-safety/keyword-list') > -1 || path.indexOf('activation/brand-safety/blocking-profile') > -1)
                    return true;
                return false;
            };

            vm.pageSize = vm.config.pageSize().toString();

            vm.pageSizeChanged = function (size) {
                vm.pageSize = size;
                $scope.updatePageData();
            };

            $scope.$watch('vm.sort.inventoryLists.keyword', function (){
              if(vm.inventoryListTypeFilter){
                let tempList = $filter('filter')(vm.inventoryLists, {inventoryListType: vm.inventoryListTypeFilter});
                vm.total =  $filter('filter')(tempList, vm.sort.inventoryLists.keyword).length;
              }else{
                vm.total = $filter('filter')(vm.inventoryLists, vm.sort.inventoryLists.keyword).length;
              }
              vm.pageNumber = 1;
            });

            $scope.$watch('vm.sort.keywordLists.keyword', function (){
              vm.total = vm.keywordLists ? $filter('filter')(vm.keywordLists, vm.sort.keywordLists.keyword).length : 0;
              vm.pageNumber = 1;
            });

            $scope.$watch('vm.sort.blockingProfiles.keyword', function (){
              vm.total = vm.blockingProfiles ? $filter('filter')(vm.blockingProfiles, vm.sort.blockingProfiles.keyword).length: 0;
              vm.pageNumber = 1;
            });

            return vm;

        }]);

})();
