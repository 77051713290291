(function () {
    'use strict';

    angular.module('app')
        .directive('columnsManager', function () {
            return {
                template: require('@views/app/columns-manager.html').default,
                restrict: 'E',
                scope: { model: '=', columns: '=' },
                controller: ['$scope', 'hub', 'userSettingsService', 'columnsService', function ($scope, hub, userSettingsService, columnsService) {
                    var vm = this;
                    var COLUMN_OPTIONS_ID = 128;

                    vm.model = $scope.model;
                    vm.columns = [];
                    vm.sections = [];


                    var data = columnsService.getSectionedUserColumns();
                    var isFullBasket = userSettingsService.getFullBasket();

                    var accountSettings = {};
                    vm.model.options.forEach(function (it, idx) {
                        if (it.optionId == COLUMN_OPTIONS_ID) {
                            accountSettings = JSON.parse(it.value);
                        }
                    });
                    var fbColumns = userSettingsService.fullBasketColumns();
                    var sections = [];
                    for (var section in data) {
                        var columns = [];
                        for (var column in data[section]) {

                            data[section][column].enabled = true;
                            data[section][column].customName = '';

                            //hide for advertisers
                            if (vm.model.accountType == 1) {
                                if (data[section][column].key == 'CT' || data[section][column].key == 'RDR' || data[section][column].key == 'RDCPM' ||
                                    data[section][column].name.indexOf('Full Basket') > -1)
                                    continue;

                                if (data[section][column].key == 'MMBCPM' || data[section][column].key == 'BACPM' || data[section][column].key == 'PFEE' ||
                                    data[section][column].key == 'BIFEE' || data[section][column].key == 'DCST' || data[section][column].key == 'MCST' ||
                                    data[section][column].key == 'MACPM' || data[section][column].key == 'AGRM' || data[section][column].key == 'AGRMP' ||
                                    data[section][column].key == 'AGMMB' || data[section][column].key == 'AGMMBC' || data[section][column].key == 'AGRMMP' ||
                                    data[section][column].key == 'AGRMB' || data[section][column].key == 'AGRCSD' || data[section][column].key == 'COM' ||
                                    data[section][column].key == 'AGBC' || data[section][column].key == 'AGBCT' || data[section][column].key == 'AGCPMR' ||
                                    data[section][column].key == 'AGED' || data[section][column].key == 'AGIG' || data[section][column].key == 'AGPT' ||
                                    data[section][column].key == 'AGSD')
                                    data[section][column].enabled = false;
                            }

                            if (accountSettings) {
                                if (accountSettings.hidden)
                                    data[section][column].enabled = accountSettings.hidden.indexOf(column) == -1;
                                if (accountSettings.customNames) {
                                    accountSettings.customNames.forEach(function (it, idx) {
                                        if (it.key == column)
                                            data[section][column].customName = it.customName;
                                    });
                                }
                            }
                            columns.push(data[section][column]);
                        }
                        sections.push({ "name": section, "columns": columns });
                    }
                    vm.columns = columns;
                    vm.sections = sections;

                    vm.settings = { hidden: [], customNames: [] };

                    vm.save = function () {
                        var hiddenColumns = [];
                        var customNames = [];
                        vm.sections.forEach(function (section, idx) {
                            section.columns.forEach(function (item, idxColumn) {
                                if (!item.enabled)
                                    hiddenColumns.push(item.key);

                                if (item.customName && item.customName != '' && item.customName != item.name)
                                    customNames.push({ key: item.key, customName: item.customName });
                            });
                        });
                        vm.settings.hidden = hiddenColumns;
                        vm.settings.customNames = customNames;
                    }

                    return vm;
                }],
                controllerAs: 'vm',
                link: function (scope, element, attrs, controller) {

                    if (scope.columns) {
                        scope.columns.getData = function () {

                            controller.save();


                            return controller.settings;
                        };
                    }
                }
            }
        });



})();
