(function () {
    'use strict';

    angular.module('app').factory('tableService', ['$filter', 'page', 'config', '$rootScope', tableService]);

    function tableService($filter, page, config, $rootScope) {
      
        var service = {};
        service.data = {};
        service.filters = {};
        service.sort = {
            
        };


        service.search = function (list, fields) {

            var listInstance = [];
            if (service.data[list].all == null)
                listInstance = service.data[list].list;
            else
                listInstance = service.data[list].all;
            // https://docs.angularjs.org/api/ng/filter/orderBy
            // From the angularjs docs :  Note: For the purpose of sorting, null and undefined are considered "greater than" any other value
            // another possible way use custom comparator with valueOf
            if (service.sort[list].column){     
                var nullValues = $filter('filter')(listInstance, function(item){
                    var prop = service.sort[list].column;
                    var result = item[prop] == null;
                    return result;
                });

                var notNullValues = $filter('filter')(listInstance, function(item){
                    var prop = service.sort[list].column;
                    var result = item[prop] != null;
                    return result;
                });

                listInstance = $filter('orderBy')(notNullValues, service.sort[list].column, service.sort[list].sortDesc);
                if(service.sort[list].sortDesc) {
                    listInstance = listInstance.concat(nullValues);
                } else {
                    listInstance = nullValues.concat(listInstance);
                }
            }

            if (service.filters[list]) {
                for (var name in service.filters[list]) {
                    var filter = service.filters[list][name];
                    if (filter) {
                        //create 'all' collection if filter is applied
                        if (service.data[list].all == null)
                            service.data[list].all = angular.copy(listInstance);
                        listInstance = $filter('filter')(service.data[list].list, filter);
                    }
                }

            }
            //create 'all' collection if filter is applied and KW is set
            if (service.data[list].all == null && service.sort[list].keyword)
                service.data[list].all = angular.copy(listInstance);

            if (list !== 'campaigns') {
                if (fields) {
                    service.data[list].list = listInstance.filter(function (item) {
                        var res = false;
                        fields.forEach(function (field) {
                            var fieldValue = item[field] ? item[field] : '';
                            if (fieldValue.toString().toLowerCase().indexOf(service.sort[list].keyword.toLowerCase()) > -1)
                                res = true;
                        });
                        return res;
                    });
                }
                else
                    service.data[list].list = $filter('filter')(listInstance, service.sort[list].keyword);
            }
            else
                service.data[list].list = listInstance;

            service.pageSelect(1, list);
        }
        service.setData = function(listInstance, name, sortDetails)
        {
            service.init(name);
            service.data[name].list = listInstance;
           // service.data[name].all = listInstance;

            service.sort[name] = sortDetails;
            
        }

        service.init = function (name) {
            service.data[name] = { list: null, all: null, page :null};
            service.filters[name] = {};
            service.sort[name] = {};

        }

        service.sortFn = function (column, list) {
            if (service.sort[list].column == column)
                service.sort[list].sortDesc = !service.sort[list].sortDesc;
            else {
                service.sort[list].column = column;
                service.sort[list].sortDesc = false;
            }

            service.search(list);
        }


        service.pageSelect = function (page, listName, tableUpdateNeeded) {
            var end, start;
            var pageSize = parseInt(config.pageSize());
            start = (page - 1) * pageSize;
            end = start + pageSize;
            service.data[listName].page = service.data[listName].list.slice(start, end);
            service.data[listName].pageNumber = page;
            if (tableUpdateNeeded)
                $rootScope.$broadcast('pageChanged', null);
        };


        return service;


    }

    angular.module('app').factory('serverTableService', ['config', serverTableService]);
    function serverTableService(config){
        function getInstance(filter = {keyword: ''}) {
            var pageSize = parseInt(config.pageSize());
            let service = {
                pager: { pageSize, pageNumber: 1, total: 0 },
                sort: { sortDesc: true, sortName: 'id' },
                filter,
                data: null
            };
            service.reset = () => {
                service.pager = { pageSize: pageSize, pageNumber: 1, total: 0 };
                service.sort = {sortDesc: true, sortName: 'id'};

            }
            service.pageChanged = () => {
                service.search();
            }
            service.pageSizeChanged = (size) => {
                service.pager = { pageSize: size, pageNumber: 1, total: 0 };
                pageSize = size;
                service.search();
            }
            service.setPagedSearchPromise = (promise) => {
                service.pagedSearchPromise = promise;
            }
            service.sortFn = (sortName) => {
                if(service.sort.sortName == sortName) {
                    service.sort.sortDesc = !service.sort.sortDesc;
                } else {
                    service.sort.sortName = sortName;
                    service.sort.sortDesc = true;
                }
                service.search();
            }
    
            service.search = () => {
                return service.pagedSearchPromise().then(data => {
                    service.data = data.list;
                    service.pager.total = data.total;
                    return data;
                });
            }
            return service;
        }
        return {
            getInstance: getInstance
        }
        
    }
})();