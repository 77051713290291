(function () {
    'use strict';

    angular.module('app').factory('hub', ['logger','page','config','datacontext','webapicontext', 'storagecontext','appSettings','accountService','Enum', 'api', hub]);

    function hub(log, page, config, datacontext, webapicontext, storagecontext, appSettings, accountService, Enum, api) {
        var service = {
            getLog: log.getLogFn,
            page: page,
            config: config,
            datacontext: datacontext,
            webapicontext: webapicontext,
            storagecontext: storagecontext,
            settings: appSettings,
            accountService: accountService,
            Enum: Enum,
            api: api,
            downloadFile: function (url) {
                return api.get(url).then(function (r) {

                    var a = document.createElement('a');
                    a.href = r.url;
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                });
            }
            
        };

        return service;


    }
})();