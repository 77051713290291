(function () {
    'use strict';

    var serviceId = 'validationService';
    angular.module('app').factory(serviceId, [validationServiceFactory]);

    function validationServiceFactory() {

        var service = {
            inCampaignBounds: function (startDate, endDate, campaign) {
                if (!startDate || !endDate) {
                    return true;
                }

                startDate = moment.isMoment(startDate) ? startDate : moment(startDate);
                endDate = moment.isMoment(endDate) ? endDate : moment(endDate);

                const flights = (campaign?.flights || [])
                                .filter(f => f.startDate || f.endDate) // needs at least one datetime
                                .map(f => ({
                                    startDate: f.startDate || '0000-01-01T00:00:00Z', // Beginning of time
                                    endDate: f.endDate || '3000-01-01T00:00:00Z', // far far away in the future
                                }));

                if (flights.length > 0) {
                    const valid = flights.some(f => {
                        if (f.startDate && startDate.isBefore(f.startDate)) {
                            return false;
                        }
                        if (f.endDate && endDate.isAfter(f.endDate)) {
                            return false;
                        }
                        return true;
                    });
                    return valid;
                } else {
                    return true;
                }
            },
        
            validateFlight: function (flight, adGroup, campaign) {
                let dateRangeValid = true;
                let startDateValid = true;
                let endDateValid = true;
                let inBounds = true;

                if (! flight) {
                    if (adGroup.budgetCapSpendModeId !== 2) { // Not Lifetime
                        inBounds = service.inCampaignBounds(adGroup.startDate, adGroup.endDate, campaign);
                    }
                    return { dateRangeValid, startDateValid, endDateValid, inBounds }; 
                }

                const now = new Date(Date.now());
                const flights = adGroup.flights.filter(x => x.statusId !== 1);

                if (flights.indexOf(flight) > -1) {
                    const startDate = new Date(flight.startDate);
                    const endDate = new Date(flight.endDate);
                    if (!flight.startDateIsInThePast || !flight.endDateIsInThePast) {
                        if (flight === _.first(flights) || flight.likeFirstFlight) {
                            dateRangeValid = !flight.endDate || (endDate - startDate) / 1000 / 60 >= 59;
                        } else {
                            dateRangeValid = !flight.endDate || (endDate - startDate) / 1000 / 60 >= (23 * 60 + 59);
                        }
                    }
                    
                    startDateValid = flight.startDateIsInThePast 
                        || (flight.startDate !== undefined && flight.startDate !== null && (adGroup.channelId === 9 || startDate.getTime() >= (now.getTime() + 10 * 60 * 1000)));
                    endDateValid = flight.endDateIsInThePast || flight.endDate == null || adGroup.channelId === 9
                        || (dateRangeValid && Math.ceil((endDate.getTime() - now.getTime()) / 60 / 1000) >= 30);

                    inBounds = adGroup.channelId !== 9 /* Data Only */ && adGroup.budgetCapSpendModeId === 2 /* lifetime */
                        ? service.inCampaignBounds(flight.startDate, flight.endDate, campaign)
                        : true;
                }

                flight.dateRangeValid = dateRangeValid || adGroup.channelId == 9;
                flight.endDateValid = endDateValid || adGroup.channelId == 9;
                flight.startDateValid = startDateValid || adGroup.channelId == 9;
                flight.inBounds = inBounds

                return { dateRangeValid, startDateValid, endDateValid, inBounds };
            },

            validateAdGroup: function (adGroup, campaign, isLaunch) {
                let adGroupFormValid = true;

                let bannersValid = true;
                let videoValid = true;
                let facebookValid = true;
                let emailValid = true;
                let audioValid = true;
                let flightsValid = true;

                if (adGroup.channelId == 6) {
                    adGroup.flights[0].startDate = adGroup.startDate;
                    adGroup.flights[0].endDate = adGroup.endDate;
                    if (adGroup.emailDeploymentType == 1) {
                        adGroup.endDate = null;
                        adGroup.flights[0].endDate = null;
                    }
                }
                
                if (adGroup.budgetCapSpendModeId !== 2) {
                    adGroup.inBounds = flightsValid = service.inCampaignBounds(adGroup.startDate, adGroup.endDate, campaign);
                }

                adGroup
                    .flights
                    .filter(x => x.statusId !== 1)
                    .slice(0)
                    .sort((l, r) => (l.startDate.valueOf() - r.startDate.valueOf()))
                    .forEach((flight, index, arr) => {
                        if (flightsValid) {
                            const { dateRangeValid, startDateValid, endDateValid, inBounds } = service.validateFlight(flight, adGroup, campaign);
                            flightsValid = startDateValid && endDateValid && dateRangeValid && inBounds;
                            if (index !== arr.length - 1) {
                                const nextFlight = arr[index + 1];
                                flightsValid = flightsValid && (nextFlight.startDate.valueOf() > flight.endDate.valueOf());
                            }
                        }
                    });
                adGroup.datesInvalid = !flightsValid && adGroup.channelId !== 9;
                adGroupFormValid = flightsValid || adGroup.channelId == 9;

                var statusNotDraft = adGroup.adGroupStatusId && adGroup.adGroupStatusId != 6;

                adGroup.audienceSelectionError = false;
                if (!adGroup.isMeasurementOnly && (adGroup.channelId == 9 && !adGroup.dynamicSequentialMessaging)
                    && !(adGroup.audienceSegmentGroups
                        && adGroup.audienceSegmentGroups.length
                        && adGroup.audienceSegmentGroups[0].segments
                        && adGroup.audienceSegmentGroups[0].segments.length)) {
                    adGroup.audienceSelectionError = true;
                    adGroupFormValid = false;
                }

                if (!adGroup.creatives || (adGroup.creatives.length == 0 && (statusNotDraft || isLaunch || adGroup.channelId == 6))) {
                    bannersValid = false;
                    videoValid = false;
                    facebookValid = false;
                    emailValid = false;
                }
                else {
                    if (adGroupFormValid) {
                        adGroup.creatives.forEach(function (creative) {
                            bannersValid = bannersValid && (creative.bannerURL && !creative.thirdPartyServed || creative.thirdPartyServed);
                            videoValid = videoValid && (creative.videoURL && !creative.thirdPartyServed
                                || creative.thirdPartyServed && (creative.nonSecureVASTDocumentURL && creative.nonSecureVASTValid !== false
                                    || creative.secureVASTDocumentURL && creative.secureVASTValid !== false));
                            facebookValid = facebookValid && (creative.bannerURL || !creative.creativeSlides.find(function (cs) { return !cs.imageURL; }));
                            if (!creative.thirdPartyServed && !isURL(creative.destinationURL) && adGroup.channelId != '5' && creative.creativeTypeId != 2 && adGroup.channelId != '6') {
                                adGroupFormValid = false;
                            }
                            else {
                                if (creative.creativeSlides && creative.creativeSlides.find(function (cs) { return !cs.clickURL; })) {
                                    adGroupFormValid = false;
                                }
                                else {
                                    if (creative.duplicateName || !creative.name) {
                                        adGroupFormValid = false;
                                    }
                                }
                            }
                        });
                    }
                }

                if (adGroupFormValid) {
                    if ((Math.abs(adGroup.UTCCalendarEndDate - adGroup.UTCCalendarStartDate) / 365) < 25 && adGroup.channelId == '3') {
                        adGroupFormValid = false;
                    }
                    else {
                        var startCalendarDate = new Date() + 5 * 60 * 1000;
                        if (adGroup.channelId != 9 && adGroup.startDate < new Date(startCalendarDate)
                            && !(adGroup.adGroupStatusId == 1
                                || adGroup.adGroupStatusId == 10
                                || adGroup.adGroupStatusId == 3
                                || adGroup.hasImpression
                                || adGroup.startDateIsInThePast)) {
                            adGroupFormValid = false;
}
                        else {
                            if (adGroup.channelId != 9 && adGroup.endDate != null && adGroup.endDate < adGroup.startDate
                                && (adGroup.endDate < new Date(startCalendarDate) || !adGroup.adGroupId)) {
                                adGroupFormValid = false;
                            }
                        }
                    }
                }

                var lastFlightEndDate = adGroup.flights[adGroup.flights.length - 1].endDate;
                adGroup.isPassedAdGroup = adGroup.channelId !== 9 && lastFlightEndDate != null && lastFlightEndDate < new Date(startCalendarDate);

                var creativeFormValid = (bannersValid && adGroup.channelId == '1')
                    || (videoValid && adGroup.channelId == '2')
                    || (facebookValid && campaign.channelId == '3')
                    || (audioValid && adGroup.channelId == '5')
                    || (emailValid && campaign.channelId == '6');

                adGroupFormValid = adGroupFormValid && !adGroup.duplicateName;

                if (adGroup.channelId !== 9 && (statusNotDraft || isLaunch)) {
                    adGroupFormValid = adGroupFormValid && (adGroup.channelId == 6 && !campaign.isMultiChannel || checkDates(adGroup, campaign)) && !adGroup.tooManyCategoriesSelected;
                }

                if (adGroup.channelId == 6) {
                    adGroupFormValid = adGroupFormValid && emailValid;
                }

                if (adGroup.channelId == 16 && adGroup.endemicFormatId !== 0) {
                    adGroupFormValid = adGroupFormValid && adGroup.creatives.length && adGroup.creatives.find(c => !c.thirdPartyServed);
                }

                if (adGroup.dynamicSequentialMessaging && (!adGroup.sourceAdGroups || adGroup.sourceAdGroups.length == 0 || adGroup.sourceAdGroupsInvalid))
                    adGroupFormValid = false;

                const result = adGroupFormValid && adGroup.budgetCap != null;

                return result;
            },

            validateCreative: function (creative) {
                var bannersValid = (creative.bannerURL) && !creative.thirdPartyServed || creative.thirdPartyServed;
                bannersValid = bannersValid && creative.height && creative.width;

                var videoValid = (creative && creative.videoURL && !creative.thirdPartyServed) || (creative && creative.thirdPartyServed && (creative.nonSecureVASTDocumentURL || creative.secureVASTDocumentURL));

                var facebookValid = creative && (creative.bannerURL && service.validateCreativeSlides(creative).valid || videoValid);

                var emailValid = creative && creative.emailFiles && creative.emailFiles['html'] && creative.emailFiles['html'].length > 0;

                var audioValid = true;

                var creativeFormValid = (bannersValid && creative.channelId == '1') || (videoValid && (creative.channelId == '2' || creative.channelId == '4')) || (audioValid && creative.channelId == '5')  || (facebookValid && creative.channelId == '3') || (emailValid && creative.channelId == '6');

                if ((creative.secureVASTValid !== undefined && creative.secureVASTValid === false) || (creative.nonSecureVASTValid !== undefined && creative.nonSecureVASTValid === false))
                    creativeFormValid = false;

                if (!creative.thirdPartyServed && creative.creativeTypeId != 2 && creative.channelId != 6 && creative.channelId != 5 && !isURL(creative.destinationURL))
                    creativeFormValid = false;

                if (creative.provisionalAuditData)
                {
                    var anyCheckedSensitiveCategory = _.some(creative.provisionalAuditData.sensitiveCategories, { checked: true });
                    if (creative.sensitiveCategoriesApply && !anyCheckedSensitiveCategory)
                        creativeFormValid = false;
                }

                creative.impressionTrackers.forEach((item, index) => {
                    for(let x = index + 1; x < creative.impressionTrackers.length; x++){
                        if(item.impressionTracker == creative.impressionTrackers[x].impressionTracker){
                            creativeFormValid = false;
                        }
                    }
                });

                if(creative.name.length > 285) {
                    creativeFormValid = false;
                }

                return creativeFormValid && !creative.duplicateName;
            },

            validateCreativeSlides: function (creative) {
                var validationSummary = { errorMessage: '', valid: true, invalidItems: [] };
                if (creative.channelId != 3 || creative.creativeTypeId != 2)
                    return validationSummary;

                creative.creativeSlides.forEach(function (cs) {
                    if (creative.creativeTypeId == 2) {
                        if (!cs.clickURL || !cs.imageURL) {
                            var index = creative.creativeSlides.indexOf(cs) + 1;
                            validationSummary.invalidItems.push('#' + index);
                        }
                    }
                    else if (creative.creativeTypeId == 3) {
                        if (!cs.clickURL || !cs.imageURL || !cs.headline || !cs.description) {
                            var index = creative.creativeSlides.indexOf(cs) + 1;
                            validationSummary.invalidItems.push('#' + index);
                        }
                    }
                });
                if (validationSummary.invalidItems.length > 0) {
                    var itemsName = creative.creativeTypeId == 2 ? 'cards' : 'products';
                    validationSummary.errorMessage = 'Invalid ' + itemsName + ': ' + validationSummary.invalidItems.join(', ') + '.';
                    validationSummary.valid = false;
                }
                return validationSummary;
            },

            validateAudience: function () {
                return true;
            },

            validateDeal: function () {
                return true;
            }
        };

        function checkDates(adGroup) {
            var minDate = new Date();
            minDate.setDate(minDate.getDate() - 1);
            var startDate = new Date(adGroup.startDate);
            var endDate = adGroup.endDate && adGroup.endDate.valueOf(Date) ? new Date(adGroup.endDate) : null;

            var datesValid = !endDate || startDate <= endDate;

            if (adGroup.adGroupStatusId == 6 || !adGroup.campaignId)
                datesValid = datesValid && startDate >= minDate;

            return datesValid;
        }

        function isURL(str) {
            if (!str)
                return false;

            var pattern = new RegExp("([a-z]+\:\/+)([^\/\s]*)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#]*)#?([^ \#]*)");
            return pattern.test(str);
        }

        return service;
    }
})();
