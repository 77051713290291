(function () {
    'use strict';
    /**
     * @ngdoc function
     * @name app.directive:authorize
     * @authorize directive
     * # authorize
     * shows/hides ui control depending on available actions to user
     */
    angular.module('app').directive('authorize', [
            'authService',
            function (authService) {
                return {
                    restrict: 'A',
                    link: function (scope, element, attrs) {
                        var makeVisible = function () {
                            element.removeClass('hidden');
                        },
                            makeHidden = function () {
                                element.addClass('hidden');
                            },
                            determineVisibility = function (resetFirst) {
                                var result;
                                if (element.hasClass('hidden'))
                                    return;
                                if (resetFirst) {
                                    makeVisible();
                                }




                                result = authService.hasAccess(resource, 'List');
                                if (result) {
                                    makeVisible();
                                } else {
                                    makeHidden();
                                }
                            },

                           resource = attrs.authorize;


                        if (attrs.authorize != "" && resource.length > 0) {
                            determineVisibility(true);
                        }
                    }
                };
            }]);

})();
