(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:copy
 * @copy directive
 * # <copy  />
 * shows data range dropdown list (sample)
 */

    angular.module('app')
      .directive('copy', function () {
          return {
                template: '<div class="copy-url" ng-show="vm.getValue()" ng-click="vm.copyClipboard()">{{vm.stateText}}</div>',
                restrict: 'E',
                scope: { model: '@ngModel', id: '@' },
                controller: ['$scope', 'copyService', function ($scope, copyService) {
                    var vm = this;
                    var COPY_STATE_TEXT = 'Copy';
                    var COPIED_STATE_TEXT = 'Copied!';

                    vm.model = $scope.model;
                    vm.id = $scope.id;
                    vm.stateText = COPY_STATE_TEXT;
                    vm.copiedValue = null;

                    function getValue() {
                        return $scope.$parent.$eval(vm.model);
                    }

                    vm.getValue = getValue;
                   
                    vm.copyClipboard = function() {
                        vm.copiedValue = copyService.copy(getValue());
                        vm.stateText = COPIED_STATE_TEXT;
                    }

                    $scope.$parent.$watch(vm.model, function(){
                        var value = getValue();
                        vm.stateText = value == vm.copiedValue ? COPIED_STATE_TEXT : COPY_STATE_TEXT;
                    });

                    return vm;
                }]
                ,
                replace: true,
                controllerAs: 'vm'
          }
      });
})();