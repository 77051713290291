
(function () {
    'use strict';



    angular.module('app')
        .controller('summaryReportController', ['$routeParams', '$timeout', '$scope', '$rootScope', '$filter', 'hub', 'api', 'authService', 'campaignService', 'timezoneService', 'columnsService', 'customReportService', 'dialogService', '$location', 'campaignSummaryReportService', 'mtaDateRangeService', 'userSettingsService', 'ntbDateRangeService', 'featureService', '$window', function ($routeParams, $timeout, $scope, $rootScope, $filter, hub, api, authService, campaignService, timezoneService, columnsService, customReportService, dialogService, $location, campaignSummaryReportService, mtaDateRangeService, userSettingsService, ntbDateRangeService, featureService, $window) {
            var log = hub.getLog('summaryReportController');
            var CAMPAIGN_SUMMARY_REPORT_TYPE = 3;
            var NEW_TO_ADVERTISER_REPORT_TYPE = 4;
            var SALES_CHANNEL_REPORT_TYPE = 5;
            var vm = this;
            vm.log = log;
            vm.warn = hub.getLog('summaryReportController', 'warn');
            var init;
            vm.hasAccess = authService.hasAccess;
            hub.page.setTitle('Campaign Performance Summary');
            vm.page = hub.page;
            vm.page.setSidebarMode('AdvertisersWithCampaigns');
            vm.config = hub.config;
            vm.report = null;
            vm.activeAdGroup = null;
            vm.activeCreativeIndex = 0;
            vm.isLoading = false;
            vm.activeStatistics = null;
            vm.creativeBannerURL = null;

            vm.naValue = 'N/A';
            vm.creative = null;
            vm.campaignSummaryReportSetting = campaignSummaryReportService.getCampaignSummaryReportSettings($location.path());
            vm.reportTypeId = vm.campaignSummaryReportSetting.reportTypeId;
            vm.userSettingsService = userSettingsService;
            vm.customReportService = customReportService;
            vm.userSettingsService = userSettingsService;
            vm.sizes = campaignService.getSizes();
            vm.sort = { creatives: { column: 'creativeId', sortDesc: true }, adGroups: {column: 'id', sortDesc: true } };

            //report info
            vm.info = { name: '', id: 0, campaignID: 0, adGroupID: 0, dateRange: null, creativeIndex: 0 };

            vm.getEasternTimeZone = timezoneService.getEasternTimeZone;

            var FACEBOOK_COLUMNS = ['IMP', 'CLC', 'CTR', 'LCLCF', 'LCTRF', 'SPEND', 'ACPM'];
            var ADGROUP_TABLE_COLUMNS_ONLINE = ['IMP', 'UNQM', 'AVEF', 'CLC', 'CTR', 'BPV', 'VR', 'OPA', 'FPA', 'TPA',  'TP', 'SPEND', 'ROAS',  'ACPM'];
            var ADGROUP_TABLE_COLUMNS_INCREMENTAL = ['IMP', 'UNQM', 'AVEF', 'CLC', 'CTR', 'BPV', 'VR', 'SPEND', 'TP',  'TPA',  'ROAS', 'INS', 'IROAS', 'ACPM'];
            var SALES_REPORT_COLUMNS = ['OP', 'OPA', 'FP', 'FPA', 'TP'];
            var ADGROUP_TABLE_COLUMNS_NEW_TO_ADVERTISER = ['IMP', 'UNQM', 'AVEF', 'CSTN', 'CSTE', 'CST', 'TPAN', 'TPAE', 'TPA', 'CPNC', 'SPEND', 'ROAS', 'ACPM'];
            var ADGROUP_TABLE_COLUMNS_SALES_CHANNEL = ['IMP', 'UNQM', 'AVEF', 'CSTDTC', 'CSTWS', 'CST', 'TPADTC', 'TPAWS', 'TPA', 'SPEND', 'ROAS', 'ACPM'];

            vm.columns = columnsService.getUserColumns(true);
            vm.showIncremental = false;

            function setCustomersSalesChartsOptions() {
                vm.customersChartOptions = campaignSummaryReportService.getPieChartDefaults();
                vm.customersChartOptions.titleField = "customerType";
                vm.customersChartOptions.valueField = "value";
                if (vm.reportTypeId == NEW_TO_ADVERTISER_REPORT_TYPE) {
                    vm.customersChartData = campaignSummaryReportService.newVsExistingCustomerData;
                    vm.customersChartData[0].value = vm.activeStatistics.newCustomers;
                    vm.customersChartData[1].value = vm.activeStatistics.existingCustomers;
                }
                else if (vm.reportTypeId == SALES_CHANNEL_REPORT_TYPE) {
                    vm.customersChartData = campaignSummaryReportService.dtcVsWholesaleCustomerData;
                    vm.customersChartData[0].value = 0;
                    vm.customersChartData[1].value = vm.activeStatistics.customers;
                }

                vm.salesChartOptions = campaignSummaryReportService.getPieChartDefaults();
                vm.salesChartOptions.titleField = "saleType";
                vm.salesChartOptions.valueField = "value";
                if (vm.reportTypeId == NEW_TO_ADVERTISER_REPORT_TYPE) {
                    vm.salesChartData = campaignSummaryReportService.newVsExistingSalesData;
                    vm.salesChartData[0].value = vm.activeStatistics.newSales;
                    vm.salesChartData[1].value = vm.activeStatistics.existingSales;
                }
                else if (vm.reportTypeId == SALES_CHANNEL_REPORT_TYPE) {
                    vm.salesChartData = campaignSummaryReportService.dtcVsWholesaleSalesData;
                    vm.salesChartData[0].value = 0;
                    vm.salesChartData[1].value = vm.activeStatistics.totalPurchaseAmount;
                }
            }

            vm.formatSegmentName = function (seg) {
                var name = seg.name.replace(/\|>/g, ' > ');
                if (name.length > 45 && $location.$$absUrl.indexOf('print.html') > 0)
                    return name.substr(0, 45) + '...';
                else
                    return name;
            };

            vm.getActiveName = function () {
                if (vm.activeAdGroup !== null && vm.isAdGroupView)
                    return vm.activeAdGroup.name;
                else if (vm.report.campaign !== null)
                    return vm.report.campaign.name;
                return vm.naValue;

            };

            var facebookAdGroupTableColumns = [];
            var adGroupTableColumns = [];
            var salesReportColumns = [];

            function setupAgColumns() {
                adGroupTableColumns = [];
                var COLS = vm.showIncremental ? ADGROUP_TABLE_COLUMNS_INCREMENTAL : ADGROUP_TABLE_COLUMNS_ONLINE;

                COLS.forEach(function (key) {
                    if (vm.columns[key]) {
                            adGroupTableColumns.push(vm.columns[key]);
                    }

                });
            }

            function setupNewToAdvertiserAgColumns() {
                adGroupTableColumns = [];

                ADGROUP_TABLE_COLUMNS_NEW_TO_ADVERTISER.forEach(function (key) {
                    if (vm.columns[key]) {
                        adGroupTableColumns.push(vm.columns[key]);
                    }

                });
            }

            function setupSalesChannelAgColumns() {
                adGroupTableColumns = [];

                ADGROUP_TABLE_COLUMNS_SALES_CHANNEL.forEach(function (key) {
                    if (vm.columns[key]) {
                        adGroupTableColumns.push(vm.columns[key]);
                    }

                });
            }

            FACEBOOK_COLUMNS.forEach(function (key) {
                if (vm.columns[key]) {
                    facebookAdGroupTableColumns.push(vm.columns[key]);
                }
            });

            SALES_REPORT_COLUMNS.forEach(function (key) {
                if (vm.columns[key])
                    salesReportColumns.push(vm.columns[key]);
            });

            vm.salesReportColumns = salesReportColumns;

            vm.dateRange = { day: 'Lifetime', key: 'AllTime' };
            vm.dateRanges = [

                { day: 'This Week', key: 'ThisWeek' },
                { day: 'Last Week', key: 'LastWeek' },
                { day: 'This Month', key: 'ThisMonth' },
                { day: 'Last Month', key: 'LastMonth' },
                { day: 'This Quarter', key: 'ThisQuarter' },
                { day: 'Last Quarter', key: 'LastQuarter' },
                { day: 'This Year', key: 'ThisYear' },
                { day: 'Last Year', key: 'LastYear' },
                { day: 'Lifetime', key: 'AllTime' }
            ];


            if (campaignService.campaignsStatistic.campaignsForSidebar.length === 0 || campaignService.liveOnly) {
                if(!($location.$$absUrl.indexOf("print.html")> -1)){
                    campaignService.getCampaignsStatistic().then(function () {
                        $rootScope.$broadcast('campaignsLoaded', null);
                    });
                }
            };
            vm.getStatEndDate = function () {
                return new Date();

            };

            vm.executionTime = 'n/a';

            vm.changeRange = function (dateRange) {
                loadData(dateRange.key);
            };

            vm.endDate = function (date) {
                if (!date)
                    return null;

                if (date.indexOf('9999') >= 0 || date.indexOf('2037') >= 0)
                    return 'No End Date';
                else
                    return $filter('date')(date, 'mediumDate', vm.getEasternTimeZone(date));
            };

            function getNormalizedCreativeIndex (idx) {
                var index = typeof idx === "number" ? idx : parseInt(idx);
                if (isNaN(index)) {
                    index = 0;
                }
                var crts = vm.report ? vm.report.creatives: null;
                if (crts === null || crts.length === 0)
                    return null;
                if (index >= 0) {
                    return index % crts.length;
                } else {
                    return crts.length - 1;
                }
            };

            vm.setCreative = function (idx) {
                var index = getNormalizedCreativeIndex(idx);
                if (index !== null) {
                    var crts = vm.report.creatives;
                    var creative = crts[index];
                    vm.creative = creative;
                    vm.creative.size = vm.sizes.find(function (s) { return s.id === creative.bannerSize; });
                    vm.creativeBannerURL = creative.bannerURL;
                    vm.activeCreativeIndex = index;

                    vm.creative.width = parseInt(vm.creative.size.name.substring(0, creative.size.name.indexOf('x')));
                    vm.creative.height = parseInt(vm.creative.size.name.substring(creative.size.name.indexOf('x') + 1));

                    switch (vm.creative.size.id) {
                        case 1:
                            vm.creative.zoom = 0.37;
                            break;
                        case 2:
                            vm.creative.zoom = 0.9;
                            break;
                        case 3:
                            vm.creative.zoom = 0.455;
                            break;
                        case 4:
                            vm.creative.zoom = 0.85;
                            break;
                        case 5:
                            vm.creative.zoom = 0.9;
                            break;
                        case 6:
                            vm.creative.zoom = 0.85;
                            break;
                        default:
                            vm.creative.zoom = 1;
                            break;
                    }
                    vm.creative.previewWidth = vm.creative.width * vm.creative.zoom;
                    vm.creative.previewHeight = vm.creative.height * vm.creative.zoom;
                } else {
                    vm.activeCreativeIndex = 0;
                    vm.creativeBannerURL = null;
                    vm.creative = null;
                }
                vm.info.creativeIndex = vm.activeCreativeIndex;
            };

            vm.go = function (direction) {
                var idx = vm.activeCreativeIndex;
                vm.setCreative(direction ? idx + 1 : idx - 1);
            };


            vm.greaterThan = function (prop, val) {
                return function (item) {
                    return item[prop] > val;
                };
            };

            vm.canSave = function () {
                return vm.info.name && vm.info.name.trim().length > 0;
            };

            vm.metrics = [];
            vm.isAdGroupView = false;

            var storeReportInfo = function() {
                vm.originalInfo = _.cloneDeep(vm.info);
                vm.originalInfo.creativeIndex = vm.originalInfo.creativeIndex ? vm.originalInfo.creativeIndex : 0;
            };

            vm.saveReportPopup = function (info) {

                var modalOptions = {
                    templateUrl: "newReport.html",
                    animation: false,
                    controller: ['$scope', '$uibModalInstance', 'model', function ($scope, $modalInstance, model) {

                        $scope.model = model;
                        $scope.ok = function () {
                            $modalInstance.close(model);
                        };

                        $scope.cancel = function () {
                            $modalInstance.dismiss('cancel');
                        };
                        $scope.canSubmit = function () {
                            return $scope.model.name;
                        };

                    }],
                    size: 'md',
                    resolve: {
                        model: function () {
                            return { name: info ? info.name : vm.service.info.name };
                        }
                    }
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (model) {
                    if (vm.info.name !== model.name) {
                        vm.info.name = model.name;
                        vm.confirmOverwrite = true;
                        vm.idToSaveAs = 0;
                    } else {
                        vm.confirmOverwrite = true;
                    }
                    vm.__saveReport();
                }, function () {
                });
            };

            vm.__saveReport = function () {
                customReportService.getReportByName(vm.info.name).then(function (d) {
                    if (d.reportType != 0 && d.reportType != CAMPAIGN_SUMMARY_REPORT_TYPE && d.reportType != NEW_TO_ADVERTISER_REPORT_TYPE && d.reportType != SALES_CHANNEL_REPORT_TYPE) {
                        hub.getLog('Another report', 'error')('Another report with the name "' + vm.info.name + '" exists, please rename this report to save it.');
                    } else {
                        var basicAskOverWriteCondition = d && d.id && !vm.confirmOverwrite;
                        if (basicAskOverWriteCondition && (vm.info.id != d.id  || vm.info.id  && hasReportChanges())) {
                            vm.idToSaveAs = d.id;
                            vm.saveReportPopup(vm.info);
                        }
                        else {
                            if (vm.idToSaveAs)
                                vm.info.id = vm.idToSaveAs;

                            var data = getReportData();
                            authService.trackEvent({ action: 'SaveReport', view: $location.path(), data: JSON.stringify({ id: data.id, name: data.name, reportType: data.reportType }) });
                            hub.api.post('api/reports/save', data)
                                .then(function (reportData) {
                                    vm.info.id = reportData.id;
                                    vm.confirmOverwrite = false;
                                    log(data.name + ': report saved');
                                    var reportPath = '/reporting/' + vm.campaignSummaryReportSetting.reportUrl + '/' + reportData.id;
                                    $location.path(reportPath , true);
                                    storeReportInfo();
                                    if(vm.isMTA != userSettingsService.multiTouchMode){
                                        $timeout(function () {
                                            $window.location.reload();
                                        }, 1000);
                                    }
                                    console.log('report data', reportData);
                                }, function (err) {
                                    if (!err.data.logged) {
                                        hub.getLog('Custom Report', 'error')('Report Save Error. Duplicate name');
                                    }
                                });
                        }
                    }
                });
            };

            function getReportData() {
                var data = {
                    id: vm.info.id,
                    name: vm.info.name,
                    reportType: vm.reportTypeId,
                    parameters: {
                        campaignID: vm.info.campaignID,
                        adGroupID: vm.info.adGroupID,
                        creativeIndex: vm.info.creativeIndex,
                        dateRange: vm.dateRange.key,
                        sort: vm.sort
                    }
                };
                return data;
            }

            vm.save = function () {
                if (!vm.info.name)
                    return;
                vm.__saveReport();
            };
            const sizeWeight = (name) => {
                const dims = name.indexOf('x') > -1 ?  name.split('x') : ['0','0'];
                return parseInt(dims[0]) * 1000 + parseInt(dims[1])
            }

            vm.makeSort = (list) => {
                vm.report.statistics[list] = $filter('orderBy')(vm.report.statistics[list], vm.sort[list].column, vm.sort[list].sortDesc);
            }

            vm.sortFn = function (column, list) {
                if (vm.sort[list].column == column)
                  vm.sort[list].sortDesc = !vm.sort[list].sortDesc;
              else {
                  vm.sort[list].column = column;
                  vm.sort[list].sortDesc = false;
              }
              vm.makeSort(list);

            };

            vm.showSegments = function () {
                return vm.report.statistics.segments &&
                    vm.report.statistics.segments.find(function (s) { return s.sales > 0 || s.dtcSales > 0 || s.wholesaleSales > 0; });
            };

            vm.isProductLoading = true;
            vm.export = function () {
                var info = vm.info;
                var dirty = hasReportChanges();
                var report = {
                    id: dirty ? 0 : info.id,
                    name: !info.id ? null : info.name,
                    reportType: vm.reportTypeId,
                    originalId: info.id || 0,
                    parameters: {
                        dateRange: info.dateRange,
                        campaignID: info.campaignID,
                        adGroupID: info.adGroupID,
                        creativeIndex: vm.activeCreativeIndex || 0,
                        sort: vm.sort
                    }
                };
                hub.api.post('api/reports/scheduleExport', report)
                    .then(function (data) {
                        vm.log('Your report export has been scheduled. You will have an email with download link as soon as report is generated.');
                    });
            };

            function isCustomReport () {
                var parameter = $routeParams.id;
                var isReportId = !isNaN(parseFloat(parameter)) && isFinite(parameter);
                return isReportId;
            }

            function setDateRange (dateRange) {
                if (dateRange) {
                    var dr = vm.dateRanges.find(function (item) { return item.key.toLowerCase() === dateRange.toLowerCase(); });
                    if (dr !== null) {
                        vm.dateRange = dr;
                        vm.info.dateRange = dr.key;
                    }
                }
            }

            function hasReportChanges() {
                return JSON.stringify(vm.originalInfo) != JSON.stringify(vm.info);
            }

            vm.getReportParam = function () {
                var result = 'reportId=';
                if (vm.info.id) {
                    return result + vm.info.id;
                } else if ($routeParams.reportId) {
                    return result + $routeParams.reportId;
                }
                return '';
            };

            function getCampaignSummaryParams(dr) {
                var parameter = $routeParams.id;
                var dateRange = dr || $routeParams.range || 'AllTime';
                var creativeIndex = $routeParams.creativeIndex;
                if ($routeParams.sort) {
                    vm.sort = JSON.parse($routeParams.sort);
                }

                var result = null;
                if (parameter) {
                    var typeCode = parameter.substr(0, 1);
                    var id = parameter.substr(1);
                    var isAdGroupView = typeCode === 'A';
                    result = {
                        creativeIndex: creativeIndex,
                        isAdGroupView: isAdGroupView,
                        name: '',
                        idParam: id,
                        dateRange: dateRange,
                        adGroupID: isAdGroupView ? id : 0,
                        campaignID: isAdGroupView ? 0 : id
                    };
                }
                return new Promise(function(resolve, reject){
                    resolve(result);
                });
            }

            function getCustomReportParams(dateRange){
                var parameter = $routeParams.id;
                vm.isLoading = true;
                return api.get('api/reports/customreport/' + parameter)
                    .then(function (result) {
                        var isAdGroupView = result.parameters.adGroupID > 0;

                        vm.sort = result.parameters.sort || vm.sort;

                        return {
                            isAdGroupView: isAdGroupView,
                            idParam: isAdGroupView ? result.parameters.adGroupID : result.parameters.campaignID,
                            id: result.id,
                            name: result.name,
                            creativeIndex: result.parameters.creativeIndex,
                            campaignID: result.parameters.campaignID,
                            adGroupID: result.parameters.adGroupID,
                            dateRange: dateRange || result.parameters.dateRange,
                            reportMta : result.reportMta
                        };

                });
            }

            function getApiParams(dateRange) {
                if(isCustomReport()) {
                    return getCustomReportParams(dateRange);
                } else {
                    return getCampaignSummaryParams(dateRange);
                }
            }


            function loadData(dateRange, initial) {
                getApiParams(dateRange).then(function(apiParams){
                    if(apiParams != null) {
                        var idParam = apiParams.idParam;
                        var isAdGroupView = apiParams.isAdGroupView;
                        var creativeIndex = apiParams.creativeIndex;
                        var dateRange = apiParams.dateRange;
                        vm.info = apiParams;
                        vm.isAdGroupView = isAdGroupView;
                        setDateRange(dateRange);
                        if (initial) {
                            storeReportInfo();
                        }
                        var urlSuffix = (isAdGroupView ? 'adgroup/' : 'campaign/') + idParam + '/' + dateRange;
                        urlSuffix = urlSuffix + '/' + vm.reportTypeId;
                        loadCampaignSummary(urlSuffix, creativeIndex);
                    } else {
                        //reset report settings
                    }
                });
            }

            function calculateMetrics(entity) {
                if (entity.totalPurchaseAmount && (entity.newSales || entity.newSales == 0))
                    entity.existingSales = entity.totalPurchaseAmount - entity.newSales;
                if (entity.existingCustomers && (entity.newCustomers || entity.newCustomers == 0))
                    entity.customers = entity.existingCustomers + entity.newCustomers;
                entity.costPerNewCust = entity.newCustomers ? entity.spend / entity.newCustomers : 0;

                if (vm.reportTypeId === SALES_CHANNEL_REPORT_TYPE) {
                    entity.dtcCustomers = 0;
                    entity.wholesaleCustomers = entity.customers;
                    entity.dtcTotalPurchaseAmount = 0;
                    entity.wholesaleTotalPurchaseAmount = entity.totalPurchaseAmount;

                }
            }

            function isFacebook () {
                if (!vm.report || !vm.report.campaign) {
                    return false;
                }
                const { campaign, adGroups } = vm.report;
                return campaign.channelId == 3 || adGroups.find(a => a.channelId == 3) != null;
            }

            function hasCreativePerformance() {
                if (isFacebook()) {
                    const { statistics } = vm.report;
                    return statistics.creatives != null && statistics.creatives.length > 0;
                }
                return true;
            }

            function loadCampaignSummary(urlSuffix, creativeIndex) {
                if(urlSuffix){
                    vm.isLoading = true;
                    api.get('api/reports/campaignsummary/' + urlSuffix + '?'+ vm.getReportParam() )
                    .then(function (result) {
                        vm.isLoading = false;
                        vm.report = result.header;
                        vm.isFacebook = result.header.campaign.channelId == 3;
                        if(vm.isFacebook) {
                            vm.report.statistics.combined = vm.isAdGroupView ? result.header.statistics.adGroups : [result.header.statistics.campaign];
                        }

                        vm.showIncremental = result.header.campaign.showIncremental;
                        vm.isMTA = result.isMTA;
                        if (vm.reportTypeId === NEW_TO_ADVERTISER_REPORT_TYPE)
                            setupNewToAdvertiserAgColumns();
                        else if (vm.reportTypeId === SALES_CHANNEL_REPORT_TYPE)
                            setupSalesChannelAgColumns();
                        else
                            setupAgColumns();

                        vm.adGroupTableColumns =  vm.isFacebook ? facebookAdGroupTableColumns : adGroupTableColumns;

                        if (vm.campaignSummaryReportSetting) {
                            vm.metricsSections = vm.isFacebook ? vm.campaignSummaryReportSetting.fbMetrics : (vm.showIncremental ? vm.campaignSummaryReportSetting.nonFbIncMetrics : vm.campaignSummaryReportSetting.nonFbMetrics);
                            const campaignFilter = (x) => x.field == 'channel' ;
                            const adgroupFilter = (x) => x.field == "adGroups";
                            const entityFilter = vm.isAdGroupView? adgroupFilter: campaignFilter;
                            const newSections = [];
                            const excludeFilter = (x) => !entityFilter(x);
                            for(let section of vm.metricsSections){
                                if(section.findIndex(entityFilter) == -1) {
                                    newSections.push(section);
                                } else {
                                    newSections.push(section.filter(excludeFilter))
                                }
                            }
                            vm.metricsSections = newSections;
                            var tabs = vm.isFacebook ? vm.campaignSummaryReportSetting.fbTabs : vm.campaignSummaryReportSetting.nonFbTabs;
                            tabs = _.filter(tabs, function (t) {
                                return (!t.feature || featureService.isActive(t.feature)) && (
                                    ((t.key == 'creatveDetails' && hasCreativePerformance() || t.key != 'creatveDetails'))
                                );
                            });
                            vm.tabs = tabs;

                        }


                        vm.executionTime = result.executionTimeMs;

                        if (vm.report && vm.report.adGroups !== null && vm.report.adGroups.length > 0) {
                            vm.activeAdGroup = result.header.adGroups[0];

                            if (vm.report.statistics && vm.report.statistics.campaign)
                                calculateMetrics(vm.report.statistics.campaign);

                            if (vm.report.statistics && vm.report.statistics.adGroups)
                                vm.report.statistics.adGroups.forEach(function (ag) { calculateMetrics(ag); });

                            if (vm.report.statistics && vm.report.statistics.metrics) {

                                vm.report.statistics.metrics.forEach(function (it) {
                                    it.roas = $filter('number')(it.roas, '1');
                                    it.averageFrequency = $filter('number')(it.averageFrequency, '2');
                                    it.date = vm.page.fixDate(it.date);
                                });

                                if (vm.report.statistics.products)
                                    vm.report.statistics.averageItemPrice = vm.page.divideTotals(vm.report.statistics.products, 'totalPurchaseAmount', 'totalPurchases');

                                vm.metrics = $filter('orderBy')(vm.report.statistics.metrics, 'date', false);

                            }



                            var activeAdGroupStatistics;
                            if (vm.report.statistics && vm.report.statistics.adGroups !== null && vm.report.statistics.adGroups.length) {
                                activeAdGroupStatistics = vm.report.statistics.adGroups.find(function (item) { return item.adGroupId === vm.activeAdGroup.id; });
                                vm.makeSort('adGroups');
                            }
                            //statistics - adGroup or campaign
                            vm.activeStatistics = vm.isAdGroupView ? activeAdGroupStatistics : vm.report.statistics.campaign;

                            if (vm.metrics[vm.metrics.length - 1].cumulativeAdvertiserPageVisits !== vm.activeStatistics.advertiserPageVisits)
                                vm.activeStatistics.advertiserPageVisits = vm.metrics[vm.metrics.length - 1].cumulativeAdvertiserPageVisits;


                            if (vm.report.creatives !== null && vm.report.creatives.length > 0) {
                                const creatives = vm.report.creatives.map(c => {
                                    const {adGroupId,...creative} = c;
                                    const size = vm.sizes.find(s => c.bannerSize == s.id);
                                    return {...creative, size, sizeWeight: sizeWeight(size.name)};
                                });
                                vm.report.creatives = _.unionBy(creatives, 'id');
                            }
                            if(vm.report.statistics && vm.report.statistics.creatives !== null) {
                                vm.report.statistics.creatives = vm.report.statistics.creatives.map(cs => {
                                    const creative = vm.report.creatives.find(c => c.id == cs.creativeId);
                                    return {
                                        ...cs,
                                        size: creative && creative.size,
                                        sizeWeight: creative && sizeWeight(creative.size.name)
                                    };
                                });
                                vm.makeSort('creatives');
                            }
                            vm.setCreative(creativeIndex || vm.activeCreativeIndex);

                            vm.metricsData = {};
                            if (vm.reportTypeId === NEW_TO_ADVERTISER_REPORT_TYPE || vm.reportTypeId === SALES_CHANNEL_REPORT_TYPE) {
                                vm.metricsData.channel = vm.activeStatistics.chan;
                                vm.metricsData.newSales = vm.page.formatValue(vm.activeStatistics.newSales, '$0', vm.naValue);
                                vm.metricsData.spend = vm.page.formatValue(vm.activeStatistics.spend, '$0', vm.naValue);
                                vm.metricsData.newCustomers = vm.page.formatValue(vm.activeStatistics.newCustomers, '0', vm.naValue);
                                vm.metricsData.existingSales = vm.page.formatValue(vm.activeStatistics.existingSales, '$0', vm.naValue);
                                vm.metricsData.costPerNewCust = vm.page.formatValue(vm.activeStatistics.costPerNewCust, '$0.00', vm.naValue);
                                vm.metricsData.existingCustomers = vm.page.formatValue(vm.activeStatistics.existingCustomers, '0', vm.naValue);
                                vm.metricsData.sales = vm.page.formatValue(vm.activeStatistics.totalPurchaseAmount, '$0', vm.naValue);
                                vm.metricsData.roas = vm.page.formatValue(vm.activeStatistics.roas, '0.0', vm.naValue);
                                vm.deliveryChartOptions = campaignSummaryReportService.getDeliveryChartOptions();
                                setCustomersSalesChartsOptions();
                                if (vm.reportTypeId === SALES_CHANNEL_REPORT_TYPE) {
                                    vm.metricsData.channel = vm.activeStatistics.chan;
                                    vm.metricsData.spend = vm.page.formatValue(vm.activeStatistics.spend, '$0', vm.naValue);
                                    vm.metricsData.roas = vm.page.formatValue(vm.activeStatistics.roas, '0.0', vm.naValue);
                                    vm.metricsData.wholesaleSalesOffline = vm.page.formatValue(vm.activeStatistics.offlinePurchaseAmount, '$0', vm.naValue);
                                    vm.metricsData.wholesaleSalesOnline = vm.page.formatValue(vm.activeStatistics.onlinePurchaseAmount, '$0', vm.naValue);
                                    vm.metricsData.dtcSalesOffline = vm.page.formatValue(0, '$0', vm.naValue);
                                    vm.metricsData.dtcSalesOnline = vm.page.formatValue(0, '$0', vm.naValue);
                                    vm.metricsData.sales = vm.page.formatValue(vm.activeStatistics.totalPurchaseAmount, '$0', vm.naValue);
                                    vm.metricsData.existingSales = vm.page.formatValue(vm.activeStatistics.existingSales, '$0', vm.naValue);
                                    vm.metricsData.wholesaleCustomers = vm.page.formatValue(vm.activeStatistics.customers, '0', vm.naValue);
                                    vm.metricsData.dtcCustomers = vm.page.formatValue(0, '0', vm.naValue);

                                    vm.deliveryChartOptions = campaignSummaryReportService.getDeliveryChartOptionsDTC();
                                    setCustomersSalesChartsOptions();
                                }
                            }
                            else if (vm.reportTypeId === CAMPAIGN_SUMMARY_REPORT_TYPE) {
                                vm.metricsData.channel = vm.report.campaign.chan;
                                vm.metricsData.adGroups = vm.report.adGroups.length;
                                vm.metricsData.ctr = vm.page.formatValue(vm.activeStatistics.ctr, '%', vm.naValue);
                                vm.metricsData.incrementalSales = vm.page.formatValue(vm.activeStatistics.incrementalSales, '$0', vm.naValue);
                                vm.metricsData.onlineSales = vm.page.formatValue(vm.activeStatistics.onlinePurchaseAmount, '$0', vm.naValue);
                                vm.metricsData.spend = vm.isAdGroupView ? vm.page.formatValue(vm.activeStatistics.spend, '$0', vm.naValue) : vm.page.formatValue(vm.report.statistics.campaign.spend, '$0', vm.naValue);
                                vm.metricsData.impressions = vm.page.formatValue(vm.activeStatistics.impressions, '0', vm.naValue);
                                vm.metricsData.uniqueMessaged = vm.page.formatValue(vm.activeStatistics.uniqueMessaged, '0', vm.naValue);
                                vm.metricsData.viewability = vm.page.formatValue(vm.activeStatistics.viewability, '%', vm.naValue);
                                vm.metricsData.averageCPM = vm.page.formatValue(vm.activeStatistics.averageCPM,  '$0.00', vm.naValue);
                                vm.metricsData.averageFrequency = vm.page.formatValue(vm.activeStatistics.averageFrequency,  '0.0', vm.naValue);
                                vm.metricsData.clicks = vm.page.formatValue(vm.activeStatistics.clicks, '0', vm.naValue);
                                vm.metricsData.acc = vm.page.formatValue(vm.activeStatistics.acc, 'fixed:15', vm.naValue);
                                vm.metricsData.camp = vm.page.formatValue(vm.activeStatistics.camp, 'fixed:15', vm.naValue);
                                vm.metricsData.ret = vm.page.formatValue(vm.activeStatistics.ret, 'fixed:15', vm.naValue);

                                vm.metricsData.advertiserPageVisits = vm.page.formatValue(vm.activeStatistics.advertiserPageVisits, '0', vm.naValue);
                                vm.metricsData.offlineSales = vm.page.formatValue(vm.activeStatistics.offlinePurchaseAmount, '$0', vm.naValue);
                                vm.metricsData.itemSold = vm.isMTA ?
                                    vm.page.formatValue(vm.activeStatistics.totalPurchases, '0.000', vm.naValue):
                                    vm.page.formatValue(vm.activeStatistics.totalPurchases, '0', vm.naValue);

                                vm.metricsData.visitRate = vm.page.formatValue(vm.activeStatistics.visitRate, '%', vm.naValue);
                                vm.metricsData.sales = vm.isAdGroupView ? vm.page.formatValue(vm.activeStatistics.totalPurchaseAmount, '$0', vm.naValue) : vm.page.formatValue(vm.report.statistics.campaign.totalPurchaseAmount, '$0', vm.naValue);
                                vm.metricsData.roas = vm.page.formatValue(vm.activeStatistics.roas, '0.0', vm.naValue);
                                vm.metricsData.incrementalROAS = vm.page.formatValue(vm.activeStatistics.incrementalROAS, '0.0', vm.naValue);

                                vm.visitChartOptions = campaignSummaryReportService.getVisitChartOptions();
                                vm.reachChartOptions = campaignSummaryReportService.getReachChartOptions();
                            }
                        }
                    }, function (error) {
                        vm.isLoading = false;
                        hub.getLog('summaryReportController', 'error')('Unable to load campaign summary report');
                    });
                }
            }

            loadData(null, true);

            $scope.$watch('vm.info.dateRange', function(dateRange){
                if(dateRange && dateRange != 'AllTime') {
                    if(vm.isMTA){
                        mtaDateRangeService.dateRangeChanged(vm.info.dateRange, vm.isMTA);
                    }

                    if(vm.reportTypeId == NEW_TO_ADVERTISER_REPORT_TYPE) {
                        ntbDateRangeService.dateRangeChanged(vm.info.dateRange);
                    }
                }
            });

            $scope.$watch('vm.report.campaignDates', function(dates) {
                if(vm.info.dateRange == 'AllTime') {
                    if(dates && dates.startDate && dates.endDate) {
                        if(vm.isMTA) {
                            mtaDateRangeService.customRangeChanged({ start: moment(dates.startDate), end: moment(dates.endDate) }, vm.isMTA);
                        }
                        if(vm.reportTypeId == NEW_TO_ADVERTISER_REPORT_TYPE) {
                            ntbDateRangeService.customRangeChanged({ start: moment(dates.startDate), end: moment(dates.endDate) });
                        }
                    }
                }
            })

            return vm;
        }]);

})();


