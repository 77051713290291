(function () {
    'use strict';
    /**
     * @ngdoc function
     * @name app.directive:thousand
     * @thousand directive
     * # thousand
     * formats value with ,
     */
    angular.module('app').directive('thousand', [
            '$filter', '$timeout', '$compile',
            function ($filter, $timeout, $compile) {
                return {
                    restrict: 'A',
                    scope: { model: '=ngModel'},
                    controller: ['$scope', '$rootScope', function () {
                        var vm = this;
                        return vm;
                    }],
                    replace: true,
                    require: 'ngModel',
                    link: function($scope, elem, attr, ctr, ngModel){
                        var moneyRegexp =  /^\d{1,3}(?:,?\d{3})*(\.\d{0,2})?$/;
                        var decimalRegExp = /^(\-)?\d+(\.\d{0,2})?$/;
                        var input = $(elem);
                        var wrapper = $('<span></span>');
                        if(input.hasClass('form-control')) {
                            wrapper.addClass('form-control');
                        }
                        input.parent().append(wrapper);
                        input.hide();

                        var isInteger = attr.thousand == 'integer';
                        // if invalid value is input - no value refreshed with <span>{{model | number}}</span>
                        // that is why jQuery way is used

                        function updateValue(value){
                            var newValue = value;
                            if(moneyRegexp.test(value) || decimalRegExp.test(value)) {
                                var tmp = value.toString().replace(/,/g, "");
                                newValue =  $filter('number')(tmp, isInteger ? 0 : 2);
                            }
                            wrapper.html(newValue);
                        }

                        wrapper.on('click', function(){
                            wrapper.hide();
                            input.show();
                            input.focus();
                        });
                        input.on('blur', function(ev){
                            var input = $(ev.currentTarget);
                            var value  = input.val();
                            updateValue(value);
                            input.hide();
                            wrapper.show();
                        });
                        updateValue($scope.model);
                        $scope.$parent.$watch(attr.ngModel, function(newValue, oldValue){
                            if(newValue != oldValue) {
                                updateValue(newValue);
                            }
                        });
                        $compile(wrapper)($scope);
                    },
                    controllerAs: 'vm'
                };
            }]);

})();
