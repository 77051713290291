(function () {
    'use strict';

    angular.module('app')
        .controller('zoomCreativeController', ZoomCreativeController);
    ZoomCreativeController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'data', 'hub', 'validationService', 'authService', '$timeout', '$route', '$location', 'creativeService', 'campaignService', 'appSettings'];

    function ZoomCreativeController($scope, $rootScope, $modalInstance, $sce, data, hub, validationService, authService, $timeout, $route, $location, creativeService, campaignService, appSettings) {

        var vm = this;

        vm.callToActions = creativeService.getCallToActions();
        vm.cancel = cancel;
        vm.changeAdFormat = changeAdFormat;
        vm.copyCreative = copyCreative;
        vm.creative = data.creative;
        vm.canSelect = data.canSelect;
        vm.creativeChecked = creativeChecked;
        vm.creativeCheckedMode = data.creativeChecked != null;
        document.addEventListener("keydown", keyDownTextField, false);
        vm.editCreative = data.editCreative;
        vm.facebookAdFormats = data.facebookAdFormats;
        vm.facebookAdFormat = vm.facebookAdFormats ? vm.facebookAdFormats[0] : '';
        vm.getCallToActionText = getCallToActionText;
        vm.hideNavigation = data.hideNavigation;
        vm.isObserver = authService.isObserver;
        vm.hasAccess = authService.hasAccess;
        vm.nextCreative = nextCreative;
        vm.previousCreative = previousCreative;
        vm.removeCreative = removeCreative;
        vm.showCreative = true;
        vm.isCreativeLibrary = $location.$$url.indexOf('/activation/creative/') > -1;
        vm.adBlocked = $rootScope.canRunAds === undefined;


        if (!vm.creative.width && vm.creative.channelId == 1) {
            vm.sizes = campaignService.getSizes();
            vm.creative.size = vm.sizes.find(function (s) { return s.id == vm.creative.bannerSize });
            vm.creative.width = vm.creative.size.name.substring(0, vm.creative.size.name.indexOf('x'));
            vm.creative.height = vm.creative.size.name.substring(vm.creative.size.name.indexOf('x') + 1);
            vm.creative.height = parseInt(vm.creative.height);
            vm.creative.width = parseInt(vm.creative.width);
        }

        vm.creative.creativePreview = $sce.trustAsResourceUrl(appSettings.apiUrl + "api/creative/zoom-preview/" + vm.creative.id);


        if ((vm.creative.channelId == 2 || vm.creative.channelId == 4 || vm.creative.channelId == 5) && vm.creative.videoOptions) {
            vm.creative.videoOptions.autostart = true;
            vm.creative.videoOptions.mute = false;
        }

        function loadVastVideo() {
            if ((vm.creative.channelId == 2 || vm.creative.channelId == 4 || vm.creative.channelId == 5) && vm.creative.thirdPartyServed && (vm.creative.secureVASTDocumentURL || vm.creative.nonSecureVASTDocumentURL)) {
                $scope.vastPlayerStatus = 'stopped';
                $timeout(function () {
                    vm.player = new VASTPlayer(document.getElementById('vastPlayer_' + vm.creative.id));                    
                    vm.player.once('AdStopped', function () {
                        $scope.vastPlayerStatus = 'unload';
                        $timeout(function () {
                            $scope.vastPlayerStatus = 'stopped';
                        });
                    });
                }, 500);
            }
        }
        loadVastVideo();

        vm.playVastVideo = function () {
            if ($scope.vastPlayerStatus === 'stopped' || $scope.vastPlayerStatus === 'pause') {
                if ($scope.vastPlayerStatus === 'stopped')
                {
                    vm.player.load(vm.creative.secureVASTDocumentURL ? vm.creative.secureVASTDocumentURL : vm.creative.nonSecureVASTDocumentURL).then(
                        function startAd() {
                            $timeout(function () {
                                vm.player.startAd();
                            });
                            $scope.vastPlayerStatus = 'play';
                        }
                    );
                    vm.player.once('AdStopped', function () {
                        $scope.vastPlayerStatus = 'unload';
                        $timeout(function () {
                            $scope.vastPlayerStatus = 'stopped';
                        });
                    });
                }
                else {
                    vm.player.resumeAd();
                    $scope.vastPlayerStatus = 'play';
                }
            }
            else {
                vm.player.pauseAd();
                $scope.vastPlayerStatus = 'pause';
            }
        }

        vm.setFbPreview = function () {
            var ad_format = vm.facebookAdFormat.value;//'DESKTOP_FEED_STANDARD';
            hub.webapicontext.getFbPreview(vm.creative.fbAdId, ad_format).then(function (data) {
                if (data) {
                    var fbPreview = data.data[0].body;
                    fbPreview = fbPreview.replace('scrolling="yes"', 'scrolling="no"');
                    fbPreview = fbPreview.replace('height="690"', 'height="500"');
                    if (vm.facebookAdFormat.value == 'MESSENGER_MOBILE_INBOX_MEDIA') {
                        fbPreview = fbPreview.replace('width="274"', 'width="350"');
                        fbPreview = fbPreview.replace('height="213"', 'height="300"');
                    }
                    vm.fbPreview = $sce.trustAsHtml(fbPreview);
                }
                else
                    vm.fbPreview = 'Preview is not available.';
            });
        }

        if (vm.creative.channelId == 3 && !vm.isCreativeLibrary)
            vm.setFbPreview();

        function cancel() {
            $modalInstance.dismiss('cancel');
        };

        function copyCreative() {
            data.copyCreative(vm.creative);
        }

        function changeAdFormat(next) {
            if (next && vm.facebookAdFormat.id < vm.facebookAdFormats.length)
                vm.facebookAdFormat = vm.facebookAdFormats[vm.facebookAdFormat.id];
            else if (!next && vm.facebookAdFormat.id > 1)
                vm.facebookAdFormat = vm.facebookAdFormats[vm.facebookAdFormat.id - 2];
        }

        function creativeChecked() {
            vm.creative.checked = !vm.creative.checked;
            data.creativeChecked(vm.creative);
        }

        function getCallToActionText(value) {
            var callToAction = vm.callToActions.find(function (item) { return item.value == value; });
            return callToAction ? callToAction.text : 'Shop Now';
        }


        function keyDownTextField(e) {
            if (vm.hideNavigation)
                return;

            var keyCode = e.keyCode;
            if (keyCode == 39) {
                nextCreative();
                $scope.$apply();
            } else if (keyCode == 37) {
                previousCreative();
                $scope.$apply();
            }
        }

        function nextCreative(isRemoved) {
            vm.showCreative = false;
            var found = false;
            data.creatives.forEach(function (item, index) {
                if (item.id == vm.creative.id && !found) {
                    found = true;
                    if (index > 0 && (index == data.creatives.length - 1)) {
                        vm.creative = data.creatives[0];
                    } else if (data.creatives.length == 1) {
                        vm.creative = null;
                    } else {
                        vm.creative = data.creatives[index + 1];
                    }

                    if (isRemoved) {
                        data.creatives.splice(index, 1);
                    }

                    if (vm.isCreativeLibrary)
                        $location.path('activation/creative/' + vm.creative.id + '/zoom', false);

                    if (vm.creative.channelId == 3 && !vm.isCreativeLibrary)
                        vm.setFbPreview();

                    $timeout(function () {
                        vm.showCreative = true;
                        runVastVideo();
                    });
                }
            });
        }

        function previousCreative() {
            vm.showCreative = false;
            var found = false;
            for (var item in data.creatives) {
                if (data.creatives[item].id == vm.creative.id && !found) {
                    found = true;
                    if (item == 0) {
                        vm.creative = data.creatives[data.creatives.length - 1];
                        break;
                    } else {
                        vm.creative = data.creatives[item - 1];
                    }
                }
            }

            if (vm.isCreativeLibrary)
                $location.path('activation/creative/' + vm.creative.id + '/zoom', false);

            if (vm.creative.channelId == 3 && !vm.isCreativeLibrary)
                vm.setFbPreview();

            $timeout(function () {
                vm.showCreative = true;
                runVastVideo();
            });
        }

        function removeCreative() {
            data.removeCreative(vm.creative);

        }

        $rootScope.$on("nextCreative", function (event, args) {
            nextCreative(args);
        });

        $scope.$on('creativeUpdated', function (event, data) {
            vm.showCreative = false;
            vm.creative = data;
            $timeout(function () {
                vm.showCreative = true;
            });
        });

        $scope.$on('modal.force.close', function (event) {
            $modalInstance.dismiss('cancel');
        });

        var original = $location.path;
        $location.path = function (path, reload) {
            if (reload === false) {
                var lastRoute = $route.current;
                var un = $rootScope.$on('$locationChangeSuccess', function () {
                    $route.current = lastRoute;
                    un();
                });
            }
            return original.apply($location, [path]);
        };

    };
})();
