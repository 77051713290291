(function () {
    'use strict';

    var serviceId = 'timezoneService';
    angular.module('app').factory(serviceId, [timezoneServiceFactory]);

    function timezoneServiceFactory() {
        const tzEST = 'America/New_York';
        var service = {
            getEasternTimeZone: function(date) {
                return moment(date).tz(tzEST).format('z');
            },
            getInfo: function() {
                var m = moment.utc();
                var currentEastTimeAbbreviation = m.tz(tzEST).format('z');
                var now = new Date();
                var offset = -5.00;
                if (currentEastTimeAbbreviation === "EDT") {
                    offset = -4.00;
                }
                var tzDifference = offset * 60 + now.getTimezoneOffset();
                return {
                    tzDifference: tzDifference,
                    offset: offset,
                    currentEastTimeAbbreviation: currentEastTimeAbbreviation
                };
            },
            parseUTCDate: function(dateString) {
                if (dateString.indexOf('T') > 0) {
                    var date = dateString.substring(0, 10).split('-');
                    var dateObj = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]));
                    return dateObj;
                } 
                return new Date();
            },
            dateInEastern: function(dateString) {
                var dt = moment(dateString).tz("UTC");
                var result = moment().year(dt.year()).month(dt.month()).date(dt.date());
                return result;
            },
            est: function(date) {
                return moment.tz(date, tzEST);
            },
            estFormat: function(date, suffix = ' (EST)') {
                var format = 'MM/DD/YYYY h:mm A ';
                return `${moment.tz(date, tzEST).format(format)}${suffix}`;
            },
            processDatepickerDateObsolete: function(date, utcDate, hour, minutes, meridiem, tzName) {
                var hoursOffset = "";
                var dateShort = "";
                var minutesOffset = 0;
                var result = {};

                if (!utcDate) {
                    minutesOffset = moment(date).tz(tzName).utcOffset();
                    dateShort = moment(date).tz(tzName).format('YYYY/MM/DD');
                    hoursOffset = moment(date).tz(tzName).format('ZZ');
                }
                else {
                    minutesOffset = moment(utcDate).tz(tzName).utcOffset();
                    utcDate.setMinutes(utcDate.getMinutes() - minutesOffset);
                    dateShort = moment(utcDate).tz(tzName).format('YYYY/MM/DD');
                    hoursOffset = moment(utcDate).tz(tzName).format('ZZ');
                }

                var dateString = dateShort + ' ' + hour + ':' + minutes + ' ' + meridiem + ' ' + hoursOffset;
                result.date = new Date(dateString);
                result.utcDate = new Date(dateString);
                result.utcDate.setMinutes(result.utcDate.getMinutes() + minutesOffset);
                return result;
            },
            processDatepickerDate: function(date, utcDate1, hour, minutes, meridiem, tzName) {                
                const dayHours = (+hour % 12) + (meridiem == "PM" ? 12 : 0)
                const { estDate, utcDate } = service.estUtc(date, dayHours, minutes);
                const result = { utcDate,  date: estDate };
                let hours =  (moment.tz(estDate, tzEST).hours() % 12) || 12;
                result.hours = `${hours < 10 ? '0':''}${hours}`;

                return result;
            },
            utcToEst: function(date, hours = 0, minutes = 0) {
                const tmp = moment.tz(date, "UTC").format();                            
                const result = moment.tz(tmp.substr(0, 10), tzEST).hours(hours).minutes(minutes).toDate();
                return result;
            },
            getNextDate: function(date) {
                const tmp = moment(date).tz(tzEST).startOf('day').add(1, 'day').toDate();
                return tmp;                
            },
            getDayEnd: function(date) {
                const tmp = moment(date).tz(tzEST).endOf('day').seconds(0).toDate();
                return tmp;
            },
            getMonthStart: function (date) {
                const tmp = moment(date).tz(tzEST).startOf('month').seconds(0).toDate();
                return tmp;
            },
            getMonthEnd: function (date, seconds) {
                const tmp = moment(date).tz(tzEST).endOf('month').seconds(seconds ? 59 : 0).toDate();
                return tmp;
            },
            getNextMonth: function (date) {
                const tmp = moment(date).tz(tzEST).startOf('month').add(1, 'month').toDate();
                return tmp;
            },
            getNextRange: function(date) {
                const startDate = moment(date).tz(tzEST).startOf('day').add(1, 'day').toDate();
                const endDate = this.getDayEnd(startDate);
                return {
                    startDate,
                    endDate
                }
            },
            estUtc: function(date, hours = 0, minutes= 0) {
                const estDate = moment(date).tz(tzEST).hours(+hours).minutes(+minutes).toDate();
                const minutesOffset = moment(estDate).tz(tzEST).utcOffset();
                const utcDate = moment(estDate).tz(tzEST).add(minutesOffset, 'minute').toDate();
                return { utcDate, estDate };   
            },
            asEst: function(dateString) {
                const dt = dateString.substr(0,dateString.length-1);
                return this.estFormat(dt);
            },
            asEstToNow: function(dateString) {
                const dt = dateString.substr(0,dateString.length-1);
                return moment.tz(dt, tzEST).toNow(true) + ' ago ';
            }
        };

        return service;
    }
})();