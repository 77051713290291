/**
 * Created by naomiokubo on 3/22/16.
 */
(function () {
    'use strict';


    angular.module('app')
        .controller('matchReportController', ['$scope', '$rootScope', '$uibModal', '$filter', 'hub', 'statcontext', 'authService', 'config', '$anchorScroll', '$location', function ($scope, $rootScope, $modal, $filter, hub, statcontext, authService, config, $anchorScroll,$location) {
            var log = hub.getLog('reportingController');
            var vm = this;
            vm.log = log;
            vm.config = config;
            vm.warn = hub.getLog('reportingController', 'warn');
            hub.page.setTitle('Match Reporting');
            hub.page.setSidebarMode('');
            vm.previewPager = {
                pageSize: 10,
                pageNumber: 1
            };
            vm.itemsPerPage = 10;
            vm.pageSize = config.pageSize();
            vm.listName = 'reportData';
            vm.sort = {
                reportData: { column: 'adGroupName', sortDesc: false }
            };
            vm.pageSizeChanged = function(size) {
                vm.pageSize = size;
                vm.pageSelect(1, vm.listName);
            };
            vm.pageSelect = function (page, listName) {
                var end, start, se;
                
                var list = vm[listName];
                var orderedList = $filter('orderBy')(list, vm.sort[listName].column, vm.sort[listName].sortDesc);
                var filteredList = $filter('reportFilter')(orderedList, vm.keyword);
                vm['filtered_'+ listName] = filteredList;
                if(listName === 'deliveries') {
                    vm.hasExpired = _.filter(filteredList, function(item){
                        return item.isExpired;
                    }).length > 0;
                }

                se = getStartEnd(page);
                start = se.start;
                end = se.end;

                if(filteredList && filteredList.length < start) {
                    se = getStartEnd(--page);
                    start = se.start;
                    end = se.end;
                }
                if (vm[listName]) {
                    vm['paged_' + listName] = filteredList.slice(start, end);
                    vm['currentPage_' + listName] = page;
                }
            };

            vm.sortFn = function (column, list = 'reportData') {
                if (vm.sort[list].column == column)
                    vm.sort[list].sortDesc = !vm.sort[list].sortDesc;
                else {
                    vm.sort[list].column = column;
                    vm.sort[list].sortDesc = false;
                }

                vm.pageSelect(vm['currentPage_' + list],list);
            }

            hub.api.get('api/matchreport').then(function (data) {                
                authService.getUserInfo().then(function (userInfo) {
                    vm.reportData = data;
                    const isDevelopment = userInfo.environment === 'Development';                    
                    vm.reportData.forEach(function (r, index) {
                        if (isDevelopment) {
                            r.adGroupName = 'Report ' + (index + 1);
                            if (index == 1)
                                r.adGroupName = r.adGroupName + ' - Sell Side'
                            r.reportFormat = 'Report Format ' + Math.floor(1 + Math.random() * Math.floor(4));
                            r.campaignName = 'Campaign ' + Math.floor(1 + Math.random() * Math.floor(10));
                        }
                        r.createdDateString = $filter('date')(r.createdDate);
                        r.updatedDateString = $filter('date')(r.updatedDate);
                    });

                    
                    vm.filterReports(1)
                });
            });

            $scope.$watch('vm.keyword', function(keyword){
                vm.filterReports();
            });

            var getStartEnd = function(page) {
                var start = (page - 1) * parseInt(vm.pageSize);
                var end = start + parseInt(vm.pageSize);
                return {
                    start: start,
                    end: end
                };
            }
            vm.filterReports = function (page = 1) {
                const listName = vm.listName;
                const list = vm[listName];
                const orderedList = $filter('orderBy')(list, vm.sort[listName].column, vm.sort[listName].sortDesc);
                const filteredList = $filter('reportFilter')(orderedList, vm.keyword);
                vm['filtered_'+ listName] = filteredList;

                let se = getStartEnd(page);
                let {start, end } = se;                
                if(filteredList && filteredList.length < start) {
                    se = getStartEnd(--page);
                    start = se.start;
                    end = se.end;
                }
                if (vm[listName]) {
                    vm['paged_' + listName] = filteredList.slice(start, end);
                    vm['currentPage_' + listName] = page;
                }
            };

            vm.preview = function (row) {
                vm.previewPager.pageNumber = 1;
                vm.previewTotal = Math.min(100, row.rowsCount);
                vm.currentReport = row;
                let segments = row.reportPath.split('/');
                hub.webapicontext.getCsvPreview(segments[0], segments[1], 0, vm.previewPager.pageSize + 1).then(function (data) {
                    readCsv(data, true);
                    let counter = 0;
                    const scrollTo = function(){
                        const el=document.querySelector(".preview-data");
                        if(el) {
                            el.scrollIntoView();
                            return true;
                        }
                        return false;         
                    };
                    let tid = setInterval(function(){
                        if(scrollTo() || counter > 3) {
                            clearInterval(tid);
                            counter = 0;
                        } else {
                            counter++;
                        }
                    }, 1000);
                });
            };

            vm.getDownloadUrl = function (row) {
                return hub.api.get('api/matchreport/' + row.adGroupExternalId + '/' + row.matchReportTypeId ).then(function (r) {
                    console.log(r);

                    var a = document.createElement('a');
                    a.href = r.url;
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                });
            };
            vm.previewPageSelect = function (pageNumber) {
                let segments = vm.currentReport.reportPath.split('/');
                const pageSize = vm.previewPager.pageSize;
                hub.webapicontext.getCsvPreview(segments[0], segments[1], (pageNumber - 1) * pageSize, pageSize + (pageNumber == 1 ? 1 : 0)).then(function (data) {
                    readCsv(data);
                });
            };

            function readCsv(data) {
                if (data) {
                    var cells = data.map(function (row) {
                        return hub.page.csvToArray(row).map($.trim);
                    });

                    var isFirstPage = vm.previewPager.pageNumber == 1;

                    if (isFirstPage)
                        vm.previewHeaders = cells[0];

                    vm.previewData = [];
                    for (var i = isFirstPage ? 1 : 0; i < cells.length; i++) {
                        var row = {};
                        var j = 0;
                        vm.previewHeaders.forEach(function (head) {
                            row[head] = cells[i][j++];
                        });
                        vm.previewData.push(row);
                    }                   
                }
            }

            return vm;
        }]);


})();