//provides text resources for labels, tooltips and error messages

(function () {
  'use strict';
  var serviceId = 'resourceService';
  angular.module('app').factory(serviceId, ['logger', 'datacontext', resourceService]);

  function resourceService(logger, datacontext) {
    var service = {
      resources: {},

      load: function () {
        var that = this;
        return datacontext.getLocalJson('resources').then(function (result) {
          that.resources = result;
        });
      },
      log: function (module, section, key) {
        var msg = this.get(section, key);
        logger.getLogFn(module, msg.type)(msg.description, '', true, true);
      },
      get: function (sectionKey, key) {
        var section = this.resources.sections?.[sectionKey];
        if (section) {
          return {
            description: section[key] ? section[key].caption + ' ' : '[DESCRIPTION IS NOT FOUND]',
            title: section[key] ? section[key].name : '[NOT FOUND (' + key + ')]'
          };
        } else {
          return { title: '[Section not found]' };
        }
      }
    };

    service.load();

    return service;


  }
})();
