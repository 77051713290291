(function () {
    'use strict';
    angular.module('app')
        .directive('creativeWizard', ['$location', function () {
            return {
                template: require('@views/creative/editors/creative-wizard.html').default,
                restrict: 'E',
                controllerAs: 'vm',
                scope: { adGroup: '=', viewMode: '=', hideSearch: '=' },
                controller: ['$rootScope', '$scope', 'campaignService', 'creativeService', '$sce', 'Enum', 'hub', '$location', '$uibModal', 'authService', '$timeout', function ($rootScope, $scope, campaignService, creativeService, $sce, Enum, hub, $location, $modal, authService, $timeout) {
                    var log = hub.getLog('Creatives');
                    var vm = this;
                    const isSuperAdmin = authService.isSuperAdmin();
                    vm.log = log;
                    vm.viewMode = $scope.viewMode;
                    vm.hideSearch = $scope.hideSearch;
                    $scope.adGroup.namingIncrement = 0;
                    vm.adGroup = $scope.adGroup;
                    vm.validationAlert = true;

                    vm.getCreativeStatusById = creativeService.getCreativeStatusById;
                    vm.getCreativeStatusIconById = creativeService.getCreativeStatusIconById;

                    vm.campaign = campaignService.getSelectedCampaign();
                    vm.selectedAdvertiserId = vm.campaign.advertiserId;
                    vm.sizes = campaignService.getSizes();
                    vm.selectedSize = vm.sizes[0];

                    vm.thirdPartyTypes = [{ id: '1', name: 'HTML' }];//, { id: '2', name: 'JavaScript' }];
                    vm.creatives = [];
                    vm.hasAccess = authService.hasAccess;
                    vm.isObserver = authService.isObserver;
                    vm.request = { id: 0 };

                    vm.pageNumber = 1;


                    function FilterFacebookAdFromats() {
                        var facebookAdFormats = creativeService.getFacebookPreviewAdFormats();
                        vm.facebookAdFormats = facebookAdFormats; //[];
                        return;

                        if (vm.adGroup.audience.deviceTypes.indexOf(1) > -1 && vm.adGroup.audience.placements.length > 0)
                            vm.facebookAdFormats.push(facebookAdFormats[0]);
                        if (vm.adGroup.audience.deviceTypes.indexOf(8) > -1 && (vm.adGroup.audience.placements.indexOf(1) > -1 || vm.adGroup.audience.placements.indexOf(2) > -1))
                            vm.facebookAdFormats.push(facebookAdFormats[1]);
                        if (vm.adGroup.audience.deviceTypes.indexOf(8) > -1 && (vm.adGroup.audience.placements.indexOf(1) > -1 || vm.adGroup.audience.placements.indexOf(2) > -1))
                            vm.facebookAdFormats.push(facebookAdFormats[2]);
                        if (vm.adGroup.audience.deviceTypes.indexOf(8) > -1 && vm.adGroup.audience.placements.indexOf(1) > -1)
                            vm.facebookAdFormats.push(facebookAdFormats[3]);
                        if ((vm.adGroup.audience.deviceTypes.indexOf(1) > -1 || vm.adGroup.audience.deviceTypes.indexOf(8) > -1) && vm.adGroup.audience.placements.indexOf(4) > -1)
                            vm.facebookAdFormats.push(facebookAdFormats[4]);
                    }

                    FilterFacebookAdFromats();

                    $scope.$on('facebookAdFromatsFilterConditionsChanged', function (event, data) {
                        FilterFacebookAdFromats();
                    });


                    vm.facebookAdFormat = vm.facebookAdFormats[0];
                    vm.changeAdFormat = function (next) {
                        if (next && vm.facebookAdFormat.id < vm.facebookAdFormats.length)
                            vm.facebookAdFormat = vm.facebookAdFormats[vm.facebookAdFormat.id];
                        else if (!next && vm.facebookAdFormat.id > 1)
                            vm.facebookAdFormat = vm.facebookAdFormats[vm.facebookAdFormat.id - 2];
                    }

                    vm.callToActions = creativeService.getCallToActions();
                    vm.getCallToActionText = function (value) {
                        var callToAction = vm.callToActions.find(function (item) { return item.value == value; });
                        return callToAction ? callToAction.text : 'Shop Now';
                    }

                    if (vm.campaign.marketerId) {
                        vm.selectedMarketer = campaignService.marketers.find(function (r) { return r.id == vm.campaign.marketerId; })
                        vm.selectedMarketer.avatarUrl = hub.settings.imagesUrl + 'avatars/' + vm.selectedMarketer.avatar;
                    }

                    function setCreatives() {
                        var filter = { channels: [vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25 ? 1 : vm.adGroup.channelId], sizes: [], statuses: [], owners: [], marketers: [vm.campaign.marketerId], creativeTypes: [], advertisers: [vm.selectedAdvertiserId] };
                        if (vm.selectedSize && vm.selectedSize.id)
                            filter.sizes = [vm.selectedSize.id];
                        else
                            filter.sizes = [];

                        campaignService.getCreatives(vm.selectedAdvertiserId, vm.campaign.marketerId, vm.pageNumber - 1, vm.keyword, filter, undefined, true, true, true).then(function (d) {
                            vm.creatives = _.filter(d.creatives, function (c) { return (c.channelId !== 1 || c.isHtml5 || c.bannerSize) && c.advertiserId == vm.selectedAdvertiserId && (c.channelId == vm.adGroup.channelId || c.channelId == 2 && vm.adGroup.channelId == 4 || (vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25) && c.channelId == 1) && (c.creativeTypeId == vm.adGroup.creativeTypeId && vm.adGroup.creativeTypeId == 3 || vm.adGroup.creativeTypeId != 3 && c.creativeTypeId != 3); });

                            if (vm.adGroup.endemicFormatId === 1){
                                vm.creatives = vm.creatives.filter(c => !c.isHtml5);
                            }

                            vm.creativesTotalCount = d.totalCount;
                            vm.creatives.forEach(function (creative) {
                                if (creative.channelId == 1 && creative.bannerSize > 0) {
                                    var size = Enum.getName('BannerSizes', creative.bannerSize);
                                    creative.size = size;
                                    creative.width = parseInt(size.substring(0, size.indexOf('x')));
                                    creative.height = parseInt(size.substring(size.indexOf('x') + 1));

                                    if (creative.thirdPartyServed) {
                                        switch (creative.size) {
                                            case '728x90':
                                                creative.zoom = 0.3;
                                                break;
                                            case '300x250':
                                                creative.zoom = 0.25;
                                                creative.marginLeft = '120px';
                                                break;
                                            case '160x600':
                                                creative.zoom = 0.15;
                                                creative.marginLeft = '150px';
                                                break;
                                            case '320x50':
                                                creative.zoom = 0.6;
                                                creative.marginLeft = '60px';
                                                break;
                                            case '300x600':
                                                creative.zoom = 0.15;
                                                creative.marginLeft = '130px';
                                                break;
                                            case '300x50':
                                                creative.zoom = 0.6;
                                                creative.marginLeft = '120px';
                                                break;
                                            default:
                                                creative.zoom = 1;
                                                break;
                                        }
                                        creative.previewWidth = creative.width * creative.zoom;
                                        creative.previewHeight = creative.height * creative.zoom;
                                    }
                                }
                                else if (creative.channelId == 1 && !creative.thirdPartyServed) {
                                    var img = new Image();
                                    img.onload = function () {
                                        creative.size = this.width + 'x' + this.height;
                                    }
                                    img.src = creative.bannerURL;
                                }
                                else if (creative.channelId == 2 || creative.channelId == 4) {
                                    creative.videoFile = $sce.trustAsResourceUrl(creative.videoURL);
                                    creative.videoOptions = { height: 100 };
                                }

                                if (vm.adGroup.creatives) {
                                    vm.adGroup.creatives.forEach(function (c) {
                                        if (c.id == creative.id) {
                                            creative.checked = true;
                                            c.added = true;
                                            creative.fbAdId = c.fbAdId;
                                            creative.facebookAdGroupCreativeStatus = c.facebookAdGroupCreativeStatus;
                                        }
                                    });
                                }
                            });

                            if (vm.adGroup.creatives) {
                                vm.adGroup.creatives.forEach(function (c) {
                                    if (!vm.creatives.find(function (cr) { return cr.id == c.id; })) {
                                        c.checked = true;
                                        vm.creatives.push(c);
                                    }
                                });
                            }
                            vm.rejectedCreatives = vm.creatives.filter(function (creative) {
                                return (creative.creativeStatusId == 40 || creative.facebookAdGroupCreativeStatus == 'rejected') && creative.checked && (vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25 ? 1 : vm.adGroup.channelId) == creative.channelId;
                            });
                            vm.rejectedCreativesCount = vm.rejectedCreatives.length;
                          vm.selectedCreativesCount = vm.creatives.filter(function (c) { return c.checked && c.facebookAdGroupCreativeStatus != 'rejected' && c.creativeStatusId != 40 && !c.enewsBannerId; }).length;
                            refreshCreatives();
                            setShowRotationFlag();
                        });
                    }

                    function refreshCreatives() {
                        $rootScope.$broadcast('creativesRefreshed', null);
                        vm.hideSearch = true;
                        $timeout(function () {
                            vm.hideSearch = false;
                        });
                    }

                    function setShowRotationFlag(loading = false) {
                        const CMI = 49;
                        const VIDEO = 2;
                        const ENDEMIC = 16;

                        const showRotateSwitch = vm.campaign.marketerId === CMI &&
                                              (vm.adGroup.channelId === VIDEO || vm.adGroup.channelId === ENDEMIC) &&
                                              (loading || vm.selectedCreativesCount > 1);

                        vm.adGroup.creativeRotationEnabled &&= showRotateSwitch; // reset to disabled when not visible
                        vm.showRotateSwitch = isSuperAdmin && showRotateSwitch; // User permissions don't participate in the reset of creativeRotationEnabled
                    }

                    if (vm.adGroup.channelId)
                        setCreatives();

                    vm.selectedCreativesCount = vm.creatives.filter(function (c) { return c.checked && c.facebookAdGroupCreativeStatus != 'rejected' && c.creativeStatusId != 40 && !c.enewsBannerId; }).length;

                    vm.thirdPartyCreativesCount = function () {
                        return _.filter(vm.adGroup.creatives, function (c) { return c.thirdPartyServed && c.uploaded; }).length;
                    };
                    vm.uploadedCreativesCount = function () {
                        return _.filter(vm.adGroup.creatives, function (c) { return !c.thirdPartyServed && c.uploaded; }).length;
                    };

                    vm.creativeNameChanged = function (creative) {
                        creative.duplicateName = false;
                        if (vm.adGroup.creatives.filter(function (c) { return c.name === creative.name && c.guid !== creative.guid; }).length > 0) {
                            creative.duplicateName = true;
                        }
                        if (creative.advertiserId) {
                            var validationData = {};
                            validationData.entityName = creative.name;
                            validationData.entityType = "creative";
                            validationData.advertiserId = creative.advertiserId;
                            validationData.creativeId = creative.id
                            hub.webapicontext.validateName(validationData).then(function (data) {
                                if (data.message !== "Valid Name")
                                    creative.duplicateName = true;
                            });
                        }
                    }


                    vm.isCreativePage = false;
                    vm.setCreativeWizardMode = function (mode) {
                        vm.creativeWizardMode = mode;
                        vm.validationAlert = false;
                    }

                    $scope.$on('creativesUpdated', function (event, data) {
                        vm.adGroup.creatives = data;
                        setCreatives();
                    });

                    $scope.$on('creativesKeywordChanged', function (event, keyword) {
                        vm.pageNumber = 1;
                        vm.keyword = keyword;
                        setCreatives();
                    });

                    $scope.$on('creativesSizeChanged', function (event, size) {
                        vm.pageNumber = 1;
                        vm.selectedSize = size;
                        setCreatives();
                    });

                    $scope.$on('creativesPageNumberChanged', function (event, pageNumber) {
                        vm.pageNumber = pageNumber;
                        setCreatives();
                    });

                    $scope.$on('recalculateCreatives', function (event) {
                        vm.selectedCreativesCount = vm.creatives.filter(function (c) { return c.checked && c.facebookAdGroupCreativeStatus != 'rejected' && c.creativeStatusId != 40 && !c.enewsBannerId; }).length;
                        vm.rejectedCreatives = vm.creatives.filter(function (creative) {
                            return (creative.creativeStatusId == 40 || creative.facebookAdGroupCreativeStatus == 'rejected') && creative.checked && (vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25 ? 1 : vm.adGroup.channelId) == creative.channelId;
                        });
                        vm.rejectedCreativesCount = vm.rejectedCreatives.length;
                        setShowRotationFlag();
                    });


                    $scope.$on('cleanSelectedCreatives', function (event) {
                        vm.creatives.forEach(c => c.checked = false);
                        vm.selectedCreativesCount = vm.creatives.filter(function (c) { return c.checked && c.facebookAdGroupCreativeStatus != 'rejected' && c.creativeStatusId != 40 && !c.enewsBannerId; }).length;
                        setShowRotationFlag();
                    });

                    $scope.$on('adGroupSelected', function (event, data) {
                        var selectedAdGroup = campaignService.getSelectedAdGroup();
                        if (selectedAdGroup.creatives)
                        {
                            vm.adGroup = selectedAdGroup;
                            setCreatives();
                        }

                    });

                    $scope.$on('adGroupChannelChanged', function (event, data) {
                        if (vm.adGroup.channelIndex === data)
                            setCreatives();

                        setShowRotationFlag();
                    });


                    vm.editCreative = function (creative) {
                        var modalInstance;
                        modalInstance = $modal.open({
                            templateUrl: 'views/creative/editors/creative.html',
                            animation: false,
                            controller: 'editCreativeController',
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    return { creative: creative, justUploaded: true, removeJustUploaded: vm.removeJustUploaded };
                                }
                            }
                        });
                        modalInstance.result.then((function (data) {
                            hub.webapicontext.saveCreative(data.creative).then(function (d) {
                                var savedCreative = d.data;
                                vm.log(savedCreative.name + " Saved");
                                vm.adGroup.creatives.splice($.inArray(savedCreative, vm.adGroup.creatives), 1);
                                savedCreative.checked = false;
                                $rootScope.$broadcast('creativesUpdated', vm.adGroup.creatives);
                            });

                        }), (function () {
                        }));
                    }

                    vm.removeJustUploaded = function (creative) {
                        creative.checked = false;
                        vm.adGroup.creatives.splice($.inArray(creative, vm.adGroup.creatives), 1);
                        vm.creatives.splice($.inArray(creative, vm.creatives), 1);
                    }

                    vm.removeCreative = function (creative) {
                        hub.webapicontext.removeCreative(creative.id).then(function () {
                            vm.log(creative.name + " Removed");
                            $rootScope.$broadcast('creativesUpdated', null);
                        });
                    }

                    vm.removeUploadedCreative = function (creative) {
                        creative.checked = false;
                        vm.adGroup.creatives.splice($.inArray(creative, vm.adGroup.creatives), 1);
                        vm.creatives.splice($.inArray(creative, vm.creatives), 1);
                    }

                    vm.creativeChecked = function (creative) {
                        if (!vm.adGroup.creatives)
                            vm.adGroup.creatives = [];

                        if (vm.adGroup.channelId == 6 && vm.adGroup.adGroupId) {
                            creative.checked = true;
                            return;
                        }

                        if (!creative.checked) {
                            if (creative.id > 0) {
                                var creatives = angular.copy(vm.adGroup.creatives);
                                creatives.forEach(function (c) {
                                    if (c.id == creative.id || c.enewsBannerId == creative.id) {
                                        var creativeToDelete = vm.adGroup.creatives.find(cr => cr.id == c.id);
                                        vm.adGroup.creatives.splice($.inArray(creativeToDelete, vm.adGroup.creatives), 1);
                                        if (vm.adGroup.creativeIds) {
                                            vm.adGroup.creativeIds.splice($.inArray(c.id, vm.adGroup.creativeIds), 1);
                                        }
                                    }
                                });
                                creative.checked = false;
                                $rootScope.$broadcast('creativeRemoved', creative);
                            }
                            else {
                                vm.removeUploadedCreative(creative);
                            }
                        }
                        else {
                            vm.adGroup.creatives.push(creative);
                            creative.checked = true;
                        }

                        vm.rejectedCreatives = vm.creatives.filter(function (creative) {
                            return (creative.creativeStatusId == 40 || creative.facebookAdGroupCreativeStatus == 'rejected') && creative.checked && (vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25 ? 1 : vm.adGroup.channelId) == creative.channelId;
                        });
                        vm.rejectedCreativesCount = vm.rejectedCreatives.length;
                        vm.selectedCreativesCount = vm.creatives.filter(function (c) { return c.checked && c.facebookAdGroupCreativeStatus != 'rejected' && c.creativeStatusId != 40 && !c.enewsBannerId; }).length;
                        setShowRotationFlag();
                    }

                    vm.zoomCreative = function (creative) {
                        var modalInstance;
                        modalInstance = $modal.open({
                            templateUrl: 'views/creative/editors/creative-zoom.html',
                            animation: false,
                            controller: 'zoomCreativeController as vm',
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    return { canSelect: !vm.hideSearch, creatives: vm.creatives, creative: creative, creativeChecked: vm.creativeChecked, facebookAdFormats: vm.facebookAdFormats };
                                }
                            }
                        });
                    }

                    vm.hoverCreative = null;
                    vm.hover = function (creative, $event, show) {
                        if (show) {
                            var isCampaign = $location.$$url.indexOf('campaign-wizard') > -1;
                            if (isCampaign) {
                                var offsetTop = -creative.height + $event.screenY - 150;
                                var offsetLeft = $event.pageX - 130;
                                creative.position = 'top: ' + offsetTop + 'px; left:' + offsetLeft + 'px;';
                            }
                            else {
                                var offsetTop = -creative.height - 35 - $event.toElement.offsetParent.offsetParent.scrollTop;
                                var offsetLeft = -85;
                                creative.position = 'margin-top: ' + offsetTop + 'px; margin-left:' + offsetLeft + 'px;';
                            }

                            creative.show = true;
                        } else {
                            creative.show = false;
                        }
                    }

                    vm.rotateSwitchDisabled = function () {
                        return vm.viewMode == 'readonly' || vm.adGroup.advancedOptimization;
                    }

                    if (vm.adGroup.advancedOptimization) {
                        // Turn off rotation is advancedOptimization is on. This is to fix bad AdGroups if any
                        vm.adGroup.creativeRotationEnabled = false;
                    }

                    setShowRotationFlag(true);

                    return vm;
                }]
            }
        }]);
})();
