(function () {
    'use strict';
    angular.module('app')
        .controller('accountManagerController', ['$scope', '$rootScope', '$uibModalInstance', '$filter', 'config', 'hub', 'externalservices', 'account', 'accountManagers', 'accounts', '$location', 'campaignService', '$timeout', 'viewMode', 'authService', 'timezoneService',
            function ($scope, $rootScope, $modalInstance, $filter, config, hub, externalserivces, account, accountManagers, accounts, $location, campaignService, $timeout, viewMode, authService, timezoneService) {

                var vm = this;
                vm.roleNames = {
                    superAdmin: true,
                    admin: false,
                    marketer: false,
                    manufacturer: false
                };
                vm.viewMode = viewMode;
                vm.tabs = [{ title: 'Your Info' }, { title: 'Payment' }, { title: 'Logo' }, { title: 'Settings' }, { title: 'Advertisers' }, { title: 'Campaign Reporting' }, { title: 'Contract Terms' }];
                vm.activeTab = 0;
                vm.showColumnsSettings = true;
                vm.cancelAnyway = false;

                vm.platformFeeTypes = [
                    { name: '% of Spend (Fee Based)', id: 1 },
                    { name: '% of Media Cost (Fee Based)', id: 3 },
                    { name: '% of Spend (Bid Reduction)', id: 4 },
                    { name: '% of Media Cost (Bid Reduction)', id: 5 }
                ];

                vm.paymentMethods = [
                    { name: 'Credit Card', id: 1 },
                    { name: 'PayPal', id: 2 },
                    { name: 'Bank Account', id: 3 },
                    { name: 'Invoice', id: 4 }
                ];

                vm.sftpFileNameFormats = [
                    { name: 'Name', id: 1 },
                    { name: 'Day', id: 2 },
                    { name: 'Month', id: 3 },
                    { name: 'Year', id: 4 },
                    { name: 'Timestamp', id: 5 },
                    { name: 'Campaign', id: 6 },
                    { name: 'Advertiser', id: 7 }
                ];

                vm.sftpPossibleValues = [
                    { name: 'Timestamp (“PST”, “CST”, “EST”, “UTC”)', id: 1 },
                    { name: 'Imp', id: 2 },
                    { name: 'Click', id: 3 },
                    { name: 'Event (imp or click or view)', id: 4 },
                    { name: 'NPI', id: 5 },
                    { name: 'View', id: 6 },
                    { name: 'Custom', id: 7 }
                ];

                var timeInfo = timezoneService.getInfo();
                var currentEastTimeAbbreviation = timeInfo.currentEastTimeAbbreviation;
                var tzDifference = timeInfo.tzDifference;
                vm.currentEastTimeAbbreviation = currentEastTimeAbbreviation;
                vm.tzDifference = tzDifference;


                vm.parseStartDate = function () {
                    var date = moment();
                    if (vm.account.marketerContract.startDate) {
                        date = moment(timezoneService.parseUTCDate(vm.account.marketerContract.startDate));
                    }

                    vm.account.marketerContract.startDate = new Date(date.format('YYYY/MM/DD'));
                };

                vm.datepickers = {
                    startDate: false,
                    endDate: false
                }
                vm.openDatepicker = function ($event, which) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    for (var prop in vm.datepickers) {
                        vm.datepickers[prop] = false;
                    }
                    vm.datepickers[which] = true;
                };

                vm.confirmCancel = function () {
                    vm.cancelAnyway = true;
                };

                vm.selectAdvertiser = function (b) {
                    if (!vm.account.advertiserIds)
                        vm.account.advertiserIds = [];
                    if (b.linked && vm.account.advertiserIds.indexOf(b.id) === -1)
                        vm.account.advertiserIds.push(b.id);
                    if (!b.linked && vm.account.advertiserIds.indexOf(b.id) > -1)
                        vm.account.advertiserIds.splice(vm.account.advertiserIds.indexOf(b.id), 1);

                };

                vm.selectAllAdvertisers = function () {
                    if (vm.advertisers.allLinked) {
                        _.forEach(vm.advertisers, function (b) {
                            b.linked = true; vm.selectAdvertiser(b);
                        });
                    }
                    else {
                        vm.account.advertiserIds = [];
                        _.forEach(vm.advertisers, function (b) {
                            b.linked = false;
                        });
                    }
                };

                vm.checkMarketer = function (r) {
                    if (!vm.account.marketerIds)
                        vm.account.marketerIds = [];
                    if (r.linked && vm.account.marketerIds.indexOf(r.id) === -1)
                        vm.account.marketerIds.push(r.id);
                    if (!r.linked && vm.account.marketerIds.indexOf(r.id) > -1)
                        vm.account.marketerIds.splice(vm.account.marketerIds.indexOf(r.id), 1);

                };

                vm.toggleShowSelectedAdvertisers = function () {
                    if (vm.showSelectedAdvertisers)
                        vm.showSelectedAdvertisers = false;
                    else
                        vm.showSelectedAdvertisers = true;
                }

                vm.selectAdvertiserToGroup = function (adv) {
                    if (!vm.account.advertiserIds)
                        vm.account.advertiserIds = [];
                    if (adv.checked && !(_.includes(vm.account.advertiserIds, adv.id))) {
                        vm.account.advertiserIds.push(adv.id);
                    }
                    if (!adv.checked && _.includes(vm.account.advertiserIds, adv.id)) {
                        vm.account.advertiserIds.splice(vm.account.advertiserIds.indexOf(adv.id), 1);
                    }
                }


                vm.isMarketer = authService.isMarketerUser();
                vm.isSuperAdmin = authService.isSuperAdmin();

                vm.marketerSelectionChanged = function (r) {
                    if (r.checked) {
                        r.isMarketerDataAccessible = true;
                        r.advertiserCanSeeMediaPlatformFees = false;
                        r.includeAdvertiserInteractionFee = false;
                    }
                    else {
                        r.isMarketerDataAccessible = false;
                        r.advertiserCanSeeMediaPlatformFees = false;
                        r.includeAdvertiserInteractionFee = false;
                    }
                }

                vm.selectAllMarketers = function () {
                    if (vm.marketers.allLinked) {
                        _.forEach(vm.marketers, function (r) {
                            r.linked = true; vm.checkMarketer(r);
                        });
                    }
                    else {
                        vm.account.marketerIds = [];
                        _.forEach(vm.marketers, function (r) {
                            r.linked = false;
                        });
                    }
                };

                vm.isPercentModel = function () {
                    var platformFeeType = vm.account.marketerContract.platformFeeType;
                    return platformFeeType == 1 || platformFeeType == 3 || platformFeeType == 4 || platformFeeType == 5;
                };

                vm.platformFeeTypeChanged = function () {
                    $scope.$broadcast("platformFeeTypeChanged", vm.account.marketerContract.platformFeeType);
                };

                vm.selectedMarketerChanged = function () {
                    vm.account.marketerContract = null;
                };

                vm.init = function (account) {
                    vm.account = account;
                    if (!vm.account.id)
                        vm.account.status = '4';
                    else if (vm.account.status === null || typeof vm.account.status === 'undefined') {
                        if (vm.account.accountType == 1 || vm.account.accountType == 2)
                            vm.account.status = '3';
                        else
                            vm.account.status = '0';
                    }
                    else
                        vm.account.status = vm.account.status.toString();

                    campaignService.getChannels().filter(c => [1, 3, 8, 11, 12, 13, 14, 15, 17, 18, 21, 22, 23, 25].indexOf(c.id) > -1).forEach(function (c) {
                        if (!vm.account.exchangeSettings[c.id])
                            vm.account.exchangeSettings[c.id] = { channelId: c.id };
                    });
                    if (vm.account.exchangeSettings[12].extra)
                        vm.account.exchangeSettings[12].extra = parseInt(vm.account.exchangeSettings[12].extra, 0);

                    vm.marketers = accounts.filter(function (a) { return a.accountType == 2; });
                    if (vm.account.accountType === 5) {
                        vm.marketers = vm.marketers.filter(m => m.options.some(o => o.optionId === 10120 && o.enabled === true));
                    }
                    vm.account.marketer = vm.marketers.find(function (m) { return vm.account.marketerId == m.id; });
                    if (vm.marketers && vm.marketers.length && vm.isMarketer && vm.marketers && !vm.account.marketer) {
                        vm.account.marketer = vm.marketers[0];
                    }
                    if (vm.account.id && vm.account.accountType == 1)
                        vm.account.isActive = vm.account.status == 0;
                    if (!vm.account.id && vm.account.accountType == 1)
                        vm.account.isActive = true;

                    if (vm.account.accountType == 1 && vm.account.accountPayment && vm.account.accountPayment.paymentMethodId)
                        vm.account.accountPayment.paymentMethod = vm.paymentMethods.find(function (pm) { return pm.id == vm.account.accountPayment.paymentMethodId; });

                    vm.accountId = account.id;

                    vm.canManageAccount = false;
                    if (authService.hasAccess('accounts', 'ManageAdvertisers'))
                        vm.canManageAccount = true;
                    if (authService.hasAccess('accounts', 'ManageMarketers') && authService.userAccountType() === "Admin" && (vm.account.accountType === 2 || vm.account.accountType === 4))
                        vm.canManageAccount = true;

                    vm.advertisers = accounts.filter(function (a) { return a.accountType == 1 && (!a.linkedAccount || a.linkedAccount.id == vm.account.id); });

                    vm.associatedAdvertisers = vm.advertisers.filter(function (b) {
                        return _.map(b.advertiserMarketers, 'marketerAccountId').find(function (rid) { return rid == vm.accountId; });
                    });
                    vm.advertisers.allLinked = false;

                    if (vm.account.accountType == 2) {
                        hub.webapicontext.contractsEditable().then(function (data) {
                            var isDraft = vm.account.status == 4;
                            if (data.contractsEditable || isDraft) {
                                vm.contractReadOnly = false;
                            } else {
                                vm.contractReadOnly = vm.account.marketerContract && vm.account.marketerContract.id > 0;
                            }
                        });
                        vm.parseStartDate();
                        vm.account.marketerContract.marketerId = vm.account.id;
                    }


                    vm.marketers.allLinked = false;
                    if (vm.account.accountType == 4) {
                        var linkedNum = 0;
                        _.forEach(vm.account.marketerIds, function (marketerId) {
                            var list = _.find(vm.marketers, function (r) { return r.id == marketerId; });
                            if (list) {
                                list.linked = true;
                                linkedNum++;
                            }
                        });
                        if (vm.marketers.length === linkedNum)
                            vm.marketers.allLinked = true;
                    }

                    vm.saveActionText = account.id == 0 ? "Create Account" : (account.accountType == 2 ? "Save Draft" : "Save");
                    vm.cancelActionText = account.id == 0 ? "Close" : "Cancel";
                    hub.webapicontext.accountManagers().then(function (accountManagers) {
                        vm.accountManagers = accountManagers;
                    });
                    hub.datacontext.getLocalJson('lists').then(function (d) {
                        vm.externalDSPs = $filter('orderBy')(d['bidders'].values, 'name', false);
                    });

                    vm.account.avatarUrl = vm.account.avatar;
                    vm.account.paymentTerms = vm.account.paymentTerms ? vm.account.paymentTerms : '1';
                    vm.account.advertiser = vm.account.advertiser ? vm.account.advertiser : '1';
                    vm.account.division = vm.account.division ? vm.account.division : '1';
                    var accountsChecked = [];
                    accounts.forEach(function (it) {
                        it.checked = false;

                        accountsChecked.push(it);
                    });

                    vm.accounts = accountsChecked;

                    if (vm.account.accountType == 3) {
                        vm.advertisers.forEach(function (adv) {
                            if (vm.account.advertiserIds)
                                adv.checked = vm.account.advertiserIds.indexOf(adv.id) > -1;
                            adv.fbAttributed = false;
                        });
                    }

                    vm.attributedAdvertisersCount = 0;
                    var acc = vm.accounts.find(function (a) { return a.id == vm.account.id; });
                    if (acc)
                        vm.account.facebookAdvertiserAttribution = acc.facebookAdvertiserAttribution;
                    if (vm.account.facebookAdvertiserAttribution && vm.account.facebookAdvertiserAttribution.advertiserIds) {
                        vm.attributedAdvertisersCount = vm.account.facebookAdvertiserAttribution.advertiserIds.length;
                        vm.account.facebookAdvertiserAttribution.advertiserIds.forEach(function (advertiserId) {
                            var advertiser = vm.advertisers.find(function (b) { return b.id == advertiserId; });
                            if (advertiser)
                                advertiser.fbAttributed = true;
                        });
                    }

                    var menuOpt = vm.account.options.find(function (opt) { return opt.optionId == 32 });
                    if (menuOpt) {
                        vm.showColumnsSettings = menuOpt.value.indexOf('/campaign') > -1;
                    }
                    var dgidRecOption = vm.account.options.find(o => o.optionId == 10180);
                    if (dgidRecOption && dgidRecOption.value) {
                      dgidRecOption.value = JSON.parse(dgidRecOption.value).join();
                    }


                };

                vm.fbAttribute = function (advertiser, checked) {
                    if (checked)
                        vm.attributedAdvertisersCount++;
                    else
                        vm.attributedAdvertisersCount--;

                    advertiser.fbAttributed = checked;
                };

                vm.populateZip = function () {
                    if (vm.account.address.zip && vm.account.address.zip.length == 5) {
                        externalserivces.getZipAddress(vm.account.address.zip).then(function (addr) {
                            if (!addr.error) {
                                vm.account.address.city = addr.data.city;
                                vm.account.address.state = addr.data.state;
                                vm.account.address.country = addr.data.country;
                            }
                        });

                    }
                };

                vm.init(account);
                vm.ADVERTISER = 1;
                vm.RETAIL = 2;

                $scope.config = config;
                vm.enabled = true;

                $scope.$on('toggleColumnsSettings', function (event, checked) {
                    vm.showColumnsSettings = !checked;
                });

                vm.account.selectedItems = [];
                vm.assignedMarketers = $filter('orderBy')(vm.accounts.filter(function (a) { return a.accountType == 2 && a.checked }), 'name', false);
                if (vm.assignedMarketers && vm.assignedMarketers.length > 0) {
                    vm.selectedMarketer = vm.assignedMarketers[0];
                    campaignService.setSelectedMarketerId(vm.selectedMarketer.id);

                    hub.webapicontext.getSegments(vm.selectedMarketer.id, -1, -1, vm.selectedMarketer.taxonomyId).then(function (data) {
                        $rootScope.$broadcast('segmentsChanged', data);
                    });
                }
                vm.selectMarketer = function (r) {
                    vm.selectedMarketer = r;
                    campaignService.setSelectedMarketerId(r.id);
                    hub.webapicontext.getSegments(r.id, -1, -1, r.taxonomyId).then(function (data) {
                        $rootScope.$broadcast('segmentsChanged', data);
                    });
                }

                //custom columns
                vm.columns = {};
                vm.settings = {};
                vm.isNew = false;

                function areOptionsValid() {
                    if (vm.account.id != 0) {
                        var opts = vm.settings.getData ? vm.settings.getData() : [];
                        //validation failed
                        if (_.find(opts, { isInvalid: true })) {
                            $('#accountForm').validator('validate');

                            vm.isInProgress = false;

                            hub.getLog('Account options', 'error')('Validation errors. Correct Settings and try again.');
                            vm.activeTab = 3;

                            return false;
                        }
                    }
                    return true;
                }

                $scope.disableLaunch = function () {
                    var opts = vm.settings.getData ? vm.settings.getData() : [];
                    return this.form_account.$invalid || _.find(opts, { isInvalid: true });
                }

                $scope.validate = function (isLaunch, backgroundCheck) {
                    // marketer contract can be saved separately - separate validation.
                    if (this.form_account.$invalid) {
                        $('#accountForm').validator('validate');
                        $('input[ng-model*="vm.account.marketerContract."]').trigger('blur');

                        if (($('#NationalFacebookGrossPerc.ng-invalid-max').length) || ($('#NationalFacebookGrossPerc.ng-empty').length))
                            $("input#NationalFacebookGrossPerc + div").hide();

                        if (($('#CoopFacebookGrossUpPerc.ng-invalid-max').length) || ($('#CoopFacebookGrossUpPerc.ng-empty').length))
                            $("input#CoopFacebookGrossUpPerc + div").hide();

                        $.each($('.tab-pane'), function (index, el) {
                            var invalidCount = $(el).find('*[class*="ng-invalid"]').length;
                            if (invalidCount != 0 && !backgroundCheck) {
                                vm.activeTab = index;
                            }
                        });

                        if (vm.account.id && !backgroundCheck) {
                            if ($("#AppNexusAdvertiserID_checked").prop("checked")) {
                                var apnAcc = vm.settings.getData().find(function (s) { return s.id == 512 });
                                if (!apnAcc || !apnAcc.value || apnAcc.value == NaN)
                                    vm.activeTab = 2;
                                else
                                    vm.activeTab = 0;
                            }
                        }

                        if (isLaunch && !backgroundCheck) {
                            //vm.activeTab = 5;
                            hub.getLog('Account Settings', 'error')('Validation errors. Correct option values and try again.');
                        }

                        return false;
                    }
                    else if (!areOptionsValid(backgroundCheck) && isLaunch)
                        return false;
                    else
                        return true;
                }

                vm.accountManagerChanged = function (id) {
                    vm.account.manager = vm.accountManagers.find(function (am) { return am.id === vm.account.manager.id; });
                }

                $scope.save = function (isLaunch) {
                    vm.account.isValid = $scope.validate(isLaunch);
                    if (vm.account.accountType == 2) {
                        vm.account.isLaunch = isLaunch;
                        if ((isLaunch || !vm.account.id) && !vm.account.isValid)
                            return;
                    }
                    else if (!vm.account.isValid)
                        return;

                    if (vm.account.accountType == 1 && vm.account.accountPayment && vm.account.accountPayment.paymentMethod)
                        vm.account.accountPayment.paymentMethodId = vm.account.accountPayment.paymentMethod.id;
                    vm.isInProgress = true;
                    preSaveAccount(isLaunch);

                    if (vm.activeTab == 1) {
                        $modalInstance.dismiss('avatarTab');
                    }
                }

                $scope.validateRevShare = function() {
                    if (vm.account.marketerContract.defaultFeeAmount > 100) {
                        vm.account.marketerContract.defaultFeeAmount = 100;
                    }

                    if (vm.account.marketerContract.defaultFeeAmount < 0) {
                        vm.account.marketerContract.defaultFeeAmount = 0;
                    }
                }

                function saveAccount(isLaunch) {
                    const startTime = new Date();
                    var isAdvertiserManager = authService.hasAccess('accounts', 'ManageAdvertisers')
                        && !authService.hasAccess('accounts', 'Manage Accounts');
                    var isPublisherManager = authService.hasAccess('accounts', 'ManagePublishers')
                        && !authService.hasAccess('accounts', 'Manage Accounts');
                    if (vm.account.accountType == 1) {
                        vm.account.status = vm.account.isActive ? 0 : 4;
                    }
                    hub.webapicontext.saveAccount(vm.account, isAdvertiserManager, isPublisherManager).then(function (d) {
                        saveDirectSoldFees(d);
                        hub.getLog('Account Management')(vm.account.name + ': account saved');
                        vm.isInProgress = false;
                        if (vm.accountId > 0) {
                            $rootScope.$broadcast('accountChanged', vm.account);
                            proceedSavingAccount(isLaunch);
                            $modalInstance.dismiss(account);
                        }
                        else {
                            vm.init(d);
                            $rootScope.$broadcast('accountChanged', vm.account);
                            vm.isNew = true;
                            vm.activeTab = 1;
                            $('#accountForm').validator('destroy');
                            if ([1,3,4,5].indexOf(account.accountType) >= 0)
                                $modalInstance.dismiss(account);

                            $timeout(function () {
                                $scope.validate();
                                vm.activeTab = 0;
                            }, 600);
                        }

                        var data = { id: vm.accountId, name: vm.account.name };
                        authService.trackEvent({ action: 'SaveAccount', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });

                    }, function (err) {
                        if (!err.data.logged) {
                            hub.getLog('Account Management', 'error')(vm.account.name + ': account save error');
                        }
                        vm.isInProgress = false;
                    });

                }

                function saveMarketerContract(isLaunch, isAccountSaved) {
                    if (vm.account.accountType === 2 && vm.account.marketerContract != null) {
                        var contract = vm.account.marketerContract;
                        contract.isValid = vm.account.isValid;
                        if (isAccountSaved && (contract && !contract.id || vm.account.status == 4)) {
                            hub.webapicontext.saveMarketerContract(vm.account.marketerContract)
                                .then(function (d) {
                                    hub.getLog('Account Management')(vm.account.name + ' marketer contract saved');
                                    saveAccount(isLaunch);
                                }, function (err) {
                                    if (err.data && !err.data.logged) {
                                        hub.getLog('Account Management', 'error')('Marketer Contract Save error');
                                        console.log(err);
                                    }
                                });
                        } else {
                            saveAccount(isLaunch);
                        }
                    } else {
                        saveAccount(isLaunch);
                    }
                }

                function saveDirectSoldFees(account) {
                    if (account.accountType !== 2) {
                      return;
                    }
                    
                    const fees = {
                        marketerId: account.id,
                        directSoldSOVFee: account.marketerContract?.directSoldSOVFee ?? vm.account.marketerContract?.directSoldSOVFee,
                        directSoldHCPFee: account.marketerContract?.directSoldHCPFee ?? vm.account.marketerContract?.directSoldHCPFee
                    };

                    hub.webapicontext.saveDirectSoldFees(fees)
                }

                function preSaveAccount(isLaunch) {
                    saveMarketerContract(isLaunch, vm.account.id > 0);
                }

                function proceedSavingAccount(isLaunch) {

                    var columnSettings = vm.columns.getData ? vm.columns.getData() : null;
                    var opts = vm.settings.getData() ? vm.settings.getData() : [];

                    if (vm.account.isLaunch && !areOptionsValid()) {
                        return;
                    }

                    var dgidMacroOption = opts.find(o => o.id == 10170);
                    var dgidRecOption = opts.find(o => o.id == 10180);
                    if (dgidRecOption && dgidRecOption.value) {
                       dgidRecOption.value = dgidMacroOption.enabled ? dgidRecOption.value.replace(' ', '').split(',') : "";
                    }

                    var accountOptions = [];
                    accountOptions = _.map(opts, function (item) { return { optionId: item.id, enabled: item.enabled, valueJSON: (item.value == 0 || item.value) ? ((typeof item.value === 'string') ? item.value : JSON.stringify(item.value)) : "" }; });

                    //column settings
                    if (columnSettings)
                        accountOptions.push({ optionId: 128, enabled: true, valueJSON: JSON.stringify(columnSettings) });

                    if (accountOptions && accountOptions.length) {
                        var startTime = new Date();
                        hub.webapicontext.saveAccountOptions({ accountId: vm.account.id, options: accountOptions }).then(function (res) {
                            hub.getLog('Account Management')(vm.account.name + ' account options saved');

                            var data = { id: vm.account.id, name: vm.account.name };
                            authService.trackEvent({ action: 'SaveAccountOptions', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });

                            if (vm.account.accountType === 1) {
                                $modalInstance.dismiss(account);
                                vm.isInProgress = false;
                            } else {
                                $modalInstance.dismiss(account);
                                $rootScope.$broadcast('accountChanged', vm.account);
                                vm.isInProgress = false;
                            }
                        }, function (err) {
                            if (!err.data.logged) {
                                hub.getLog('Account Management', 'error')(vm.account.name + ': account options save error');
                                console.log(err);
                            }
                            vm.isInProgress = false;
                        });
                    }
                    if (vm.account.accountType === 1) {
                    }
                    else if (vm.account.accountType === 2 && vm.account.marketerContract != null) {

                        var fbAttributedIds = _.map(_.filter(vm.advertisers, function (item) { return item.fbAttributed == true; }), 'id');

                        hub.webapicontext.saveMarketerFbAttribution({ accountKey: vm.account.accountKey, accountID: vm.account.id, advertiserIds: fbAttributedIds, taxonomyId: vm.account.taxonomyId }).then(function (d) {
                            hub.getLog('Account Management')(vm.account.name + ' facebook attribution saved.');
                            $modalInstance.dismiss(account);
                            vm.isInProgress = false;
                        }, function (err) {
                            vm.isInProgress = false;
                            if (!err.data.logged) {
                                hub.getLog('Account Management', 'error')('Marketers Save error');
                                console.log(err);
                            }
                        });


                        if (!isLaunch) {
                            $rootScope.$broadcast('accountChanged', vm.account);
                        }
                        vm.isInProgress = false;
                        $modalInstance.dismiss(account);
                    }
                }


                vm.cancel = function (cancelClicked) {
                    vm.cancelClicked = cancelClicked;
                    $modalInstance.dismiss('cancel');
                };


                vm.uploadImage = function (file) {
                    return hub.webapicontext.uploadFile('CompanyAccount/LogoUpload?accountID=' + vm.accountId, file).then(function (data) {
                        vm.account.avatarUrl = data.data.filename + "?uploaded=" + new Date().getTime();
                        $scope.$root.$broadcast('image-uploaded', { type: 'account', entity: vm.account });
                        vm.isNew = false;
                    });
                }

                $scope.$on('modal.closing', function (event, reason) {
                    if (!vm.showCaution && (reason == 'cancel') && event.currentScope.form_account.$dirty && vm.cancelClicked) {
                        $timeout(function () {
                            vm.showCaution = true;
                        });
                        event.preventDefault();
                    }
                });

                $scope.$on('modal.force.close', function () {
                    $('#accountForm').validator('destroy');
                    $modalInstance.dismiss('cancel');
                });

                $scope.$on('contractChanged', function (event, data) {
                    vm.account.marketerContract[data.name] = data.value;
                });


                return vm;


            }]);

})();
