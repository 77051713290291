(function () {
    'use strict';
    var serviceId = 'campaignSummaryReportService';
    angular.module('app').factory(serviceId, ['customReportService', 'hub', campaignSummaryReportFactory]);

    function campaignSummaryReportFactory(customReportService, hub) {

        var service = {
            getCampaignSummaryReportSettings: function (path) {
                return this.campaignSummaryReportSettings.find(function (r) { return path.indexOf(r.reportUrl) > -1; });
            },
            campaignSummaryReportSettings: [
                {
                    reportTypeId: 3,
                    reportUrl: 'campaign-summary',
                    reportName: 'Campaign Summary',
                    nonFbIncMetrics: [
                        [{ name: 'Media Type', field: 'channel' }, { name: 'Incremental Sales', field: 'incrementalSales' }, { name: 'Spend', field: 'spend' }],
                        [{ name: 'Advertiser Page Visits', field: 'advertiserPageVisits' }, { name: 'Sales', field: 'sales' }, { name: 'ROAS', field: 'roas' }],
                        [{ name: 'Visit Rate', field: 'visitRate' }, { name: 'Item Sold', field: 'itemSold' }, { name: 'Incremental ROAS', field: 'incrementalROAS' }]],
                    nonFbMetrics: [
                        [{ name: 'Impressions', field: 'impressions' }, { name: 'Uniques', field: 'uniqueMessaged' }, { name: 'Viewability', field: 'viewability' }],
                        [{ name: 'Clicks', field: 'clicks' }, { name: 'CTR', field: 'ctr' }, { name: 'Spend', field: 'spend' }],
                        [{ name: 'Advertiser', field: 'acc' }, { name: 'Campaign', field: 'camp' }, { name: 'Media Type', field: 'channel' }, {name: 'Ad Groups', field: 'adGroups'}]],
                    fbMetrics: [
                        [
                            { name: 'Impressions', field: 'impressions' },
                            { name: 'Clicks', field: 'clicks' },
                            { name: 'CTR', field: 'ctr' }
                        ],
                        [
                            { name: 'Spend', field: 'spend' },
                            { name: 'Media CPM', field: 'averageCPM' },
                            { name: 'Marketer', field: 'ret'}
                        ],
                        [
                            { name: 'Advertiser', field: 'acc' },
                            { name: 'Campaign', field: 'camp' },
                            { name: 'Media Type', field: 'channel' },
                            {name: 'Ad Groups', field: 'adGroups'}
                        ]
                    ],
                    nonFbTabs: [
                        { name: 'Reach', key: 'reach' },
                        { name: 'Engagement', key: 'engagement' },
                        { name: 'Performance Details', key: 'performanceDetails' },
                        { name: 'Creative Performance Details', key: 'creatveDetails', feature: 'CREATIVE-PERF' },

                    ],
                    fbTabs: [
                        { name: 'Engagement', key: 'engagement' },
                        { name: 'Performance Details', key: 'performanceDetails' },
                        {name: 'Social', key: 'social'}]
                }
            ],
            getPieChartDefaults: function () {
                var pie = {
                    "type": "pie",
                    "dataProvider": [],
                    "color": "#5F646F",
                    "labelText": "[[percents]]%\n[[title]]",
                    "outlineColor": "#FFFFFF",
                    "outlineAlpha": "0.8",
                    "outlineThickness": "1",
                    "fontSize": "13",
                    "fontFamily": "Lato, Helvetica",
                    "align": "left",
                    "colors": ["#64CCC9", "#36B37E"],
                    "marginTop": "0",
                    "marginBottom": "80",
                    "radius": "100",
                    "export": {
                        "enabled": true
                    }
                };

                return pie;
            },
            newVsExistingCustomerData: [{
                "customerType": "New",
                "color": "#64CCC9"
            }, {
                "customerType": "Existing",
                "color": "#36B37E"
            }],
            newVsExistingSalesData: [{
                "saleType": "New",
                "color": "#64CCC9"
            }, {
                "saleType": "Existing",
                "color": "#36B37E"
            }],
            dtcVsWholesaleCustomerData: [{
                "customerType": "DTC",
                "color": "#64CCC9"
            }, {
                "customerType": "Wholesale",
                "color": "#36B37E"
            }],
            dtcVsWholesaleSalesData: [{
                "saleType": "DTC",
                "color": "#64CCC9"
            }, {
                "saleType": "Wholesale",
                "color": "#36B37E"
            }],
            balloonFunction: function (item, graph) {
                var result = graph.balloonText;
                for (var key in item.dataContext) {
                    var format = (key === 'dtcTotalPurchaseAmount' || key === 'wholesaleTotalPurchaseAmount' || key === 'impressions' || key === 'uniqueMessaged' || key === 'advertiserPageVisits' || key === 'cumulativeAdvertiserPageVisits') ? { precision: 0, decimalSeparator: '', thousandsSeparator: ',' } :
                        (key === 'cumulativeRoas' ? { precision: 1, decimalSeparator: '.', thousandsSeparator: ',' } : { precision: 2, decimalSeparator: '.', thousandsSeparator: ',' });

                    if (item.dataContext.hasOwnProperty(key) && !isNaN(item.dataContext[key])) {
                        var formatted = AmCharts.formatNumber(item.dataContext[key], format, format.precision);
                        result = result.replace("[[" + key + "]]", formatted);
                    }
                }
                return result;
            },
            getVisitChartOptions: function () {
                var visitChartOptions = angular.copy(hub.config.chart.templates.serial);
                visitChartOptions.graphs[0].valueField = 'ctr';
                visitChartOptions.graphs[0].title = 'Click Through Rate (%)';
                visitChartOptions.graphs[0].balloonText = '';
                visitChartOptions.graphs[0].hideBulletsCount = 30;
                visitChartOptions.graphs[0].lineColor = '#2598D5';
                visitChartOptions.graphs[0].type = 'line';
                visitChartOptions.graphs[0].dashed = true;
                visitChartOptions.graphs.pop();

                return visitChartOptions;
            },
            getReachChartOptions: function () {
                var reachChartOptions = angular.copy(hub.config.chart.templates.serial);
                reachChartOptions.graphs[0].valueField = 'uniqueMessaged';
                reachChartOptions.graphs[0].name = '[font-size: 11px]Unique Impressions[/]';
                reachChartOptions.graphs[0].lineColor = 'rgba(37, 152, 213, 0.7)';
                reachChartOptions.graphs[0].type = 'line';
                reachChartOptions.graphs[0].hideBulletsCount = 30;
                reachChartOptions.graphs[0].balloonText = "";
                reachChartOptions.graphs[0].fillOpacity = 0.5;
                reachChartOptions.graphs[0].colors= ['#3DDBD9','#2598D5'];


                reachChartOptions.graphs[1].valueField = 'averageFrequency';
                reachChartOptions.graphs[1].name = '[font-size: 11px]Average Frequency[/]';
                reachChartOptions.graphs[1].valueAxis = 'v2';
                reachChartOptions.graphs[1].lineColor = '#2598D5';
                reachChartOptions.graphs[1].type = 'line';
                reachChartOptions.graphs[1].hideBulletsCount = 30;
                reachChartOptions.graphs[1].balloonText = "";
                reachChartOptions.graphs[1].dashed = true;
                reachChartOptions.graphs[1].opposite = true;
                return reachChartOptions;
            },
            getDeliveryChartOptions: function () {
                var deliveryChartOptions = angular.copy(hub.config.chart.templates.serial);
                deliveryChartOptions.graphs[0].valueField = 'uniqueMessaged';
                deliveryChartOptions.graphs[0].title = 'Unique Impressions';
                deliveryChartOptions.graphs[0].type = 'line';
                deliveryChartOptions.graphs[0].hideBulletsCount = 30;
                deliveryChartOptions.graphs[0].balloonText = "";

                deliveryChartOptions.graphs[1].valueField = 'averageFrequency';
                deliveryChartOptions.graphs[1].title = 'Average Frequency';
                deliveryChartOptions.graphs[1].valueAxis = 'v2';
                deliveryChartOptions.graphs[1].lineColor = '#3192D4';
                deliveryChartOptions.graphs[1].type = 'line';
                deliveryChartOptions.graphs[1].hideBulletsCount = 30;
                deliveryChartOptions.graphs[1].balloonText = "";
                deliveryChartOptions.graphs[1].fillAlphas = 0.1;
                return deliveryChartOptions;
            },
            getDeliveryChartOptionsDTC: function () {
                var deliveryChartOptions = angular.copy(hub.config.chart.templates.serial);
                deliveryChartOptions.graphs[0].valueField = 'dtcTotalPurchaseAmount';
                deliveryChartOptions.graphs[0].title = 'DTC Sales';
                deliveryChartOptions.graphs[0].type = 'line';
                deliveryChartOptions.graphs[0].hideBulletsCount = 30;
                deliveryChartOptions.graphs[0].balloonText = "<span style='font-size:11px;margin-bottom:-10px;display:block;font-weight: 600;text-align:left'>[[category]]</span><br><b style='font-size:13px;color:#999;margin-bottom:-15px;display:block;text-align:left'>[[dtcTotalPurchaseAmount]]</b><br><span style='font-size:11px;margin-bottom:-10px;display:block;text-align:left'>DTC Sales</span><br><b style='font-size:13px;color:#3192D4;margin-bottom:-15px;display:block;text-align:left'>[[wholesaleTotalPurchaseAmount]]</b><br><span style='font-size:11px;margin-bottom:10px;display:block;text-align:left'>Wholesale Sales</span>";
                deliveryChartOptions.graphs[0].balloonFunction = this.balloonFunction;

                deliveryChartOptions.graphs[1].valueField = 'wholesaleTotalPurchaseAmount';
                deliveryChartOptions.graphs[1].title = 'Wholesale Sales';
                deliveryChartOptions.graphs[1].valueAxis = 'v2';
                deliveryChartOptions.graphs[1].lineColor = '#3192D4';
                deliveryChartOptions.graphs[1].type = 'line';
                deliveryChartOptions.graphs[1].hideBulletsCount = 30;
                deliveryChartOptions.graphs[1].balloonText = "";
                deliveryChartOptions.graphs[1].fillAlphas = 0.1;
                return deliveryChartOptions;
            }
        };
        return service;
    }
})();
