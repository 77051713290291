(function () {
    'use strict';

    angular.module('app').directive('embedViewer', ['$compile', '$timeout', function ($compile, $timeout) {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, ele, attrs) {
                var size = attrs.size;
                var type = attrs.type;
                var iframeName = attrs.iframeName;
                var creativeId = attrs.creativeId;

                scope.$watch(attrs.embedViewer, function (val) {
                        $timeout(function () {
                            size = size ? size : '';
                            var iframeId = iframeName ? iframeName : 'iframe' + size + creativeId;
                            var iframe = document.getElementById(iframeId);
                            if (iframe) {
                                var html_string = "<html><div style=\"top: 0;left: 0;position: absolute;\">" + val + "</div></html>";
                                var iframedoc = iframe.document;

                                if (iframe.contentDocument)
                                    iframedoc = iframe.contentDocument;
                                else if (iframe.contentWindow)
                                    iframedoc = iframe.contentWindow.document;
                                if (iframedoc) {
                                    iframedoc.open();
                                    iframedoc.writeln(html_string);
                                    iframedoc.close();
                                }
                            }
                        });
                });
            }
        };
    }]);

})();
