
(function () {
  'use strict';
  angular.module('app')
      .controller('dealController', ['$scope', '$rootScope', '$uibModal', '$filter', 'config', 'hub', 'authService', 'campaignService', 'tableService', 'dialogService', 'featureService', '$location', 'accountService', '$routeParams', '$route', 'copyService', 'pixelService', 'logger', 'timezoneService', 'serverTableService', function ($scope, $rootScope, $modal, $filter, config, hub, authService, campaignService, tableService, dialogService, featureService, $location, accountService, $routeParams, $route, copyService, pixelService, logger, timezoneService, serverTableService) {
          var log = hub.getLog('Deal Management');
          var vm = this;
          vm.log = log;
          vm.config = hub.config;
          vm.warn = hub.getLog('Deal Management', 'warn');
          vm.sts = serverTableService.getInstance({ keyword: ''});
          vm.ts = tableService;
          var init;
          var rootVM = $rootScope.rootVM;
          vm.sort = { deals: { pageNumber: 1, column: 'createdDate', sortDesc: true, keyword: '' } };

          vm.account = accountService.get();
          //disable Audience download if opt=12500 is set
          var downloadOpt = vm.account && vm.account.options ? vm.account.options.find(f => f.optionId === 12500) : null;

          vm.canDownload = !downloadOpt || !downloadOpt.enabled;

          hub.page.setTitle('Deals');
          vm.hasAccess = authService.hasAccess;
          vm.isObserver = authService.isObserver;
          vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('deals', 'Manage');
          vm.isAdmin = authService.isSuperAdmin();
          vm.page = hub.page;
          vm.config = hub.config;

          vm.currentEastTimeAbbreviation = timezoneService.getInfo().currentEastTimeAbbreviation;

          vm.canRemove = function (deal) {
              if (vm.isAdmin)
                  return true;
              var account = accountService.get();
              if (account.accountType == 1)
                  return account.id == deal.advertiserId;
              else if (account.accountType == 2)
                  return account.id == deal.marketerId;
              else if (account.accountType == 3)
                  return account.advertiserIds.indexOf(deal.advertiserId) > -1;
          }





          vm.showId = featureService.isActive('ENTITYID');
          vm.showAdvertiserName = featureService.isActive('ADVERTISERNAME');


          vm.audienceStatuses = campaignService.getAudienceStatuses();
          vm.channels = campaignService.getChannels();
          vm.getChannelById = function (channelId) {
              var name = '';
              vm.channels.forEach(function (item, index) {
                  if (item.id === channelId)
                      name = item.name;
              });
              return name;
          };

          vm.getAudienceStatus = function (statusId) {
              var status = vm.audienceStatuses.find(function (as) { return as.id === statusId; });
              if (status)
                  return status.name;
              else
                  return "";
          };

          $scope.updatePageData = function (paged) {
              const deals = vm.deals;
              campaignService.getAccounts(1).then(function () {
                  for (let deal of deals) {
                      var advertiser = campaignService.advertisers.find(function (a) { return a.id == deal.advertiserId; });
                      if (advertiser)
                          deal.advertiserName = advertiser.name;

                  }
              });
          };

          $scope.hover = function (entity, show) {
              // Shows/hides the delete button on hover
              return entity.showActions = show;
          };

          $scope.search = function (item) {
              if (!vm.sort.deals.keyword || (item.name.toLowerCase().indexOf(vm.sort.deals.keyword.toLowerCase()) != -1) || (item.marketer.toLowerCase().indexOf(vm.sort.deals.keyword.toLowerCase()) != -1) || (item.advertiser.toLowerCase().indexOf(vm.sort.deals.keyword.toLowerCase()) != -1)) {
                  return true;
              }
              return false;
          };



          vm.sortFn = function (column, list) {
              if (list === 'deals') {
                  vm.sts.sortFn(column);
              } else {
                  vm.ts.sortFn(column, list);
                  vm.sort[list].column = vm.ts.sort[list].column;
                  vm.sort[list].sortDesc = vm.ts.sort[list].sortDesc;
                  vm.sort[list].keyword = vm.ts.sort[list].keyword;
              }


          };


          vm.editDeal = function (deal, viewMode) {
              if (!viewMode)
                  viewMode = 'edit';

            const location = $location.path();
            $location.path(`${location}`, false);
                var modalInstance;
                modalInstance = $modal.open({
                    templateUrl: 'views/audience/editors/deal-editor.html',
                    animation: true,
                    controller: 'editDealController',
                    size: 'lg',
                    backdrop: 'static',
                    resolve: {
                        data: function () {
                            if (!deal) {
                                campaignService.setSelectedMarketerId('');
                                var advertiserId = null;
                                var marketerId = 1;
                                if (vm.account) {
                                    if (vm.account.accountType == 1)
                                        advertiserId = vm.account.id;
                                    else if (vm.account.accountType == 2)
                                        marketerId = vm.account.id;
                                }
                                return { name: '', marketerId, advertiserId, channelId: null };
                            }
                            else {
                                return deal;
                            }
                        },
                        viewMode: function () { return viewMode; }
                    }
                });
                modalInstance.result.then((function (audience) {
                    vm.log(audience.name + " Saved");
                    vm.deals = $rootScope.rootVM.deals;
                    vm.loadData(null, true);
                    $scope.updatePageData();
                    $location.path(location, false);
                }), (function () {
                    $location.path(location, false);
                }));
          };



          var original = $location.path;
          $location.path = function (path, reload) {
              if (reload === false) {
                  var lastRoute = $route.current;
                  var un = $rootScope.$on('$locationChangeSuccess', function () {
                      if (IsCurrentPage($route.current.$$route.originalPath))
                          $route.current = lastRoute;

                      un();
                  });
              }
              return original.apply($location, [path]);
          };
          function IsCurrentPage(path) {
            return path.indexOf('/admin/deals') > -1;
        }


          vm.processUrl = function () {
              const isCurrent = IsCurrentPage($location.$$path);
              if (isCurrent) {
                  var id = $routeParams.id;
                  if (id) {
                      var deal = vm.deals.find(function (a) { return a.id == id; });
                      vm.editDeal(deal, (vm.isObserver('deals', deal) ? 'readonly' : 'edit'));
                  } else if (vm.canEdit && $location.$$path.indexOf('/new') > -1) {
                      vm.editDeal(null, 'edit');
                  } else {
                      $rootScope.$broadcast('modal.force.close', null);
                  }
              }
          };

          vm.loadData = function (process = true, force = false) {
              if (!rootVM.deals || force) {
                  var marketerId = 0;
                  var activeTab;
                  if (!vm.sts.pagedSearchPromise) {
                    const cb = () => {
                        const pager = {
                            pageSize: vm.sts.pager.pageSize,
                            pageNumber: vm.sts.pager.pageNumber,
                            sortDescending: vm.sts.sort.sortDesc,
                            sortName: vm.sts.sort.sortName
                        };

                        const flt = {
                            audienceType: null,
                            marketerId: vm.sts.filter.marketerId,
                            isAudiencePage: true,
                            channelId: null,
                            advertiserId: null,
                            keyword: vm.sts.filter.keyword
                        };
                        return hub.webapicontext.searchDeals(flt, pager)
                            .then(data => {
                                rootVM.deals = data.list;
                                vm.deals = rootVM.deals;
                                campaignService.getAccounts().then(function () {
                                    vm.deals.forEach(function (a) {
                                        var marketer = campaignService.marketers.find(function (m) { return m.id === a.marketerId })
                                        a.marketerName = marketer ? marketer.name : '';
                                        var advertiser = campaignService.advertisers.find(function (m) { return m.id === a.advertiserId })
                                        a.advertiserName = advertiser ? advertiser.name : '';
                                        a.channelName =  campaignService.getChannels().find(x => x.id == a.channelId).name;
                                    });
                                });
                                $scope.updatePageData();
                                if (process) {
                                    vm.processUrl();
                                }
                                return data;
                            });
                    }
                    vm.sts.setPagedSearchPromise(cb);
                    }
                    vm.sts.search().then(data => {
                        console.log('done', data);
                    });
              }
              else {
                  vm.deals = rootVM.deals;
                  if (process) {
                      vm.processUrl();
                  }
                  $scope.updatePageData();
              }
          }
          vm.setFilter = function () {

            if(vm.sts.filter.keyword.length > 0){
              vm.sts.filter.keyword = "";
            }
            rootVM.deals = null;
            vm.sts.reset();
            vm.sts.filter = {
                keyword: vm.sts.filter.keyword
            };
            vm.loadData();
          };
          vm.setFilter();

          $rootScope.$on('dealsSaved', function(name, value) {
              vm.loadData(false, true);
          });
          $rootScope.$on('dealUpdate', function (name, notification) {
              if (rootVM.deals && rootVM.deals.find(a => a.id == notification.value)) {
                  logger.showToastPermanent('Lasso Notification: Deal List has been changed.');
                  rootVM.deals = null;
                  vm.loadData(false);
              }
          });

          vm.pageSize = vm.config.pageSize().toString();

          vm.pageSizeChanged = function (size) {
              vm.pageSize = size;
              vm.sts.pageSizeChanged(size);
          };

      }]);
      angular.module('app')
      .controller('editDealController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'data', 'viewMode', 'hub', 'campaignService', 'validationService', '$timeout', '$location', 'authService', function ($scope, $rootScope, $modalInstance, $sce, data, viewMode, hub, campaignService, validationService, $timeout, $location, authService) {
          var vm = this;
          $scope.vm = vm;
          vm.isAudiencePage = true;
          vm.viewMode = viewMode;
          vm.config = hub.config;
          vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('deals', 'Manage');

          $scope.canSubmit = function () {
              var audienceValid = false;

              if (typeof this.audienceForm !== 'undefined')
                  audienceValid = this.audienceForm.$valid && validationService.validateAudience(vm.deal);

              return audienceValid;
          };


          $scope.ok = function () {
              var startTime = new Date();

              if (typeof this.audienceForm !== 'undefined') {
                  var isValid = this.audienceForm.$valid && validationService.validateDeal(vm.deal);
                  if (!isValid) {
                      vm.deal.validation = true;
                      $('#audienceForm').validator('validate');
                      $timeout(function () {

                      });
                      return;
                  }
              }
              else
                  return;
              vm.isInProgress = true;
              hub.webapicontext.saveDeal(vm.deal).then(function () {
                  vm.isInProgress = false;
                  var data = { ...vm.deal };
                  authService.trackEvent({ action: 'SaveDeal', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                  $modalInstance.close(vm.deal);
                  $rootScope.$broadcast('dealSaved', vm.deal);
              });
          };


          $scope.cancel = function (cancelClicked) {
              vm.cancelClicked = cancelClicked;
              $modalInstance.dismiss('cancel');
          };

          vm.selectedChannelChanged = function (){
            vm.deal.channelId = vm.selectedChannel.id;
          }
          vm.selectedAdvertiserChanged = function() {
              vm.deal.advertiserId = vm.selectedAdvertiser.id;
          }
          vm.selectedMarketerChanged = function() {
            if (vm.selectedMarketer) {
                vm.advertisers = _.orderBy(campaignService.advertisers.filter(function (a) { return a.marketerId == vm.selectedMarketer.id; }), 'name');
                vm.selectedAdvertiser = undefined;
                vm.deal.marketerId = vm.selectedMarketer.id;
            }
          }
          vm.deal = angular.copy(data);

          campaignService.getAccounts().then(function () {
            vm.marketers = _.orderBy(campaignService.marketers, 'name');
            vm.advertisers = _.orderBy(campaignService.advertisers, 'name');
            vm.channels = campaignService.getChannels();
            vm.selectedChannel = vm.channels.find(x => x.id == vm.deal.channelId);
            vm.selectedMarketer = vm.marketers.find(m => m.id == vm.deal.marketerId);
            vm.selectedAdvertiser = vm.advertisers.find(m => m.id == vm.deal.advertiserId);
            vm.selectedMarketerChanged();
        });

          $scope.$on('modal.closing', function (event, reason, closed) {
              if (!vm.showCaution && reason == 'cancel' && event.currentScope.audienceForm.$dirty && vm.cancelClicked) {
                  $timeout(function () {
                      vm.showCaution = true;
                  });
                  event.preventDefault();
              }
          });

          $scope.$on('modal.force.close', function (event) {
              $modalInstance.dismiss('cancel');
          });

      }]);

})();
