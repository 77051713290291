(function () {
    'use strict';
    var serviceId = 'config';
    angular.module('app').factory(serviceId, ['appSettings', 'accountService', 'userSettingsService', 'featureService', 'localStorageService', 'columnsService', '$q', configFactory]);

  window.includedColumns = {};

    function configFactory(appSettings, accountService, userSettingsService, featureService, localStorageService, columnsService, $q) {

        var service = {
            appName: appSettings.appName,
            version: appSettings.version,
            settings: appSettings,
            get dateFilter() { return localStorageService.get('date-filter') ? localStorageService.get('date-filter') : 'ThisMonth'; },
            get dateFilterDay() { return localStorageService.get('date-day') ? localStorageService.get('date-day') : null; },
            get dateFilterDateRange() { return localStorageService.get('date-day'); },
            dateRanges: [
                { day: 'Today', key: 'Today', dimention: 'D' },
                { day: 'Yesterday', key: 'Yesterday', dimention: 'D' },
                { day: 'This Week', key: 'ThisWeek', dimention: 'W' },
                { day: 'Last Week', key: 'LastWeek', dimention: 'W' },
                { day: 'Last 7 Days', key: 'Last7Days', dimention: 'J' },
                { day: 'This Month', key: 'ThisMonth', dimention: 'M' },
                { day: 'Last Month', key: 'LastMonth', dimention: 'M' },
                { day: 'Last 30 Days', key: 'Last30Days', dimention: 'K' },
                { day: 'This Quarter', key: 'ThisQuarter', dimention: 'Q' },
                { day: 'Last Quarter', key: 'LastQuarter', dimention: 'Q' },
                { day: 'This Year', key: 'ThisYear', dimention: 'Y' },
                { day: 'Last Year', key: 'LastYear', dimention: 'Y' },
                { day: 'All Time', key: 'AllTime', dimention: 'A' },
                { day: 'Custom Range', key: 'ExactRange', custom: true, dimention: 'D'}
            ],
            chart: {
                templates:
                {
                    serial:
                    {
                        "type": "serial",
                        "theme": "light",
                        "decimalSeparator": '.',
                        "thousandsSeparator": ',',
                        "marginRight": 40,
                        "marginLeft": 40,
                        "color": "#5F646F",
                        "fontSize": "9",
                        "fontFamily": "Lato, Helvetica, arial",
                        "autoMarginOffset": 20,
                        "pathToImages": "http://www.amcharts.com/lib/3/images/",
                        "legend": {
                            "useGraphSettings": true,
                            "valueAlign": "left",
                            "fontSize" : 11
                        },
                        "dataProvider": [],
                        "valueAxes": [{
                            "id": "v1",
                            "color": "#5F646F",
                            "axisColor": "#c1c1c4",
                            "gridAlpha": 0.1,
                            "position": "left"
                        }
                            , {
                            "id": "v2",
                            "axisColor": "#b3e0fe",
                                "color": "#5F646F",
                               "minimum": 0,

                            "position": "right",

                               "autoOffset": true
                            }
                        ],
                        "graphs": [{
                            "valueAxis": "v1",
                            "lineColor": "#ddd",
                            "color": "#5F646F",
                            "bullet": "round",
                            "lineThickness": 1,
                            "bulletBorderThickness": 1,
                            "title": "No title",
                            "valueField": "a",
                            "fillAlphas": 0.2
                        }, {
                            "valueAxis": "v2",
                            "balloonText": "[[category]]<br><b><span style='font-size:13px;'>[[value]]</span></b>",
                            "lineColor": "#b3e0fe",
                            "color": "#5F646F",
                            "bullet": "round",
                            "lineThickness": 1,
                            "bulletBorderThickness": 1,
                            "title": "No -- title",
                            "valueField": "b",
                            "fillAlphas": 0.2,
                        }],
                        "categoryField": "date",
                        "dataDateFormat": "YYYY-MM-DD",
                        "categoryAxis": {
                            "gridAlpha": 0.0,
                            "parseDates": true,
                            "axisColor": "#ddd",
                            "minorGridEnabled": false
                        },
                        "chartCursor": {
                            "categoryBalloonDateFormat": "YYYY-MM-DD",
                            "cursorAlpha": 0,
                            "valueLineEnabled": true,
                            "valueLineBalloonEnabled": true,
                            "valueLineAlpha": 0.5,
                            "fullWidth": true
                        },
                        "export": {
                            "enabled": true,
                            "position": "bottom-right"
                        }
                    },
                    pie: {
                        "type": "pie",
                        "theme": "light",
                        "dataProvider": [],
                        "titleField": "category",
                        "valueField": "value",
                        "labelRadius": 5,
                        "color": "#5F646F",
                        "radius": "42%",
                        "innerRadius": "50%",
                        "labelText": "[[category]]",
                        "export": {
                            "enabled": true
                        }
                    },
                    gauge:
                    {
                        "type": "gauge",
                        "theme": "light",
                        "color": "#5F646F",
                        "axes": [{
                            "axisAlpha": 0,
                            "tickAlpha": 0,
                            "labelsEnabled": false,
                            "startValue": 0,
                            "endValue": 100,
                            "startAngle": 0,
                            "endAngle": 360,
                            "bands": []
                        }],
                        "allLabels": [{
                            "text": "",
                            "x": "49%",
                            "y": "5%",
                            "size": 13,
                            "bold": true,
                            "color": "#5F646F",
                            "align": "right"
                        }],
                        "export": {
                            "enabled": true
                        }
                    }


                },
                colors: ["#c1c1c4", "#3192D4", "#b3e0fe"],
                gridStyle: {
                    hoverable: true,
                    clickable: true,
                    tickColor: "#ddd",
                    borderWidth: 1,
                    borderColor: "#ddd"
                },
                pointStyle: {
                    show: true,
                    lineWidth: 1,
                    fill: true,
                    fillColor: "#ffffff",
                    symbol: "circle",
                    radius: 5

                },
                getBarChartData: function (data) {

                    var s1 = [];
                    var s2 = [];
                    s1.push([2, data[0]]);
                    s2.push([3, data[1]]);

                    var a = [
                        {
                            data: s1,
                            label: 'Unexposed ',
                            bars: {
                                order: 0
                            }
                        },
                        {
                            data: s2,
                            label: 'Exposed',
                            bars: {
                                order: 0
                            }
                        }
                    ];
                    return a;

                },

                options:
                {
                    series: {
                        lines: {
                            show: true,
                            fill: false
                        },
                        points: {
                            show: true,
                            lineWidth: 1,
                            fill: true,
                            fillColor: "#ffffff",
                            symbol: "circle",
                            radius: 5

                        },
                        shadowSize: 0
                    },
                    grid: {
                        hoverable: true,
                        clickable: true,
                        tickColor: "#ddd",
                        borderWidth: 1,
                        borderColor: "#ddd"
                    },
                    colors: ["#c1c1c4", "#3192D4", "#b3e0fe"],
                    legend: {
                        position: 'se',
                        noColumns: 0,
                        margin: [0, -30]
                    },
                    tooltip: false,
                    tooltipOpts: {
                        defaultTheme: true
                    },
                    xaxis: {
                        ticks: [
                            [1, 'Day 1'], [2, 'Day 2'], [3, 'Day 3'], [4, 'Day 4'], [5, 'Day 5'], [6, 'Day 6'], [7, 'Day 7'],
                        ],
                        show: false
                    },
                    yaxes: [
                        { position: "left", show: true }, {
                            position: "right", show: true
                        }
                    ]

                }

            },
            pageSize: getSavedPageSize,
            tables: {
                columns: getTableSetting(),
                hiddenColumns: _getTableHiddenColumns,
                userColumns: getSavedColumns,
                canShowColumn: canShowColumn,
                canShowColumnSystem: canShowColumnSystem,
                getAvailableColumns: getAvailableColumns,
                availableColumns: {},
                save: saveHiddenColumns,
                saveColumns: _saveColumns,
                accountService: accountService,
                featureService: featureService,
                getName: showCustomName,
                getOrderedColumns: _getOrderedColumnsWhenColumnsLoaded,
                getCampaignColumns: _getCampaignColumnsWhenColumnsLoaded
            },
            csvLocationFields: [
                { id: 1, name: "NPI", column: "npi", headers: ["npi", "id"] },
                { id: 16, name: "DGID", column: "dgid", headers: ["dgid"] },
                { id: 2, name: "Name", column: "name", headers: ["name", "full_name"] },
                { id: 3, name: "Address", column: "address", headers: ["address", "street"] },
                { id: 8, name: "City", column: "city", headers: ["city", "town"] },
                { id: 9, name: "State", column: "state", headers: ["state"] },
                { id: 10, name: "Postal Code", column: "zipcode", headers: ["postal", "zipcode", "zip_code", "zip code", "zip"] },
                { id: 12, name: "Latitude", column: "latitude", headers: ["latitude"] },
                { id: 13, name: "Longitude", column: "longitude", headers: ["longitude"] }
            ],
            csvAudienceFields: [
                { id: 16, name: "DGID", column: "dgid", headers: ["dgid"] },
                { id: 4, name: "Phone Number", column: "phone", headers: ["phone", "tel", "telephone"] },
                { id: 5, name: "Email Address", column: "email", headers: ["email"] },
                { id: 2, name: "Name", column: "name", headers: ["name", "full_name"] },
                { id: 6, name: "Last Name", column: "lastname", headers: ["lastname", "last_name", "surname", "sname"] },
                { id: 7, name: "First Name", column: "firstname", headers: ["firstname", "first_name"] },
                { id: 3, name: "Address", column: "address", headers: ["address", "street"] },
                { id: 8, name: "City", column: "city", headers: ["city", "town"] },
                { id: 9, name: "State", column: "state", headers: ["state"] },
                { id: 10, name: "Postal Code", column: "zipcode", headers: ["postal", "zipcode", "zip_code", "zip code", "zip"] },
                { id: 11, name: "Mobile Ad ID", column: "idfa", headers: ["idfa", "aaid", "adv_id"] },
                { id: 14, name: "Hashed NPI", column: "hashednpi", headers: ["hashednpi", "hashed_npi"] },
                { id: 15, name: "Hashed Mobile Ad ID", column: "hashedmaid", headers: ["hashed maid"] }
            ]
        };


        var CAMPAIGN_LEVEL = 'campaign';
        var ADGROUP_LEVEL = 'adgroup';
        var SEGMENT_LEVEL = 'segment';

        return service;

        function _getTableHiddenColumns(table) {
            var columns = getSavedHiddenColumns()[table];
            if (columns && columns.hiddenColumns && columns.hiddenColumns.length > 0)
                return columns.hiddenColumns;
            else
                return columns ? (columns.hiddenColumns || []) : [];
        }

        function _getOrderedColumns(table, excludeHidden, serv) {
            var uc = serv.userColumns();


            var allColumns = angular.copy(serv.columns[table]);
            var userHiddenColumns = serv.hiddenColumns(table);

            if (!uc[table]) {
                var settings = { columnOrder: allColumns, hiddenColumns: [] };
                serv.saveColumns(table, settings);
                uc = serv.userColumns();
            }

            var NON_CAMPAIGN_FEATURES_KEYS = ['PFEE', 'BIFEE', 'DCST', 'AGRMB', 'AGSD', 'AGED', 'AGBCT', 'AGBC', 'AGIG', 'AGRMMP', 'AGRCSD'];

            var that = serv;
            var orderedColumns = [];
            if (uc[table] && uc[table].columnOrder) {
                uc[table].columnOrder.forEach(function (col) {
                    var c = allColumns.find(function (item) { return item.key == col; });
                    if (c) {
                        if (table == 'campaigns' && (c.feature == 'R' + c.key) && NON_CAMPAIGN_FEATURES_KEYS.indexOf(c.key) == -1)
                            c.feature = 'C' + c.key;
                        if (!c.feature || that.featureService.isActive(c.feature)) {
                            if (excludeHidden) {


                                //use new profilesettings => {hiddenColumns:[],columnOrder: []}
                                if (that.canShowColumn(table, c.key) && (!userHiddenColumns || userHiddenColumns.indexOf(c.key) == -1))
                                    orderedColumns.push({ name: that.getName(c.key, table), key: c.key, column: c.column, format: c.format, width: c.width, levels: c.levels });
                            }
                            else
                                orderedColumns.push({ name: that.getName(c.key, table), key: c.key, column: c.column, format: c.format, width: c.width, levels: c.levels });

                        }
                        var index = allColumns.indexOf(c);
                        if (index != -1)
                            allColumns.splice(index, 1);
                    }
                });

                if (allColumns.length > 0) //some of the columns were not ordered...
                {
                    allColumns.forEach(function (c) {
                        if ((table == 'campaigns' || table == 'adGroups' || table == 'adGroupSegments') && c.feature == ('R' + c.key) && NON_CAMPAIGN_FEATURES_KEYS.indexOf(c.key) == -1)
                            c.feature = 'C' + c.key;
                        if (!c.feature || that.featureService.isActive(c.feature)) {
                            if (excludeHidden) {
                                if (that.canShowColumn(table, c.key) && (!userHiddenColumns || userHiddenColumns.indexOf(c.key) == -1))
                                    orderedColumns.push({ name: that.getName(c.key, table), key: c.key, column: c.column, format: c.format, width: c.width, levels: c.levels });
                            }
                            else
                                orderedColumns.push({ name: that.getName(c.key, table), key: c.key, column: c.column, format: c.format, width: c.width, levels: c.levels });

                        }
                    });
                }

                return filterAdjustedIncrementalColumns(orderedColumns);
            }
            else
                return filterAdjustedIncrementalColumns(allColumns);
        }

        function _getCampaignColumns(level, serv) {
            if(!level) {
                level = 'campaign';
            }
            var columns = serv.getOrderedColumns('campaigns', true, serv)
                .filter(function(c){
                    return !c.levels || c.levels.indexOf(level) > - 1;
                });
            if(level != 'campaign'){
                columns.forEach(function(c){
                    if (c.key == 'ID') {
                        if(level == ADGROUP_LEVEL) {
                            c.column = 'adGroupId';
                        } else if (level == SEGMENT_LEVEL) {
                            c.column = 'segmentId';
                        }
                        c.format = "text";
                    }
                });
            }
            return columns;
        }

        function _getOrderedColumnsWhenColumnsLoaded(table, excludeHidden) {
            if (columnsService.columnsLoaded) {
                this.columns = getTableSetting();
                return _getOrderedColumns(table, excludeHidden, this);
            }
            else {
                return columnsService.getUserColumns().then(function () {
                    this.columns = getTableSetting();
                    return _getOrderedColumns(table, excludeHidden, this);
                });
            }
        }


        function _getCampaignColumnsWhenColumnsLoaded(level) {
            if (columnsService.columnsLoaded)
                return _getCampaignColumns(level, this);
            else {
                return columnsService.getUserColumns().then(function () {
                    return _getCampaignColumns(level, this);
                });
            }
        }

        function filterAdjustedIncrementalColumns(columns) {
            //Incremented Sales columns
            var INCREMENTAL_SALES = ['INS', 'IROAS'];
            //Adjusted offline Sales columns
            var ADJUSTED_SALES = ['FAS', 'FASIS', 'ROAS', 'TAS', 'TIS', 'TROAS', 'VROAS','CROAS'];

            //do we need to display adj sales
            var adjustedSalesSet = true;
            //do we need to display incremental sales
            var salesLiftSet = true;

            return columns.filter(function (col) {
                if (!adjustedSalesSet) {
                    if (ADJUSTED_SALES.indexOf(col.key) != -1) // exclude adjusted sales column if present and if option 9200 value != 1
                        return false;
                }
                if (!salesLiftSet)
                    return INCREMENTAL_SALES.indexOf(col.key) == -1;

                return true;
            });
        }


        function saveHiddenColumns(table, columnsList) {

            var hc = getSavedHiddenColumns();
            hc[table] = columnsList;
            //localStorage['hiddenColumns'] = JSON.stringify(hc);
            var settings = sessionStorage['profileData'] ? JSON.parse(sessionStorage['profileData']) : {};


            settings.hiddenColumns = hc;
            sessionStorage['profileData'] = JSON.stringify(settings)


        }
        function _saveColumns(table, columnsList) {

            var uc = this.userColumns();
            uc[table] = columnsList;
            //localStorage['hiddenColumns'] = JSON.stringify(hc);
            var settings = sessionStorage['profileData'] ? JSON.parse(sessionStorage['profileData']) : {};


            settings.columns = uc;
            sessionStorage['profileData'] = JSON.stringify(settings)


        }

        function getTableSetting() {
            function getCommonColumns() {
                if (columnsService.columnsLoaded)
                    return columnsService.getUserColumns();
                else
                    return columnsService.getUserColumns().then(function (data) {
                        return data;
                    });
            };

            var ts = {
                retailMarketPlace : [
                    { name: 'Total Revenue', key: 'REV' }, { name: 'Ad Revenue', key: 'AR' },
                    { name: 'Offer Revenue', key: 'CR' }, // { name: 'Redemption Value', key: 'CV' },
                    { name: 'Redemption', key: 'ShS' }, { name: 'Shoppers Reached', key: 'ShR' },
                    { name: 'Cost / Untis Moved', key: 'CUMO' } // { name: 'Investment Ratio', key: 'INVRAT' },
                    //  { name: 'Consumer Value Ratio', key: 'CONSVALRAT' }
                ],
                getColumnNames : function () {
                    if (columnsService.columnsLoaded) {
                        var common = getCommonColumns();
                        var acc = accountService.get();
                        if (acc && (acc.accountType != 2 && acc.accountType != 4)) {
                            //remove view/click columns from advertiser account
                            common = _.omit(common, ['VFPA', 'CFPA', 'VOPA', 'COPA', 'VOP', 'COP', 'VFP', 'CFP', 'VPA', 'CPA', 'VP', 'CP', 'VROAS', 'CROAS']);

                        }
                        return common;
                    }
                    return getCommonColumns().then(function (common) {

                        var acc = accountService.get();
                        if (acc && (acc.accountType != 2 && acc.accountType != 4)) {
                            //remove view/click columns from advertiser account
                            common = _.omit(common, ['VFPA', 'CFPA', 'VOPA', 'COPA', 'VOP', 'COP', 'VFP', 'CFP', 'VPA', 'CPA', 'VP', 'CP', 'VROAS', 'CROAS']);

                        }
                        return common;
                    });
                }
            }
            //COMBINED SALES - NOT NEEDED
            if (columnsService.columnsLoaded)
                ProcessTS(ts.getColumnNames(), ts);
            else
                ts.getColumnNames().then(function (data) {
                    ProcessTS(data, ts);
                });
            return ts;
        }

        function ProcessTS(columns, ts) {
            var isFullBasket = userSettingsService.getFullBasket();
            if (!isFullBasket) {

                var fullCartColumns = userSettingsService.fullBasketColumns();
                var advertiserColumns = {};
                for (var prop in columns) {
                    if (fullCartColumns.indexOf(prop) == -1)
                        advertiserColumns[prop] = columns[prop]; //.name = 'Full Basket ' + columns[prop].name;
                }
                columns = _.clone(advertiserColumns);
            }

            ts.adGroups = _.concat([{ name: 'Status', key: 'STATUS', column: 'status', format: 'text', width: 90, levels: [ADGROUP_LEVEL, CAMPAIGN_LEVEL] },
            { name: 'ID', key: 'ID', column: 'adGroupId', format: 'text', feature: 'ENTITYID', width: 80 }
            ]
                , _.values(_.omit(_.clone(columns), 'PROG','CAT','PROD','ADVERTISER'))); //, 'AGBC', 'AGBCT', 'AGRMM', 'AGRMB', 'AGRCSD', 'AGSD', 'AGED', 'AGIG', 'AGCPMR')));

            //set campaign-based feature name for campaign tab columns
            ts.adGroups.forEach(function (item) {
                if (item.feature === 'V-FC')
                    item.feature = 'V-FCC';
                if (item.feature === 'C-FC')
                    item.feature = 'C-FCC';
            });

            //remove COOP fin columns from campaigns views
            ts.campaigns = angular.copy(ts.adGroups).filter(x =>  x.key !=" CHAN");
            ts.campaigns.forEach(function (c) {
                if (c.key == 'ID') {
                    c.column = 'campaignId';
                    c.format = "text";
                }
            });

            var segmentColumns = ts.adGroups.filter(function (c) {
                return ['AGMMBC', 'AGCPMR'].indexOf(c.key) !== -1;
            });

            ts.adGroupSegments =
                angular.copy(ts.campaigns)
                    .filter(function (c) {
                        return ['STATUS', 'RET', 'CHAN', 'LCLCF', 'LCTRF', 'AGRMMP', 'AGRMB', 'RMP', 'AGRM', 'DCST', 'PFEE', 'BIFEE', 'DCST'].indexOf(c.key) === -1;
                    })
                    .concat(segmentColumns);
            ts.adGroupSegments.forEach(function (c) {
                if (c.key == 'ID') {
                    c.column = 'segmentId';
                    c.format = "text";
                }
            });

            var salesPerformanceKeys = [
                'IDNSLS', 'IRDS', 'TRDS', 'TSLS', 'UDNSLS', 'URDS','STR', 'AOV', 'IAOV', 'VSTS', 'IVSTS'
            ];

            ts.salesPerformanceReports = _.values(_.clone(columns)).filter(function(c){
                return salesPerformanceKeys.indexOf(c.key) > -1;
            });

            ts.customReports = _.values(_.clone(columns)).filter(function (it) {
                return ['AGPT'].concat(salesPerformanceKeys).indexOf(it.key) === -1 ;
            });


            var sellPerformanceColumns = [
                { name: 'Impressions', key: 'IMP', format: '0', column: 'impressions', type: 'd'},
                { name: 'CPM', key: 'RPM', format: '$', column: 'RevenuePerMile', type: 'd'},
                { name: 'Clicks', key: 'CLC', format: '0', column: 'clicks', type: 'd'},
                { name: 'Impressions Requests', key: 'IMPR', format: '0', column: 'impressions requests', type: 'd'},
                { name: 'HCPs Visited', key: 'HCPV', format: '0', column: 'HCPs Visited', type: 'd'},
                { name: 'Unique HCPs', key: 'UNIQHCP', format: '0', column: 'Unique HCPs', type: 'd'},
                { name: 'View Measured Impressions', key: 'VIMPM', format: '0', column: 'View Measured Impressions', type: 'd'},
                { name: 'Viewable Impressions', key: 'VIMP', format: '0', column: 'Viewable Impressions', type: 'd'},
                { name: 'Viewability', key: 'VB', format: '100%', column: 'Viewability', type: 'd'},
                { name: 'Fill Rate', key: 'FR', format: '%', column: 'FillRate', type: 'd'},
                { name: 'CTR', key: 'CTR', format: '%', column: 'CTR', type: 'd'},
                { name: 'Revenue', key: 'REV', format: '$', column: 'revenue', type: 'fn'},
                { name: 'Direct Sold Revenue', key: 'DSRev', format: '0', column: 'DSRev', type: 'fn'},
                { name: 'Net Revenue', key: 'NetREV', format: '$', column: 'NetRevenue', type: 'fn'},
                { name: 'Revenue Share', key: 'RevShare', format: '%', column: 'RevShare', type: 'fn'},
            ];

            var sitePerformanceColumns = [
                { name: 'Impressions', key: 'B_IMP', format: '0', column: 'impressions', type: 'd'},
                { name: 'Clicks', key: 'B_CLC', format: '0', column: 'clicks', type: 'd'},
                { name: 'CTR', key: 'B_CTR', format: '%', column: 'ctr', type: 'd'},
                { name: 'Media Cost', key: 'B_MEDIA_COST', format: '$', column: 'media cost', type: 'fn'},
                { name: 'Uniques', key: 'B_UNIQUES', format: '0', column: 'uniques', type: 'd'},
                { name: 'Media Type', key: 'B_CHAN', format: 'text', column: 'channelID', type: 'r'},
                { name: 'Average Frequency', key: 'B_AVEF', format: '0.00', column: 'average frequency', type: 'd'},
                { name: 'Spend', key: 'B_SPEND', format: '$', column: 'Spend', type: 'fn'},
                { name: 'Data CPM', key: 'B_DCPM', format: '$', column: 'Data CPM', type: 'fn'},
                { name: 'Data Cost', key: 'B_DCST', format: '$', column: 'Data Cost', type: 'fn'},
                { name: 'Partner Fees', key: 'B_PFS', format: '$', column: 'Partner Fees', type: 'fn'},
                { name: 'View Measured Impressions', key: 'B_VMIMP', format: '0', column: 'View Measured Impressions', type: 'd'},
                { name: 'Viewable Impressions', key: 'B_VIMP', format: '0', column: 'Viewable Impressions', type: 'd'},
                { name: 'Post View Conversions', key: 'B_PVCV', format: '0', column: 'Post View Conversions', type: 'd'},
                { name: 'Post Click Conversions', key: 'B_PCCV', format: '0', column: 'Post Click Conversions', type: 'd'},
                { name: 'Unique HCPs', key: 'B_UNIQHCP', format: '0', column: 'Unique HCPs', type: 'd'},
                { name: 'Total CPM', key: 'B_ACPM', format: '$', column: 'Total CPM', type: 'fn'},
                { name: 'Media CPM', key: 'B_MACPM', format: '$', column: 'Media  CPM', type: 'fn'},
                { name: 'Viewability', key: 'B_VB', format: '100%', column: 'Viewability', type: 'd'},
                { name: 'Cost Per HCP', key: 'B_HCPCOST', format: '$', column: 'Cost Per HCP', type: 'fn'}
            ]

            ts.siteReports = sitePerformanceColumns;
            ts.sellReports = sellPerformanceColumns;


            ts.salesReports = _.concat(_.values(_.pick(_.clone(columns), 'OP', 'OPA', 'FP', 'FPA', 'TP', 'VOP', 'VOPA', 'VFP', 'VFPA',
                'COP', 'COPA', 'CFP', 'CFPA', 'VP', 'VPA', 'CP', 'CPA', 'TPA', 'COM',
                'FOP', 'FOPA', 'FFP', 'FFPA', 'FTP', 'FVOP', 'FVOPA', 'FVFP', 'FVFPA',
                'FCOP', 'FCOPA', 'FCFP', 'FCFPA', 'FVP', 'FVPA', 'FCP', 'FCPA', 'FTPA'
            )),
                [
                    { name: 'Marketer', key: 'RET', format: 'text', column: 'ret', type: 'r', feature: 'BYMarketer' },
                    { key: 'ADVERTISER', name: 'Product Advertiser', format: 'text', type: 'r' }
                ]
            );

            ts.reportingErrorsOld = [
                { name: 'Account', key: 'AN', column: 'accountName', format: 'text' },
                { name: 'Lasso Impressions', key: 'TID', column: 'tdmImpressions', format: '0' },
                { name: 'Provider Impressions', key: 'AID', column: 'sourceImpressions', format: '0' },
                { name: 'Impression Difference', key: 'ID', column: 'impressionDiff', format: '0' },
                { name: 'Lasso Clicks', key: 'TLD', column: 'tdmClicks', format: '0' },
                { name: 'Provider Clicks', key: 'ALD', column: 'sourceClick', format: '0' },
                { name: 'Clicks Difference', key: 'CLD', column: 'clickDiff', format: '0' },
                { name: 'Lasso Other Actions', key: 'TCD', column: 'tdmconversions', format: '0' },
                { name: 'Provider Other Actions', key: 'ACD', column: 'sourceConversions', format: '0' },
                { name: 'Other Actions Difference', key: 'COD', column: 'conversionDiff', format: '0' },
                { name: 'Lasso Uniques', key: 'TUD', column: 'tdmUniques', format: '0' },
                { name: 'Provider Uniques', key: 'AUD', column: 'sourceUniques', format: '0' },
                { name: 'Uniques Difference', key: 'CUD', column: 'uniquesDiff', format: '0' },
                { name: 'Updated Date', key: 'UD', column: 'updatedDate', format: '0' }


            ];


            ts.UPCs = [{ name: 'Advertiser', key: 'BR' }, { name: 'Category', key: 'CAT' }, { name: 'Subcategory', key: 'SC' }, { name: 'Description', key: 'DSC' }, { name: 'UPC #', key: 'UID' }];
            ts.couponCampaigns = [{ name: 'Offers Delivered', key: 'CD' }, { name: 'Clips', key: 'CL' }, { name: 'Clip Rate', key: 'CLR' }, { name: 'Redemptions', key: 'RD' },
            { name: 'Redemption Rate', key: 'RDR' }, { name: 'Cost/Unit Moved', key: 'CUM' }, { name: 'Spend', key: 'SP' }, { name: 'Cost per Clip', key: 'CPC' },];
            ts.couponAdGroups = [{ name: 'Offers Delivered', key: 'CD' }, { name: 'Clips', key: 'CL' }, { name: 'Clip Rate', key: 'CLR' }, { name: 'Redemptions', key: 'RD' },
            { name: 'Redemption Rate', key: 'RDR' }, { name: 'Cost/Unit Moved', key: 'CUM' }, { name: 'Spend', key: 'SP' }, { name: 'Cost per Clip', key: 'CPC' },];
            ts.couponAdGroupUpcs = [{ name: 'Offers Delivered', key: 'CD' }, { name: 'Clips', key: 'CL' }, { name: 'Clip Rate', key: 'CLR' }, { name: 'Redemptions', key: 'RD' },
            { name: 'Redemption Rate', key: 'RDR' }, { name: 'Cost/Unit Moved', key: 'CUM' }, { name: 'Spend', key: 'SP' }, { name: 'Cost per Clip', key: 'CPC' },];

            ts.retailCategories = [{ name: 'Total Revenue', key: 'TR' }, { name: 'Ad Revenue', key: 'AR' }, { name: 'Offer Revenue', key: 'CR' }, { name: 'Redemption Value', key: 'CV' },
            { name: 'Shopper Savings', key: 'ShS' }, { name: 'Digital Shoppers', key: 'DS' }, { name: 'Rev / Shopper', key: 'RS' }, { name: '# of Advertisers Live', key: 'BL' }, { name: 'Invest Ratio', key: 'INVRAT' }, { name: 'Value Ratio', key: 'VALRAT' }];

            ts.users = [{ name: 'First Name', key: 'FN' }, { name: 'Last Name', key: 'LN' }, { name: 'Account', key: 'ACC' }, { name: 'Roles', key: 'R' }, { name: 'Lock User', key: 'LCK' }, { name: 'Impersonate', key: 'IMP' }];
            ts.locations = [{ name: 'Location Group', key: 'AT' }, { name: 'ID', key: 'ID' }, { name: 'Status', key: 'ST' }, { name: 'Location Type', key: 'LT' }, { name: 'Total Addresses', key: 'TA' }, { name: 'File Url', key: 'LK' }, { name: 'Matched Addresses', key: 'MA' }, { name: 'Account', key: 'AC' }, { name: 'Modified Date', key: 'MD' }];
            ts.accounts = [{ name: 'ID', key: 'ID' }, { name: 'Account Type', key: 'AT' }, { name: 'Status', key: 'ST' }, { name: 'Manager', key: 'MN' }, { name: 'Modified Date', key: 'MD' }];
            ts.reportingErrors = [{ name: 'Advertiser Account', key: 'AN' }, { name: 'Campaign Name', key: 'CN' }, { name: 'AdGroup Name', key: 'AG' }, { name: '12DM Impressions', key: 'TID' }, { name: 'Provider Impressions', key: 'AID' }, { name: 'Impression Difference', key: 'ID' }, { name: '12DM Clicks', key: 'TLD' }, { name: 'Provider Clicks', key: 'ALD' }, { name: 'Click Difference', key: 'CLD' },
                { name: 'Lasso Other Actions', key: 'TCD' }, { name: 'Provider Other Actions', key: 'ACD' }, { name: 'Other Actions Difference', key: 'COD' },
                { name: 'Lasso Uniques', key: 'TUD' }, { name: 'Provider Uniques', key: 'AUD' }, { name: 'Uniques Difference', key: 'CUD' }, { name: 'Updated Date', key: 'UD' }];

            ts.topSegments = [{ name: 'Uniques Available', key: 'UNQM' }, { name: 'Uniques Reached', key: 'UNR' }, { name: '% Reached', key: 'PR' }, { name: 'Frequency', key: 'FREQ' }];


            ts.adGroupsInNewCampaign = [];
        }

        //columns are optional
        function getAvailableColumns(table, columns) {
            var exclusions = this.hiddenColumns(table);
            var incl = [];
            exclusions = exclusions ? exclusions : [];
            var cols = !columns ? this.columns[table] : columns;

            for (var i = 0; i < cols.length; i++) {
                if (exclusions.length == 0 || $.inArray(cols[i].key, exclusions) == -1) {
                    incl.push(cols[i].key);
                }
            }
            return incl;
        }

        function getSavedHiddenColumns() {
            var data = sessionStorage['profileData'];
            if (data) {
                var col = JSON.parse(data);
                return col.columns ? col.columns : (col.hiddenColumns ? col.hiddenColumns : {});
            }
            else {
                var tables = {};
                return tables;
            }
        }
        function getSavedColumns() {
            var data = sessionStorage['profileData'];
            if (data) {
                var col = JSON.parse(data);
                return col.columns ? col.columns : {};
            }
            else {
                var tables = {};
                return tables;
            }
        }



        function getSavedPageSize() {
            const DEFAULT_PAGE_SIZE = 25;
            var data = sessionStorage['profileData'];
            if (data) {
                var d = JSON.parse(data);
                if (d.gridSettings && d.gridSettings.pageSize){
                    return d.gridSettings.pageSize;
                }
                return DEFAULT_PAGE_SIZE;
            }
            else {
                return DEFAULT_PAGE_SIZE;
            }
        }

        function showCustomName(columnKey, tableName) {
            var column = _.find(this.columns[tableName], { 'key': columnKey });
            if (this.accountService) {
                return this.accountService.getColumnName(columnKey, column.name);

            }
            return column.name;
        }


        function canShowColumnSystem(key) {


            if (this.accountService) {

                if (this.accountService.isColumnHidden(key))
                    return false;

            }
            return true;

        }

        function canShowColumn(table, column, columns) {

            if (!featureService.isActive(column))
                return false;


            if (!this.availableColumns[table]) {
                this.availableColumns[table] = this.getAvailableColumns(table, columns);
            }

            if (this.accountService) {

                if (this.accountService.isColumnHidden(column))
                    return false;

            }
            return ($.inArray(column, this.availableColumns[table]) != -1);




        }

    }
})();
