(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:fallbackSrc
 * @fallbackSrc directive
 * # <img fallback-src="image source"  />
 * shows alternative image if main image fails to load
 */

    angular.module('app').directive('fallbackSrc', function () {
        var fallbackImage = {
            link: function postLink(scope, element, attr) {

                var errorHanlder = function () {
                    element.off('error', errorHanlder);
                    var newSrc = attr.fallbackSrc;// || missingDefault;
                    if (element[0].src !== newSrc) {
                        element[0].src = newSrc;
                    }
                };
                element.on('error', errorHanlder);

              
            }
        }
        return fallbackImage;
    });

})();
