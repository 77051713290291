(function () {
    'use strict';
    /**
     * @ngdoc function
     * @name payMeApp.controller:usersController
     * @User management controller
     * # usersController
     * Controller of the app
     */


    angular.module('app')
      .controller('usersController', ['$scope', '$timeout', '$uibModal', '$filter', 'hub', 'authService', '$routeParams', function ($scope, $timeout, $modal, $filter, hub, authService, $routeParams) {

          var vm = this;
          vm.page = hub.page;
          hub.page.setTitle('User Management');
          vm.config = hub.config;

          vm.selectedRole = vm.roleNames[-1];


          vm.sort = {
              users: { column: 'userName', sortDesc: true, keyword: '' }
          };
          vm.users = [];
          vm.selectedTab = 0;
          vm.page.setTitle('User Management');
          vm.displayRoles = function (roles) {
              return roles.join(', ');
          }
          vm.isObserver = authService.isObserverRole();

          vm.selectedUser = null;
          vm.selectUser = function (u) {
              vm.selectedUser = u;
          }
          vm.shouldOpenModal = function () {
            return document.querySelector('.modal-container') === null;
          }
          vm.editUser = function (user) {
             if(!vm.shouldOpenModal()) return;
              if (!user) {
                  vm.openModal({});
              }
              else {
                  vm.openModal(user);
              }
          };

          vm.impersonate = function (user) {
              authService.impersonate(user.userName)
          };

          vm.openModal = function (user) {
              vm.selectUser(user);
              var modalInstance = $modal.open({
                  templateUrl: 'views/admin/user.html',
                  animation: true,
                  backdrop: 'static',
                  controller: 'userManagerController',
                  controllerAs: 'vm',
                  size: 'lg',
                  resolve: {
                      user: function () {
                          return user;
                      },
                      account: function () {
                          return vm.selectedAccount;
                      },
                      accountId: function () {
                          return vm.accountId;
                      }
                  }
              });

              modalInstance.result.then(function (user) {

              }, function (result) {
                  if (result == "cancel")
                      return;

                  if (!result.id) {
                      vm.users.push(result);
                  }
                  else {
                      vm.selectedUser = result;
                      for (var i = 0; i < vm.users.length; i++) {
                          if (vm.users[i].id == result.id)
                              vm.users[i] = result;
                      }
                  }
              });
          };

          vm.getData = function () {
              if (typeof vm.accountId !== 'undefined') {
                  hub.webapicontext.getUsersByAccountID(vm.accountId).then(function (d) {
                      vm.users = d.users;
                      vm.accountName = d.accountName;
                  });
              }
              else {
                  hub.webapicontext.getUsers().then(function (d) {
                      vm.users = d;
                  });
              }

          }
          vm.getData();
          return vm;
      }]);

})();
