(function () {
    'use strict';

    var serviceId = 'forecastService';
    angular.module('app').factory(serviceId, ['$q', 'context', forecastServiceFactory]);

    function forecastServiceFactory($q, context) {

        var service = {
            calculatePotentialReach: function (includedGroups, recencyFrom, recencyTo, taxonomyId, channelId, advertiserId) {
                var deferred = $q.defer();
                context.webapi.estimateAudienceReach(includedGroups, recencyFrom, recencyTo, taxonomyId, channelId, advertiserId).then(function (d) {
                    deferred.resolve(d.potentialReach);
                },
                function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            filterSelectedCategoriesPotentialReachByLocation: function (selectedLocations, potentialReach, locationsExcluded) {
                var postcodePercentage = 0;

                if (locationsExcluded)
                    postcodePercentage = 1;

                selectedLocations.forEach(function (item) {
                    if (item.type === "PostalCode" || item.type === "Region" || item.type === "DMA" || item.type === "City") {
                        if (locationsExcluded)
                            postcodePercentage = postcodePercentage - item.populationPercentage;
                        else
                            postcodePercentage = postcodePercentage + item.populationPercentage;
                    }

                });
                    return Math.ceil(potentialReach * postcodePercentage / 1000) * 1000;
            },

        };
        return service;

    }
})();
