(function () {
  'use strict';

  angular.module('app')
    .directive('amchart', function () {
      return {
        template: '<div id="{{chartId}}" class="{{cssClass}}" style="width: {{width}}px;height: {{height}}px;margin: auto;"></div>',
        restrict: 'E',
        scope: { chartId: '@', width: '@', height: '@', type: '@', data: '=', options: '=', cssClass: '@' },
        replace: true,
        link: function (scope, ele, attrs) {

        },
        controller: ['$scope', '$rootScope', '$parse', 'hub', '$timeout', function ($scope, $rootScope, $parse, hub, $timeout) {
          var vm = this;
          var chart = null;
          vm.data = [];
          vm.chartOptions = null;

          $scope.$watch('data', function (value) {
            vm.data = value;
            if (vm.data) {
              if (!chart)
                vm.buildChart();
              else {
                chart.dataProvider = vm.data;
                chart.validateData();
              }
            }
          });
          $scope.$watch('options', function (value) {
            $timeout(function () {
              vm.chartOptions = value;
              if (vm.chartOptions) {
                if (vm.data)
                  vm.chartOptions.dataProvider = vm.data;

                chart = AmCharts.makeChart($scope.chartId, vm.chartOptions);
              }
            });
          });

          $timeout(function () {
            if (chart)
              chart.invalidateSize();
          }, 1000);

          vm.buildChart = function () {
            if ($scope.type == 'gauge') {
              chart = AmCharts.makeChart($scope.chartId, {
                "type": "gauge",
                "theme": "light",
                "axes": [{
                  "axisAlpha": 0,
                  "tickAlpha": 0,
                  "labelsEnabled": false,
                  "startValue": 0,
                  "endValue": 100,
                  "startAngle": 0,
                  "endAngle": 360,
                  "bands": [{
                    "color": "#66d6c3",
                    "startValue": 0,
                    "endValue": 65,
                    "radius": "100%",
                    "innerRadius": "75%"
                  }, {
                    "color": "#eee",
                    "startValue": 65,
                    "endValue": 100,
                    "radius": "100%",
                    "innerRadius": "75%"
                  }]
                }],
                "allLabels": [{
                  "text": "",
                  "x": "49%",
                  "y": "5%",
                  "size": 15,
                  "bold": true,
                  "color": "#84b761",
                  "align": "right"
                }],
                "export": {
                  "enabled": true
                }
              });
            } else if ($scope.type == 'pie') {
              chart = AmCharts.makeChart($scope.chartId, {
                "type": "pie",
                "theme": "light",
                "dataProvider": vm.data

                ,
                "titleField": "fullName",
                "valueField": "impressions",
                "labelRadius": 5,

                "radius": "42%",
                "innerRadius": "60%",

                "export": {
                  "enabled": true
                }
              });
            } else if ($scope.type == 'serial') {
              chart = AmCharts.makeChart($scope.chartId, {
                "type": "serial",
                "theme": "light",
                "dataProvider": vm.data,

                "valueAxes": [{
                  "gridColor": "#FFFFFF",
                  "gridAlpha": 0.2,
                  "dashLength": 0
                }],
                "gridAboveGraphs": true,
                "startDuration": 1,
                "graphs": [{
                  "balloonText": "[[adSize]]: <b>[[views]]%</b>",
                  "fillAlphas": 0.8,
                  "lineAlpha": 0.2,
                  "fillColorsField": "color",
                  "type": "column",
                  "valueField": "views"
                }],

                "chartCursor": {
                  "categoryBalloonEnabled": false,
                  "cursorAlpha": 0,
                  "zoomable": false
                },
                "categoryField": "adSize",
                "categoryAxis": {
                  "gridPosition": "start",
                  "gridAlpha": 0,
                  "tickPosition": "start",
                  "tickLength": 20
                },
                "export": {
                  "enabled": true
                }

              });
            }
          }
          return vm;
        }],
        controllerAs: 'vm'
      }
    });

})();
