(function () {
    'use strict';
    /**
     * @ngdoc function
     * @name app.directive:categoriesControl
     * @categoriesControl directive
     * # <categories-control/>
     * [DESCRIPTION]
     */

    angular.module('app').directive('segment', ['$compile', function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            link: function (scope, ele, attrs) {
                var segmentType = attrs.type;
                scope.$watch(attrs.segment, function (segment) {
                    var name = segment.fullName;
                    if (!name) {
                        name = segment.bc ? segment.bc.replace('|>', ' > ') + ' > ' + segment.name : segment.name;
                    }

                    var fullIdsList = segment.fullIdsList;
                    if (!segment.fullIdsList) {
                        fullIdsList = '' + segment.id + '';
                    }

                    var separator = ' > ';
                    var segments = name.split(separator);
                    var segmentsIDs = fullIdsList.split(separator);
                    var output = '';
                    var keyword = '';
                    separator = '<span>' + separator + '</span>';

                    segments.forEach(function (item, index) {
                        keyword += (keyword.length > 0 ? separator : '') + item.replace(/'/g, "\\'");
                        var id = segmentsIDs[index];

                        if (segmentType == "selected") {
                            output += (output.length > 0 ? separator : '') + '<a ng-click="$event.stopPropagation(); vm.toggleSegment(segment)">' + item + '</a>';
                        } else if (index == segments.length - 1) {
                            output += (output.length > 0 ? separator : '') + '<a style="{{ !vm.segmentChecked(c) ? \'\' : \'\' }}">' + item + '</a>';
                        } else {
                            output += (output.length > 0 ? separator : '') + '<a ng-click="vm.loadChildren(\'' + id + '\', \'' + encodeURIComponent(item.replace(/'/g, "%27")) + '\')">' + item + '</a>';
                        }
                    });

                    if (segment.childCount > 0) {
                        output += separator;
                    }

                    ele.html(output);
                    $compile(ele.contents())(scope);
                });
            }
        };
    }]);


    angular.module('app')
        .directive('categoriesControl', ['$location', function () {
            return {
                template: require('@views/app/categories-control.html').default,
                restrict: 'E',
                controllerAs: 'vm',
                scope: { mode: '=', locked: '=', channelIndex: '=', controlDisabled: '=', viewMode: '=', taxonomyId: '=', audienceType: '=', audienceTypeId: '=', isDsm: '=', isConversion: '=', isOptimizationConversion: '=', isInventory: '=', inventoryType: '=', isLocationGroups: '=', isStatesLocations: '=', isDmaLocations: '=', isCitiesLocations: '=', isBrandSafety: '=', includedSegments: '=', includedGroups: '=', channelId: '=', isCustom: '=', advertiserId: '=', isConsumer: '=', isLassoTrigger: '=' },
                controller: ['$rootScope', '$scope', '$parse', 'hub', 'campaignService', '$timeout', 'dragulaService', 'featureService', function ($rootScope, $scope, $parse, hub, campaignService, $timeout, dragulaService, featureService) {
                    var vm = this;

                    vm.log = hub.getLog('Campaign Management');
                    vm.logPreview = hub.getLog('Campaign Management', 'preview');

                    vm.viewMode = $scope.viewMode;
                    vm.channelIndex = $scope.channelIndex;

                    vm.includedGroups = $scope.includedGroups;
                    vm.includedSegments = $scope.includedSegments;
                    vm.selectedSegments = [];
                    if (vm.includedGroups) {
                        vm.includedGroups.forEach(function (g) {
                            g.segments.forEach(function (s) {
                                vm.selectedSegments.push(s);
                            });
                        });
                        var maxIdGroup = _.maxBy(vm.includedGroups, 'id');
                        vm.groupId = maxIdGroup ? maxIdGroup.id + 1 : 1;
                    }

                    vm.disableExcludeSegment = false;
                    if ($scope.isBrandSafety)
                        vm.searchEntity = 'Brand Safety segments';
                    else if ($scope.isStatesLocations)
                        vm.searchEntity = 'states';
                    else if ($scope.isLocationGroups)
                        vm.searchEntity = 'locations';
                    else if ($scope.isInventory)
                        vm.searchEntity = 'Deals';
                    else if ($scope.isConversion){
                        vm.searchEntity = 'Conversion';
                        console.log('Conversion: included-segments', vm.includedSegments);
                        console.log('Conversion: selected-items', vm.selectedItems);
                        vm.disableExcludeSegment = true;
                    }
                    else if ($scope.isOptimizationConversion){
                        vm.searchEntity = 'Conversion';
                        vm.disableExcludeSegment = true;
                        console.log('Optimization Conversion: included-segments', vm.includedSegments);
                        console.log('Optimization Conversion: selected-items', vm.selectedItems);
                    }
                    else
                        vm.searchEntity = 'audiences';

                    if ((!vm.includedGroups || vm.includedGroups.length == 0) && $scope.channelId == 3) {
                        vm.targetAndBetweenSegmentGroups = true;
                    }

                    if (vm.includedSegments)
                        vm.selectedSegments = vm.includedSegments;
                    else
                        vm.includedSegments = [];

                    vm.selectedMarketerId = campaignService.getSelectedMarketerId();

                    vm.maxLength = 50;
                    vm.loadedLength = 0;
                    vm.loadMoreStep = 100;
                    vm.showCustomLoadMore = false;
                    vm.controlDisabled = $scope.controlDisabled;
                    vm.locked = $scope.locked;
                    vm.showAllMode = true;
                    vm.taxonomyId = $scope.taxonomyId;
                    vm.audienceTypeId = $scope.audienceTypeId;
                    vm.isBrandSafety = $scope.isBrandSafety;

                    $scope.sortBy = function (item) {
                        return item.bc + item.name;
                    };

                    if ($scope.mode === 'audience_popup')
                        vm.width = 355;
                    else if ($scope.mode === 'audience_wizard')
                        vm.width = 415;
                    else if ($scope.mode === 'creative_popup')
                        vm.width = 370;
                    else if ($scope.mode === 'creative_wizard')
                        vm.width = 615;
                    else if ($scope.mode === 'advertisers_categories' || $scope.mode === 'advertisers')
                        vm.width = 350;

                    function sortWeatherSegments(a, b){
                        const segNames = ["Weather Conditions", "Weather Forecast", "Flu Conditions", "Nielsen"];
                        if(segNames.indexOf(a.name.trim()) < segNames.indexOf(b.name.trim())){
                            return -1;
                        }else if(segNames.indexOf(a.name.trim()) > segNames.indexOf(b.name.trim())){
                            return 1;
                        }
                        return 0;
                    }

                    $scope.$on('hideLoadMore', function () {
                        vm.showCustomLoadMore = false;
                    });

                    $scope.$on('segmentsChanged', function (event, data) {
                        if (!$scope.isOptimizationConversion && !$scope.isConversion && !$scope.isBrandSafety && !$scope.isStatesLocations && !$scope.isCitiesLocations && !$scope.isDmaLocations && !$scope.isLocationGroups && !$scope.isInventory && data.channelIndex == vm.channelIndex) {
                            vm.segments = angular.copy(_.orderBy(data.data, 'name'));
                            if(vm.segments && vm.segments[0] && vm.segments[0].segments && vm.segments[0].segments[0] && vm.segments[0].segments[0].name && vm.segments[0].segments[0].audienceType.match(/weather/i)){
                                vm.segments.sort(sortWeatherSegments);
                            }
                            setLoaded(vm.maxLength);
                            vm.showCustomLoadMore = $scope.isCustom && vm.segments.length > vm.maxLength;
                        }
                    });

                    $scope.$on('brandSafetySegmentsChanged', function (event, data) {
                        if ($scope.isBrandSafety && data.channelIndex == vm.channelIndex) {
                            vm.segments = angular.copy(data.data);
                            setLoaded(vm.maxLength);
                        }
                    });

                    $scope.$on('conversionSegmentsChanged', function (event, data) {
                        if (($scope.isConversion || $scope.isOptimizationConversion) && data.channelIndex == vm.channelIndex) {
                            vm.segments = angular.copy(data.data);
                            setLoaded(vm.maxLength);
                        }
                    });

                    $scope.$on('stateLocationSegmentsChanged', function (event, data) {
                        if ($scope.isStatesLocations && data.channelIndex == vm.channelIndex)
                            vm.segments = angular.copy(data.data);
                        setLoaded(vm.maxLength);
                    });

                    $scope.$on('cityLocationSegmentsChanged', function (event, data) {
                        if ($scope.isCitiesLocations && data.channelIndex == vm.channelIndex)
                            vm.segments = angular.copy(data.data);
                        setLoaded(vm.maxLength);
                    });

                    $scope.$on('dmaLocationSegmentsChanged', function (event, data) {
                        if ($scope.isDmaLocations && data.channelIndex == vm.channelIndex)
                            vm.segments = angular.copy(data.data);
                        setLoaded(vm.maxLength);
                    });


                    $scope.$on('locationGroupsSegmentsChanged', function (event, data) {
                        if ($scope.isLocationGroups && data.channelIndex == vm.channelIndex)
                            vm.segments = angular.copy(data.data);
                        setLoaded(vm.maxLength);
                    });

                    $scope.$on('inventoryByCategorySegmentsChanged', function (event, data) {
                        if ($scope.isInventory && $scope.inventoryType == 'ByCategory' && data.channelIndex == vm.channelIndex)
                            vm.segments = angular.copy(data.data);
                        setLoaded(vm.maxLength);
                    });

                    $scope.$on('inventoryByPublisherSegmentsChanged', function (event, data) {
                        if ($scope.isInventory && $scope.inventoryType == 'ByPublisher' && data.channelIndex == vm.channelIndex)
                            vm.segments = angular.copy(data.data);
                        setLoaded(vm.maxLength);
                    });


                    $scope.$on('taxonomyChanged', function (event, data) {
                        vm.taxonomyId = data;
                        if (vm.searchSegment != null && vm.searchSegment != '') {
                            vm.searchSegment = '';
                            vm.search();
                        }
                    });

                    $scope.$on('includedGroupsChanged', function (event, data) {
                        if ($scope.mode.indexOf(data.mode) > -1 && data.channelIndex == vm.channelIndex) {
                            vm.includedGroups = data.includedGroups;
                        }
                    });

                    $scope.$on('includedSegmentsChanged', function (event, data) {
                        if ($scope.mode.indexOf(data.mode) > -1) {
                            vm.includedSegments = data.includedSegments;
                        }
                    });

                    $scope.$on('selectedItemsChanged', function (event, data) {
                        if ($scope.mode.indexOf(data.mode) > -1) {
                            vm.includedGroups = data.includedGroups;
                        }
                        vm.controlDisabled = data.controlDisabled;
                    });

                    $scope.$on('excludeMyAdvertiserCustomersChanged', function (event, data) {
                        vm.excludeMyAdvertiserCustomers = data.excludeMyAdvertiserCustomers;
                    });

                    $scope.$on('dataCPMUpdated', function (event, data) {
                        if (vm.channelIndex == data.channelIndex + 1 && vm.segments && vm.includedGroups) {
                            var segment = vm.segments.find(function (s) { return s.audienceId == -1 && s.audienceType == "channelEvent"; });
                            if (segment) {
                                segment.displayCpm = data.dataCpm;
                                segment.socialCpm = data.socialCpm;
                                segment.emailPrice = data.emailPrice;
                            }
                            var includedSegment = FindSegment(vm.includedGroups, -1);
                            if (includedSegment && includedSegment.audienceType == "channelEvent") {
                                includedSegment.displayCpm = data.dataCpm;
                                includedSegment.socialCpm = data.socialCpm;
                                includedSegment.emailPrice = data.emailPrice;
                            }
                        }
                    });

                    $scope.$on('listedAudiencesAdded', function (event, audienceIds) {
                        if ($scope.audienceType === 'Custom') {

                            var groupToRemoves = vm.includedGroups.filter(function (g) { return g.listedAudiences; });
                            if (groupToRemoves && groupToRemoves.length > 0)
                                groupToRemoves.forEach(function (groupToRemove) {
                                    vm.includedGroups.splice($.inArray(groupToRemove, vm.includedGroups), 1);
                                });


                            if (audienceIds && audienceIds.length > 0) {
                                audienceIds.forEach(function (id) {
                                    id = id.trim();
                                    var segments = vm.searchSegment ? vm.findings : vm.segments;
                                    var segment = FindSegment(segments, id);
                                    if (segment) {
                                        var group = {
                                            segments: [], booleanOperator: 'OR', id: vm.groupId, listedAudiences: true
                                        };
                                        vm.includedGroups.push(group);
                                        vm.groupId++;

                                        vm.includeSegment(segment, group);
                                    }
                                });
                            }
                        }
                    });



                    function setLoaded(max) {
                        if (vm.segments) {
                            vm.loadedLength = Math.min(vm.segments.length, max);
                            vm.loadedSegments = vm.segments.slice(0, vm.loadedLength);
                            vm.showLoadMore = vm.loadedSegments.length < vm.segments.length;
                        }
                    }

                    vm.customPageNumber = 1;
                    vm.loadMore = function (segment) {
                        var from = vm.maxLength;
                        if (segment) {
                            from = segment.segments.length;
                            segment.showLoadMoreSpinner = true;
                            vm.selectedMarketerId = campaignService.getSelectedMarketerId();
                            hub.webapicontext.getSegments(vm.selectedMarketerId, segment.id, from, vm.taxonomyId).then(function (data) {
                                segment.showLoadMoreSpinner = false;
                                if (data && data.length > 0) {
                                    var parentChecked = ParentIncluded(segment);
                                    angular.forEach(data, function (item) {
                                        item.parentChecked = parentChecked;
                                    });
                                    [].push.apply(segment.segments, data);
                                    segment.showLoadMore = data.length == vm.loadMoreStep;
                                }
                                else {
                                    segment.showLoadMore = false;
                                }
                            });
                        }
                        else {
                            if (!$scope.isCustom)
                                setLoaded(vm.loadMoreStep + vm.loadedLength);
                            else {
                                vm.selectedMarketerId = campaignService.getSelectedMarketerId();
                                vm.customPageNumber++;
                                hub.webapicontext.getAudiences('Custom', vm.selectedMarketerId, false, $scope.channelId == 16 ? 1 : $scope.channelId, $scope.advertiserId, vm.customPageNumber).then(function (data) {
                                    if (data.length > 0) {
                                        data.forEach(function (s) {
                                            var segment = {
                                                dataSourceId: s.dataSourceId,
                                                audienceId: s.id,
                                                accountId: s.advertiserId ? s.advertiserId : s.marketerId,
                                                audienceType: s.audienceType,
                                                name: s.name,
                                                isInternal: s.isInternal,
                                                fullName: s.name,
                                                socialCpm: s.socialCpm,
                                                emailPrice: s.emailPrice,
                                                displayCpm: s.displayCpm,
                                                id: s.apnSegmentId ? s.apnSegmentId : s.id,
                                                fullIdsList: (s.apnSegmentId ? (s.apnSegmentId).toString() : (s.id).toString()),
                                                activeReach: s.matchedDeviceRecords,
                                                createdDate: s.createdDate
                                            };
                                            vm.segments.push(segment);
                                        });
                                        vm.loadedSegments = vm.segments;
                                    } else {
                                        vm.showCustomLoadMore = false;
                                    }
                                });
                            }
                        }

                    }

                    vm.isFbSecondLevel = function (segment) {
                        if ($scope.isBrandSafety)
                            return false;
                        var breadCrumbArr = segment.fullIdsList.split('>');
                        return $scope.channelId == 3 && breadCrumbArr && breadCrumbArr.length == 2;
                    };

                    vm.toggleSegment = function (segment) {
                        vm.isToggled = true;

                        if (segment.isParent) {
                            segment.showSpinner = true;
                            $rootScope.$broadcast('prescriberParentToggled', segment);
                            return;
                        }

                        var isFbSecondLevel = vm.isFbSecondLevel(segment);
                        if (!isFbSecondLevel && !segment.segments && !segment.noupdate && segment.segmentType != 3) {
                            segment.showSpinner = true;
                            vm.selectedMarketerId = campaignService.getSelectedMarketerId();
                            if (!$scope.isBrandSafety)
                                hub.webapicontext.getSegments(vm.selectedMarketerId, segment.id, -1, vm.taxonomyId).then(function (data) {
                                    segment.showSpinner = false;
                                    if (data && data.length > 0) {
                                        angular.forEach(data, function (item) {
                                            item.isFbSecondLevel = vm.isFbSecondLevel(item);
                                        });
                                        segment.segments = data;
                                    }
                                    else {
                                        segment.noupdate = true;
                                    }
                                });
                            else
                                hub.webapicontext.getBrandSafetySegments(segment.id, 0).then(function (data) {
                                    segment.showSpinner = false;
                                    if (data && data.length > 0) {
                                        segment.segments = data;
                                        segment.segments.forEach(function (s) { s.parentNodeId = segment.id; s.fullName = segment.fullName + '>'; });
                                    }
                                    else {
                                        segment.noupdate = true;
                                    }
                                });

                        }
                        segment.isOpen = !segment.isOpen;
                    }

                    function FindSegment(segments, segmentId) {
                        var segment = segments.find(function (s) { return s.id + '_' + s.audienceId == segmentId; });
                        if (!segment)
                            segment = segments.find(function (s) { return s.id == segmentId; });
                        if (!segment && $scope.audienceType == 'Custom')
                            segment = segments.find(function (s) { return s.audienceId == segmentId; });

                        if (!segment) {
                            segments.forEach(function (s) {
                                if (!segment && s.segments)
                                    segment = FindSegment(s.segments, segmentId);
                            });
                        }
                        return segment;
                    }

                    var bsIdsToIgnore = [67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85];
                    vm.isBrandSafetyParentNode = function (id) {
                        return $scope.isBrandSafety && bsIdsToIgnore.indexOf(id) > -1;
                    }

                    $scope.$on('segmentsBag.drag', function (e, el, source) {
                        var segments = vm.searchSegment ? vm.findings : vm.segments;
                        var segment = FindSegment(segments, source[0].id);

                        if ($scope.channelId == 9 && $scope.isLassoTrigger || vm.locked || $scope.channelId == 9 && vm.includedGroups.find(function (g) { return g.segments.length > 0; }) || $scope.isDsm && $scope.channelId == 6 || !segment || source[0].id.indexOf('null_') > -1 || vm.segmentChecked(segment) || vm.isBrandSafetyParentNode(segment.id) || vm.excludeMyAdvertiserCustomers && (segment.id == 6210741741776897 || segment.id == 6210741741785089))
                            dragulaService.find($scope, 'segmentsBag').drake.cancel(true);
                    });

                    $scope.$on('segmentsBag.over', function (e, el, source) {
                        if (!$scope.isBrandSafety && !$scope.isStatesLocations && !$scope.isCitiesLocations && !$scope.isDmaLocations && !$scope.isLocationGroups && !$scope.isInventory && !$scope.isConversion && !$scope.isOptimizationConversion) {
                            for (var i = 0; i < $("[name='segmentGroup']").length; i++)
                                $("[name='segmentGroup']")[i].style = '';

                            if (source[0].id == 'groupsField') {
                                if ($scope.channelId != 3)
                                    source[0].style = 'border: 1px dashed #dddddd;';
                            }
                            else {
                                $('#groupsField')[0].style = '';
                                if (source[0].id > 0 && source[0].attributes['name'] && source[0].attributes['name'].value === 'segmentGroup') {
                                    source[0].style = 'border: 1px dashed #dddddd;';
                                }
                            }
                        }
                        else {
                            if (source[0].id == 'groupsField') {
                                source[0].style = 'border: 1px dashed #dddddd;';
                            }
                            else {
                                $('#groupsField')[0].style = '';
                                if (source[0].id > 0 && source[0].attributes['name'] && source[0].attributes['name'].value === 'segmentGroup') {
                                    source[0].style = 'border: 1px dashed #dddddd;';
                                }
                            }
                        }
                    });

                    $scope.$on('optimizationConversionSelected', function (event, segment){
                        if($scope.isConversion){
                            $timeout(function () {
                                let s = angular.copy(segment);
                                s.disableExcludeSegment = true;
                                vm.includePlainSegment(s);
                            }, 100);
                        }
                    });
                    $scope.$on('segmentsBag.drop', function (e, el, target, source) {
                        if ($scope.isOptimizationConversion && vm.includedSegments.length > 0){
                            dragulaService.find($scope, 'segmentsBag').drake.cancel(true);
                            return;
                        }
                        if ($scope.isOptimizationConversion){
                            var segments = vm.searchSegment ? vm.findings : vm.segments;
                            var segment = FindSegment(segments, source[0].id);
                            $rootScope.$broadcast('optimizationConversionSelected', segment);
                        }
                        if (!$scope.isBrandSafety && !$scope.isStatesLocations && !$scope.isCitiesLocations && !$scope.isDmaLocations && !$scope.isLocationGroups && !$scope.isInventory && !$scope.isConversion && !$scope.isOptimizationConversion) {
                            dragulaService.find($scope, 'segmentsBag').drake.cancel(true);
                            $('#groupsField')[0].style = '';
                            for (var i = 0; i < $("[name='segmentGroup']").length; i++)
                                $("[name='segmentGroup']")[i].style = '';

                            $timeout(function () {
                                var group = vm.includedGroups.find(function (g) { return g.id == target[0].id; });
                                var segments = vm.searchSegment ? vm.findings : vm.segments;
                                var segment = FindSegment(segments, source[0].id);
                                if (group || target[0].id === 'groupsField') {
                                    vm.includeSegment(segment, group);
                                }
                            });
                        }
                        else {
                            dragulaService.find($scope, 'segmentsBag').drake.cancel(true);
                            $('#groupsField')[0].style = '';

                            $timeout(function () {
                                var segments = vm.searchSegment ? vm.findings : vm.segments;
                                var segment = FindSegment(segments, source[0].id);
                                vm.includePlainSegment(segment);
                            });
                        }
                    });

                    vm.includeSegment = function (segment, group) {
                        if (segment && !vm.segmentChecked(segment)) {
                            segment.combinationAudienceType = $scope.audienceTypeId == 1 ? 'marketer' : 'advertiser';

                            if (group) {
                                var seg = group.segments.find(function (s) { return s.id + '_' + s.audienceId == segment.id; });
                                if (!seg)
                                    seg = group.segments.find(function (s) { return s.id == segment.id; });

                                if (seg)
                                    return;
                            }

                            if (!group) {
                                group = {
                                    segments: [], booleanOperator: 'OR', id: vm.groupId
                                };
                                vm.includedGroups.push(group);
                                vm.groupId++;
                            }

                            segment.excluded = false;

                            var alertId = '#included';
                            if ($scope.channelId == 3 && group.id == 2) {
                                segment.excluded = true;
                                alertId = '#excluded';
                            }

                            if (group.segments.indexOf(segment) < 0)
                                group.segments.push(angular.copy(segment));
                            $timeout(function () {
                                $(alertId + segment.id + group.id).show();
                                $timeout(function () {
                                    $(alertId + segment.id + group.id).hide();
                                }, 3000);
                            });

                            $scope.includedGroups = vm.includedGroups;
                            $rootScope.$broadcast('includedGroupsSegmentsChanged', null);
                            if ($scope.isBrandSafety)
                                $rootScope.$broadcast('includedBrandSafetySegmentsChanged', null);
                        }
                    };

                    vm.removeSegmentFromGroup = function (segment, group) {
                        if (vm.locked || $scope.channelId == 9 && $scope.isLassoTrigger)
                            return;

                        group.segments.splice($.inArray(segment, group.segments), 1);
                        if (group.segments.length === 0 && $scope.channelId != 3)
                            vm.includedGroups.splice($.inArray(group, vm.includedGroups), 1);

                        vm.selectedSegments.splice($.inArray(segment, vm.selectedSegments), 1);

                        $scope.includedGroups = vm.includedGroups;

                        $rootScope.$broadcast('includedGroupsSegmentsChanged', null);

                        if (vm.isBrandSafety && vm.includedGroups.length === 0)
                            $rootScope.$broadcast('brandSafetyItemsExist', false);
                    };

                    vm.removeGroup = function (group) {
                        if (vm.locked || $scope.channelId == 9 && $scope.isLassoTrigger)
                            return;

                        var segmentsToRemove = angular.copy(group.segments);
                        segmentsToRemove.forEach(function (s) {
                            s.included = false;
                            vm.removeSegmentFromGroup(s, group);
                        });

                        $scope.includedGroups = vm.includedGroups;

                        $rootScope.$broadcast('includedGroupsSegmentsChanged', null);

                        if (vm.isBrandSafety && vm.includedGroups.length === 0)
                            $rootScope.$broadcast('brandSafetyItemsExist', false);
                    };

                    vm.includePlainSegment = function (segment) {
                        if (segment && !vm.segmentChecked(segment)) {
                            if ($scope.isInventory && vm.includedSegments.length > 0)
                                segment.excluded = vm.includedSegments[0].excluded;

                            if (vm.includedSegments.indexOf(segment) < 0)
                                vm.includedSegments.push(segment);
                        }
                        if ($scope.isBrandSafety)
                            $rootScope.$broadcast('includedBrandSafetySegmentsChanged', null);
                    };

                    vm.removePlainSegment = function (segment) {
                        if ($scope.isBrandSafety && vm.locked)
                            return;

                        vm.includedSegments.splice($.inArray(segment, vm.includedSegments), 1);
                        if ($scope.isBrandSafety)
                            $rootScope.$broadcast('includedBrandSafetySegmentsChanged', null);
                    };

                    vm.includeExcludeSegment = function (segment, group) {
                        if ($scope.channelId == 9)
                            return;
                        if (group) {
                            var seg = group.segments.find(function (s) { return s.id + '_' + s.audienceId == segment.id + '_' + segment.audienceId; });
                            if (!seg)
                                seg = group.segments.find(function (s) { return s.id == segment.id; });
                            seg.excluded = !seg.excluded;
                        } else
                            segment.excluded = !segment.excluded;

                        if (segment.audienceType === 'Inventory') {
                            vm.includedSegments.filter(function (s) { return s.audienceType === 'Inventory'; }).forEach(function (st) {
                                st.excluded = segment.excluded;
                            });
                        }

                        if (segment.locationSegmentType === 'state') {
                            vm.selectedSegments.filter(function (s) { return s.locationSegmentType === 'state'; }).forEach(function (st) {
                                st.excluded = segment.excluded;
                            });
                        }

                        if (segment.locationSegmentType === 'city') {
                            vm.selectedSegments.filter(function (s) { return s.locationSegmentType === 'city'; }).forEach(function (st) {
                                st.excluded = segment.excluded;
                            });
                        }

                        if (!$scope.isBrandSafety) {
                            $scope.includedGroups = vm.includedGroups;
                            var idPart = segment.excluded ? '#excluded' : '#included';
                            var idOldPart = !segment.excluded ? '#excluded' : '#included';
                            $(idOldPart + segment.id).hide();
                            $(idPart + segment.id).show();
                            $timeout(function () {
                                $(idPart + segment.id).hide();
                            }, 3000);
                            $rootScope.$broadcast('includedGroupsSegmentsChanged', null);
                        }
                        if ($scope.isBrandSafety)
                            $rootScope.$broadcast('includedBrandSafetySegmentsChanged', null);

                        var disableExcludeMyAdvertiserCustomers = vm.selectedSegments.find(function (s) {
                            return (s.id == 6210741741776897 || s.id == 6210741741785089);
                        });
                        $rootScope.$broadcast('disableExcludeMyAdvertiserCustomers', { disableExcludeMyAdvertiserCustomers: disableExcludeMyAdvertiserCustomers });
                    };

                    $scope.$watch('vm.selectedSegments.length', function (newVal) {
                        if (!$scope.isBrandSafety) {
                            var disableExcludeMyAdvertiserCustomers = vm.selectedSegments.find(function (s) {
                                return (s.id == 6210741741776897 || s.id == 6210741741785089);
                            });
                            $rootScope.$broadcast('disableExcludeMyAdvertiserCustomers', { disableExcludeMyAdvertiserCustomers: disableExcludeMyAdvertiserCustomers });
                        }
                    });

                    vm.segmentVisible = function (segment) {
                        return segment && (!segment.isCompetitiveSeparation || segment.audienceType !== "Location");
                    };

                    vm.segmentChecked = function (segment) {
                        if (!segment)
                            return false;

                        var checked = false;
                        if (!$scope.isBrandSafety && !$scope.isStatesLocations && !$scope.isCitiesLocations && !$scope.isDmaLocations && !$scope.isLocationGroups && !$scope.isInventory && !$scope.isConversion && !$scope.isOptimizationConversion) {
                            if (vm.includedGroups) {
                                vm.includedGroups.forEach(function (g) {
                                    if (g.segments)
                                        g.segments.forEach(function (s) {
                                            if (segment.id === s.id)
                                                checked = false;
                                        });
                                });
                            }
                        }
                        else {
                            if (vm.includedSegments) {
                                vm.includedSegments.forEach(function (s) {
                                    if (s.audienceId && segment.id === s.id && segment.audienceId == s.audienceId)
                                        checked = true;
                                    else if (!s.audienceId && segment.id === s.id)
                                        checked = true;
                                });
                            }
                        }
                        return checked;
                    };

                    vm.clickItem = function (item) {
                        vm.isToggled = false;

                        if (vm.isChecked(item)) {
                            vm.removeItem(item);
                        } else {
                            vm.addItem(item);
                        }
                    }

                    $rootScope.$on('brandSafetyItemsExist', function (event, exist) {
                        if (!vm.isBrandSafety) {
                            if (exist) {
                                vm.brandSafetyItemsExist = true;
                            }
                            else
                                vm.brandSafetyItemsExist = false;
                        }
                    });


                    vm.selectedAll = false;
                    vm.selectAll = function () {
                        if (!vm.selectedAll) {
                            angular.forEach(vm.findings, function (item) {
                                if (!vm.isChecked(item)) {
                                    vm.addItem(item);
                                }
                            });
                            vm.selectedAll = true;
                        }
                        else {
                            angular.forEach(vm.findings, function (item) {
                                vm.removeItem(item);
                            });
                            vm.selectedAll = false;
                        }
                    }

                    function checkParent(parentNodeId) {
                        var segment = _.find(vm.segments, function (elem) { return elem.id == parentNodeId; });

                        if (!segment) {
                            angular.forEach(vm.segments, function (rootSegment) {
                                if (!segment) {
                                    segment = _.find(rootSegment.segments, function (elem) { return elem.id == parentNodeId; });
                                }
                            });
                        }

                        if (segment && segment.segments && segment.segments.length > 0 && !vm.isToggled) {
                            var allChildrenUnchecked = true;
                            for (var i = 0; i < segment.segments.length; i++) {
                                if (segment.segments[i].checked || segment.segments[i].partiallyIncluded) {
                                    allChildrenUnchecked = false;
                                    break;
                                }
                            }

                            if (allChildrenUnchecked) {
                                segment.partiallyIncluded = false;
                                segment.checked = false;
                                vm.removeItem(segment);
                            }

                            var allChildrenChecked = true;
                            for (var i = 0; i < segment.segments.length; i++) {
                                if (!segment.segments[i].checked || segment.segments[i].partiallyIncluded) {
                                    allChildrenChecked = false;
                                    break;
                                }
                            }

                            if (allChildrenChecked) {
                                segment.partiallyIncluded = false;
                                segment.checked = true;
                                for (var i = 0; i < segment.segments.length; i++) {
                                    vm.removeItem(segment.segments[i]);
                                }
                                vm.addItem(segment);
                            }
                        }
                    }

                    vm.search = function () {
                        if ($scope.isStatesLocations) {
                            if (vm.searchSegment)
                                vm.findings = vm.segments.filter(s => s.name.toLowerCase().indexOf(vm.searchSegment.toLowerCase()) > -1);
                            return;
                        }

                        if (vm.searchSegment && vm.searchSegment.length > 1) {
                            vm.findings = [];
                            vm.searchInProgress = true;

                            const flt = {
                                audienceType: $scope.audienceType,
                                isAudiencePage: false,
                                keyword: vm.searchSegment,
                                marketerId: vm.selectedMarketerId,
                                advertiserId: $scope.advertiserId,
                                channelId: $scope.channelId
                            };
                            const pager = {
                                pageSize: 100,
                                pageNumber: 1,
                                sortDescending: true,
                                sortName: 'Name'
                            };
                            hub.webapicontext.searchAudiences(flt, pager).then(data => {
                                if ($scope.isInventory) {
                                    data.list = data.filter(function (s) {
                                        return s.dealId.toString().indexOf(vm.searchSegment.toLowerCase()) > -1 || s.dealNameCategory && s.dealNameCategory.toLowerCase().indexOf(vm.searchSegment.toLowerCase()) > -1
                                            || s.dealNamePublisher && s.dealNamePublisher.toLowerCase().indexOf(vm.searchSegment.toLowerCase()) > -1 });
                                } else if ($scope.channelId === 3) {     // channelId 3 is FaceBook/Insta
                                    data.list = data.list.filter(function (s) {
                                      return s.audienceType != 'Consumer'
                                        || s.isInternal
                                        && s.audienceType == 'Consumer'
                                        && s.name.indexOf("Lasso Modeled Audiences") >= 0
                                    });
                                } else {
                                    data.list = data.list.filter(function (r) {
                                        return (
                                          r.emailPrice
                                          || $scope.channelId != 6
                                        )
                                          && (
                                            $scope.channelId !== 3
                                            || r.audienceType == 'Conversion'
                                            || (
                                              featureService.isActive('UNIVERSAL_PIXELS')
                                              && r.audienceType == 'UniversalPixelAudienceConversion'
                                            )
                                            || r.audienceType == 'Demographics'
                                            || r.socialCpm
                                          )
                                          && r.apnSegmentId;
                                    });
                                }

                                if ($scope.channelId === 16 && !$scope.isInventory)
                                    data.list = data.list.filter(function (s) { return s.dataFileUrl || s.audienceType === 'Custom'; });

                                data.list.forEach(function (s) {
                                    var segment = {
                                        dataSourceId: s.dataSourceId,
                                        audienceId: s.id,
                                        accountId: s.advertiserId ? s.advertiserId : s.marketerId,
                                        audienceType: s.audienceType,
                                        name: s.name,
                                        isInternal: s.isInternal,
                                        fullName: s.name,
                                        socialCpm: s.socialCpm,
                                        emailPrice: s.emailPrice,
                                        displayCpm: s.displayCpm,
                                        id: s.apnSegmentId ? s.apnSegmentId : s.id,
                                        fullIdsList: (s.apnSegmentId ? (s.apnSegmentId).toString() : (s.id).toString()),
                                        activeReach: s.matchedDeviceRecords,
                                        createdDate: s.createdDate
                                    };

                                    if ($scope.isInventory) {
                                        segment.fullName = $scope.inventoryType == 'ByPublisher' ? s.dealNamePublisher : s.dealNameCategory;
                                        segment.name = s.deal;
                                    }

                                    vm.findings.push(segment);
                                });
                                vm.searchInProgress = false;
                            });
                        }
                    };

                    vm.switchMode = function () {
                        vm.showAllMode = !vm.showAllMode;
                        if (!vm.showAllMode) {
                            vm.showMoreSelected = false;
                            vm.byClickOnParent = false;
                        }
                        else {
                            if (vm.searchSegment != null && vm.searchSegment != '') {
                                vm.search();
                            };
                        }
                    }

                    vm.loadChildren = function (id, name) {
                        vm.selectedMarketerId = campaignService.getSelectedMarketerId();
                        hub.webapicontext.getSegments(vm.selectedMarketerId, id, -1, vm.taxonomyId).then(function (data) {
                            if (data && data.length > 0) {
                                vm.showAllMode = true;
                                vm.findings = data;
                                vm.byClickOnParent = true;
                                vm.searchSegment = name.replaceAll('%20', ' ').replaceAll('%26', '&');
                            }
                        });
                    }

                    vm.htmlDecode = function (value) {
                        return $('<div/>').html(value).text();
                    }

                    vm.htmlEncode = function (value) {
                        return $('<div/>').text(value).html();
                    }

                    vm.maxFindingsLength = 50;

                    vm.loadMoreFindings = function () {
                        var i = 0;
                        angular.forEach(vm.findings, function (elem) {
                            if (elem.hidden && i < vm.maxFindingsLength) {
                                elem.hidden = false;
                                i++;
                            }
                        });
                        if (i == 0) {
                            vm.showMoreSelected = false;
                        }
                    }
                }]
            }
        }]);

})();
