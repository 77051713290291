(function () {
    'use strict';

    var serviceId = 'api';
    angular.module('app').factory(serviceId, ['$q', '$http', '$window', 'appSettings','appCache', 'localStorageService', api]);


    function api($q, $http, $window, appSettings, appCache, localStorageService) {
        const locks = {};
        //service definition
        var service = {
            post: _post,
            postSingle: postSingle,
            postDownload: _postDownload,
            put: _put,
            get: _get,
            'delete': _delete,
            getCached: _getCached,
            getSingle: getSingle,
            getDirect: _getDirect,
            getEnum: _getEnum
        };
        return service;

        function _validateAPIVersion(APIVersion) {
            var localAPIBuildNumber = localStorageService.get('api-build');
            if(localAPIBuildNumber)
            {
                if(APIVersion !== localAPIBuildNumber) {
                    localStorageService.set('api-build', APIVersion);
                    var newVersionNumber = APIVersion.replace('r-');
                    var oldVersionNumber = localAPIBuildNumber.replace('r-');
                    if (isNumeric(newVersionNumber) && isNumeric(oldVersionNumber) && parseFloat(newVersionNumber) > parseFloat(oldVersionNumber)) {
                        console.log('API Version update: ' + APIVersion + ', page refresh.');

                    }

                    console.log('New API version found: ' + APIVersion);
                }

            }
            else
                localStorageService.set('api-build', APIVersion);
        }

        function isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }


        function _post(url, obj) {
            var deferred = $q.defer();
            $http.post(appSettings.apiUrl + url, obj).then(function (data) {
                _validateAPIVersion(data.headers('api-build'));
                deferred.resolve(data.data);
            },
          function (error) {
              deferred.reject(error);
          });

            return deferred.promise;
        }

        function _postDownload(url, obj) {
            $http({
                url: appSettings.apiUrl + url,
                data: obj,
                method: 'POST',
                params: {},
                responseType: 'arraybuffer'
            }).then(function (data, status, headers, config) {

                var file = new Blob([data.data], {
                    type: 'image/jpg'
                });

                var fileURL = URL.createObjectURL(file);
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = obj.fileName;
                document.body.appendChild(a);
                a.click();
            });
        }

        function _put(url, obj) {
            var deferred = $q.defer();
            $http.put(appSettings.apiUrl + url, obj).then(function (data) {
                _validateAPIVersion(data.headers('api-build'));
                deferred.resolve(data.data);
            },
          function (error) {
              deferred.reject(error);
          });

            return deferred.promise;
        }

        function _delete(url, obj) {
            var deferred = $q.defer();
            $http.delete(appSettings.apiUrl + url, obj).then(function (data) {
                _validateAPIVersion(data.headers('api-build'));
                deferred.resolve(data.data);
            },
          function (error) {
              deferred.reject(error);
          });

            return deferred.promise;
        }

        function getKey({ method, url, data }) {
            return JSON.stringify({
                method,
                url,
                data
            });
        }

        function getFromLocksOrNew({url, method, data, host}) {
            const key = getKey({ url, method, data });
            let createPromise;
            if (method === 'post') {
                createPromise = () => _post(url, data);
            } else if (method === 'get') {
                createPromise = () => _get(url,host);
            }
            if(!locks[key]) {
                const promise = createPromise();
                locks[key] = promise;
                promise.then((data)=> {
                    locks[key] = null;
                });
                return promise;
            } else {
                return locks[key];
            }

        }

        function postSingle(url, obj) {
            return getFromLocksOrNew({url, method: 'post', data: obj });
        }

        function getSingle(url, host) {
            return getFromLocksOrNew({ url, method: 'get',host });
        }

        function _get(url, host) {
            var deferred = $q.defer();
            $http.get((host ? host: appSettings.apiUrl) + url).then(function (data) {
                _validateAPIVersion(data.headers('api-build'));
                deferred.resolve(data.data);
            },
          function (error) {
              deferred.reject(error);
          });

            return deferred.promise;
        }

        function _getDirect(url, host) {

        return    $http.get((host ? host : appSettings.apiUrl) + url);

        }


        function _getCached(path, key, noCache) {
            var deferred = $q.defer();
            if (!noCache && appCache.get(key) != null) {
                deferred.resolve(appCache.get(key));
            }
            else {
                $http.get(appSettings.apiUrl + path).then(function (data) {
                    _validateAPIVersion(data.headers('api-build'));
                    deferred.resolve(data.data);
                    appCache.put(key, data.data);
                },
              function (error) {
                  deferred.reject(error);
              });
            }
            return deferred.promise;

        }

        function _getEnum(name) {
            return _getCached('api/json/enumValues/' + name, name + '_ENUM');

        }

    }

    })();
