(function() {
    'use strict';

    angular.module('app')
           .directive('changePasswordForm', function() {
               return {
                    template: require('@views/app/change-password-form.html').default,
                    restrict: 'E',
                    controllerAs: 'vm',
                    scope: { model: '=' },
                    controller: ['$scope', 'hub', '$location', '$timeout', 'authService', function($scope, hub,  $location, $timeout, authService) {

                        var vm = this;
                        var INCORRECT_PASSWORD_ERROR = 'incorrect password.';
                        var PASSWORD_MATCH_ERROR = 'new password should not match last 6 password';
                        vm.accountSettings = false;
                        vm.code = $location.search().code;
                        vm.code = vm.code == true ? null : vm.code;
                        vm.changePassword = changePassword;
                        vm.disableCurrentPasswordError = disableCurrentPasswordError;
                        vm.disableNewPasswordError = disableNewPasswordError;
                        vm.enabled = true;
                        vm.firstTimeUser = authService.authentication.firstTimeUser;
                        vm.isResetPassword = isResetPassword;
                        vm.logout = logout;
                        vm.match = false;
                        vm.model = $scope.model;
                        vm.newPassword = '';
                        vm.onCurrentPasswordChange = onCurrentPasswordChange;
                        vm.userId = $location.search().id;
                        vm.userId = vm.userId == true ? null : vm.userId;
                        vm.verifyNewPassword = verifyNewPassword;
                        vm.invalidCurrentPsswd = false;
                        disableCurrentPasswordError();
                        disableNewPasswordError();

                        if($location.path() === '/settings/account-settings')
                            vm.accountSettings = true;

                        function isResetPassword  ()
                        {
                            return vm.code && vm.userId;
                        }

                        if (isResetPassword())
                            hub.page.setTitle('Set new password');
                        else
                            hub.page.setTitle('Account Settings');

                        function logout () {
                            authService.logOut();
                        }

                        function disableCurrentPasswordError() {
                            vm.invalidCurrentPsswd = false;
                        }

                        function disableNewPasswordError () {
                            vm.invalidNewPsswd = false;
                            vm.newPasswordSecurityFail = false;
                            vm.confirmPasswordFail = false;
                        }
                        function onCurrentPasswordChange () {
                            disableCurrentPasswordError();
                        }

                        function verifyNewPassword () {
                            disableNewPasswordError();
                            if (vm.newPassword){
                                vm.match = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%\^&\*\(\)_\+`\-=\{\}\|\[\]\\:";'<>,\.\?\/])[A-Za-z\d~!@#$%\^&\*\(\)_\+`\-=\{\}\|\[\]\\:";'<>,\.\?\/]{8,}$/.test(vm.newPassword);
                            }
                        }

                        function changePassword () {
                            disableCurrentPasswordError();
                            disableNewPasswordError();

                            if (this.form_changePassword.$invalid) {
                                $('#changePasswordForm').validator('validate');
                                    return;
                            }

                            if (vm.match == false) {
                                vm.invalidNewPsswd = true;
                                return;
                            }

                            if ( vm.confirmPassword != vm.newPassword) {
                                vm.confirmPasswordFail = true;
                                return;
                            }
                            else
                            {
                                if (!vm.enabled)
                                    return;
                                    vm.enabled = false;

                                if (!isResetPassword()) {
                                    authService.changePassword(vm.currentPassword, vm.newPassword).then(function (result) {
                                        hub.getLog('Change Password', 'info')('Password has been changed.');
                                        authService.authentication.firstTimeUser = false;
                                        authService.refreshUserData()

                                    }, function (err) {
                                        var errors = err.data;
                                        vm.invalidCurrentPsswd = false;
                                        vm.invalidNewPsswd = false;
                                        if(errors.length) {
                                            var error = errors[0].toLowerCase();
                                            if( error === INCORRECT_PASSWORD_ERROR){
                                                vm.invalidCurrentPsswd = true;
                                            } else if (error == PASSWORD_MATCH_ERROR) {
                                                vm.newPasswordSecurityFail = true;
                                            }
                                        }

                                        vm.enabled = true;
                                    });
                                }
                                else
                                {
                                    authService.setPassword(vm.userId, vm.code, vm.newPassword).then(function (result) {
                                        hub.getLog('Change Password', 'info')('Password has been set. Please login the site user your new password after page reload.');
                                        $timeout(function () {
                                            window.location.href = window.location.origin + window.location.pathname;
                                        }, 2000);

                                    }, function () {
                                        vm.enabled = true;
                                    });

                                }
                            }

                        }
                        return vm;
                    }]
               }

            })

})();


