(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:sortColumn
 * @sortColumn directive
 * # <sort-column  />
 * renders table show column with up/down sign
 */



    angular.module('app')
      .directive('sortColumn', function () {
          return {
              template:require('@views/app/sort-column.html').default,
              restrict: 'E',
              scope: { model: '=', name: '@', column: '@', onsort: '&?', locked:'@' },
              controller: ['$scope', function ($scope) {
                  var vm = this;
                  vm.sort = $scope.model;
                  vm.name = $scope.name;
                  vm.column = $scope.column;
                  vm.onsort = $scope.onsort;
                  vm.disabled = $scope.locked == 'true' ? true : false;

                  vm.getSortIcon = function () {}


                  vm.order = function (column) {
                      if (vm.onsort != null && typeof vm.onsort == 'function') {
                          vm.onsort();
                      }
                      else if (column) {
                          if (vm.sort.column == column)
                              vm.sort.sortDesc = !vm.sort.sortDesc;
                          else {
                              vm.sort.column = column;
                              vm.sort.sortDesc = false;
                          }
                      }
                  }

                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });

})();
