(function () {
    'use strict';

    const moneyFormat = function (_this, n, s) {
      var x = 3;
      var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
      return (
        (s ? s : "") +
        _this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,")
      );
    }

    /**
 * @ngdoc function
 * @name app.directive:contentEditable
 * @contentEditable directive
 * # <tag  content-editable=""/>
 * allows model update and binding in content-editable tags
 */
    angular.module('app').directive("contentEditable", function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attrs, ngModel) {

                function read() {
                    var e = parseFloat(element.html().replace('$', '').replace(',', ''));
                    if (e)
                        ngModel.$setViewValue(e);
                    ngModel.$render();
                }

                ngModel.$render = function () {
                    var view = ngModel.$viewValue;
                    if (typeof view == 'number')
                        element.html('$' + moneyFormat(view, 2) || "");
                    else
                        element.html("");
                };

                element.bind("blur", function () {
                    scope.$apply(read);
                });
            }
        };
    });

})();
