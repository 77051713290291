(function () {
    'use strict';

    var serviceId = 'noEndDateAdgroupService';

    angular.module('app').factory(serviceId, [noEndDateAdgroupService]);

    function noEndDateAdgroupService() {
        var service = {
            getDate: function() {
                return moment().year(2037).endOf('year');
            }
        };
        return service;

    }
})();
(function () {
    'use strict';

    var serviceId = 'mtaDateRangeService';
    angular.module('app').factory(serviceId, ['hub', '$timeout', '$rootScope','localStorageService', 'dateRangeService', 'noEndDateAdgroupService', mtaDateRangeService]);

    function mtaDateRangeService(hub, $timeout, $rootScope, localStorageService, dateRangeService, noEndDateAdgroupService) {
        var start = moment(new Date('2019-4-1'));
        var end = noEndDateAdgroupService.getDate();
        var mtaRange = {start: start, end: end };
        var logId = 'Date Range Filter';
        var service = {
            customRangeChanged: function(range, isMta) {

                if(isMta == null) {
                    var userInfo = localStorageService.get('userInfo');
                    isMta = userInfo.multiTouchMode;
                }

                if (isMta && !document.printMode && !dateRangeService.isSubrange(mtaRange, range)) {
                    hub.getLog(logId, 'stop')();
                    $timeout(function () {
                        if ($location.$$absUrl.indexOf('print.html') == -1)
                            hub.getLog(logId, 'preview')('Sales Data is unavailable for Multi-Touch Attribution prior to 4/1/2019');
                    });
                } else {
                    hub.getLog(logId, 'stop')();
                }
            },
            dateRangeChanged: function(value, isMta) {
                var range = dateRangeService.getRange(value);
                this.customRangeChanged(range, isMta);
                $rootScope.$broadcast('rangeChanged', value);
            }
        };
        return service;

    }
})();

(function () {
    'use strict';

    var serviceId = 'ntbDateRangeService';
    angular.module('app').factory(serviceId, ['hub', 'dateRangeService', '$timeout', '$location', 'noEndDateAdgroupService', ntbDateRangeService]);

    function ntbDateRangeService(hub, dateRangeService, $timeout, $location, noEndDateAdgroupService) {
        var start = moment('2019-1-1');
        var end = noEndDateAdgroupService.getDate();
        var ntbRange = {start: start, end: end };
        var logId = 'NTB Date Range Filter';

        var service = {
            customRangeChanged: function(range) {
                if (!dateRangeService.isSubrange(ntbRange, range)) {
                    hub.getLog(logId, 'stop')();
                    $timeout(function () {
                        if ($location.$$absUrl.indexOf('print.html') == -1)
                            hub.getLog(logId, 'preview')('New to advertiser data is unavailable for campaigns prior to 2019.');
                    })
                } else {
                    hub.getLog(logId, 'stop')();
                }
            },
            dateRangeChanged: function(value) {
                var range = dateRangeService.getRange(value);
                this.customRangeChanged(range);
            }
        };
        return service;
    }
})();
