(function () {
    'use strict';

    angular.module('app').factory('context', ['api','datacontext', 'webapicontext', 'storagecontext', 'statcontext','externalservices', context]);

    function context(api, datacontext, webapicontext, storagecontext, statcontext, externalservices) {
        var service = {
            data: datacontext,
            webapi: webapicontext,
            api: api,
            storage: storagecontext,
            stat: statcontext,
            external: externalservices
         
        };

        return service;


    }
})();