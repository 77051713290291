(function () {
    'use strict';

    angular.module('app')
      .controller('creativeRequestController', ['$scope', '$rootScope', '$uibModal', '$sce', 'hub', 'authService', '$compile', 'campaignService', 'angularGridInstance', '$timeout', 'tableService', '$location', '$routeParams', '$route', function ($scope, $rootScope, $modal, $sce, hub, authService, $compile, campaignService, angularGridInstance, $timeout, tableService, $location, $routeParams, $route) {
          var rootVM = $rootScope.rootVM;
          var log = hub.getLog('CreativeRequests');
          var vm = this;
          vm.log = log;
          vm.rootVM = rootVM;
          vm.page = hub.page;
          vm.config = hub.config;
          vm.pageNumber = 1;
          vm.sort = { creatives: { column: 'createdDate', sortDesc: true, keyword: '' } }
          vm.allImgsBlank = false;
          vm.hasAccess = authService.hasAccess;
          vm.isObserver = authService.isObserver;

          vm.sizes = campaignService.getSizes();
          vm.selectedSizeId = '0';
          vm.selectedStatusId = '-1';

          vm.channels = campaignService.getChannels();
          vm.selectedChannelId = '0';

          vm.ts = tableService;
          vm.ts.init('creativeRequests');

          $scope.clearSearch = function () {
              vm.sort.creativeRequests.keyword = '';
              $scope.search();
          }

          $scope.search = function () {
              vm.pageNumber = 1;
              vm.pageSelected();
          }

          vm.newCreativeRequest = function () {
              $location.path("/activation/creative-request/new", false);
              var modalInstance = $modal.open({
                  templateUrl: 'views/creative-request/editors/creative-request.html',
                  animation: true,
                  controller: 'requestController',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {
                      data: function () {
                          return {
                              request: {
                                  id: 0
                              }
                          };
                      },
                      viewMode: function () { return "new"; }
                  }
              });

              modalInstance.result.then((function (data) {
                  LoadData(true);
                  $location.path('activation/creative-request', false);
              }), (function () {
                      $location.path('activation/creative-request', false);
              }));
          }

          vm.page.setTitle('Creatives');

          vm.creativeRequests = [];

          vm.getCreativeRequests = function () {
              campaignService.getCreativeRequests(vm.pageNumber - 1, vm.sort.creatives.keyword, vm.sort.creatives.column, vm.sort.creatives.sortDesc).then(function (d) {
                  vm.creativeRequests = d.results;
                  vm.totalCount = d.totalCount;
              });
          }

          vm.pageSize = vm.config.pageSize().toString();
          vm.pageSizeChanged = function (size) {
              vm.pageSize = size;
              vm.getCreativeRequests();
          }

          vm.pageSelected = function () {
              campaignService.getCreativeRequests(vm.pageNumber - 1, vm.sort.creatives.keyword, vm.sort.creatives.column, vm.sort.creatives.sortDesc).then(function (d) {
                  vm.creativeRequests = d.results;
                  vm.totalCount = d.totalCount;
              });
          }

          vm.sortFn = function (column) {
              vm.sort.creativeRequests.sortDesc = column == vm.sort.creativeRequests.column ? !vm.sort.creativeRequests.sortDesc : true;
              vm.sort.creativeRequests.column = column;
              $scope.search();
          }

          $scope.hover = function (entity) {
              return entity.showActions = true;
          };

          $scope.out = function (entity) {
              return entity.showActions = false;
          };

          rootVM.getChannelById = function (channelId) {
              var name = '';
              campaignService.getChannels().forEach(function (item, index) {
                  if (item.id == channelId)
                      name = item.name;
              });
              return name;
          }

          rootVM.getSizeById = function (sizeId) {
              var name = '';
              campaignService.getSizes().forEach(function (item, index) {
                  if (item.id == channelId)
                      name = item.name;
              });
              return name;
          }

          vm.viewCreativeRequest = function (request, viewMode) {
              if (!viewMode)
                  viewMode = 'edit';

              $location.path('activation/creative-request/' + request.id, false);

              var modalInstance = $modal.open({
                  templateUrl: 'views/creative-request/editors/creative-request.html',
                  animation: true,
                  controller: 'requestController',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {
                      data: function () {
                          return { request: request };
                      },
                      viewMode: function () { return viewMode; }
                  }
              });

              modalInstance.result.then((function (data) {
                  LoadData(true);                  
                  $location.path('activation/creative-request', false);
                  vm.log("Creative request has been updated.");
              }), (function () {
                      $location.path('activation/creative-request', false);
              }));
          };

          vm.processUrl = function () {
              var requestId = $routeParams.requestId;
              if (requestId && requestId != 'new') {
                  hub.webapicontext.getCreativeRequest(requestId).then(function (d) {
                      var mode = (!vm.isObserver('creativerequests', d) ? 'edit' : 'readonly');
                      vm.viewCreativeRequest(d.result, mode);
                  });
              } else if (requestId == 'new')
                  vm.newCreativeRequest();
              else
                  $rootScope.$broadcast('modal.force.close', null);
          }

          function LoadData(notNew) {
              vm.getCreativeRequests();
              if ($routeParams.requestId == 'new' && !notNew)
                  vm.newCreativeRequest();
          };

          LoadData();
          vm.processUrl();

          var original = $location.path;
          $location.path = function (path, reload) {
              if (reload === false) {
                  var lastRoute = $route.current;
                  var un = $rootScope.$on('$locationChangeSuccess', function () {
                      if (IsCurrentPage($route.current.$$route.originalPath))
                          $route.current = lastRoute;

                      un();
                  });
              }
              return original.apply($location, [path]);
          };

          function IsCurrentPage(path) {
              if (path.indexOf('activation/creative-request') > -1)
                  return true;
              return false;
          }

      }]);

    angular.module('app')
        .controller('requestController', ['$scope', '$uibModalInstance', 'data', 'viewMode', '$timeout', 'authService', 'hub', function ($scope, $modalInstance, data, viewMode, $timeout, authService, hub) {
            var vm = this;
            $scope.vm = vm;

            $scope.request = data.request;
            $scope.viewMode = viewMode;
            vm.hasAccess = authService.hasAccess;
            vm.isSuperAdmin = authService.isSuperAdmin();

            vm.allowSendToCreativeTeam = function () {
                return $scope.request.availableActions.indexOf('SendToCreativeTeam') > -1;
            }

            vm.allowSendToAdvertiser = function () {
                return $scope.request.availableActions.indexOf('SendToAdvertiser') > -1;
            }

            vm.allowSendToAdmin = function () {
                return $scope.request.availableActions.indexOf('SendToAdmin') > -1;
            }

            $scope.cancel = function (cancelClicked) {
                vm.cancelClicked = cancelClicked;
                $timeout(function () {
                    $modalInstance.dismiss('cancel');
                });
            };

            $scope.ok = function (owner) {
                if (!creativeRequestForm.checkValidity()) {
                    $('#creativeRequestForm').validator('destroy');
                    $('#creativeRequestForm').validator('validate');
                    return;
                }
                vm.isInProgress = true;
                if (this.$parent.request.id > 0) {
                    vm.processRequest(this.$parent.request, owner);                    
                }
                else {
                    vm.submitRequest(this.$parent.request);
                }                
            };

            $scope.$on('modal.closing', function (event, reason, closed) {
                if (!vm.showCaution && reason == 'cancel' && event.currentScope.creativeRequestForm.$dirty && vm.cancelClicked) {
                    $timeout(function () {
                        vm.showCaution = true;
                    });
                    event.preventDefault();
                }
            });

            $scope.$on('modal.force.close', function (event) {
                $modalInstance.close();
            });

            vm.submitRequest = function (request) {
                if (request.callToAction == 'Others')
                    request.callToAction = request.callToActionCustom;

                if (authService.userAccountType() === "Admin")
                    request.owner = 'TDMAM';
                else
                    request.owner = 'Advertiser';

                hub.webapicontext.submitRequestCreative(request).then(function (cr) {
                    request.submitted = true;
                    vm.isInProgress = true;
                    $modalInstance.close({ 'request': request });     
                    vm.log("Your creative request has been submitted.");
                });
            }

            vm.processRequest = function (request, owner) {
                if (request.callToAction == 'Others')
                    request.callToAction = request.callToActionCustom;
                var req = angular.copy(request);
                req.owner = owner;
                hub.webapicontext.processRequestCreative(req).then(function (cr) {
                    vm.isInProgress = true;
                    $modalInstance.close({ 'request': request, 'owner': owner });
                    vm.log("Your creative request has been processed.");
                });
            }
        }]);
})();