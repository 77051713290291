(function () {
    'use strict';
    /**
     * @ngdoc function
     * @name payMeApp.controller:MainCtrl
     * @description
     * # MainCtrl
     * Controller of the payMeApp
     */
    angular.module('app')
        .controller('campaignController', ['$scope', '$rootScope', 'userSettingsService', '$uibModal', 'hub', 'context', '$routeParams', '$route', '$location', 'tableService', 'authService', 'appCache', 'campaignService', 'dialogService', '$q', 'blockUI', 'featureService', 'accountService', 'timezoneService', '$timeout', '$window', 'roleService', 'logger', '$document', 'pacingService',
            function ($scope, $rootScope, userSettingsService, $modal, hub, context, $routeParams, $route, $location, tableService, authService, appCache, campaignService, dialogService, $q, blockUI, featureService, accountService, timezoneService, $timeout, $window, roleService, logger, $document, pacingService) {
                var CAMPAIGN_MAGIC_COLUMN_NUMBER = 6;
                var DEFAULT_AG_PROGRESS = 2;
                var ADGROUP_MAGIC_COLUMN_NUMBER = CAMPAIGN_MAGIC_COLUMN_NUMBER - 2;
                var rootVM = $rootScope.rootVM;
                var log = hub.getLog('Campaigns');
                const sectionTypeEnum = {
                    AllCampaigns: 'ALL_CAMPAIGNS',
                    Campaign: 'CAMPAIGN',
                    AdGroup: 'AD_GROUP',
                    Segments: 'SEGMENTS'
                };
                const entityTypeEnum = {
                    campaign: 'campaign',
                    adGroup: 'adGroup'
                }
                const entityTypes = {
                    [entityTypeEnum.campaign]: { id: 'campaignId', status: 'campaignStatusId' },
                    [entityTypeEnum.adGroup]: { id: 'adGroupId', status: 'adGroupStatusId' }
                };
                const entityListEnum = {
                    campaigns: 'campaigns',
                    adGroups: 'adGroups',
                }

                var vm = this;
                vm.log = log;
                vm.sectionTypes = sectionTypeEnum;
                vm.hasPacingBar = authService.hasFeature('PACINGBAR');
                vm.hasCampaignMonitoringV2 = authService.hasFeature('CAMPAIGN_MONITORING_V2')
                vm.goToCampaignMonitoringV2 = function () {
                  $location.url("/app/activation?campaignMonitoringV2=true");
                }

                vm.adgroupProgress = $routeParams.campaignId ? 0 : DEFAULT_AG_PROGRESS;
                vm.counter = { upcoming: 0, inProgress: 0, completed: 0 };
                vm.changeProgressType = function (newType) {
                    if (newType == vm.adgroupProgress) {
                        return;
                    }
                    vm.adgroupProgress = newType;
                    vm.pageNumber = 1;
                    vm.loadAdGroups();
                }

                vm.userSettingsService = userSettingsService;
                vm.isFullBasket = userSettingsService.getFullBasket();
                vm.audienceTypes = campaignService.getCampaignAudienceTypes();

                vm.ts = tableService;
                vm.ts.init(entityListEnum.campaigns);
                vm.ts.init(entityListEnum.adGroups);

                vm.rootVM = rootVM;
                vm.page = hub.page;
                vm.config = hub.config;
                vm.page.setTitle('Campaign Management');
                vm.canLaunch = authService.isSuperAdmin() || authService.hasAccess('campaigns', 'Launch');
                vm.isAdmin = authService.isSuperAdmin();
                vm.accountId = authService.userInfoData().accountId;
                vm.isCampaignEditPage = (campaignId) => $location.$$path.includes('/activation/campaign/' + campaignId + '/edit');
                vm.nullDate = '0001-01-01T00:00:00Z';

                vm.isAdvertiser = authService.userAccountType() === "Advertiser";
                vm.isMarketer = authService.userAccountType() === "Marketer";
                vm.isManufacturer = authService.userAccountType() === "Manufacturer";
                vm.isMarketerHoldingCompany = authService.userAccountType() === "MarketerHoldingCompany";
                vm.isAdOps =
                    authService.authentication.userRoles[0] === "Advertiser Ad Ops"
                    || authService.authentication.userRoles[0] === "Marketer Ad Ops";
                vm.canRemove = !vm.isAdOps;
                vm.hasEndemicFeature = authService.hasFeature("ENDEMIC");

                if (authService.userAccountType() !== "Advertiser") {
                    vm.page.setSidebarMode('AdvertisersWithCampaigns');
                }
                else {
                    vm.page.setSidebarMode('Default');
                }

                vm.userAccounts = authService.getUserAccounts();
                var viewAdvertiserIds = vm.userAccounts.viewAdvertiserIds ? JSON.parse(vm.userAccounts.viewAdvertiserIds) : [];
                var viewMarketerIds = vm.userAccounts.viewMarketerIds ? JSON.parse(vm.userAccounts.viewMarketerIds) : [];
                if ((!viewAdvertiserIds || viewAdvertiserIds.length === 0) && (!viewMarketerIds || viewMarketerIds.length === 0)) {
                    vm.hideUserAccountsFilter = true;
                }

                vm.changeUserAccountsFilter = function () {
                    vm.userAccounts.noFilter = !vm.userAccounts.noFilter;
                    authService.setUserAccounts(vm.userAccounts.viewMarketerIds, vm.userAccounts.viewAdvertiserIds, vm.userAccounts.noFilter)
                        .then(function () {
                            if (vm.activeTab === 0){
                                vm.loadCampaigns();
                            } else {
                                vm.loadAdGroups();
                            }
                        });
                };

                var EXCLUDE_COLUMNS = ['AGRM', 'AGRMP', 'BPV', 'CONV', 'VR', 'ACPM', 'RET', 'TIMPSRV', 'IMPSRV', 'ACTIMP', 'ACTBTD'];

                var EXCLUDE_ON_FB_COLUMNS = ['BPV', 'CONV', 'COSTUNT', 'VR', 'FAS', 'FASIS', 'INS', 'IROAS',
                    'VOP', 'VOPA', 'VFP', 'VFPA', 'COP', 'COPA', 'CFP', 'CFPA', 'VP', 'VPA', 'CP', 'CPA', 'VROAS', 'CROAS',
                    'FBPV', 'FCONV', 'FVR', 'FFAS', 'FFASIS', 'FINS', 'FIROAS',
                    'VOP', 'VOPA', 'VFP', 'VFPA', 'COP', 'COPA', 'CFP', 'CFPA', 'VP', 'VPA', 'CP', 'CPA', 'VROAS', 'CROAS',
                    'FVOPA', 'FCOPA', 'FVFPA', 'FCFPA', 'FVP', 'FCP', 'FVOP', 'FCOP', 'FVROAS', 'FCROAS', 'FVPA', 'FCPA', 'FVFP', 'FCFP'
                ];
                var EXCLUDE_MEDIA_COLUMNS = ['PFEE', 'BIFEE', 'AGMMB', 'AGMMBC', 'AGPT', 'AGRMB', 'AGRMMP', 'RMP', 'AGRM', 'AGRMP'];


                var EXCLUDE_ON_NON_FB_COLUMNS = [
                    'LCLCF', 'LCTRF'
                ];
                vm.tableColumns = [];

                //check whether advertiser can see marketer platform fees
                vm.canShowPlatformFees = false;


                vm.setCanShowPlatformFees = function () {
                    if (authService.userAccountType() != "Advertiser" && authService.userAccountType() != "Manufacturer")
                        return vm.canShowPlatformFees = true;

                    var campaignId = $routeParams.campaignId;

                    var account = accountService.get();
                    vm.canShowPlatformFees = campaignService.canShowPlatformFees(campaignId, account);

                    if (!vm.canShowPlatformFees) {
                        vm.tableColumns = vm.tableColumns.filter(function (c) { return EXCLUDE_MEDIA_COLUMNS.indexOf(c.key) == -1; });
                    }

                    _cols = angular.copy(vm.tableColumns);
                    if (campaignId)
                        vm.setAdGroupColumns();

                };


                // we can't directly filter at config level using authService bcs circular ref-ce error
                vm.tableColumns = hub.config.tables.getCampaignColumns().filter(function (col) { return !authService.isObserver('campaigns') || col.key != 'ACCMAN' && authService.isObserver('campaigns') });
                //needed for 5925 to change --colNum manually

                vm.tableColumns = vm.tableColumns.filter(function (c) { return EXCLUDE_COLUMNS.indexOf(c.key) === -1; });
                var colNum = vm.tableColumns.length + CAMPAIGN_MAGIC_COLUMN_NUMBER; // Advertiser Account + Campaign
                document.documentElement.style.setProperty("--colNum", colNum);
                //remove platform fee columns if we advertiser is not allowed to see them



                var _cols = angular.copy(vm.tableColumns);
                vm.adGroupTableColumns = [];
                vm.originalAdGroupHeader = {};

                vm.sort = {
                    campaigns: { column: 'campaignId', sortDesc: true, keyword: '' },
                    adGroups: { column: 'adGroupId', sortDesc: true, keyword: '' },
                    segments: { column: 'impressions', sortDesc: true, keyword: '' }
                };

                //set sort settings from session storage
                if (sessionStorage) {
                    if (sessionStorage["sort_campaigns"])
                        vm.sort.campaigns = JSON.parse(sessionStorage["sort_campaigns"]);
                    if (sessionStorage["sort_adGroups"])
                        vm.sort.adGroups = JSON.parse(sessionStorage["sort_adGroups"]);
                    if (sessionStorage["sort_segments"])
                        vm.sort.segments = JSON.parse(sessionStorage["sort_segments"]);
                }

                vm.campaigns = [];
                vm.adGroups = [];
                vm.hasAccess = authService.hasAccess;
                vm.isObserver = authService.isObserver;

                vm.selectedCampaign = campaignService.getSelectedCampaign();
                vm.selectedAdGroup = campaignService.getSelectedAdGroup();

                vm.updatedThroughDate = null;
                vm.selectedCampaignId = 0;
                vm.selectedAdGroupId = 0;
                vm.sectionType = sectionTypeEnum.AllCampaigns;
                vm.tabs = [{ active: true, title: 'Campaigns' }, { active: false, title: 'Ad Groups' }, { active: false, title: 'Segments' }];

                vm.showId = true;
                vm.showAdvertiserName = featureService.isActive('ADVERTISERNAME');
                vm.showCampaignComments = true;

                vm.checkedEntities = { campaign: [], adGroup: [], segment: [] };
                vm.checkEntity = function (entity, entityType) {
                    if (vm.checkedEntities[entityType].indexOf(entity) === -1)
                        vm.checkedEntities[entityType].push(entity);
                    else {
                        vm.checkedEntities[entityType].splice($.inArray(entity, vm.checkedEntities[entityType]), 1);
                        vm.allChecked = false;
                    }
                };
                vm.entityChecked = function (entity, entityType) {
                    return vm.checkedEntities[entityType].indexOf(entity) > -1;
                };

                vm.checkAllEntities = function (entityType) {
                    vm.checkedEntities[entityType] = [];
                    if (vm.allChecked) {
                        if (entityType === 'campaign')
                            vm.campaigns.forEach(function (e) {
                                vm.checkedEntities[entityType].push(e);
                            });
                        else if (entityType === 'adGroup')
                            vm.filteredAdGroups.filter(function (ag) { return ag.name.toLowerCase().indexOf(vm.sort.adGroups.keyword.toLowerCase()) > -1; }).forEach(function (e) {
                                vm.checkedEntities[entityType].push(e);
                            });

                    } else {
                        vm.checkedEntities[entityType] = [];
                    }
                };

                const getEntityType = () => {
                    return vm.sectionType === sectionTypeEnum.AllCampaigns ?
                        entityTypeEnum.campaign :
                        entityTypeEnum.adGroup;
                }

                const getCheckedEntities = () => {
                    return vm.checkedEntities[getEntityType()];
                }

                const canManage = () => {
                    return vm.hasAccess('campaigns', 'Manage');
                }

                vm.canPerformMultiple = function () {
                    return getCheckedEntities().length > 0 && canManage();
                };

                vm.canPerformSingle = function () {
                    return getCheckedEntities().length === 1 && canManage();
                };

                vm.canPerformRemove = function () {
                    return getCheckedEntities()
                        .filter((e) => e.hasImpression).length == 0 &&
                        canManage();
                }

                vm.pauseRestartMultiple = function (action, entityType) {
                    if (!entityType)
                        entityType = getEntityType();

                    var entityId = entityTypes[entityType].id;
                    var entityStatusId = entityTypes[entityType].status;

                    var pauseRestartList = vm.checkedEntities[entityType].filter(function (e) {
                        return e[entityStatusId] === 3 || e[entityStatusId] === 1 || e[entityStatusId] === 2 || e[entityStatusId] === 5 || e[entityStatusId] === 10 || e[entityStatusId] == 0 && entityType === 'segment';
                    });
                    if (pauseRestartList.length > 0)
                        hub.webapicontext.pauseRestartMultiple(action, _.map(pauseRestartList, entityId), entityType).then(function () {
                            pauseRestartList.forEach(function (c) {
                                c[entityStatusId] = action === 'restart' ? 1 : 3;
                            });
                            vm.checkedEntities[entityType] = [];
                            var message = action === 'restart' ? "started" : "paused";
                            vm.log("Checked " + entityType + "s were " + message);
                            vm.loadAdGroups();
                        });
                    else {
                        var message = action === 'restart' ? "No items to restart." : "No items to pause.";
                        vm.log(message);
                    }
                };

                vm.editEntity = function () {
                    const entityType = getEntityType();
                    if (vm.checkedEntities[entityType].length !== 1)
                        return;

                    var statusField = entityTypes[entityType].status;

                    var mode;
                    const firstEntity = vm.checkedEntities[entityType][0];
                    if (canManage() && [9, 4].indexOf(firstEntity[statusField]) == -1)
                        mode = 'edit';
                    else
                        mode = 'readonly';

                    if (entityType === entityTypeEnum.campaign)
                        vm.editCampaign(firstEntity, mode);
                    else
                        vm.editAdGroup(firstEntity, mode);
                };

                vm.copyEntities = function () {
                    const entityType = getEntityType();
                    const entityId = entityTypes[entityType].id;
                    hub.webapicontext.copyMultiple(_.map(vm.checkedEntities[entityType], entityId), entityType).then(function (res) {
                        if (res) {
                            vm.checkedEntities[entityType] = [];
                            vm.log("Checked " + entityType + "s were copied.");
                            if (entityType == "campaign") {
                                vm.loadCampaigns();
                            } else {
                                vm.loadAdGroups();
                            }
                        } else {
                            vm.log("Can not copy legacy " + (entityType === 'campaign' ? "campaigns." : "adgroups."));
                        }
                    });
                };

                vm.removeEntity = function () {
                    const entityType = getEntityType();
                    const notImpressed = (e) => !e.hasImpression;
                    if (vm.checkedEntities[entityType].filter(notImpressed).length < 1)
                        return;
                    let remove = entityType === entityTypeEnum.campaign ? vm.removeCampaigns : vm.removeAdGroups;
                    remove(vm.checkedEntities[entityType].filter(notImpressed));
                };


                // -------------- filtering -----------
                vm.filter = { channels: [], programs: [], marketers: [], statuses: [], adGroupStatuses: [], accountManagers: [], advertisers: [] };
                vm.filterViewModel = angular.copy(vm.filter);

                if (sessionStorage["campaign_adgroup_active_tab"] && sessionStorage["campaign_adgroup_active_tab"] == "1"){
                    vm.activeTab = 1;
                } else {
                    vm.activeTab = 0;
                }
                if (!$routeParams.campaignId) {
                    campaignService.setSelectedCampaign('All Campaigns');
                }

                vm.filtersOn = function () {
                    if (!vm.advertisers || vm.advertisers.length === 0 || vm.channels.length === 0 || vm.programs.length === 0 || !vm.marketers || vm.marketers.length === 0 || vm.statuses.length === 0 || vm.accountManagers.length == 0 && vm.isAdmin)
                        return false;
                    const isCampaignTab = vm.activeTab === 0;
                    return vm.filter.programs.length !== vm.programs.length
                        || vm.advertisers && vm.filter.advertisers.length !== vm.advertisers.length
                        || vm.marketers && vm.filter.marketers.length !== vm.marketers.length
                        || vm.filter.statuses.length !== vm.statuses.length && isCampaignTab
                        || vm.filter.channels.length !== vm.channels.length && !isCampaignTab
                        || vm.filter.adGroupStatuses.length !== vm.statuses.length && !isCampaignTab
                        || vm.filter.accountManagers.length !== vm.accountManagers.length;
                };

                vm.clearFilters = function () {
                    if (sessionStorage && vm.activeTab === 0) {
                        sessionStorage.removeItem("campaigns_filter");
                        sessionStorage.removeItem("sort_campaigns");
                        setFilter(vm.activeTab);
                        vm.filterCamapigns();
                    } else if (sessionStorage && vm.activeTab === 1) {
                        sessionStorage.removeItem("adgroups_filter");
                        sessionStorage.removeItem("sort_adGroups")
                        vm.showFilter = false;;
                        setFilter(vm.activeTab);
                        vm.filterViewModel = angular.copy(vm.filter);
                        vm.filterAdGroups();
                    }
                };



                vm.checkAll = function (list, filterKeyword, initially) {
                    if (list)
                        vm.filterViewModel[filterKeyword] = vm.filterViewModel[filterKeyword].length < list.length ? _.map(list, 'id') : initially ? _.map(list, 'id') : [];
                };

                vm.filterChecked = function (item, list, filterList) {
                    var checked = filterList.indexOf(item.id) > -1;
                    if (!checked)
                        filterList.push(item.id);
                    else
                        filterList.splice($.inArray(item.id, filterList), 1);
                };


                vm.isLegacy = function (item) {
                    return item.isLegacy;
                }
                vm.showLegacyIcon = roleService.isCurrentInternal();
                vm.isGrossMetric = (col) => {
                    return ['TRX', 'NRX', 'PROVNTB', 'PATNTB'].indexOf(col.key) > -1
                }

                vm.formatValue = function (item, column) {
                    if (vm.isGrossMetric(column)) {
                        return !item.hasGrossMetrics ? '000' : (vm.page.formatValue(item[column.column], column.format) || 0);
                    }
                    var isFb = item.channelId === 3;

                    if (!vm.canShowPlatformFees && EXCLUDE_MEDIA_COLUMNS.indexOf(column.key) != -1) {
                        return '';
                    }
                    else if (vm.canShowPlatformFees && EXCLUDE_MEDIA_COLUMNS.indexOf(column.key) != -1) {
                        //check is we can show fees based on campaign
                        if (authService.userAccountType() == "Advertiser" || authService.userAccountType() == "Manufacturer") {

                            var account = accountService.get();
                            var advertiserMarketerSettings = account.advertiserMarketers.find(function (br) { return br.marketerId == item.marketerId; });
                            if (!advertiserMarketerSettings && !advertiserMarketerSettings.advertiserCanSeeMediaPlatformFees) {
                                return '';
                            }

                        }



                    }

                    if (isFb) //FB
                    {
                        if (EXCLUDE_ON_FB_COLUMNS.indexOf(column.key) !== -1) {
                            return '';
                        } else if (EXCLUDE_ON_NON_FB_COLUMNS.indexOf(column.key) !== -1) {
                            return vm.page.formatValue(item[column.column] || 0, column.format);
                        }
                    } else if (!isFb && EXCLUDE_ON_NON_FB_COLUMNS.indexOf(column.key) !== -1) {
                        return '';
                    }

                    if (column.column === 'campaignComments' || column.column === 'advertiserName')
                        return vm.page.formatValue(item[column.column], 'fixed:25');
                    if (column.column === 'viewability') {
                        return vm.page.formatValue(item[column.column], '100%', '0.00%');
                    }
                    return vm.page.formatValue(item[column.column], column.format);
                };


                //prepare column set for Adgroups view (campaign). Remove Online/Offline purchase columns from FB campaign
                vm.setAdGroupColumns = function () {
                    var level = 'adgroup';
                    var tableColumns = hub.config.tables.getCampaignColumns(level);
                    _cols = angular.copy(tableColumns);
                    var isFb = vm.isFacebookCampaign();
                    vm.adGroupTableColumns = [];
                    _cols.forEach(function (col) {
                        if (!isFb && EXCLUDE_ON_NON_FB_COLUMNS.indexOf(col.key) == -1 ||
                            isFb && EXCLUDE_ON_FB_COLUMNS.indexOf(col.key) == -1) {
                            vm.adGroupTableColumns.push(col);
                        }
                    });

                    if (!vm.canShowPlatformFees) {
                        vm.adGroupTableColumns = vm.adGroupTableColumns.filter(function (c) { return EXCLUDE_MEDIA_COLUMNS.indexOf(c.key) === -1; });
                    }
                    vm.adGroupTableColumns = vm.adGroupTableColumns.filter(function (c) { return EXCLUDE_COLUMNS.indexOf(c.key) === -1; });

                    var colNum = null;
                    if (level == 'adgroup') {
                        colNum = vm.adGroupTableColumns.length + ADGROUP_MAGIC_COLUMN_NUMBER;
                    } else {
                        colNum = tableColumns.length + ADGROUP_MAGIC_COLUMN_NUMBER;
                    }
                    document.documentElement.style.setProperty("--nestColNum", colNum);

                };

                vm.cancelFilter = function () {
                    vm.filterViewModel = angular.copy(vm.filter);
                    vm.liveOnlyFilter = vm.liveOnly;
                    vm.showFilter = false;
                };

                vm.applyFilterClicked = function (list, keyword) {
                    if (list === 'campaigns') {
                        vm.filterCamapigns();
                        sessionStorage["campaigns_filter"] = JSON.stringify(vm.filter);
                    }
                    else if (list === 'adgroups') {
                        vm.filterAdGroups(true, keyword);
                        sessionStorage["adgroups_filter"] = JSON.stringify(vm.filter);
                    }
                };


                vm.filterCamapigns = function () {
                    vm.filter = angular.copy(vm.filterViewModel);
                    vm.setKeyword();
                    vm.showFilter = false;
                };

                vm.filterAdGroups = function () {
                    vm.filter = angular.copy(vm.filterViewModel);
                    vm.loadAdGroups();
                    vm.showFilter = false;
                };


                vm.recalculateTotals = function () {
                    vm.agHeader.adGroupId = null;
                    vm.agHeader.campaignId = null;
                    vm.agHeader.visitRate = null;
                    vm.agHeader.averageFrequency = null;
                    vm.agHeader.uniqueMessaged = null;
                    vm.agHeader.costPerUnit = null;
                    vm.agHeader.conversionRate = null;
                    vm.agHeader.fullCartIncrementalROAS = null;
                    vm.agHeader.costPerHCP = null;
                    vm.agHeader.uniqueHCPs = null;
                    vm.agHeader.adGroupId = null;
                    vm.agHeader.pacingPercent = null;
                };

                // -------------- filtering -----------

                const checkAll = (statusesKey) => {
                    vm.checkAll(vm.advertisers, 'advertisers', true);
                    vm.checkAll(vm.channels, 'channels', true);
                    vm.checkAll(vm.marketers, 'marketers', true);
                    vm.checkAll(vm.statuses, statusesKey, true);
                    vm.checkAll(vm.programs, 'programs', true);
                    vm.checkAll(vm.accountManagers, 'accountManagers', true);
                }


                campaignService.getAccounts(2).then(function () {
                    var onFetchAccountManagers = function (data) {
                        vm.accountManagers = data;
                        vm.marketers = campaignService.marketers;
                        vm.advertisers = campaignService.advertisers;
                        vm.filterListsLoaded = true;
                        setFilter(vm.activeTab);

                        if (vm.accountId) {
                            if (vm.isMarketer) {
                                const marketer = vm.marketers.find(m => m.id === vm.accountId);
                                const dataOnlyAllowedObj = marketer.options.find(function (ro) { return ro.optionId == 10130; })
                                vm.dataOnlyAllowed = dataOnlyAllowedObj && dataOnlyAllowedObj.enabled;
                            }
                            if (vm.isAdvertiser) {
                                const advertiser = vm.advertisers.find(a => a.id === vm.accountId);
                                const marketer = vm.marketers.find(m => m.id === advertiser.marketerId);
                                const dataOnlyAllowedObj = marketer.options.find(function (ro) { return ro.optionId == 10130; })
                                vm.dataOnlyAllowed = dataOnlyAllowedObj && dataOnlyAllowedObj.enabled;

                            }
                        }
                    }
                    if (vm.isAdmin) {
                        hub.webapicontext.accountManagers().then(function (data) {
                            onFetchAccountManagers(data);
                        });
                    } else {
                        onFetchAccountManagers([]);
                    }
                });

                vm.channels = campaignService.getChannels();
                vm.getChannelNameById = function (channelId) {
                    var name = '';
                    vm.channels.forEach(function (item, index) {
                        if (item.id === channelId)
                            name = item.name;
                    });
                    return name;
                };

                vm.programs = campaignService.getPrograms();

                vm.getProgramNameById = function (programId) {
                    var name = '';
                    vm.programs.forEach(function (item, index) {
                        if (item.id === programId)
                            name = item.name;
                    });
                    return name;
                };
                vm.pageNumber = 1;
                vm.loadCampaigns = function (cb, isExport) {
                    if (!vm.filterListsLoaded || !vm.dateRange || campaignsBlockUI.isBlocking()) {
                        return;
                    }
                    campaignsBlockUI.start();
                    campaignService.getCampaigns({
                        pageNumber: vm.pageNumber - 1,
                        keyword: vm.keyword,
                        filter: vm.filter,
                        sortColumn: vm.sort.campaigns.column,
                        sortDescending: vm.sort.campaigns.sortDesc,
                        dateRange: vm.dateRange,
                        isExport: isExport
                    }).then(function () {
                        $rootScope.$broadcast('campaignsLoaded', null);
                        campaignsBlockUI.stop();
                        if (cb) {
                            cb();
                        }
                        if (!vm.processed) {
                            vm.processUrl();
                        }
                    });
                }

                vm.loadAdGroups = function (cb, isExport) {
                    const campaignId = currentCampaignId();
                    if (!vm.filterListsLoaded || !vm.dateRange || campaignsBlockUI.isBlocking()) {
                        return;
                    }
                    campaignsBlockUI.start();
                    if (vm.adgroupProgress == null && !campaignId) {
                        vm.adgroupProgress = DEFAULT_AG_PROGRESS;
                    }
                    if (vm.adgroupProgress != null && campaignId) {
                        vm.adgroupProgress = null;
                    }
                    vm.sort.adGroups.keyword = vm.agKeyword;

                    if (!campaignId)
                        sessionStorage["sort_adGroups"] = JSON.stringify(vm.sort.adGroups);

                    if (campaignId)
                        vm.filter = { channels: [], programs: [], marketers: [], statuses: [], adGroupStatuses: [], accountManagers: [], advertisers: [] };

                    //TODO: think
                    campaignService.getPagedAdGroupsStatistic({
                        pageNumber: vm.pageNumber - 1,
                        keyword: vm.agKeyword,
                        progress: vm.adgroupProgress,
                        filter: { ...vm.filter, statuses: vm.filter.adGroupStatuses },
                        sortColumn: vm.sort.adGroups.column,
                        sortDescending: vm.sort.adGroups.sortDesc,
                        dateRange: vm.dateRange,
                        campaignId: campaignId,
                        isExport: isExport
                    }).then(() => {
                        campaignsBlockUI.stop();
                        if (!isExport) {
                            vm.counter = campaignService.adGroupsStatistic.counter;
                            vm.campaigns = campaignService.adGroupsStatistic.campaigns;
                            vm.adGroups = campaignService.adGroupsStatistic.results;
                            if (campaignId) {
                                const campaign = vm.campaigns.find(function (x) { return x.campaignId == campaignId; });
                                campaignService.setSelectedCampaign(campaign)
                            } else {
                                campaignService.setSelectedCampaign('All Campaigns')
                            }
                            vm.adGroups.forEach(function (ag) {
                                const marketer = vm.marketers.find(a => a.id == ag.marketerId);
                                ag.canBeCopied = !(ag.isLegacy && marketer.marketerContract);
                                ag.status = vm.getStatusNameById(ag.adGroupStatusId);
                                PrepareFlightData(ag);
                            });
                            vm.filteredAdGroups = vm.adGroups;
                            vm.originalAdGroups = campaignService.adGroupsStatistic.originalAdGroups;
                            vm.agTotal = campaignService.adGroupsStatistic.count;
                            vm.agHeader = campaignService.adGroupsStatistic.total;
                            vm.recalculateTotals();
                            if (vm.campaignId) {
                                getChartsData();
                                vm.selectedCampaign.actualBudget = 0;
                                campaignService.adGroupsStatistic.results.forEach(function (agst) {
                                    if (agst.actualBudget != null)
                                        vm.selectedCampaign.actualBudget += agst.actualBudget;
                                    else {
                                        const ag = campaignService.adGroupsStatistic.originalAdGroups.find(a => a.adGroupId == agst.adGroupId);
                                        if (ag)
                                            vm.selectedCampaign.actualBudget += ag.budgetCap;
                                    }
                                });
                            }
                            var agId = rootVM.selectedToEditAdGroupId ? rootVM.selectedToEditAdGroupId : $routeParams.adGroupId;
                            if (agId && vm.adGroups && vm.adGroups.length > 0) {
                                var adGroup = vm.adGroups.find(function (ag) { return ag.adGroupId == agId; });
                                if (adGroup) {
                                    vm.editAdGroup(adGroup);
                                    rootVM.selectedToEditAdGroupId = null;
                                }
                            }
                        }
                        if (cb) {
                            cb();
                        }

                        if (!vm.processed) {
                            vm.processUrl();
                        }
                    });

                };

                vm.loadData = function () {
                    if (vm.routeToAdGroupEdit) {
                        return;
                    }
                    if (vm.activeTab == 0) {
                        vm.loadCampaigns();
                    } else {
                        vm.loadAdGroups();
                    }
                }

                vm.statuses = campaignService.getCampaignStatuses();

                vm.getStatusNameById = function (statusId) {
                    var name = '';
                    vm.statuses.forEach(function (item, index) {
                        if (item.id === statusId)
                            name = item.name;
                    });
                    return name;
                };

                if(!$rootScope.$$listenerCount['reloadCampaigns']){

                    $rootScope.$on('reloadCampaigns', function () {
                    vm.loadCampaigns();
                });

            }
                const rangeWatcher = $rootScope.$on('rangeChanged', function (event, value) {
                    const isNew = vm.dateRange != value;
                    vm.dateRange = value;
                    const hasRange = vm.dateRange != null;
                    if (hasRange && isNew) {
                        vm.loadData();
                    }
                });

                $scope.$on('campaignsSummaryTotalsLoaded', function (event, header) {
                    vm.header = header;
                    var FB_CHANNEL_ID = 3;

                    var hasFBCampaigns = false;
                    vm.campaigns.forEach(function (c) {
                        var marketer = campaignService.marketers.find(function (r) { return r.id == c.marketerId; });
                        c.canBeCopied = !(c.isLegacy && marketer.marketerContract);
                        hasFBCampaigns = hasFBCampaigns || c.channelId == FB_CHANNEL_ID;
                    });

                    if (vm.header) {
                        vm.header.campaignId = null;
                        vm.header.visitRate = null;
                        vm.header.averageFrequency = null;
                        vm.header.uniqueMessaged = null;
                        vm.header.costPerUnit = null;
                        vm.header.conversionRate = null;
                        vm.header.costPerHCP = null;
                        vm.header.uniqueHCPs = null;
                        vm.header.averageFreqByHcp = null;
                        vm.header.adGroupId = null;

                        //    5834 total for columns below are only calculated for non FB campaigns
                        if (hasFBCampaigns) {
                            vm.header.roas = null;
                            vm.header.totalROAS = null;
                            vm.header.incrementalROAS = null;
                            vm.header.viewROAS = null;
                            vm.header.clickROAS = null;
                            vm.header.fullCartROAS = null;
                            vm.header.fullCartViewROAS = null;
                            vm.header.fullCartClickROAS = null;
                        }
                        vm.header.fullCartIncrementalROAS = null;
                    }
                });

                $scope.$on('adGroupsSummaryTotalsLoaded', function (event, header) {
                    vm.agHeader = header;

                    if (vm.agHeader) {
                        vm.agHeader.campaignId = null;
                        vm.agHeader.visitRate = null;
                        vm.agHeader.averageFrequency = null;
                        vm.agHeader.uniqueMessaged = null;
                        vm.agHeader.costPerUnit = null;
                        vm.agHeader.conversionRate = null;
                        vm.agHeader.fullCartIncrementalROAS = null;
                        vm.agHeader.costPerHCP = null;
                        vm.agHeader.uniqueHCPs = null;
                        vm.agHeader.adGroupId = null;
                        vm.agHeader.averageFreqByHcp = null;
                        vm.agHeader.pacingPercent = null;
                    }
                });


                $scope.$on('campaignsFilterReset', function () {
                    if (sessionStorage) {
                        sessionStorage.removeItem("campaigns_filter");
                        sessionStorage.removeItem("sort_campaigns");
                        sessionStorage.removeItem("sort_adGroups");
                        sessionStorage.removeItem("sort_segments");
                        sessionStorage.removeItem("campaign_adgroup_active_tab");
                    }
                    $location.path('activation/campaign', true);
                });


                vm.getSectionTitle = function () {
                    switch (vm.sectionType) {
                        case sectionTypeEnum.AllCampaigns:
                            return 'All Campaigns';
                        case sectionTypeEnum.Campaign: {
                            var selectedCampaign = campaignService.getSelectedCampaign();
                            return selectedCampaign ? selectedCampaign.name : '[UNKNOWN]';
                        }
                        case sectionTypeEnum.AdGroup: {
                            var selectedAdGroup = campaignService.getSelectedAdGroup();
                            return selectedAdGroup ? selectedAdGroup.name : '[UNKNOWN]';
                        }
                        default:
                            return '[NOT SET]';
                    }
                };


                vm.setView = function (viewOption) {
                    vm.sectionType = viewOption.view;

                    switch (viewOption.view) {
                        case sectionTypeEnum.AllCampaigns:
                            campaignService.setSelectedCampaign('All Campaigns');
                            $location.path('activation/campaign');
                            vm.selectedCampaignId = 0;
                            vm.selectedAdGroupId = 0;

                            vm.tabs[0].active = true;
                            vm.activeTab = 0;
                            sessionStorage["campaign_adgroup_active_tab"] = vm.activeTab;
                            break;
                        case sectionTypeEnum.Campaign:
                            var selectedCampaign = campaignService.getSelectedCampaign();
                            vm.selectedCampaignId = selectedCampaign.campaignId;

                            vm.setCampaignUrl(vm.selectedCampaignId);

                            vm.selectedAdGroupId = 0;

                            vm.tabs[1].active = true;
                            vm.activeTab = 1;
                            sessionStorage["campaign_adgroup_active_tab"] = vm.activeTab;
                            break;
                        default:

                    }
                };

                vm.changeActiveTab = function (newTab) {
                    if (campaignsBlockUI.isBlocking()) {
                        vm.activeTab = parseInt(sessionStorage["campaign_adgroup_active_tab"]);
                        for (let idx = 0; idx < vm.tabs.length; idx++) {
                            vm.tabs[idx].active = vm.activeTab == idx;
                        }
                        return;
                    }
                    vm.processed = false;
                    vm.activeTab = newTab;
                    vm.showFilter = false;
                    sessionStorage["campaign_adgroup_active_tab"] = newTab;
                    if (newTab == 0) {
                        var campaignId = $routeParams.campaignId;
                        if (campaignId) {
                            $location.path('activation/campaign');
                        }
                    }
                }


                //Generic KW
                var campaignsBlockUI = blockUI.instances.get('campaignsBlockUI');
                vm.setKeyword = function () {
                    if (vm.sectionType === sectionTypeEnum.AllCampaigns) {
                        vm.pageNumber = 1;
                        vm.sort.campaigns.keyword = vm.keyword;
                        sessionStorage["sort_campaigns"] = JSON.stringify(vm.sort.campaigns);
                        vm.loadCampaigns(() => {

                        })
                        vm.checkedEntities['campaign'] = [];
                    }
                    else if (vm.sectionType === sectionTypeEnum.Campaign) {
                        vm.sort.adGroups.keyword = vm.agKeyword;
                    }
                    else if (vm.sectionType === sectionTypeEnum.AdGroup) {
                        vm.sort.segments.keyword = vm.keyword;
                        if (vm.keyword === '' || vm.keyword.length > 0)
                            vm.segmentSelection = { adGroupId: vm.selectedAdGroupId, keyword: vm.keyword };
                    }
                };
                vm.setAgKeyword = function () {
                    vm.pageNumber = 1;
                    vm.loadAdGroups();
                }

                vm.sortFn = function (column, list) {
                    var sortDesc = column === vm.sort[list].column ? !vm.sort[list].sortDesc : true;
                    vm.sort[list].column = column;
                    vm.sort[list].sortDesc = sortDesc;

                    if (sessionStorage) {
                            sessionStorage["sort_campaigns"] = JSON.stringify(vm.sort.campaigns);
                            sessionStorage["sort_adGroups"] = JSON.stringify(vm.sort.adGroups);
                            sessionStorage["sort_segments"] = JSON.stringify(vm.sort.segments);
                    }
                    if (list == "campaigns") {
                        vm.loadCampaigns();
                    } else {
                        vm.loadAdGroups();
                    }
                };

                var makeCampaignsRemoval = function (campaigns) {

                    var getMessage = function (campaigns) {
                        if (campaigns.length == 1) {
                            var campaign = campaigns[0];
                            return 'Are you sure you want to remove campaign ' + campaign.name + '?'
                        }
                        return 'Are you sure you want to remove ' + campaigns.length + ' campaign' + (campaigns.length > 1 ? 's?' : '?')
                    }

                    var modalOptions = {
                        closeButtonText: 'Cancel',
                        actionButtonText: 'Remove',
                        message: getMessage(campaigns)
                    };

                    dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                        var startTime = new Date();
                        hub.webapicontext.removeCampaigns(_.map(campaigns, 'campaignId')).then(function (res) {
                            if (res) {
                                vm.log(res);
                                return;
                            }

                            var data = { name: _.map(campaigns, 'name').toString(), id: _.map(campaigns, 'campaignId').toString() };
                            authService.trackEvent({ action: 'RemoveCampaigns', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                            vm.log(campaigns.length + ' campaign' + (campaigns.length > 1 ? 's' : '') + " Removed");
                            vm.rootVM.cancelCampaign();
                            vm.setView({ view: 'ALL_CAMPAIGNS' });
                            vm.checkedEntities['campaign'] = [];
                            vm.loadCampaigns();
                        });
                    });
                }

                vm.removeCampaigns = function (campaigns) {
                    makeCampaignsRemoval(campaigns);
                };

                vm.removeCampaign = function (selectedCampaign) {
                    vm.removeCampaigns([selectedCampaign]);
                };

                vm.pauseRestartCampaign = function (c) {
                    if (c.campaignStatusId === 10) {
                        hub.webapicontext.restartCampaign(c.campaignId).then(function () {
                            c.campaignStatusId = 1;
                            vm.log(c.name + " Activated");
                            vm.loadCampaigns();
                        });
                    } else {
                        hub.webapicontext.pauseCampaign(c.campaignId).then(function () {
                            c.campaignStatusId = 10;
                            vm.log(c.name + " Paused");
                            vm.loadCampaigns();
                        });
                    }
                };

                $scope.hover = function (entity, show) {
                    return entity.showActions = show;
                };
                vm.editV2Campaign = function(selectedCampaign) {
                    $location.path(`app/activation/campaign/${selectedCampaign.campaignId}/edit`);
                }
                vm.overviewV2 = function(selectedCampaign) {
                    $location.path(`app/activation/campaign/${selectedCampaign.campaignId}/overview`);
                }
                vm.overview = function(selectedCampaign) {
                    $location.path(`activation/campaign/${selectedCampaign.campaignId}/`);
                }
                vm.editCampaign = function (selectedCampaign, viewMode) {
                    if (!viewMode){
                        viewMode = 'edit';
                    }

                    var modalInstance;

                    if (selectedCampaign.isMultiChannel) {
                        vm.rootVM.campaignEditMode = true;
                        hub.webapicontext.getCampaign(selectedCampaign.campaignId).then(function (result) {
                            if (result) {
                                var campaign = result[0];
                                campaignService.setSelectedCampaign(campaign, campaign.adGroups[0]);
                                $location.path('activation/campaign/campaign-wizard/' + campaign.campaignId);
                            }
                        });
                    }
                    else {
                        hub.webapicontext.getCampaign(selectedCampaign.campaignId).then(function (data) {
                            if (data) {
                                $location.path('activation/campaign/' + selectedCampaign.campaignId + '/edit', false);
                                var campaign = data[0];
                                modalInstance = $modal.open({
                                    templateUrl: 'views/campaign/editors/campaign.html',
                                    animation: false,
                                    controller: 'campaignEditorModalInstanceCtrl as vm',
                                    backdrop: 'static',
                                    resolve: {
                                        campaign: function () {
                                            return campaign;
                                        },
                                        action: function () {
                                            return 'edit';
                                        },
                                        viewMode: function () { return viewMode; }
                                    }
                                });

                                modalInstance.result.then((function (campaign, action) {
                                    $location.path('activation/campaign', false);
                                }), function () {
                                    $location.path('activation/campaign', false);
                                });

                                rootVM.cancel = function () {
                                    modalInstance.dismiss('cancel');
                                    $location.path('activation/campaign');
                                };
                            }
                        });
                    }
                };

                vm.showSettings = function (action) {
                    var modalInstance;
                    var selectedCampaign = campaignService.getSelectedCampaign();
                    if (vm.sectionType === 'CAMPAIGN') {
                        if (selectedCampaign.campaignStatusId === 6 || selectedCampaign.campaignStatusId === 8) {
                            vm.rootVM.campaignEditMode = true;
                            hub.webapicontext.getCampaign(selectedCampaign.campaignId).then(function (result) {
                                if (result) {
                                    var campaign = result[0];
                                    campaignService.setSelectedCampaign(campaign, campaign.adGroups[0]);
                                    $location.path('activation/campaign/campaign-wizard/' + campaign.campaignId);
                                }
                            });
                        }
                        else {
                            modalInstance = $modal.open({
                                templateUrl: 'views/campaign/editors/campaign.html',
                                animation: false,
                                controller: 'campaignEditorModalInstanceCtrl as vm',
                                backdrop: 'static',
                                resolve: {
                                    campaign: function () {
                                        return angular.copy(selectedCampaign);
                                    },
                                    action: function () {
                                        return action;
                                    }
                                }
                            });
                            modalInstance.result.then((function (campaign, action) { }), function () { });
                        }
                    }

                    if (vm.sectionType === sectionTypeEnum.AdGroup) {

                        vm.rootVM.uiType = 'adgroup';
                        vm.rootVM.action = action;

                        var selectedAdGroup = campaignService.getSelectedAdGroup();
                        hub.webapicontext.getCampaign(selectedAdGroup.campaignId).then(function (result) {

                            if (result.isFullBasket !== vm.isFullBasket) {
                                hub.getLog('campaigns', 'error')('Full Basket Mode has changed. Page Refresh is required');

                                $timeout(function () {
                                    $window.location.reload();
                                }, 5000);
                            }

                            if (result && result[0] && result[0].adGroups) {
                                var campaign = result[0];
                                var adGroup = _.filter(campaign.adGroups, function (ag) { return ag.adGroupId === selectedAdGroup.adGroupId; })[0];
                                campaignService.setSelectedAdGroup(adGroup, campaign);
                                campaignService.setSelectedMarketerId(campaign.marketerId);
                                if(vm.shouldOpenEditAdGroup()){
                                    modalInstance = $modal.open({
                                        templateUrl: 'views/campaign/editors/ad-group.html',
                                        animation: false,
                                        controller: 'editAdGroupController as vm',
                                        windowClass: 'app-adgroup-modal-window',
                                        backdrop: 'static',
                                        resolve: {
                                            data: { adGroup: adGroup }
                                        }
                                    });
                                }
                            }

                            rootVM.cancel = function () {
                                modalInstance.dismiss('cancel');
                            };
                            modalInstance.result.then((function (adGroup, campaign) {
                                campaignsBlockUI.start();
                                campaignService.getCampaignsStatistic(true, vm.ts.data.campaigns.pageNumber - 1, vm.keyword, vm.filter, vm.liveOnly, vm.sort.campaigns.column, vm.sort.campaigns.sortDesc).then(function () {
                                    $rootScope.$broadcast('campaignsLoaded', null);
                                    campaignsBlockUI.stop();
                                });

                            }), function () {

                            });
                        });
                    }
                };

                vm.copyCampaign = function (selectedCampaign) {

                    if (vm.copyingInProgress)
                        return;
                    vm.copyingInProgress = true;

                    var startTime = new Date();
                    hub.webapicontext.copyCampaign(selectedCampaign.campaignId).then(function (res) {
                        if (res) {
                            vm.log(selectedCampaign.name + " Copied." + (res.originalCampaignHadTriggeredAdGroup ? " Note that Lasso Trigger settings were not copied over" : ""));
                            vm.loadCampaigns(() => {
                                var data = { id: selectedCampaign.id, name: selectedCampaign.name };
                                authService.trackEvent({ action: 'CopyCampaign', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                                vm.copyingInProgress = false;
                            })
                        }
                        else {
                            vm.log("Can not copy legacy campaign.");
                            vm.copyingInProgress = false;
                        }
                    });

                };

                vm.newCampaign = function () {
                    vm.rootVM.cancelCampaign();
                    $location.path("/activation/campaign/campaign-wizard");
                };
                vm.newCampaignV2 = function () {
                    vm.rootVM.cancelCampaign();
                    $location.path("/app/activation/campaign/new");
                };

                vm.bulkCampaignResend = function () {
                    var modalInstance = $modal.open({
                        templateUrl: 'views/campaign/editors/bulk-campaign-resend.html',
                        animation: false,
                        controller: 'bulkCampaignResendController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            settings: {}
                        }
                    });

                    modalInstance.result.then((function (data) {
                        vm.log("Bulk Campaigns resent.");
                    }), (function () {
                        $rootScope.$broadcast('modal.force.close', null);
                    }));

                };

                rootVM.statusTypes = [
                    {
                        id: 1, name: 'Active'
                    },
                    {
                        id: 2, name: 'Creative Review'
                    },
                    {
                        id: 3, name: 'Paused'
                    },
                    {
                        id: 4, name: 'Complete'
                    },
                    {
                        id: 5, name: 'Scheduled'
                    },
                    {
                        id: 6, name: 'Draft'
                    },
                    { id: 7, name: 'Removed' },
                    { id: 8, name: 'Creative Review Failed' },
                    { id: 10, name: 'Inactive' },
                    { id: 15, name: 'Archived' },
                ];

                $scope.$on('campaignsLoaded', function (e, page) {
                    vm.campaigns = campaignService.pagedCampaignsStatistic.results;
                    vm.total = campaignService.pagedCampaignsStatistic.count;
                });

                //send campaigs list to CSV exporter.
                vm.exportCampaigns = function () {

                    var excludeMoneyFormatColumns = campaignService.excludeMoneyFormat();
                    var deferred = $q.defer();
                    //get column definitions
                    var campColumns = vm.tableColumns;
                    var columnsToExport = [{ column: 'advertiserName', name: 'Account' }, { column: 'name', name: 'Campaign Name' }];
                    //add visible columns and alter names if necessary
                    campColumns.forEach(function (c) {
                        if (vm.config.tables.canShowColumn('campaigns', c.key) && (vm.showCampaignComments || c.key !== 'COM'))
                            columnsToExport.push({ column: c.column, name: vm.config.tables.getName(c.key, 'campaigns'), format: c.format });

                    });

                    var headers = {};
                    //add headers
                    columnsToExport.forEach(function (ce) {
                        headers[ce.column] = ce.name;
                    });
                    //this is target list
                    var campaigns = [headers];
                    //Here we need to get ALL STATS. It can be promise.
                    vm.loadCampaigns(() => {
                        var data = _.orderBy(campaignService.exportCampaignsStatistic.results, [vm.sort.campaigns.column], [vm.sort.campaigns.sortDesc ? 'desc' : 'asc']);
                        data.forEach(function (c) {

                            var campToExport = {};
                            columnsToExport.forEach(function (ce) {
                                //6 decimal in export Spend and AveCPM
                                var format = excludeMoneyFormatColumns[ce.column];
                                if (!format) {
                                    format = ce.format;

                                }

                                if (vm.userSettingsService.multiTouchMode && format == '$') //SHOW 3 decimal point in MTA sales (export only)
                                    format = '$3';

                                campToExport[ce.column] = format ? vm.page.formatValue(c[ce.column], format) : c[ce.column];

                            });

                            campaigns.push(campToExport);

                        });
                        if (campaignService.exportCampaignsStatistic.reportingUpdates) {

                            campaignService.addReportingDatesForExport(campaigns);
                        }
                        deferred.resolve(campaigns);
                    }, true);
                    return deferred.promise;
                };

                vm.filterCampaigns = function () {
                    var FB_CHANNEL_ID = 3;
                    if (vm.campaigns && vm.campaigns.length && vm.campaigns.length > 0)
                        vm.updatedThroughDate = vm.campaigns[0].createdDate;
                    else
                        vm.updatedThroughDate = null;
                    var hasFBCampaigns = false;
                    vm.campaigns.forEach(function (c) {
                        var marketer = campaignService.marketers.find(function (r) { return r.id == c.marketerId; });
                        c.canBeCopied = !(c.isLegacy && marketer.marketerContract);
                        hasFBCampaigns = hasFBCampaigns || c.channelId == FB_CHANNEL_ID;
                    });

                    if (vm.header) {
                        vm.header.campaignId = null;
                        vm.header.visitRate = null;
                        vm.header.averageFrequency = null;
                        vm.header.uniqueMessaged = null;
                        vm.header.costPerUnit = null;
                        vm.header.conversionRate = null;
                        vm.header.costPerHCP = null;
                        vm.header.uniqueHCPs = null;
                        vm.header.averageFreqByHcp = null;
                        vm.header.adGroupId = null;

                        //    5834 total for columns below are only calculated for non FB campaigns
                        if (hasFBCampaigns) {
                            vm.header.roas = null;
                            vm.header.totalROAS = null;
                            vm.header.incrementalROAS = null;
                            vm.header.viewROAS = null;
                            vm.header.clickROAS = null;
                            vm.header.fullCartROAS = null;
                            vm.header.fullCartViewROAS = null;
                            vm.header.fullCartClickROAS = null;
                        }
                        vm.header.fullCartIncrementalROAS = null;
                    }
                };

                vm.pageSize = vm.config.pageSize().toString();

                vm.pageSizeChanged = function (size) {
                    vm.pageSize = size;
                    vm.pageNumber = 1;
                    vm.loadData();
                };

                vm.getConceptGroups = function () {
                    const campaignId = $routeParams.campaignId;
                    campaignService.getCampaignConceptGroupsExport(campaignId)
                        .then(function (data) {
                            if (data.message) {
                                vm.log(data.message);
                                return;
                            }

                            var url = data.url;
                            var downloadContainer = angular.element('<div data-tap-disabled="true"><a target="_self" download href="' + url + '"></a></div>');
                            var downloadLink = angular.element(downloadContainer.children()[0]);
                            $document.find('body').append(downloadContainer);
                            $timeout(function () {
                                downloadLink[0].click();
                                downloadLink.remove();
                            }, 500);
                        }, function () {
                            const message = 'No concept group associated with campaign id = ' + campaignId;
                            vm.log(message);
                        });
                };

                vm.exportSettings = function () {
                    const campaignId = $routeParams.campaignId;
                    campaignService.getBulkExport({
                        pageNumber: vm.pageNumber - 1,
                        keyword: vm.agKeyword,
                        progress: vm.adgroupProgress,
                        filter: { ...vm.filter, statuses: vm.filter.adGroupStatuses },
                        sortColumn: vm.sort.adGroups.column,
                        sortDescending: vm.sort.adGroups.sortDesc,
                        dateRange: vm.dateRange,
                        campaignId: campaignId
                    })
                        .then(function (data) {
                            var url = data.url;
                            var downloadContainer = angular.element('<div data-tap-disabled="true"><a target="_self" download href="' + url + '"></a></div>');
                            var downloadLink = angular.element(downloadContainer.children()[0]);
                            $document.find('body').append(downloadContainer);
                            $timeout(function () {
                                downloadLink[0].click();
                                downloadLink.remove();
                            }, 500);
                        });
                };

                vm.getAdGroupExportData = function () {

                    var excludeMoneyFormatColumns = campaignService.excludeMoneyFormat();
                    var columns = vm.adGroupTableColumns;

                    var csvData = [];
                    var excluded_columns = [];
                    var included_columns = [];
                    included_columns.push({ name: 'Advertiser Name', column: 'advertiserName', format: 'text' });
                    included_columns.push({ name: 'Campaign Name', column: 'campaignName', format: 'text' });
                    included_columns.push({ name: 'Campaign ID', column: 'campaignId', format: 'text' });
                    included_columns.push({ name: 'Ad Group', column: 'name', format: 'name' });
                    included_columns = included_columns.concat(columns);
                    csvData.push(_.map(included_columns, 'name'));
                    var deferred = $q.defer();

                    vm.loadAdGroups(() => {
                        var adGroups = campaignService.exportAdGroupsStatistic.results;
                        adGroups.forEach(function (item, index) {
                            var r = [];
                            included_columns.forEach(function (c) {
                                //6 digits spend
                                if (excludeMoneyFormatColumns[c.column] != null)
                                    r.push(!excludeMoneyFormatColumns[c.column] ? item[c.column] : vm.page.formatValue(item[c.column], excludeMoneyFormatColumns[c.column]));
                                else
                                    r.push(!c.format ? item[c.column] : vm.page.formatValue(item[c.column], c.format));
                            });
                            csvData.push(r);
                        });
                        campaignService.addReportingDatesForExport(csvData);
                        deferred.resolve(csvData);
                    }, true);


                    return deferred.promise; // csvData;

                };

                vm.bulkAdGroupUpload = function () {
                    var modalInstance = $modal.open({
                        templateUrl: 'views/campaign/editors/bulk-ad-group-upload.html',
                        animation: false,
                        controller: 'bulkAdGroupUploadController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            settings: {}
                        }
                    });

                    modalInstance.result.then((function (data) {
                        vm.log("Bulk Ad Groups saved.");
                        vm.loadData();
                    }), (function () {
                        $rootScope.$broadcast('modal.force.close', null);
                    }));

                };


                vm.pageSelected = function () {
                    var entityType = getEntityType();
                    vm.checkedEntities[entityType] = [];
                    vm.allChecked = false;

                    vm.loadCampaigns();
                };

                vm.agPageSelected = function () {
                    vm.loadAdGroups();
                };

                vm.needUpdateCampaigns = false;
                vm.segmentSelection = {};
                vm.setAdGroup = function () {
                    var selectedAdGroup = campaignService.getSelectedAdGroup();
                    if ($location.$$path.indexOf('/activation/campaign/adgroup/' + selectedAdGroup.adGroupId + '/edit') > -1) {
                        vm.activeTab = 1;
                        vm.tabs[1].active = true;
                    }
                    else {
                        vm.activeTab = 2;
                        vm.tabs[2].active = true;
                    }
                    sessionStorage["campaign_adgroup_active_tab"] = vm.activeTab;

                    if (vm.selectedAdGroupId !== (selectedAdGroup && selectedAdGroup.adGroupId)) {



                        //set campaign if we get to AD Group through ALL AD groups veiw
                        vm.selectedCampaign = campaignService.getSelectedCampaign();
                        vm.selectedCampaignId = vm.selectedCampaign.campaignId;

                        vm.selectedAdGroupId = (selectedAdGroup && selectedAdGroup.adGroupId) ? selectedAdGroup.adGroupId : 0;

                        if ($location.$$path.indexOf('/activation/campaign/adgroup/' + selectedAdGroup.adGroupId + '/edit') > -1) {
                            vm.sectionType = sectionTypeEnum.Campaign;
                            vm.tabs[1].active = true;
                        }
                        else {
                            vm.sectionType = sectionTypeEnum.AdGroup;
                            vm.tabs[2].active = true;
                        }


                        vm.segmentSelection = { adGroupId: vm.selectedAdGroupId };
                    }
                };

                function getChartsData() {
                    if (!vm.selectedCampaign.campaignId)
                        return;

                    vm.chartsSet = true;
                    campaignService.getCampaignSummary(vm.selectedCampaign.campaignId, vm.dateRange)
                        .then(function (result) {
                            var chartsData = _.orderBy(result.header.statistics.metrics);

                            var impressions = _.map(chartsData, 'impressions');
                            vm.impressionsLastTrend = impressions[1] ? (impressions[0] - impressions[1]) / impressions[1] : 0;
                            createChart('impressionsChart', chartsData, 'impressions');

                            var clicks = _.map(chartsData, 'clicks');
                            vm.clicksLastTrend = clicks[1] ? (clicks[0] - clicks[1]) / clicks[1] : 0;
                            createChart('clicksChart', chartsData, 'clicks');

                            var spend = _.map(chartsData, 'spend');
                            vm.spendLastTrend = spend[1] ? (spend[0] - spend[1]) / spend[1] : 0;
                            createChart('spendChart', chartsData, 'spend');

                            var ctr = _.map(chartsData, 'ctr');
                            vm.ctrLastTrend = ctr[1] ? (ctr[0] - ctr[1]) / ctr[1] : 0;
                            createChart('ctrChart', chartsData, 'ctr');
                        });
                }

                function createChart(chartId, data, valueField, cumulativeField) {

                    // Create chart instance
                    var chart = am4core.create(chartId, am4charts.XYChart);

                    // Add data
                    chart.data = data;

                    // Create axes
                    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                    dateAxis.dataFields.category = "date";
                    dateAxis.renderer.grid.template.location = 0.0001;
                    dateAxis.renderer.labels.template.verticalCenter = "middle";
                    dateAxis.renderer.labels.template.horizontalCenter = "middle";
                    dateAxis.renderer.minGridDistance = 50;

                    chart.yAxes.push(new am4charts.ValueAxis());
                    var series = chart.series.push(new am4charts.LineSeries());
                    series.dataFields.valueY = valueField;
                    series.dataFields.dateX = "date";

                    var gradient = new am4core.LinearGradient();
                    gradient.addColor(am4core.color('#3DDBD9'));
                    gradient.addColor(am4core.color('#2598D5'));
                    series.stroke = gradient;
                    series.fill = gradient;

                    series.strokeWidth = 2;
                    series.minBulletDistance = 10;
                    if (valueField === 'spend')
                        series.tooltipText = "[bold]{date.formatDate()}:[/] ${" + valueField + ".formatNumber('###.##')}";
                    else if (valueField === 'ctr')
                        series.tooltipText = "[bold]{date.formatDate()}:[/] {" + valueField + ".formatNumber('###.##')}%";
                    else
                        series.tooltipText = "[bold]{date.formatDate()}:[/] {" + valueField + "}";
                    series.tooltip.pointerOrientation = "vertical";

                    if (cumulativeField) {
                        var cValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        cValueAxis.renderer.labels.template.disabled = true;
                        var cSeries = chart.series.push(new am4charts.LineSeries());
                        cSeries.dataFields.valueY = cumulativeField;
                        cSeries.dataFields.dateX = "date";

                        cSeries.strokeWidth = 2;
                        cSeries.strokeDasharray = "3,4";
                    }

                    chart.cursor = new am4charts.XYCursor();
                    chart.cursor.xAxis = dateAxis;

                    return series;
                }

                function getChartOptions(metric, cumulativeMetric) {
                    var chartOptions = angular.copy(hub.config.chart.templates.serial);
                    chartOptions.graphs[0].valueField = metric;
                    if (cumulativeMetric) {
                        chartOptions.graphs[1].valueField = cumulativeMetric;
                        chartOptions.graphs[1].dashed = true;
                    }
                    return chartOptions;
                }

                function getBarChartOptions(metric) {
                    var chartOptions = angular.copy(hub.config.chart.templates.serial);
                    chartOptions.graphs[0].valueField = metric;
                    return chartOptions;
                }


                vm.setCampaign = function () {
                    var selectedCampaign = campaignService.getSelectedCampaign();
                    if (selectedCampaign === 'All Campaigns') {
                        vm.activeTab = 0;
                        sessionStorage["campaign_adgroup_active_tab"] = vm.activeTab;
                        vm.tabs[0].active = true;

                        vm.selectedCampaignId = 0;
                        vm.sectionType = sectionTypeEnum.AllCampaigns;
                    }
                    else {
                        vm.activeTab = 1;
                        sessionStorage["campaign_adgroup_active_tab"] = vm.activeTab;
                        vm.tabs[1].active = true;

                        vm.selectedCampaignId = (selectedCampaign && selectedCampaign.campaignId) ? selectedCampaign.campaignId : 0;
                        vm.selectedAdGroupId = 0;
                        vm.sectionType = sectionTypeEnum.Campaign;

                        getChartsData();

                        selectedCampaign.marketer = campaignService.marketers.find(function (r) { return r.id == selectedCampaign.marketerId; });
                        if (selectedCampaign.adGroups && selectedCampaign.adGroups.length > 0) {
                            context.stat.campaignSummary(vm.selectedCampaignId).then(function (stat) {

                                vm.adGroupHeader = {};
                                vm.adGroupHeader = stat.results.filter(function (s) { return s.adGroupId === -1; })[0];


                                if (vm.adGroupHeader) {
                                    vm.adGroupHeader.adGroupId = null;
                                    vm.originalAdGroupHeader = angular.copy(vm.adGroupHeader);
                                }


                                selectedCampaign.adGroups.forEach(function (adGroup) {
                                    var adgroupStat = stat.results.filter(function (s) { return s.adGroupId === adGroup.adGroupId; })[0];

                                    for (var i = 0; i < vm.tableColumns.length; i++) {
                                        if (vm.tableColumns[i].key !== 'ID' && vm.tableColumns[i].key !== 'Model' && vm.tableColumns[i].key !== 'AGSD' && vm.tableColumns[i].key !== 'AGED' && vm.tableColumns[i].key !== 'AGBC')
                                            adGroup[vm.tableColumns[i].column] = 0;//(vm.tableColumns[i].format == 'text' ? '':  0);
                                    }

                                    for (var attrname in adgroupStat) {
                                        if (!adGroup[attrname])
                                            adGroup[attrname] = adgroupStat[attrname];
                                    }

                                    adGroup.status = vm.getStatusNameById(adGroup.adGroupStatusId);
                                    adGroup.hasImpression = adgroupStat ? adgroupStat.hasImpression : false;
                                    PrepareFlightData(adGroup);
                                });


                                //   }


                                vm.filterAdGroups();
                            });
                        }
                    }
                };


                function PrepareFlightData(adGroup) {
                    if (adGroup.actualImpressions != undefined && adGroup.budgetTypeId == 1 || adGroup.actualSpend != undefined && adGroup.budgetTypeId == 2)
                        return;
                    if (!vm.barChartOptions)
                        vm.barChartOptions = getBarChartOptions('pacing');

                    const campaign = vm.campaigns.find(function (c) { return c.campaignId == adGroup.campaignId; });
                    if (campaign != null) {
                        adGroup.budgetTypeId = campaign.budgetTypeId;
                        pacingService.setPacingData(adGroup);
                    }

                }

                vm.isFacebookCampaign = function () {
                    return false;
                };
                vm.tz = timezoneService;
                vm.getEasternTimeZone = timezoneService.getEasternTimeZone;
                ////---Ad Group CRUD
                vm.createNewAdGroup = function () {
                    var selectedCampaign = campaignService.getSelectedCampaign();
                    if (vm.campaignId && !selectedCampaign) {
                        selectedCampaign = campaignService.adGroupsStatistic.campaigns.find(c => c.campaignId == vm.campaignId);
                        campaignService.setSelectedCampaign(selectedCampaign, null, true);
                    }
                    var advertiserId = selectedCampaign.advertiserId;
                    var deviceTypes = [1, 2, 4, 8]; //selectedCampaign.channelId === 3 ? [1, 8] : [1, 2, 4];

                    var adGroup = {
                        campaignId: selectedCampaign.campaignId, selectedLocations: [],
                        name: '', audienceSegmentGroups: [], brandSafetySegments: [], connectedTVSegments: [], locationSegments: [], recencyType: '', includedLocations: [], selectedSegmentIds: [], supplyTypes: [1, 2, 4], deviceTypes: deviceTypes, placements: [1, 2, 4], countries: ["US"], audienceLoyality: [false, false, false, false, false, false, false, false, false], excludeMyAdvertiserCustomers: false, browsers: true, buyers: true, recencyFrom: 0, recencyTo: 30,
                        creative: { destinationURL: '', created: '', status: 'Active', creativeIconURL: '', selectedItems: [] }
                    };

                    var marketer = campaignService.marketers.find(function (r) { return r.id === selectedCampaign.marketerId; });
                    var allowedMediaTypes = marketer.options.find(function (ro) { return ro.optionId === 6; });
                    var doTurnedOn = allowedMediaTypes && allowedMediaTypes.value.indexOf(9) > -1;

                    if (!selectedCampaign.isMultiChannel) {
                        if (!vm.dataOnlyAllowed && (!vm.isAdmin || !doTurnedOn) && selectedCampaign.channelId == 9)
                            adGroup.channelId = 1;
                        else
                            adGroup.channelId = selectedCampaign.channelId === 16 && !vm.hasEndemicFeature ? 1 : selectedCampaign.channelId;;

                        adGroup.channelIndex = 0;
                        if (adGroup.channelId == 4)
                            adGroup.crossDeviceExtension = true;

                        if (adGroup.channelId == 3)
                            adGroup.deviceTypes = [1, 8];
                        else
                            adGroup.deviceTypes = [1, 2, 4];
                    }
                    else {
                        adGroup.channelIndex = selectedCampaign.adGroups.length - 1;
                        adGroup.primaryAdGroup = selectedCampaign.adGroups[selectedCampaign.adGroups.length - 1];
                    }

                    if (advertiserId) {
                        adGroup.advertiserId = advertiserId;
                    }

                    if (adGroup.channelId === 6) {
                        adGroup.emailDeploymentType = 1;
                    }

                    campaignService.setSelectedAdGroup(adGroup, selectedCampaign);

                    $location.path('activation/campaign/' + selectedCampaign.campaignId + '/new', false);
                    vm.rootVM.uiType = 'adgroup';
                    var modalInstance;
                    if(!vm.shouldOpenEditAdGroup()){
                        return;
                    }
                    modalInstance = $modal.open({
                        templateUrl: 'views/campaign/editors/ad-group.html',
                        animation: false,
                        controller: 'editAdGroupController as vm',
                        windowClass: 'app-adgroup-modal-window',
                        backdrop: 'static',
                        resolve: {
                            data: { adGroup: adGroup },
                            viewMode: function () {
                                return 'new';
                            }
                        }
                    });
                    vm.rootVM.cancel = function () {
                        modalInstance.dismiss('cancel');
                    };
                    modalInstance.result.then((function () {
                        vm.loadAdGroups();
                        $rootScope.$broadcast('modal.force.close', null);
                        $location.path('activation/campaign/' + selectedCampaign.campaignId, false);
                    }), function () {
                        $rootScope.$broadcast('modal.force.close', null);
                        $location.path('activation/campaign/' + selectedCampaign.campaignId, false);
                    });
                };

                vm.getAdGroupEditUrl = function (adGroupId) {
                    return window.location.href.replace(window.location.href, "") + "activation/campaign/adgroup/" + adGroupId + "/edit";
                }

                vm.editAdGroupClick = function (event, selectedAdGroup, viewMode) {
                    event.preventDefault();
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    else if (window.event) {
                        window.event.cancelBubble = true;
                    }
                    if (event.originalEvent.button !== 2) {
                        vm.editAdGroup(selectedAdGroup, viewMode);
                    }
                };

                vm.editAdGroup = function (selectedAdGroup, viewMode) {
                    if(!vm.shouldOpenEditAdGroup()){
                        return;
                    }
                    var modalInstance;
                    if (!viewMode)
                        viewMode = 'edit';

                    vm.rootVM.uiType = 'adgroup';
                    vm.rootVM.action = 'edit';

                    hub.webapicontext.getCampaign(selectedAdGroup.campaignId).then(function (result) {
                        const selectedCampaign = campaignService.getSelectedCampaign();
                        const id = () => {
                            return typeof selectedCampaign === 'string' ? '' : selectedCampaign.campaignId;
                        }
                        if (result && result[0] && result[0].adGroups) {
                            var campaign = result[0];
                            var adGroup = _.filter(campaign.adGroups, function (ag) { return ag.adGroupId == selectedAdGroup.adGroupId; })[0];
                            adGroup.hasImpression = selectedAdGroup.hasImpression;
                            adGroup.impressions = selectedAdGroup.impressions;
                            adGroup.spend = selectedAdGroup.spend;
                            $location.path('activation/campaign/adgroup/' + adGroup.adGroupId + '/edit', false);
                            campaignService.setSelectedAdGroup(adGroup, campaign);
                            campaignService.setSelectedMarketerId(campaign.marketerId);
                            modalInstance = $modal.open({
                                templateUrl: 'views/campaign/editors/ad-group.html',
                                animation: false,
                                controller: 'editAdGroupController as vm',
                                windowClass: 'app-adgroup-modal-window',
                                backdrop: 'static',
                                resolve: {
                                    data: { adGroup: adGroup },
                                    viewMode: function () { return viewMode; }
                                }
                            });
                        }

                        rootVM.cancel = function () {
                            modalInstance.dismiss('cancel');
                            vm.setCampaignUrl(id());
                        };
                        modalInstance.result.then((function () {
                            vm.loadAdGroups();
                            vm.setCampaignUrl(id());
                            $rootScope.$broadcast('modal.force.close', null);
                        }), function () {
                            vm.setCampaignUrl(id());
                            $rootScope.$broadcast('modal.force.close', null);
                        });
                    });
                };

                vm.removeAdGroup = function (adGroup) {
                    var modalOptions = {
                        closeButtonText: 'Cancel',
                        actionButtonText: 'Remove',
                        message: 'Are you sure you want to remove ad group ' + adGroup.name + '?'
                    };
                    dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                        var startTime = new Date();
                        hub.webapicontext.removeAdGroup(adGroup.adGroupId).then(function () {
                            vm.log(adGroup.name + " Removed");
                            vm.loadAdGroups(() => {
                                var data = { id: adGroup.id, name: adGroup.name };
                                authService.trackEvent({ action: 'RemoveAdGroup', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                            })
                            if ($routeParams.campaignId)
                                vm.setCampaignUrl(adGroup.campaignId);
                        });
                    });
                };


                vm.removeAdGroups = function (adGroups) {
                    var modalOptions = {
                        closeButtonText: 'Cancel',
                        actionButtonText: 'Remove',
                        message: 'Are you sure you want to remove ' + adGroups.length + ' AdGroup' + (adGroups.length > 1 ? 's?' : '?')
                    };
                    dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                        var startTime = new Date();
                        hub.webapicontext.removeAdGroups(_.map(adGroups, 'adGroupId')).then(function () {
                            vm.log(adGroups.length + ' Ad Group' + (campaigns.length > 1 ? 's' : '') + " Removed");
                            vm.loadAdGroups(() => {
                                var data = { id: _.map(adGroups, 'adGroupId').toString(), name: _.map(adGroups, 'name').toString() };
                                authService.trackEvent({ action: 'RemoveAdGroups', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                            });
                            vm.checkedEntities['adGroup'] = [];
                            vm.setCampaignUrl(adGroups[0].campaignId);
                        });
                    });
                };

                vm.copyAdGroup = function (adGroup) {
                    if (vm.copyingInProgress)
                        return;
                    vm.copyingInProgress = true;
                    var startTime = new Date();
                    hub.webapicontext.copyAdGroup(adGroup.adGroupId).then(function (res) {
                        if (res) {
                            vm.setCampaignUrl(adGroup.campaignId);
                            vm.log(adGroup.name + " Copied");
                            vm.loadAdGroups(() => {
                                var data = { id: adGroup.id, name: adGroup.name };
                                authService.trackEvent({ action: 'CopyCreative', view: $location.$$path, data: JSON.stringify(data) },
                                    { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                                vm.copyingInProgress = false;
                            });
                        } else {
                            vm.log("Can not copy legacy ad group.");
                            vm.copyingInProgress = false;
                        }
                    });
                };

                vm.copyEditAdGroup = function (ag) {
                    const origAg = vm.originalAdGroups.find(x => x.adGroupId == ag.adGroupId);
                    const adGroup = { ...ag, ...origAg };

                    var modalOptions = {
                        templateUrl: 'views/campaign/editors/ad-group-copy-edit.html',
                        animation: false,
                        controller: 'copyEditAdGroupController',
                        size: 'lg',
                        resolve: { adGroup: adGroup }
                    };
                    dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                        vm.setCampaignUrl(adGroup.campaignId);
                        vm.log(adGroup.name + " Copied");
                        var startTime = new Date();
                        vm.loadAdGroups(() => {
                            var data = { id: adGroup.adGroupId, name: adGroup.name };
                            authService.trackEvent({ action: 'CopyCreative', view: $location.$$path, data: JSON.stringify(data) },
                                { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                            vm.copyingInProgress = false;
                        })
                    });
                };

                vm.pauseRestartAdGroup = function (ag) {

                    var modalOptions = {
                        closeButtonText: 'Cancel',
                        actionButtonText: (ag.adGroupStatusId === 10 ? 'Activate' : 'Pause'),
                        message: 'Are you sure you want to ' + (ag.adGroupStatusId === 10 ? 'activate' : 'pause') + ' ' + ag.name + '?'
                    };
                    dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                        var startTime = new Date();
                        if (ag.adGroupStatusId === 10) {
                            hub.webapicontext.restartAdGroup(ag.adGroupId).then(function () {
                                ag.adGroupStatusId = 1;
                                vm.log(ag.name + " Activated");
                                vm.loadAdGroups(() => {
                                    var data = { id: ag.id, name: ag.name };
                                    authService.trackEvent({ action: 'RestartAdGroup', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                                });
                            });
                        } else {
                            hub.webapicontext.pauseAdGroup(ag.adGroupId).then(function () {
                                ag.adGroupStatusId = 10;
                                vm.log(ag.name + " Paused");
                                vm.loadAdGroups(() => {
                                    var data = { id: ag.id, name: ag.name };
                                    authService.trackEvent({ action: 'PauseAdGroup', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                                });
                            });
                        }
                    });
                };

                function setFilter(tab) {
                    const key = tab === 0 ? "campaigns_filter" : "adgroups_filter";
                    if (sessionStorage[key]) {
                        vm.filterViewModel = JSON.parse(sessionStorage[key]);
                    } else {
                        checkAll(tab == 0 ? 'statuses' : 'adGroupStatuses');
                        vm.filterViewModel = angular.copy(vm.filterViewModel);
                    }
                    vm.filter = angular.copy(vm.filterViewModel);
                    sessionStorage[key] = JSON.stringify(vm.filter);
                }

                ////---End Ad Group CRUD
                const tabWatcher = $scope.$watch('vm.activeTab', function (newVal) {
                    if (vm.filterListsLoaded) {
                        setFilter(newVal);
                        if (newVal == 0)
                            vm.applyFilterClicked('campaigns', vm.sort.campaigns.keyword);
                        else if (newVal == 1) {
                            vm.applyFilterClicked('adgroups', vm.sort.adGroups.keyword);
                        }
                    }
                });
                const listsWatcher = $scope.$watch('vm.filterListsLoaded', function (newVal) {
                    if (newVal) {
                        vm.loadData();
                    }
                });
                $scope.$on('$routeUpdate', function ($event, next, current) {
                    tabWatcher();
                    listsWatcher();
                    campaignWatcher();
                    rangeWatcher();
                });
                $scope.$on('adGroupSelected', function () {
                    vm.selectedAdGroup = campaignService.getSelectedAdGroup();
                    if (vm.selectedAdGroup.adGroupId) {
                        var editAdGroupPath = $location.$$path.indexOf('/activation/campaign/adgroup/' + vm.selectedAdGroup.adGroupId + '/edit') > -1 ? '/edit' : '';
                        $location.path('activation/campaign/adgroup/' + vm.selectedAdGroup.adGroupId + editAdGroupPath);
                    }
                });
                $scope.$on('$destroy', function () {
                    listsWatcher();
                    rangeWatcher();
                });

                const campaignWatcher = $scope.$on('campaignSelected', function () {
                    if (vm.campaigns && vm.campaigns.length > 0) {
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        vm.selectedCampaign = selectedCampaign;
                        if (selectedCampaign === 'All Campaigns') {
                            if ($location.$$path !== "/activation/campaign") {
                                $location.path('activation/campaign');
                            }
                        }
                        else {

                            var newAdGroupPath = '';
                            if ($location.$$path.indexOf('/activation/campaign/' + selectedCampaign.campaignId + '/new') > -1)
                                newAdGroupPath = '/new';
                            else if ($location.$$path.indexOf('/activation/campaign/' + selectedCampaign.campaignId + '/edit') > -1)
                                newAdGroupPath = '/edit';

                            if (newAdGroupPath)
                                $location.path('activation/campaign/' + selectedCampaign.campaignId + newAdGroupPath);

                            vm.setCanShowPlatformFees();
                        }
                    }
                });
                vm.adGroupHeader = {};

                vm.getAdGroups = function (id) {
                    vm.adGroups = [];
                    vm.removedAdGroups = [];
                    if (id > 0) {
                        vm.campaigns.forEach(function (item, index) {
                            if (item.campaignId === id) {
                                item.adGroups.forEach(function (ag, i) {
                                    ag.budgetTypeId = item.budgetTypeId;
                                    ag.campaignName = item.name;
                                    if (ag.adGroupStatusId === 7)
                                        vm.removedAdGroups.push(ag);
                                    else
                                        vm.adGroups.push(ag);
                                });
                                vm.tabs[1].active = true;
                                return;
                            }
                        });
                    }
                    else {
                        vm.campaigns.forEach(function (item, index) {
                            if (item.adGroups)
                                item.adGroups.forEach(function (ag, i) {
                                    ag.budgetTypeId = item.budgetTypeId;
                                    ag.campaignName = item.name;
                                    if (ag.adGroupStatusId === 7)
                                        vm.removedAdGroups.push(ag);
                                    else {
                                        ag.fullName = item.name + ' > ' + ag.name;
                                        ag.advertiserName = item.advertiserName;
                                        vm.adGroups.push(ag);
                                    }
                                });
                            vm.tabs[1].active = true;
                            return;
                        });

                    }
                    vm.filterAdGroups();

                    vm.tabs[1].active = true;
                };

                vm.setCampaignUrl = function (id) {
                    if (id || vm.activeTab == 0) {
                        $location.path('activation/campaign/' + id);
                    }
                    else
                        $location.path('activation/campaign', false);
                };


                vm.setAdGroupUrl = function (id) {

                    $location.path('activation/campaign/adgroup/' + id);
                };
                const currentCampaignId = () => {
                    return $routeParams.campaignId;
                };
                vm.processUrl = function () {

                    if ($location.path().endsWith('new')) {
                        return;
                    }

                    var adGroupId = $routeParams.adGroupId;
                    let campaignId = currentCampaignId();

                    vm.campaignId = campaignId;

                    var mode = $routeParams.mode;

                    if (adGroupId) {
                        vm.routeToAdGroupEdit = true;
                        hub.webapicontext.getCampaign(0, adGroupId).then(function (result) {
                            if (result && result[0]) {
                                let adgrp = undefined;
                                if (result[0].adGroups) {
                                    for (let a of result[0].adGroups) {
                                        if (a.adGroupId == adGroupId) {
                                            adgrp = angular.copy(a);
                                            break;
                                        }
                                    }
                                }
                                if (!adgrp.startDate) {
                                    $location.path('activation/campaign/' + adgrp.campaignId, true);
                                    return;
                                }
                                if (vm.shouldOpenEditAdGroup()){
                                    campaignService.getAccounts().then(function () {
                                        if (adgrp) {
                                            vm.rootVM.uiType = 'adgroup';
                                            vm.rootVM.action = 'edit';
                                            vm.selectedCampaignId = result[0].campaignId;
                                            vm.selectedAdGroupId = adgrp.adGroupId;
                                            appCache.put("selectedAdGroup", adgrp);
                                            appCache.put("selectedCampaign", result[0]);
                                            appCache.put("selectedMarketerId", result[0].marketerId);
                                            var modalInstance = $modal.open({
                                                templateUrl: 'views/campaign/editors/ad-group.html',
                                                animation: false,
                                                controller: 'editAdGroupController as vm',
                                                windowClass: 'app-adgroup-modal-window',
                                                backdrop: 'static',
                                                resolve: {
                                                    data: { adGroup: adgrp },
                                                    viewMode: function () { return 'edit'; }
                                                }
                                            });
                                            rootVM.cancel = function () {
                                                vm.routeToAdGroupEdit = false;
                                                modalInstance.dismiss('cancel');
                                                vm.setCampaignUrl(vm.selectedCampaignId);
                                            };
                                            modalInstance.result.then((function (adGroup, campaign) {
                                                vm.routeToAdGroupEdit = false;
                                                vm.loadAdGroups();
                                                vm.setCampaignUrl(vm.selectedCampaignId);
                                                $rootScope.$broadcast('modal.force.close', null);
                                            }), function () {
                                                vm.routeToAdGroupEdit = false;
                                                vm.setCampaignUrl(vm.selectedCampaignId);
                                                $rootScope.$broadcast('modal.force.close', null);
                                            });
                                        } else {
                                            vm.log('Ad Group Not Found');
                                        }
                                    });
                                }
                            }
                        });
                        vm.processed = true;
                    }
                    else if (campaignId) {
                        const isCampaignEditPage = vm.isCampaignEditPage(campaignId);
                        if (vm.activeTab != 1 && !isCampaignEditPage) {
                            vm.changeActiveTab(1)
                        }
                        if (vm.campaigns.length === 0)
                            return;

                        var camp = _.find(vm.campaigns, { campaignId: parseInt(campaignId) });
                        if (camp) {
                            campaignService.setSelectedCampaign(camp);
                            vm.sort.adGroups.keyword = '';
                            vm.agKeyword = vm.sort.adGroups.keyword;
                            if ($location.$$path.indexOf('/activation/campaign/' + campaignId + '/edit') === -1) {
                                vm.setCampaign();
                                //update ag columns
                                vm.setAdGroupColumns();
                            }
                            else {
                                if (vm.shouldOpenEditCampaign()) {
                                    if (vm.hasAccess('campaigns', 'Manage') && camp.campaignStatusId != 9 && camp.campaignStatusId != 4)
                                        mode = 'edit';
                                    else
                                        mode = 'readonly';
                                    vm.editCampaign(camp, mode);
                                } else {
                                    return;
                                }
                            }
                            if ($location.$$path.indexOf('/activation/campaign/' + campaignId + '/new') > -1)
                                vm.createNewAdGroup();
                            vm.processed = true;
                        }
                        else if (!rootVM.searchingForCampaign) {
                            rootVM.searchingForCampaign = true;
                            if (!vm.isCampaignEditPage(campaignId)) {
                                vm.changeActiveTab(1);
                            }

                        } else if (vm.shouldOpenEditCampaign()) {
                            vm.log("Campaign is not found");
                            vm.processed = true;
                        }

                    } else {
                        vm.selectedCampaign = 'All Campaigns';
                        campaignService.setSelectedCampaign(vm.selectedCampaign);
                        vm.keyword = vm.sort.campaigns.keyword;
                        vm.setAdGroupColumns();
                        vm.processed = true;
                    }
                };
                vm.shouldOpenEditCampaign = function () {
                    return document.querySelector('#campaign-editor-modal') === null;
                }
                vm.shouldOpenEditAdGroup = function () {
                    return document.querySelector('.app-adgroup-modal-window') === null;
                }

                var original = $location.path;
                $location.path = function (path, reload) {
                    if (reload === false) {
                        var lastRoute = $route.current;
                        var un = $rootScope.$on('$locationChangeSuccess', function () {
                            if (IsCurrentPage($route.current.$$route.originalPath))
                                $route.current = lastRoute;

                            un();
                        });
                    }
                    return original.apply($location, [path]);
                };

                function IsCurrentPage(path) {
                    if (path.indexOf('activation/campaign') > -1)
                        return true;
                    return false;
                }

                vm.showHistory = function (adgroup) {
                    campaignService.setSelectedAdGroup(adgroup);
                    const path = `/activation/adgroup-history/${adgroup.adGroupId}`;
                    $location.path(path, true);
                }

                //check if rootscope campaignUpdate is already on
                if(!$rootScope.$$listenerCount['campaignUpdate']) {
                    $rootScope.$on('campaignUpdate', function (name, notification) {
                        if ($location.path().endsWith('new') || $location.path().endsWith('edit')) {
                            return;
                        }
                        const userInfo = authService.userInfoData();
                        const isSuperAdmin = authService.isSuperAdmin();
                        const message = JSON.parse(notification.message);
                        const idx = vm.activeTab;
                        if (isSuperAdmin || message.marketerId == userInfo.accountId) {
                            logger.showToastPermanent('Lasso Notification: Campaign List has been changed.');
                            if (idx == 0) {
                                vm.applyFilterClicked('campaigns', vm.sort.campaigns.keyword);
                            } else if (idx == 1 && (vm.adgroupProgress == 1 || vm.campaignId == message.campaignId)) {
                                vm.applyFilterClicked('adgroups', vm.sort.adGroups.keyword);
                            }
                        }
                    });
                }
                else
                {
                    console.log('campaignUpdate is already initialized ' + $rootScope.$$listenerCount['campaignUpdate']);
                }

                vm.processUrl();

                return vm;
            }]);

    angular.module('app')
        .controller('copyEditAdGroupController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'adGroup', 'hub', 'campaignService', 'authService', '$location', 'timezoneService', 'validationService', '$timeout', function ($scope, $rootScope, $modalInstance, $sce, adGroup, hub, campaignService, authService, $location, timezoneService, validationService, $timeout) {
            var vm = this;
            $scope.vm = vm;
            vm.adGroup = angular.copy(adGroup);
            vm.originalName = vm.adGroup.name;

            vm.datepickers = { startDate: false, endDate: false };
            vm.min;
            vm.hours = ["1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00"];
            vm.meridiems = ["AM", "PM"];

            var m = moment.utc();
            vm.currentEastTimeAbbreviation = m.tz('America/New_York').format('z');
            var now = new Date();
            var offset = -5.00;
            if (vm.currentEastTimeAbbreviation === "EDT")
                offset = -4.00;
            var tzDifference = offset * 60 + now.getTimezoneOffset();
            vm.minDate = now;
            vm.minDate.setMinutes(vm.minDate.getMinutes() - 1);

            vm.startCalendarDate = new Date(now.getTime() + tzDifference * 60 * 1000);
            vm.endCalendarDate = new Date(now.getTime() + tzDifference * 60 * 1000);

            vm.dateRangeValid = vm.adGroup.channelId != '3' || (Math.abs(vm.adGroup.UTCCalendarEndDate - vm.adGroup.UTCCalendarStartDate) / 36e5) >= 25;
            vm.endDateValid = vm.adGroup.endDate == null || vm.adGroup.endDate >= now;

            function setDate(date, dateInPastSubstitution) {
                var now = new Date();
                if (date && new Date(date) >= now) {
                    return new Date(date);
                }
                return new Date(dateInPastSubstitution);
            }

            function setAdGroupData() {
                //vm.adGroup.startDate = new Date((new Date()).getTime() + 60 * 60000);//add 60 minutes to current date time
                var startDate;
                var endDate;
                var now = new Date();
                startDate = setDate(vm.adGroup.startDate, new Date((new Date()).getTime() + 60 * 60000));
                vm.adGroup.startDate = startDate;
                vm.adGroup.startDateTime = moment(startDate).tz('America/New_York').format('h') + ':00';
                vm.adGroup.startDateHour = moment(startDate).tz('America/New_York').format('hh');
                vm.adGroup.startDateMinutes = moment(startDate).tz('America/New_York').format('mm');
                vm.adGroup.startDateMeridiem = moment(startDate).tz('America/New_York').format('A');

                endDate = setDate(vm.adGroup.endDate, new Date(now.getTime() + 30 * 24 * 60 * 60000));//add 30 days to current date time
                if (endDate && (endDate.valueOf(Date) > 0)) {
                    if (endDate.getUTCFullYear() < 2037) {
                        vm.isOngoing = false;
                        vm.adGroup.endDate = endDate;
                        vm.adGroup.endDateTime = moment(endDate).tz('America/New_York').format('h') + ':00';
                        vm.adGroup.endDateHour = moment(endDate).tz('America/New_York').format('hh');
                        vm.adGroup.endDateMinutes = moment(endDate).tz('America/New_York').format('mm');
                        vm.adGroup.endDateMeridiem = moment(endDate).tz('America/New_York').format('A');
                    }
                    else if (endDate.getUTCFullYear() >= 2037) {
                        vm.adGroup.endDate = null;
                        vm.adGroup.UTCCalendarEndDate = null;
                        vm.isOngoing = true;
                    }
                }
                else {
                    vm.adGroup.endDate = null;
                    vm.adGroup.UTCCalendarEndDate = null;
                    vm.isOngoing = true;
                }

                vm.adGroup.budgetCap = '';
                hub.webapicontext.getAdGroupsFlights([vm.adGroup.adGroupId]).then(function (flights) {
                    var flight;
                    var dateDiff = Number.MAX_VALUE;
                    for (var i = 0; i < flights.length; i++)
                    {
                        var startDate = new Date(flights[i].startDate);
                        var dateDiff1 = startDate.getTime() - now.getTime();
                        if (dateDiff1 < 0 && Math.abs(dateDiff1) < dateDiff) {
                            flight = flights[i];
                            dateDiff = dateDiff1;
                        }
                    }
                    if (flight === undefined && flights.length > 0) {
                        flight = flights[flights.length - 1];
                    }
                    vm.adGroup.budgetCap = flight.budgetCap;
                    vm.adGroup.flights = [
                        {
                            startDate: setDate(flight.startDate, new Date((new Date()).getTime() + 60 * 60000)),
                            endDate: setDate(flight.endDate, new Date(now.getTime() + 30 * 24 * 60 * 60000)),
                            budgetCap: vm.adGroup.budgetCap,
                            pacingPercent: flight.pacingPercent,
                            isLifetimePacing: vm.adGroup.budgetCapSpendModeId == 2
                        }
                    ];
                    vm.adGroup.originalMaxMediaBid = vm.adGroup.maxMediaBid;
                    vm.parseStartDate();
                    vm.parseEndDate();
                });
            }

            vm.addFlight = function () {
                const flights = vm.adGroup.flights.filter(x => x.statusId !== 1);
                var lastFlight = _.last(flights);
                if (lastFlight.endDate > now) {
                    const date = timezoneService.getDayEnd(lastFlight.endDate);
                    lastFlight.endDate = date;
                    const { utcDate } = timezoneService.estUtc(lastFlight.endDate, lastFlight.endDate.getHours(), lastFlight.endDate.getMinutes());
                    lastFlight.UTCCalendarEndDate = utcDate;
                }

                const range = timezoneService.getNextRange(lastFlight.endDate < now ? now : lastFlight.endDate);

                if (lastFlight.endDate < now)
                    range.startDate = (new Date(now)).setHours((new Date(now)).getHours() + 1);

                vm.adGroup.flights.push({ ...range, statusId: 0, pacingPercent: 100, isLifetimePacing: vm.adGroup.budgetCapSpendModeId == 2 });
                $rootScope.$broadcast('flightAdded', vm.adGroup.flights.length - 1);

            }

            vm.removeFlight = function (flight) {
                if (flight.id)
                    flight.statusId = 1;
                else
                    vm.adGroup.flights.splice($.inArray(flight, vm.adGroup.flights), 1);
                $rootScope.$broadcast('flightRemoved');
            }

            vm.getEasternTimeZone = timezoneService.getEasternTimeZone;

            vm.getEasternDateTime = function (date) {
                return moment(date).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss ZZ');
            };

            vm.adGroup.startDateIsInThePast = vm.adGroup.startDate < vm.startCalendarDate && vm.adGroup.adGroupId && vm.adGroup.adGroupStatusId === 1;

            vm.startDateChanged = function () {
                var newDate = moment(vm.adGroup.UTCCalendarStartDate).tz('America/New_York').format('YYYY/MM/DD');
                var currentDate = moment(Date.now()).tz('America/New_York').format('YYYY/MM/DD');
                if (currentDate === newDate) {
                    vm.adGroup.startDateHour = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('hh');
                    vm.adGroup.startDateMinutes = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('mm');
                    vm.adGroup.startDateMeridiem = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('A');
                }
                else {
                    vm.adGroup.startDateHour = "12";
                    vm.adGroup.startDateMinutes = "00";
                    vm.adGroup.startDateMeridiem = "AM";
                }
                vm.adGroup.startDate = timezoneService.utcToEst(vm.adGroup.UTCCalendarStartDate);
                vm.parseStartDate();
            };
            vm.parseStartDate = function () {
                var result = processDatepickerDate(vm.adGroup.startDate, vm.adGroup.UTCCalendarStartDate, vm.adGroup.startDateHour, vm.adGroup.startDateMinutes, vm.adGroup.startDateMeridiem, 'America/New_York');
                vm.adGroup.startDate = result.date;
                vm.adGroup.startDateHour = result.hours;
                vm.adGroup.UTCCalendarStartDate = result.utcDate;

                if (!(vm.adGroup.channelId != 3 && vm.adGroup.channelId != 6 && vm.adGroup.budgetCapSpendModeId == 2))
                    vm.adGroup.flights[0].startDate = vm.adGroup.startDate;
            };

            vm.parseEndDate = function () {
                if (vm.adGroup.endDate) {
                    if (vm.adGroup.UTCCalendarEndDate) {
                        vm.adGroup.endDate = timezoneService.utcToEst(vm.adGroup.UTCCalendarEndDate, vm.adGroup.endDateHour, vm.adGroup.endDateMinutes);
                    }
                    var result = processDatepickerDate(vm.adGroup.endDate, vm.adGroup.UTCCalendarEndDate, vm.adGroup.endDateHour, vm.adGroup.endDateMinutes, vm.adGroup.endDateMeridiem, 'America/New_York');
                    vm.adGroup.endDate = result.date;
                    vm.adGroup.UTCCalendarEndDate = result.utcDate;
                    vm.adGroup.endDateHour = result.hours;
                }

                if (!(vm.adGroup.channelId != 3 && vm.adGroup.channelId != 6 && vm.adGroup.budgetCapSpendModeId == 2))
                    vm.adGroup.flights[0].endDate = vm.adGroup.endDate;
            };

            vm.formatHours = function (hourType, hourValue) {
                if (hourValue.length === 1)
                    hourValue = "0" + hourValue;
                else if (hourValue.length === 3)
                    hourValue = hourValue.substr(1);

                var parsed = parseInt(hourValue)

                if (isNaN(parsed) || parsed > 12 || parsed < 1) {
                    hourValue = "01";
                    vm.selectTime(hourType, 'hour');
                }

                if (hourType === "start")
                    vm.adGroup.startDateHour = hourValue;
                else if (hourType === "end")
                    vm.adGroup.endDateHour = hourValue;
            };

            vm.formatMinutes = function (minutesType, minutesValue) {
                var parsed = parseInt(minutesValue);

                if (isNaN(parsed) || parsed > 59 || parsed < 0) {
                    minutesValue = "00";
                    vm.selectTime(minutesType, 'minute');
                }

                if (minutesValue.length === 1)
                    minutesValue = "0" + minutesValue;
                else if (minutesValue.length === 3)
                    minutesValue = minutesValue.substr(1);

                if (minutesType === "start")
                    vm.adGroup.startDateMinutes = minutesValue;
                else if (minutesType === "end")
                    vm.adGroup.endDateMinutes = minutesValue;
            };

            vm.selectTime = function (type, part) {
                if (part === 'hour') {
                    setTimeout(function () {
                        angular.element(type === 'start' ? '#startDateHour' : '#endDateHour')[0].setSelectionRange(0, 2);
                    }, 0);
                } else if (part === 'minute') {
                    setTimeout(function () {
                        angular.element(type === 'start' ? '#startDateMinutes' : '#endDateMinutes')[0].setSelectionRange(0, 2);
                    }, 0);
                } else if (part === 'meridiem') {
                    setTimeout(function () {
                        angular.element(type === 'start' ? '#startDateMeridiem' : '#endDateMeridiem')[0].setSelectionRange(0, 2);
                    }, 0);
                }
            };

            vm.toggleHours = function (hoursType, $event) {
                var intHours;
                var newHours;

                if ($event.keyCode === 38) //up arrow
                {
                    if (hoursType === 'start') {
                        intHours = parseInt(vm.adGroup.startDateHour) + 1;
                        newHours = (intHours).toString();
                        newHours = newHours.length === 1 ? "0" + newHours : newHours;
                        vm.adGroup.startDateHour = intHours === 13 ? "01" : newHours;
                        vm.parseStartDate()
                    }
                    else if (hoursType === 'end') {
                        intHours = parseInt(vm.adGroup.endDateHour) + 1;
                        newHours = (intHours).toString();
                        newHours = newHours.length === 1 ? "0" + newHours : newHours;
                        vm.adGroup.endDateHour = intHours === 13 ? "01" : newHours;
                        vm.parseEndDate();
                    }
                    vm.selectTime(hoursType, 'hour');

                }
                else if ($event.keyCode === 40) //down arrow
                {
                    if (hoursType === 'start') {
                        intHours = parseInt(vm.adGroup.startDateHour) - 1;
                        newHours = (intHours).toString();
                        newHours = newHours.length === 1 ? "0" + newHours : newHours;
                        vm.adGroup.startDateHour = intHours === 0 ? "12" : newHours;
                        vm.parseStartDate();
                    }
                    else if (hoursType === 'end') {
                        intHours = parseInt(vm.adGroup.endDateHour) - 1;
                        newHours = (intHours).toString();
                        vm.adGroup.endDateHour = intHours === 0 ? "12" : newHours;
                        vm.parseEndDate();
                    }
                    vm.selectTime(hoursType, 'hour');
                }
            };

            vm.toggleMinutes = function (minutesType, $event) {
                var intMinutes;
                var newMinutes;
                if ($event.keyCode === 38) //up arrow
                {
                    if (minutesType === 'start') {
                        intMinutes = parseInt(vm.adGroup.startDateMinutes) + 1;
                        newMinutes = intMinutes.toString();
                        newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                        vm.adGroup.startDateMinutes = intMinutes === 60 ? "00" : newMinutes;
                        vm.parseStartDate();
                    }
                    else if (minutesType === 'end') {
                        intMinutes = parseInt(vm.adGroup.endDateMinutes) + 1;
                        newMinutes = intMinutes.toString();
                        newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                        vm.adGroup.endDateMinutes = intMinutes === 60 ? "00" : newMinutes;
                        vm.parseEndDate();
                    }
                    vm.selectTime(minutesType, 'minute');
                }
                else if ($event.keyCode === 40) //down arrow
                {
                    if (minutesType === 'start') {
                        intMinutes = parseInt(vm.adGroup.startDateMinutes) - 1;
                        newMinutes = intMinutes.toString();
                        newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                        vm.adGroup.startDateMinutes = intMinutes === -1 ? "59" : newMinutes;
                        vm.parseStartDate();
                    }
                    else if (minutesType === 'end') {
                        intMinutes = parseInt(vm.adGroup.endDateMinutes) - 1;
                        newMinutes = intMinutes.toString();
                        newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                        vm.adGroup.endDateMinutes = intMinutes === -1 ? "59" : newMinutes;
                        vm.parseEndDate();
                    }
                    vm.selectTime(minutesType, 'minute');
                }
            };

            vm.toggleMeridiem = function (meridiemType, $event) {
                if ($event.keyCode === 38 || $event.keyCode === 40) {
                    if (meridiemType === 'start') {
                        vm.adGroup.startDateMeridiem = (vm.adGroup.startDateMeridiem === "AM") ? "PM" : "AM";
                        vm.parseStartDate();
                    }
                    else if (meridiemType === 'end') {
                        vm.adGroup.endDateMeridiem = (vm.adGroup.endDateMeridiem === "AM") ? "PM" : "AM";
                        vm.parseEndDate();
                    }
                }
                else if ($event.keyCode === 65) {
                    if (meridiemType === 'start' && vm.adGroup.startDateMeridiem === "PM") {
                        vm.adGroup.startDateMeridiem = "AM";
                        vm.parseStartDate();
                    }
                    else if (meridiemType === 'end' && vm.adGroup.endDateMeridiem === "PM") {
                        vm.adGroup.endDateMeridiem = "AM";
                        vm.parseEndDate();
                    }
                }
                else if ($event.keyCode === 80) {
                    if (meridiemType === 'start' && vm.adGroup.startDateMeridiem === "AM") {
                        vm.adGroup.startDateMeridiem = "PM";
                        vm.parseStartDate();
                    }
                    else if (meridiemType === 'end' && vm.adGroup.endDateMeridiem === "AM") {
                        vm.adGroup.endDateMeridiem = "PM";
                        vm.parseEndDate();
                    }
                }

                if ($event.keyCode != 9 && $event.keyCode != 16) {
                    $event.preventDefault();
                }
                vm.selectTime(meridiemType, 'meridiem');
            };

            const processDatepickerDate = timezoneService.processDatepickerDate;

            vm.dateDiff = function () {
                var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                var firstDate = new Date(vm.adGroup.startDate);
                var secondDate = new Date(vm.adGroup.endDate);
                var diffDays = Math.ceil(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
                return diffDays;
            };

            vm.ongoingChanged = function () {
                if (vm.isOngoing) {
                    vm.adGroup.endDate = null;
                    vm.adGroup.UTCCalendarEndDate = null;
                    vm.adGroup.flights[0].endDate = null;
                    vm.adGroup.flights[0].UTCCalendarEndDate = null;
                    vm.adGroup.budgetCapSpendModeId = 1;
                }
                else {
                    vm.adGroup.endDate = new Date(vm.adGroup.startDate.getTime());
                    vm.adGroup.endDate.setDate(vm.adGroup.endDate.getDate() + 1); //tomorrow
                    vm.adGroup.endDateTime = vm.adGroup.startDateTime;
                    vm.adGroup.endDateHour = "11";
                    vm.adGroup.endDateMinutes = "59";
                    vm.adGroup.endDateMeridiem = "PM";
                    vm.adGroup.flights[0].endDate = vm.adGroup.endDate;
                }
                vm.parseEndDate();
            }

            vm.openDatepicker = function ($event, which) {
                $event.preventDefault();
                $event.stopPropagation();
                for (var prop in vm.datepickers) {
                    if (prop !== which)
                        vm.datepickers[prop] = false;
                }
                if (vm.datepickers[which])
                    vm.datepickers[which] = false;
                else
                    vm.datepickers[which] = true;
            };

            vm.adGroupNameChanged = function () {
                if (vm.originalName === vm.adGroup.name) {
                    vm.adGroup.duplicateName = true;
                    return;
                }
                vm.adGroup.duplicateName = false;
                var validationData = {};
                validationData.entityName = vm.adGroup.name;
                validationData.entityType = "adgroup";
                validationData.campaignId = vm.adGroup.campaignId;
                validationData.adGroupId = vm.adGroup.adGroupId;
                hub.webapicontext.validateName(validationData).then(function (data) {
                    if (data.message !== "Valid Name")
                        vm.adGroup.duplicateName = true;
                });
            };

            $scope.$watch('vm.adGroup.UTCCalendarEndDate', function (newVal) {
                if (typeof newVal !== 'undefined') {
                    var now = new Date(vm.getEasternDateTime(Date.now()));
                    var endDate = new Date(vm.adGroup.endDate);
                    vm.endDateValid = vm.adGroup.endDate == null || (vm.adGroup.endDate >= vm.adGroup.startDate
                        && Math.ceil((endDate.getTime() - now.getTime()) / 60 / 1000) >= 30);
                    var hours = Math.abs(newVal - vm.adGroup.UTCCalendarStartDate) / 36e5;
                    vm.dateRangeValid = (hours >= 25 || vm.adGroup.channelId != '3') && Math.abs(vm.adGroup.endDate - vm.adGroup.startDate) / 36e5 >= 25;
                    vm.adGroup.datesInvalid = !vm.dateRangeValid || !vm.endDateValid || vm.startDateValid == false;

                    $timeout(function () {
                        $('#endDateValidation').trigger('input');
                        $('#dateRangeValidation').trigger('input');
                    });
                }
            });

            $scope.$watch('vm.adGroup.startDate', function (newVal) {
                if (typeof newVal !== 'undefined') {
                    var currentDate = new Date();
                    vm.startDateValid = vm.adGroup.startDateIsInThePast || newVal >= moment(currentDate).add(10, 'm').toDate();
                    vm.dateRangeValid = Math.abs(vm.adGroup.endDate - vm.adGroup.startDate) / 36e5 >= 25;
                    vm.adGroup.datesInvalid = !vm.dateRangeValid || !vm.endDateValid || vm.startDateValid == false;
                    $timeout(function () {
                        $('#startDateValidation').trigger('input');
                    });
                }
            });

            $scope.ok = function () {
                var budgetValid = true;
                var datesValid = true;
                if (vm.adGroup.flights.length == 1 && !vm.adGroup.flights[0].budgetCap || !(vm.adGroup.channelId != 3 && vm.adGroup.channelId != 6 && vm.adGroup.budgetCapSpendModeId == 2))
                    vm.adGroup.flights[0].budgetCap = vm.adGroup.budgetCap;

                vm.adGroup.flights.forEach(function (f) {
                    if (!f.budgetCap) {
                        budgetValid = false;
                    }

                    const { dateRangeValid, endDateValid, inBounds } = validationService.validateFlight(f, vm.adGroup, vm.campaign);
                    if (!dateRangeValid || !endDateValid || !inBounds || f.startDateValid == false || vm.adGroup.datesInvalid)
                        datesValid = false;
                });

                let getRoundedDate = (minutes, d = new Date()) => {
                    let ms = 1000 * 60 * minutes;
                    let roundedDate = new Date(Math.floor(d.getTime() / ms) * ms);
                    return roundedDate;
                }

                if (vm.adGroup.flights) {
                    vm.adGroup.flights.forEach(function (f) {
                        f.startDate = getRoundedDate(1, f.startDate);
                        if (f.endDate) {
                            f.endDate = getRoundedDate(1, f.endDate);
                            f.endDate.setSeconds(59);
                        }
                    });
                }

                vm.adGroup.startDate = getRoundedDate(1, vm.adGroup.startDate);
                if (vm.adGroup.endDate) {
                    vm.adGroup.endDate = getRoundedDate(1, vm.adGroup.endDate);
                    vm.adGroup.endDate.setSeconds(59);
                }

                if (vm.copyingInProgress || vm.adGroup.duplicateName || !budgetValid || !datesValid) {
                    $('#copyAdGroupForm').validator('validate');
                    return;
                }

                vm.copyingInProgress = true;
                hub.webapicontext.copyEditAdGroup(vm.adGroup).then(function () {
                    vm.copyingInProgress = false;
                    $modalInstance.close(vm.adGroup);
                });
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            setAdGroupData();
            vm.adGroupNameChanged();
        }]);

    angular.module('app')
        .controller('bulkCampaignResendController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'settings', 'hub', 'campaignService', 'authService', '$location', '$timeout', 'Enum', function ($scope, $rootScope, $modalInstance, $sce, settings, hub, campaignService, authService, $location, $timeout, Enum) {
            var vm = this;
            $scope.vm = vm;
            var rootVM = $rootScope.rootVM;
            vm.isAdmin = authService.isSuperAdmin();

            $scope.ok = function () {
                vm.isInProgress = true;
                var campaignIds = vm.campaignIds.replace(' ', '').split(',');
                hub.webapicontext.resendBulkCampaigns(campaignIds).then(function () {
                    $modalInstance.close();
                });
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };
        }]);

    angular.module('app')
        .controller('bulkAdGroupUploadController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'settings', 'hub', 'campaignService', 'authService', '$location', '$timeout', 'Enum', 'timezoneService', function ($scope, $rootScope, $modalInstance, $sce, settings, hub, campaignService, authService, $location, $timeout, Enum, timezoneService) {
            var vm = this;
            $scope.vm = vm;
            var rootVM = $rootScope.rootVM;
            vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('creatives', 'Manage');
            vm.sizes = campaignService.getSizes();
            vm.showPreview = true;
            vm.page = hub.page;
            vm.canSave = true;

            vm.uploadCSV = function (file) {
                vm.isInProgress = true;
                return hub.webapicontext.uploadFile('AdGroup/BulkUpload', file).then(function (data) {
                    if (data.data) {
                        vm.lassoTriggerAdGroupToCopyExists = data.data.results.find(function (ag) { return ag.validationResult.lassoTriggerAdGroupToCopyExists; });
                        var validAdGroups = data.data.results.filter(function (ag) { return ag.validationResult.isValid; });
                        var invalidAdGroups = data.data.results.filter(function (ag) { return !ag.validationResult.isValid; });

                        vm.validAdGroups = [];
                        validAdGroups.forEach(function (ag) {
                            vm.validAdGroups.push(ag);
                            ag.flights.forEach(function (f) {
                                f.flightStartDate = timezoneService.est(f.flightStartDate);
                                f.flightEndDate = timezoneService.est(f.flightEndDate);
                                vm.validAdGroups.push(f);
                            })
                        });

                        vm.invalidAdGroups = [];
                        invalidAdGroups.forEach(function (ag) {
                            vm.invalidAdGroups.push(ag);
                            ag.flights.forEach(function (f) {
                                vm.invalidAdGroups.push(f);
                            })
                        });

                        vm.headers = _.orderBy(data.data.headers, 'order');
                        vm.validAdGroups.forEach(function (ag) {
                            var channel = campaignService.getChannels().find(function (c) { return c.id === ag.channelId; });
                            if (channel)
                                ag.maxCPM = channel.estimatedMediaCPM; // channel.id == 3 ? campaignMarketer.marketerContract.facebookMediaCPM : channel.estimatedMediaCPM;
                        });

                        vm.isInProgress = false;
                    }
                });
            };

            vm.includedChanged = function () {
                $timeout(function () {
                    vm.canSave = vm.validAdGroups.find(ag => ag.include);
                });
            }

            vm.getEasternDateTime = function (date) {
                return moment(date).tz('America/New_York').format('YYYY-MM-DD HH:mm');
            };

            $scope.ok = function () {
                vm.isInProgress = true;
                hub.webapicontext.saveBulkAdGroups(vm.validAdGroups).then(function () {
                    $modalInstance.close();
                });
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };
        }]);

    angular.module('app')
        .directive('flightProgress', function () {
            return {
                templateUrl: 'views/app/flight-progress.html',
                restrict: 'E',
                scope: { model: '=' },
                controller: ['$scope', 'timezoneService', function ($scope, timezoneService) {
                    var vm = this;
                    const INFINITE_FLIGHT = 1073741823;
                    vm.model = $scope.model;
                    vm.estFormat = timezoneService.estFormat;
                    var loadData = function (model) {
                        vm.model = model;

                        let { flightEndDate, flightStartDate, flightRemain } = model;
                        //flightEndDate, flightStartDate already in east
                        flightStartDate = flightStartDate.substr(0, flightStartDate.length - 1);

                        const startDate = moment.tz(flightStartDate, 'America/New_York');
                        const now = moment();
                        const start = timezoneService.estFormat(flightStartDate, '');
                        let end = '';

                        if (now < startDate) {
                            vm.remain = `${startDate.toNow(true)} until start`;
                        } else {
                            vm.remain = `${flightRemain} days remaining`;
                        }
                        if (INFINITE_FLIGHT == flightRemain) {
                            vm.remain = '';
                            end = 'No End Date';
                        } else {
                            flightEndDate = flightEndDate.substr(0, flightEndDate.length - 1);
                            end = timezoneService.estFormat(flightEndDate);
                        }
                        vm.formatted = `${start} - ${end}`;
                    }

                    $scope.$watch('model', function (newModel, oldModel) {
                        loadData(newModel);
                    });
                    return vm;
                }],
                controllerAs: 'vm'
            }
        });

    angular.module('app')
        .directive('flightDelivery', function () {
            return {
                template: require('@views/app/flight-delivery.html').default,
                restrict: 'E',
                scope: { model: '=' },
                controller: ['$scope', 'hub', function ($scope, hub) {
                    var vm = this;
                    const getColor = (delivery) => {
                        if (delivery === null) {
                            return null;
                        }
                        else if (delivery >= 0.9) {
                            return 'green';
                        } else if (delivery >= 0.7 && delivery < 0.9) {
                            return 'yellow';
                        } else if (delivery < 0.7) {
                            return 'red';
                        }
                    }
                    vm.format = hub.page.formatValue;
                    vm.model = $scope.model;
                    var loadData = function (model) {
                        vm.model = model;
                        let { flightDelivery } = model;
                        vm.hasDelivery = flightDelivery !== null && flightDelivery >= 0;
                        vm.flightDelivery = vm.hasDelivery ? vm.format(flightDelivery, '%') : null;
                        vm.color = getColor(flightDelivery);
                    }

                    $scope.$watch('model', function (newModel, oldModel) {
                        loadData(newModel);
                    });
                    return vm;
                }],
                controllerAs: 'vm'
            }
        });
})();
