(function () {
    'use strict';
    angular.module('app').controller('dateRangePopupController', ['$scope', '$rootScope', '$uibModalInstance', 'specificDateRange', 'hub', function ($scope, $rootScope, $modalInstance, specificDateRange, hub) {
        var vm = this;

        vm.startDate = new Date(specificDateRange.date);
        vm.specificDateFilters = [{ caption: 'Day', key: 'D' }, { caption: 'Week', key: 'W' }, { caption: 'Month', key: 'M' }, { caption: 'Quarter', key: 'Q' }];
        vm.selectedFilter = specificDateRange.range || 'M';

        vm.datepickers = {
            startDate: false,
            endDate: false
        }

        vm.openDatepicker = function ($event, which) {
            $event.preventDefault();
            $event.stopPropagation();
            for (var prop in vm.datepickers) {
                vm.datepickers[prop] = false;
            }
            vm.datepickers[which] = true;
        };

        vm.canSubmit = function () {
            return true;
        };

        vm.ok = function () {
            var dateRange = { date: vm.startDate, range: vm.selectedFilter };
            if ($modalInstance) {
                $modalInstance.dismiss(dateRange);
            }
        };

        vm.cancel = function () {
            if ($modalInstance)
                $modalInstance.dismiss();
        };

        return vm;
    }]);


})();
