(function () {
    'use strict';

    angular.module('app')
  .directive('reportFilter', function () {
      return {
          template: require('@views/app/report-filter.html').default,
          restrict: 'E',
          scope: { model: '=', mode: '=' },
          controller: ['$scope', 'hub', 'customReportService', 'campaignService', '$filter', 'accountService', 'featureService', 'authService', '$timeout', function ($scope, hub, customReportService, campaignService, $filter, accountService, featureService, authService, $timeout) {
              var vm = this;
              vm.mode = $scope.mode;
              var possibleModes = [
                  'advertisers',
                  'publishers',
                  'campaigns',
                  'adGroups',
                  'marketers',
                  'channels',
                  'mediaTypes',
                  'retailChannels',
                  'stores'
              ];

              $scope.$watch('vm.reportConfig.filter.mode', function(newValue){
                if(newValue && possibleModes.indexOf(newValue) > -1){
                    vm.filterModel = vm.display[newValue];
                    vm.setMode({}, newValue);
                    vm.visible = true;
                } else {
                    vm.visible = false;
                }
              })

              vm.display = customReportService.filter;

              if (accountService.get() && accountService.get().accountType == 2 && vm.display.marketers)
                  vm.display.marketers.removed = true;

              vm.showAll = function () {

                  vm.reportConfig.report.filter.campaigns.showAll = !vm.reportConfig.report.filter.campaigns.showAll;
                  vm.display.campaigns.hidden = vm.reportConfig.report.filter.campaigns.showAll;
                  vm.display.advertisers.hidden = vm.reportConfig.report.filter.advertisers.showAll;
                  vm.display.publishers.hidden = vm.reportConfig.report.filter.marketers.showAll;
                  vm.display.channels.hidden = vm.reportConfig.report.filter.channels.showAll;
                  vm.display.marketers.hidden = vm.reportConfig.report.filter.marketers.showAll;

                  if (vm.reportConfig.report.filter.campaigns.showAll) {
                      vm.reportConfig.report.filter.campaigns.ids = [];
                      vm.display.campaigns.items.forEach(function (it) {
                          it.checked = false;
                      });
                  }
              };

              vm.reportConfig = customReportService;

              let campaign_list = customReportService.the_filtered_list(vm.reportConfig.report.filter, vm.display.mode);

              let channels = campaignService.getChannels();
              if (vm.reportConfig.isSellSideReport()) {
                const appliedlevels = vm.reportConfig.report.levels.map(l => l.key);
                const restrictedLevelsApplied = _.intersection(appliedlevels, ['PLC', 'UNIT']);
                const channelIds = [16];
                if (restrictedLevelsApplied?.length < 1) {
                  channelIds.push(19, 20);
                }

                channels = channels.filter(c => channelIds.includes(c.id));
              }

              vm.display.channels.items = channels;
              vm.display.mediaTypes.items = campaignService.getMediaTypes();

              var adgroupsList = [];
              var campaignsList = [];

              let advertisers = null;
              let advs = customReportService.get_advertisers();
              if(campaign_list.show_all){
                advertisers = advs;
              }else{
                advertisers = advs.filter(advertiser => {
                  return campaign_list.advertiser_ids.includes(advertiser.id);
                });
              }
              vm.display.advertisers.items = $filter('orderBy')(advertisers, 'name');

              let publishers = null;
              let pubs = customReportService.get_publishers();
              if (vm.reportConfig.report.filter.marketers?.ids.length > 0) {
                publishers = pubs.filter(pub => (vm.reportConfig.report.filter.marketers?.ids || []).indexOf(pub.marketerId) >= 0);

              } else {
                publishers = pubs
              }
              vm.display.publishers.items = $filter('orderBy')(publishers, 'name');

              vm.display.campaigns.items = campaign_list.items;
              campaignsList = _.clone(campaign_list.items);
              let ids = vm.reportConfig.report.filter.campaigns.ids || [];

              ids.forEach(function (id) {
                  var camp = vm.display.campaigns.items.find(function (x) { return x.campaignId === id; });
                  if (camp)
                      camp.checked = true;
              });

              var ags = [];

              campaign_list.items.forEach(function (c) {
                  _.each(c.adGroups,
                      function (a) {
                          ags.push(a);
                      });
              });

              adgroupsList = ags;
              vm.display.adGroups.items = _.clone(adgroupsList);

              let marketers = customReportService.get_marketers()

              if(!campaign_list.show_all){
                marketers = marketers.filter(marketer => {
                  return campaign_list.marketer_ids.includes(marketer.id);
                });
              }

              if(vm.display.marketers){
                vm.display.marketers.items = $filter('orderBy')(marketers, 'name');
              }


              vm.setListState = function (mode) {
                  vm.reportConfig.report.filter[mode]?.ids.forEach(function (id) {
                      var found = vm.display[mode].items.find(function (it) {
                          return it[vm.display[mode].idColumn] == id;
                      });
                      if (found)
                          found.checked = true;
                  });
              };


              vm.setMode = function ($event, mode) {
                  if($event.stopPropagation)
                    $event.stopPropagation();

                      vm.display.advertisers.hidden = true;
                      vm.display.campaigns.hidden = true;
                      vm.display.adGroups.hidden = true;
                      vm.display.channels.hidden = true;
                      vm.display.mediaTypes.hidden = true;
                      if(vm.display.marketers)
                        vm.display.marketers.hidden = true;
                      vm.display.retailChannels.hidden = true;
                      vm.display.stores.hidden = true;
                      $timeout(function(){
                        vm.display[mode].copy = _.clone(vm.reportConfig.report.filter[mode]?.ids ?? []);
                        vm.setListState(mode);
                        vm.display[mode].hidden = false;
                      })
              };

              return vm;
          }],
          controllerAs: 'vm'

      };
  });



})();
