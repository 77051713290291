(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:fee
 * @fee directive
 * # <fee  />
 *
 */

    angular.module('app')
        .directive('fee',  ['$compile', '$filter', '$timeout', function ($compile, $filter, $timeout) {
            return {
                template: require('@views/app/fee.html').default,
                restrict: 'E',
                scope: { model: '@ngModel', percent:'=', isRequired:'=', readonly: '@readonly' },
                controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                    var vm = this;
                    $scope.pattern = $scope.percent ?  /^\d{0,3}$/ :  /^\d+(\.\d{0,2})?$/;
                    $scope.min = 0;
                    $scope.max = $scope.percent ? 100 : null;
                    $scope.step = $scope.percent ? 1 : 0.01;
                    vm.model = $scope.model;
                    vm.percent = $scope.percent;
                    vm.required = $scope.isRequired === 'true';
                    $scope.value = $scope.$parent.$eval($scope.model);
                    $scope.readonly = $scope.readonly || "vm.viewMode==='readonly' || vm.contractReadOnly";
                    return vm;
                }],
                replace: true,
                require: 'ngModel',
                link: function($scope, elem, attr) {
                    var moneyRegexp =  /^\d{1,3}(?:,?\d{3})*(\.\d{0,2})?$/;
                    var decimalRegExp = /^\d+(\.\d{0,2})?$/;

                    var advertiserTier1 = [
                        'vm.account.marketerContract.advertiserInteractionTier',
                        'vm.account.marketerContract.advertiserInteractionBannerTier',
                        'vm.account.marketerContract.advertiserInteractionVideoTier',
                        'vm.account.marketerContract.advertiserInteractionFacebookTier'
                    ];


                    var platformTier2 = [
                        'vm.account.marketerContract.platformTier2',
                        'vm.account.marketerContract.platformBannerTier2',
                        'vm.account.marketerContract.platformVideoTier2',
                        'vm.account.marketerContract.platformFacebookTier2'
                    ];

                    var advertiserTier2 = [
                        'vm.account.marketerContract.advertiserInteractionTier2',
                        'vm.account.marketerContract.advertiserInteractionBannerTier2',
                        'vm.account.marketerContract.advertiserInteractionVideoTier2',
                        'vm.account.marketerContract.advertiserInteractionFacebookTier2'
                    ];

                    var platformTier3 = [
                        'vm.account.marketerContract.platformTier3',
                        'vm.account.marketerContract.platformBannerTier3',
                        'vm.account.marketerContract.platformVideoTier3',
                        'vm.account.marketerContract.platformFacebookTier3'
                    ];

                    var advertiserTier3 = [
                        'vm.account.marketerContract.advertiserInteractionTier3',
                        'vm.account.marketerContract.advertiserInteractionBannerTier3',
                        'vm.account.marketerContract.advertiserInteractionVideoTier3',
                        'vm.account.marketerContract.advertiserInteractionFacebookTier3'
                    ];


                    var getExceptCurrent = function (array, current) {
                        return array
                                .filter(function(name){
                                    return name != current
                                })
                                .map(function(name){
                                    return name + ".toString().length > 0";
                                });
                    };

                    var platformRequired = function(tier, model) {
                        if(tier == 1) {
                            return [true];
                        } else {
                            var result = advertiserTier3
                                            .concat(platformTier3);
                            if (tier == 2) {
                                result = result
                                            .concat(advertiserTier2)
                                            .concat(platformTier2);
                            }
                            return getExceptCurrent(result, model);
                        }
                    }
                    var advertiserRequired = function(tier) {
                        var result = advertiserTier3;
                        if (tier == 1) {
                            result = result
                                .concat(advertiserTier2)
                                .concat(advertiserTier1)
                        } else if (tier == 2) {
                            result = result
                                .concat(advertiserTier2);
                        }
                        return result;
                    }

                    var required = function (tier, model) {
                        var advertiserInteractionReg = /advertiserInteraction/;
                        var isAdvertiserInteraction = advertiserInteractionReg.test(model);
                        var resultArray = null;
                        if (isAdvertiserInteraction) {
                            resultArray = advertiserRequired(tier, model);
                        } else {
                            resultArray = platformRequired(tier, model);
                        }
                        var result = resultArray[0];
                        for(var index = 1; index < resultArray.length; index++){
                            result = result +" || " + resultArray[index];
                        }
                        return result;
                    }

                    var model = attr.ngModel;
                    var tier =  parseInt(model[model.length - 1]);
                    if (isNaN(tier)) {
                        tier = 1;
                    }

                    var input = $('input', elem)
                        .attr('ng-model', attr.ngModel)
                        .attr('ng-readonly', $scope.readonly)
                        .attr('ng-required', required(tier, model))
                        .attr('ng-min', $scope.min)
                        .attr('ng-max', $scope.max)
                        .attr('step', $scope.step)
                        .attr('ng-pattern', $scope.percent ? decimalRegExp : moneyRegexp);
                    function updateValue(isCurrency, value){
                        var model = $scope.model;
                        var prop = model.slice(model.lastIndexOf('.') + 1);
                        var newValue = value;
                        if(moneyRegexp.test(value) || decimalRegExp.test(value)){
                            var tmp = value.toString().replace(/,/g, "");
                            if(isCurrency) {
                                newValue =  ($filter('currency')(tmp)||'').replace("$", '');
                                newValue = newValue.substring(0);
                            } else {
                                newValue = (tmp || '');
                            }
                        }

                        $timeout(function () {
                            $scope.$root.$broadcast('contractChanged', { name: prop, value:  newValue });
                        }, 150);

                    }

                    function makeBorder(input) {
                        if($(input).hasClass('ng-invalid')) {
                            $(input).parent().css('border','solid 1px red');
                        } else {
                            $(input).parent().css('border','solid 1px #E6EBED');
                        }
                    }

                    input.bind('focus', function(ev){
                        makeBorder(ev.currentTarget);
                    });
                    input.bind('blur', function(ev){
                        makeBorder(ev.currentTarget);
                    });

                    if(!$scope.percent) {
                        updateValue(true, $scope.$parent.$eval(model), input);
                        input.bind('focus', function(ev){
                            var input = $(ev.currentTarget);
                            var value  = input.val();
                            updateValue(false, value, input);
                        });
                        input.bind('blur', function(ev){
                            var input = $(ev.currentTarget);
                            var value  = input.val();
                            updateValue(true, value);
                        });
                    }

                    var val = $scope.$parent.$eval(attr.ngModel);

                    input.val(val);
                    $compile(input)($scope.$parent);

                },
                controllerAs: 'vm'
            }
        }]);
})();
