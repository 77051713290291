export default async function(){
    if(!window.am4core){
        window.am4core = await import("@amcharts/amcharts4/core");
        window.am4charts = await import("@amcharts/amcharts4/charts");
        const am4themesAnimated = await import("@amcharts/amcharts4/themes/animated")
        am4core.addLicense("CH211871723");
        am4core.useTheme(am4themesAnimated.default);
        am4core.options.autoSetClassName = true;
    }
}
