(function () {
    'use strict';
    angular.module('app')
        .directive('creativeChooseExisting', ['$location', function () {
            return {
                template: require('@views/creative/editors/creative-choose-existing.html').default,
                restrict: 'E',
                controllerAs: 'vm',
                scope: { adGroup: '=', creatives: '=', totalCount: '='},
                controller: ['$scope', '$rootScope', '$uibModal', 'campaignService', 'creativeService', 'hub', '$location', '$timeout', function ($scope, $rootScope, $modal, campaignService, creativeService, hub, $location, $timeout) {
                    var vm = this;
                    var log = hub.getLog('Creatives');
                    var vm = this;
                    vm.log = log;

                    vm.adGroup = $scope.adGroup;
                    vm.campaign = campaignService.getSelectedCampaign();

                    vm.sizes = campaignService.getSizes().filter(function (s) { return s.id; });
                    vm.sizes.splice(0, 0, { id: 0, name: 'All Sizes' });
                    vm.selectedSize = vm.sizes[0];

                    vm.creativeTypes = campaignService.getCreativeTypes().filter(function (ct) { return ct.id == 1 || vm.adGroup.channelId == 3 });
                    vm.creativeTypes.splice(0, 0, { id: 0, name: 'All Types' });
                    vm.selectedCreativeType = vm.creativeTypes[0];

                    vm.videoOptions = {};
                    vm.getCreativeStatusById = creativeService.getCreativeStatusById;
                    vm.getCreativeStatusIconById = creativeService.getCreativeStatusIconById;

                    vm.selectedCreativesCount = function () {
                        return _.filter($scope.creatives, function (c) { return c.checked; }).length;
                    }

                    vm.creativesToSelect = function () {
                        return $scope.creatives.filter(function (creative) {
                            return !creative.checked && ((vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25 ? 1 : vm.adGroup.channelId) == creative.channelId) && (creative.creativeTypeId == vm.selectedCreativeType.id || vm.selectedCreativeType.id == 0 || vm.selectedCreativeType.id == 1);
                        }).length > 0;
                    }

                    vm.creativeChecked = function (creative) {
                        if (!creative.id > 0)
                            return;

                        if (vm.adGroup.channelId == 6 && (vm.adGroup.creatives.length > 0 || vm.adGroup.adGroupId)) {
                            creative.checked = false;
                            return;
                        }

                        if (!vm.adGroup.creatives)
                            vm.adGroup.creatives = [];

                        if (!creative.checked) {
                            vm.adGroup.creatives.splice($.inArray(creative, vm.adGroup.creatives), 1);
                            creative.checked = false;
                        }
                        else {
                            vm.adGroup.creatives.push(creative);
                            creative.checked = true;
                            $rootScope.$broadcast('creativeAdded', creative);
                        }

                        $rootScope.$broadcast('recalculateCreatives', null);
                    }

                    vm.setKeyword = function () {
                        vm.pageNumber = 1;
                        $rootScope.$broadcast('creativesKeywordChanged', vm.keyword);
                    };

                    vm.setCreatives = function () {
                        $rootScope.$broadcast('creativesPageNumberChanged', vm.pageNumber);
                    };

                    $scope.$on('creativesRefreshed', function (event, data) {
                        vm.hideSearch = true;
                        $timeout(function () {
                            vm.hideSearch = false;
                        });
                    });

                    vm.selectedSizeChanged = function () {
                        vm.pageNumber = 1;
                        $rootScope.$broadcast('creativesSizeChanged', vm.selectedSize);
                    };

                    vm.editCreative = function (creative) {
                        var modalInstance;
                        modalInstance = $modal.open({
                            templateUrl: 'views/creative/editors/creative.html',
                            animation: false,
                            controller: 'editCreativeController',
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    return { creative: creative };
                                }
                            }
                        });
                        modalInstance.result.then((function (data) {
                            hub.webapicontext.saveCreative(data.creative).then(function () {
                                vm.log(data.creative.name + " Saved");
                                for (var i = 0; i < vm.adGroup.creatives.length; i++) {
                                    if (vm.adGroup.creatives[i].id == data.creative.id)
                                        vm.adGroup.creatives[i] = data.creative;
                                }
                                $rootScope.$broadcast('creativesUpdated', vm.adGroup.creatives);
                            });

                        }), (function () {
                        }));
                    }

                    vm.removeCreative = function (creative) {
                        hub.webapicontext.removeCreative(creative.id).then(function () {
                            vm.log(creative.name + " Removed");
                            $rootScope.$broadcast('creativesUpdated', null);
                        });
                    }

                    vm.zoomCreative = function (creative) {
                        var modalInstance;
                        modalInstance = $modal.open({
                            templateUrl: 'views/creative/editors/creative-zoom.html',
                            animation: false,
                            controller: 'zoomCreativeController as vm',
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    return { creatives: $scope.creatives, creative: creative, creativeChecked: vm.creativeChecked };
                                }
                            }
                        });
                    }


                    vm.hoverCreative = null;
                    vm.hover = function (creative, $event, show) {
                        if (show) {
                            var isCampaign = $location.$$url.indexOf('campaign-wizard') > -1;
                            if (isCampaign) {
                                var offsetTop = -creative.height + $event.screenY - 150;
                                var offsetLeft = $event.pageX - 130;
                                creative.position = 'top: ' + offsetTop + 'px; left:' + offsetLeft + 'px;';
                            }
                            else {
                                var offsetTop = -creative.height - 35 - $event.toElement.offsetParent.offsetParent.scrollTop;
                                var offsetLeft = -85;
                                creative.position = 'margin-top: ' + offsetTop + 'px; margin-left:' + offsetLeft + 'px;';
                            }

                            creative.show = true;
                        } else {
                            creative.show = false;
                        }
                    }

                    return vm;
                }]
            }
        }]);
})();