(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:dateRange
 * @dateRange directive
 * # <date-range  />
 * shows data range dropdown list (sample)
 */

    angular.module('app').directive('customValidation', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, controller)  {
                controller.$validators.customValidator = function(value) {
                    if (value == '' || value==null) {
                        return true;
                    }
                    var valid = true;
                    var format = attrs['format'];
                    var validationType = attrs['validation-type'] || 'number';


                    switch (validationType) {

                        case 'number':
                            if (isNaN(value) || (value < 0 && validationType == 'number')) {

                                valid = false;
                            }

                            if (format == '%0+' || format == '%') {
                                var maxNumber = format == '%0+' ? 999 : 100;

                                if ((format == '%0+' && value <= 0) || (format == '%' && value < 0) || value > maxNumber || (value % 1) != 0) {

                                    valid = false;
                                }

                            }
                            var moneyRangeRegExp = /^\$(\d+)\-(\d+)$/;
                            if(format && format.test(moneyRangeRegExp)) {
                                var matches = format.match(moneyRangeRegExp);
                                var min = parseFloat(matches[1]);
                                var max = parseFloat(matches[2]);
                                if ((value < min) || (value > max)) {
                                    valid = false;
                                }
                            }

                    }
                   
                    return valid;
                };
            }
        };
    });

    angular.module('app')
      .directive('validationState', function () {
          return {
              template: require('@views/app/validation-state.html').default,
              restrict: 'E',
              scope: {model: '=', messages:'=', $class:'@', controlId: '@', controlValidation:'@', validationFormat:'@', validationDisabled: '@'},
              controller: ['$scope', function ($scope) {
                  var vm = this;
                  vm.format = $scope.validationFormat;
                  vm.model = $scope.model;
                  vm.isValid =$scope.model ? $scope.model.$valid : true;
                  vm.class = $scope.class;
                  vm.controlValidation = $scope.controlValidation;
                  vm.validationDisabled = $scope.validationDisabled;
                  var controlToValidate= null;
                  if($scope.controlId)
                  {
                      controlToValidate = document.getElementById($scope.controlId);
                  }
                  vm.messages = $scope.messages || {};

                  vm.getMessage = function()
                  {
                      if (!vm.model) {
                          return vm.customValidationMessage;
                      }
                      else {
                          if (vm.model.$valid)
                          {
                              rangeValidationMessage = '';
                              return '';
                      }
                          if (vm.model.$error.required)
                              return 'Required field';
                          if (vm.model.$error.email)
                              return 'Invalid Email Format';
                          if (vm.model.$error.pattern)
                              return vm.messages.pattern || 'Invalid Format';
                          if (vm.model.$error.minlength)
                              return 'Minimum length validation failed';
                          if (vm.model.$error.maxlength)
                              return 'Maximum length validation failed';
                          if (vm.model.$error.max || vm.model.$error.min) {
                              if (!rangeValidationMessage) {
                                  var range = getRange(vm.model.$name);
                                  rangeValidationMessage =   'Out of range ' + ((range.min=='*' && range.max=='*') ? '' : ' [' + range.min + '-' + range.max + ']');
                              }
                              return rangeValidationMessage;
                      }
                          return 'Validation error';
                      }

                  }
                  vm.customValidationMessage = '';
                  var rangeValidationMessage = '';
                  function getRange(name)
                  {
                      var elem = $("#" + name);
                      if (!elem)
                          return { max: '*', min: '*' };
                      else
                          return {
                              max: (elem.attr('max') || '*'), min: (elem.attr('min') || '*')
                          };
                  }
                  var oldValue = null;
                  var valid = true;

                  vm.isValid = function()
                  {
                      if (vm.model)
                          return vm.model.$valid;
                      else
                      {
                          var value = controlToValidate && controlToValidate.value;

                          if (controlToValidate)
                          {
                              oldValue = value;
                              
                              if (!value) {
                                  if (controlToValidate.attributes['required']) {
                                      vm.customValidationMessage = 'Required field';
                                      
                                      valid = false;
                                  }
                                  else {
                                      
                                      valid = true;
                                  }
                              }
                              else {
                                  valid = true;
                                  switch (vm.controlValidation) {
                                     
                                      case 'number':
                                          if (isNaN(value) || (value < 0 && vm.controlValidation == 'number')) {
                                              controlToValidate.className = controlToValidate.className.replace("ng-valid ", "ng-invalid ");
                                              controlToValidate.className = controlToValidate.className;
                                              if (parseFloat(value) < 0)
                                                  vm.customValidationMessage = 'Value should be positive';
                                              else
                                                  vm.customValidationMessage = 'Invalid value format';

                                              valid = false;
                                          }
                                          if (vm.format == '%0+' || vm.format == '%')
                                          {
                                              var maxNumber = vm.format == '%0+' ? 999 : 100;

                                              if ((vm.format == '%0+' && value <= 0) || (vm.format == '%' && value < 0) || value > maxNumber || (value % 1) !=0)
                                              {
                                                  controlToValidate.className = controlToValidate.className.replace("ng-valid ", "ng-invalid ");
                                                  controlToValidate.className = controlToValidate.className;
                                                  if (vm.format == '%0+')
                                                      vm.customValidationMessage = 'Percent value should be a whole number, greather than zero and less than ' + maxNumber;
                                                  else
                                                      vm.customValidationMessage = 'Percent value should be whole, positive number less than ' + maxNumber;
                                                  valid = false;
                                              }

                                          }
                                        
                                  }
                              }
                              if (valid)
                                    controlToValidate.className = controlToValidate.className.replace("ng-invalid ", "ng-valid ");
                              controlToValidate.dataset.isValid = valid;
                          }
                          return valid;
                      }
                  }
                  
                
                  return vm;
              }]
              ,

              controllerAs: 'vm'
          }
      });



})();