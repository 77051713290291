(function () {
    'use strict';
    angular.module('app').controller('campaignEditorModalInstanceCtrl', ['$scope', '$rootScope', '$uibModalInstance', 'campaign', 'hub', 'viewMode', 'campaignService', '$timeout', 'authService', '$location', function ($scope, $rootScope, $modalInstance, campaign, hub, viewMode, campaignService, $timeout, authService, $location) {
        var vm = this;
        vm.campaignSettingsForm = {};
        var rootVM = $rootScope.rootVM;
        vm.rootVM = $rootScope.rootVM
        campaignService.setSelectedCampaign(campaign, null, true);
        vm.campaign = angular.copy(campaign);
        vm.campaignTypes = [];
        vm.log = hub.getLog('Campaign Manage');
        vm.isAdmin = authService.userAccountType() === "Admin";
        vm.isAdvertiser = authService.userAccountType() === "Advertiser";
        vm.isMarketer = authService.userAccountType() === "Marketer";
        vm.isManufacturer = authService.userAccountType() === "Manufacturer";
        vm.isMarketerHoldingCompany = authService.userAccountType() === "MarketerHoldingCompany";
        vm.isAdOps =
            authService.authentication.userRoles[0] === "Advertiser Ad Ops"
            || authService.authentication.userRoles[0] === "Marketer Ad Ops";
        vm.hasFeature = authService.hasFeature;
        vm.isProactDisabled = true; // vm.campaign.adGroups.find(ag => ag.channelId === 9);
        vm.isObserverRole = authService.isObserverRole();

        vm.viewMode = viewMode;

        vm.campaign.isLaunch = vm.campaign.campaignStatusId === 1;
        vm.campaign.initialCampaignStatus = vm.campaign.campaignStatusId;
        vm.changeCampaignStatus = function () {
            if (vm.campaign.initialCampaignStatus === 1) {
                vm.campaign.initialCampaignStatus = -1;
                vm.campaign.isLaunch = false;
            }
            else
                vm.campaign.isLaunch = !vm.campaign.isLaunch;
        };

        angular.forEach(rootVM.statusTypes, function (st) {
            if (vm.campaign.campaignStatusId == st.id)
                vm.campaign.campaignStatus = st.name;
        });

        vm.marketers = campaignService.marketers;
        angular.forEach(vm.marketers, function (r) {
            if (vm.campaign.marketerId == r.id)
                vm.campaign.marketer = r.name;
        });

        vm.advertisers = campaignService.advertisers;
        angular.forEach(vm.advertisers, function (r) {
            if (vm.campaign.advertiserId == r.id)
                vm.campaign.advertiser = r.name;
        });

        hub.datacontext.getLocalJson('lists').then(function (l) {
            angular.forEach(l.campaignObjectives.values, function (ct) {
                if (ct.id == vm.campaign.campaignObjectives)
                    vm.campaign.campaignObjectiveName = ct.name;
            });
            angular.forEach(l.marketerProgram.values, function (ct) {
                if (ct.id == vm.campaign.programId)
                    vm.campaign.programName = ct.name;
            });
            if (!vm.campaign.campaignObjectiveName) {
                vm.campaign.campaignObjectiveName = 'Not Selected';
            }
        });

        if (vm.campaign && vm.campaign !== 'All Campaigns') {
            vm.campaign.duplicateName = false;
            vm.campaignNameChanged = function () {
                vm.campaign.duplicateName = false;
                var validationData = {};
                validationData.entityName = vm.campaign.name;
                validationData.entityType = "campaign";
                validationData.campaignId = vm.campaign.campaignId;
                validationData.advertiserId = vm.campaign.advertiserId;
                hub.webapicontext.validateName(validationData).then(function (data) {
                    if (data.message !== "Valid Name")
                        vm.campaign.duplicateName = true;
                });
            }
        }

        vm.conceptGroupKeywordChanged = function () {
            const payload = {
                pager: {
                    pageSize: 10,
                    page: 1
                },
                filter: {
                    name: { like: vm.conceptGroupKeyword ? vm.conceptGroupKeyword : '' },
                    domain: null
                },
            };

            hub.webapicontext.getConceptGroups(payload).then(function (data) {
                vm.conceptGroups = data.list;
                vm.conceptGroups.forEach(function (cg) {
                    if (vm.campaign.conceptGroups.indexOf(cg) > -1)
                        cg.selected = true;
                });

                vm.conceptGroups = vm.conceptGroups.filter(function (cg) {
                    return !vm.campaign.conceptGroups.find(function (c) { return cg.id == c.id; });
                });

                if (vm.conceptGroups && vm.conceptGroups.length > 0)
                    vm.showConceptGroupsList = true;
            });
        }

        vm.addConceptGroup = function (cg) {
            if (cg.selected || vm.campaign.conceptGroups.length > 0)
                return;
            vm.campaign.conceptGroups.push(cg);
            vm.conceptGroupKeyword = '';
            vm.conceptGroupKeywordChanged();
        }

        vm.removeConceptGroup = function ($event, cg) {
            var checked = $event.target.checked;
            var cgIndex = vm.campaign.conceptGroups.indexOf(cg);

            if (!checked)
                vm.campaign.conceptGroups.splice(cgIndex, 1);

            vm.conceptGroupKeywordChanged();
        }

        vm.changeCampaignIsProact = function () {
            if (!vm.isProactDisabled)
                vm.campaign.isProact = !vm.campaign.isProact;
        };

        $scope.$watch('vm.campaign.grossMetrics', function (newVal, oldVal) {
            if (newVal && !vm.campaign.conceptGroups)
                vm.campaign.conceptGroups = [];
        });

        $scope.canSubmit = function () {
            return vm.campaignSettingsForm.$valid && !vm.campaign.duplicateName;
        };

        $scope.ok = function () {
            var eventStartTime = new Date();
            if (vm.viewMode == 'readonly')
                vm.campaign.viewMode = true;
            if ($modalInstance) {
                hub.webapicontext.saveCampaign(vm.campaign).then(function () {
                    $modalInstance.close(vm.campaign);
                    vm.log(vm.campaign.name + " Updated");
                    campaignService.setSelectedCampaign(vm.campaign);
                    var data = { id: vm.campaign.id, name: vm.campaign.name };
                    authService.trackEvent({ action: 'SaveCampaign', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - eventStartTime.getTime()) / 1000) / 60).toFixed(2) });
                    setTimeout(() => {
                        $rootScope.$broadcast('reloadCampaigns', null);
                    }, 1000);
                });
            }
        };

        $scope.pauseRestartCampaign = function () {
            var eventStartTime = new Date();
            if (vm.campaign.campaignStatusId == 10) {
                hub.webapicontext.restartCampaign(vm.campaign.campaignId).then(function () {
                    vm.campaign.campaignStatusId = 1;
                    $modalInstance.close(vm.campaign);
                    vm.log(vm.campaign.name + " Restarted");
                    campaignService.setSelectedCampaign(vm.campaign)
                    campaignService.getCampaignsStatistic(true).then(function () {
                        var data = { id: entity.id, name: entity.name };
                        authService.trackEvent({ action: 'PauseCampaign', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                        $rootScope.$broadcast('campaignsLoaded', null);
                    });
                });
            } else {
                hub.webapicontext.pauseCampaign(vm.campaign.campaignId).then(function () {
                    vm.campaign.campaignStatusId = 10;
                    $modalInstance.close(vm.campaign);
                    vm.log(vm.campaign.name + " Paused");
                    campaignService.setSelectedCampaign(vm.campaign)
                    campaignService.getCampaignsStatistic(true).then(function () {
                        var data = { id: entity.id, name: entity.name };
                        authService.trackEvent({ action: 'PauseCampaign', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                        $rootScope.$broadcast('campaignsLoaded', null);
                    });
                });
            }
        };


        $scope.cancel = function (cancelClicked) {
            vm.cancelClicked = cancelClicked;
            if ($modalInstance)
                $modalInstance.dismiss('cancel');
            $location.path('activation/campaign');
        };

        $scope.$on('modal.closing', function (event, reason) {
            if (!vm.showCaution && (reason == 'cancel') && vm.campaignSettingsForm.$dirty && vm.cancelClicked) {
                $timeout(function () {
                    vm.showCaution = true;
                });
                event.preventDefault();
            } else
                $location.path('activation/campaign');
        });

        $scope.$on('modal.force.close', function (event) {
            $modalInstance.dismiss('cancel');
            $location.path('activation/campaign');
        });
    }]);

    angular.module('app')
      .directive('campaignSettings', [function () {
          return {
              template: require('@views/campaign/editors/settings.html').default,
              restrict: 'E',
              controllerAs: 'vm'
          }
      }]);

})();
