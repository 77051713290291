(function () {
    'use strict';
    /**
     * @ngdoc function
     * @name payMeApp.controller:usersController
     * @User management controller
     * # usersController
     * Controller of the app
     */


    angular.module('app')
        .controller('rolesController', ['$scope', '$timeout', '$uibModal', '$filter', 'hub', 'authService', '$routeParams', function ($scope, $timeout, $modal, $filter, hub, authService, $routeParams) {

            var vm = this;
            hub.page.setTitle('Roles Management');
            vm.config = hub.config;
            vm.hasAccess = authService.hasAccess;

            vm.resources = authService.getResources();
            vm.actions = authService.getActions();

            vm.getResourceName = function (resourceId) {
                var resource = vm.resources.find(function (r) { return r.id == resourceId; });
                if (resource)
                    return resource.name;
                else
                    return '';
            }

            vm.getActionName = function (actionId) {
                var action = vm.actions.find(function (r) { return r.id == actionId; });
                if (action)
                    return action.name;
                else
                    return '';
            }


            vm.getData = function () {
                hub.webapicontext.manageRoles().then(function (d) {
                    vm.roles = d;
                    vm.roles.forEach(function (role) {
                        var resources = [];
                        var actions = [];
                        var resourceIds = [];
                        role.actions.forEach(function (a) {
                            actions[a.resourceId] = actions[a.resourceId] ? actions[a.resourceId] + ', ' + vm.getActionName(a.id) : vm.getActionName(a.id);
                            if (!resources[a.resourceId]) {
                                resourceIds.push(a.resourceId);
                                resources[a.resourceId] = vm.getResourceName(a.resourceId);
                            }
                        });
                        role.resourcesActions = [];
                        resourceIds.forEach(function (id) {
                          if (resources[id]) {
                            role.resourcesActions.push({ resource: resources[id], actions: actions[id] });
                          }
                        });
                    });
                });
            }
            vm.getData();
            return vm;
        }]);
})();
