(function () {
    'use strict';
    angular.module('app')
      .controller('userManagerController', ['$scope', '$uibModalInstance', '$filter', 'config', 'hub', 'user', 'account', 'accounts', 'authService', '$timeout', '$location', 'Enum', function ($scope, $modalInstance, $filter, config, hub, user, account, accounts, authService, $timeout, $location, Enum) {

          var vm = this;
          vm.isAdmin = authService.userAccountType() === 'Admin';
          vm.roleNames = {
              superAdmin: false,
              admin: false,
              marketer: false,
              manufacturer: false
          };
          vm.activeTab = 0;
          vm.user = angular.copy(user);
          vm.user.avatarUrl = hub.settings.imagesUrl + 'avatars/' + vm.user.avatar;
          vm.account = account;
          vm.accounts = $filter('orderBy')(accounts, 'name');
          vm.disableError = disableError;
          vm.verifyNewPassword = verifyNewPassword;
          vm.hasAccess = authService.hasAccess;


          vm.filteredAccounts = vm.accounts;

          var types = angular.copy(Enum.AccountTypes);
          vm.userAccountTypes = angular.copy(types);
          if (vm.isAdmin)
              vm.userAccountTypes.push({ id: 6, name: 'Lasso Internal' });

          vm.accountSelected = function (item) {
              return vm.account ? item.id == vm.account.id : false;
          };

          vm.filterAccounts = function () {
              vm.filteredAccounts = vm.accounts.filter(function (a) { return vm.selectedAccountType == 6 && !a.accountType || !vm.selectedAccountType || a.accountType == vm.selectedAccountType; });
              if (!vm.filteredAccounts.find(function (a) { return vm.user.account == a; }) && !vm.user.id) {
                  vm.user.account = null;
                  vm.userRoles = [];
              }
          };

          if (!vm.isAdmin)
              $timeout(function () {
                  vm.filterAccounts();
              });

          vm.accountChanged = function () {
              vm.user.account = _.find(vm.accounts, function (a) { return a.id == vm.userAccountId; });
              if (!vm.accountNotSelected())
                  vm.getRoles();
          };

          vm.accountNotSelected = function () {
              return typeof vm.userAccountId == 'undefined';
          };

          //add Lasso account
          if (!hub.accountService.get()) {
              if (vm.accounts && authService.userAccountType() === 'Admin')
                   vm.accounts.unshift({ id: 0, name: "No Account (Lasso)" });
              if (vm.user.id === 0)
                  vm.user.account = vm.accounts[0];
          }
          else
          {
              //display user's account only
              vm.user.account = hub.accountService.get();
              vm.accounts = [vm.user.account];


          }

          if(vm.account)
          {
            vm.selectedAccountType = vm.account.id == 0 ? 5 : _.find(vm.accounts, function(a) { return a.id == vm.account.id; }).accountType;
            vm.userAccountId = vm.user.account.id;
            vm.filteredAccounts = vm.accounts.filter(function (a) { return vm.selectedAccountType == 6 && !a.accountType || !vm.selectedAccountType || a.accountType == vm.selectedAccountType; });
          }
          else
          {
            vm.user.account = null;
            vm.selectedAccountType = '';
            vm.userRoles = [];
          }

          vm.userRoles = [];
          vm.rolesLoading = false;
          vm.marketers = [];

          vm.getRoles = function()
          {

              vm.user.selectedMarketerId = null;

              //get selected account
              var acc = null;
              vm.marketers = [];

              if (vm.user.account) {
                  vm.accounts.forEach(function (item,idx) {
                      if (item.id === vm.user.account.id)
                          acc = item.accountType;

                      if (vm.user.account.marketers && vm.user.account.marketers.indexOf(item.id) >= 0)
                          vm.marketers.push({id: item.id, name: item.name});

                  });

                 //get assigned marketers via account options (if available)
                  if (vm.user.account.marketersOptions && vm.user.account.marketersOptions.length > 0)
                  {
                      vm.marketers = [];
                      vm.user.account.marketersOptions.forEach(function (item, idx) {
                          vm.marketers.push({ id: item.id, name: item.name });
                      });

                  }


              }

              hub.webapicontext.getRoles(acc).then(function (d) {

                  vm.rolesLoading = true;
                  vm.roles = d;
                  vm.userRoles = [];

                  if (!acc && authService.hasAccess('accounts', 'ManageAdvertisers') && !authService.hasAccess('accounts', 'ManageMarketers') )
                  {
                      vm.roles = _.filter(vm.roles, { 'name': 'Account Manager' });
                  }

                  $.each(vm.roles, function (idx, item) {

                      vm.rolesLoading = false;

                      if (vm.user.roles && vm.user.roles.length > 0 && vm.user.roles.indexOf(item.name) >= 0) {
                            vm.userRoles.push(item.name);
                      }

                  });
              });
          }


          vm.getRoles();


          if (typeof account !== 'undefined') {
              vm.user.account = account;
          }

          vm.isInProgress = false;
          vm.user.password = '';
          $scope.config = config;

          function disableError () {
            $('#newPassword').removeClass('has-error has-danger');
            $('#changePasswordDiv').css('color',  '');
          }

          function verifyNewPassword () {
            disableError();
            if(vm.user.password)
                vm.match = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%\^&\*\(\)_\+`\-=\{\}\|\[\]\\:";'<>,\.\?\/])[A-Za-z\d~!@#$%\^&\*\(\)_\+`\-=\{\}\|\[\]\\:";'<>,\.\?\/]{8,}$/.test(vm.user.password);
            else
                vm.match = true;
          }


          $scope.save = function () {
                var startTime = new Date();

                if (this.form_user.$invalid) {
                    $('#userForm').validator('validate');
                    vm.activeTab = 0;
                    return;
                }

                if(vm.match == false){
                    $('#newPassword').addClass('has-error has-danger');
                    $('#changePasswordDiv').css('color',  '#a94442');
                    return;
                }

                disableError();
                vm.user.userName = vm.user.email;
                vm.user.roles = vm.userRoles;
                var userId = vm.user.id;

                vm.user.account.marketersOptions = null;
                vm.user.account.advertiserMarketers = null;
                vm.isInProgress = true;
                hub.webapicontext.saveUser(vm.user)
                    .then(function(d) {
                        vm.isInProgress = false;
                        hub.getLog('Account Management')(vm.user.userName + ' saved');
                        vm.user = d;

                        var data = { id: userId, name: vm.user.userName };
                        authService.trackEvent({ action: 'SaveUser', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });

                        if (!userId) {
                            $scope.$root.$broadcast('user-added', { type: 'user', entity: vm.user });
                            vm.cancel();
                        }
                        $modalInstance.dismiss(vm.user);
                    },
                    function(err) {
                        if (!err.data.logged) {
                            hub.getLog('Account Management', 'error')('Unable to save user');
                        }
                        vm.isInProgress = false;
                    });

          }

          vm.cancel = function (cancelClicked) {
              vm.cancelClicked = cancelClicked;
              $modalInstance.dismiss('cancel');
          };

          vm.uploadImage = function (file) {
              return hub.webapicontext.uploadFile('User/ImageUpload?userID=' + vm.user.id, file).then(function (data) {
                  vm.user.avatarUrl = hub.settings.imagesUrl + data.data.filename + "?uploaded=" + new Date().getTime();
                  $scope.$root.$broadcast('image-uploaded', {type: 'user', entity: vm.user });
                  vm.isNew = false;
              });
          }

          vm.resetPassword = function () {
              vm.isInProgress = true;
              hub.webapicontext.saveUser(vm.user).then(function (d) {
                  hub.getLog('Account Management')('Password changed for ' + vm.user.userName);
                  $modalInstance.dismiss('cancel');
              }, function (err) {
                  if (!err.data.logged) {
                      hub.getLog('Account Management', 'error')('Unable to change user password');

                  }
                  vm.isInProgress = false;

              }


              );
          }

          $scope.$on('modal.closing', function (event, reason, closed) {
              if (!vm.showCaution && (reason == 'cancel') && event.currentScope.form_user.$dirty && vm.cancelClicked) {
                  $timeout(function () {
                      vm.showCaution = true;
                  });
                  event.preventDefault();
              }
          });

          $scope.$on('modal.force.close', function (event) {
              $modalInstance.dismiss('cancel');
          });

          return vm;
      }]);

})();
