(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.page-size
 * @pageSize directive
 * # <page-size  />
 */

    angular.module('app')
        .directive('pageSize', function () {
            return {
                template: require('@views/app/page-size.html').default,
                restrict: 'E',
                scope: {                   
                    item:        '@',
                    value:       '=',              
                    changed:     '&',
                },
                controller: ['$scope', 'storagecontext', 'config', function ($scope, storagecontext, config) {
                    var vm = this;                    
                    vm.sizes = [10, 25, 50, 75, 100];
                    vm.item = $scope.item || 'tiles';
                    vm.value = $scope.value;
                    vm.pageSize = $scope.value;

                    $scope.$watch('value', function(value){
                        if(value != vm.pageSize) {
                            const gridSettings = {
                                pageSize: parseInt(value)
                            };
                            vm.pageSize = value;
                            storagecontext.saveProfile('gridSettings', gridSettings).then(function (d) {
                                $scope.changed()(parseInt(value)); 
                            });
                        }
                    });
                    return vm;
                }],
                controllerAs: 'vm'
            }
        });
})();