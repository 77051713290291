import am4Init from "../vendors/am4Init";

(function () {
  'use strict';

  angular.module('app')
    .directive('amchart4', function () {
      return {
        template: '<div id="{{chartId}}" class="{{cssClass}}" style="width: {{width}}px;height: {{height}}px;margin: auto;"></div>',
        restrict: 'E',
        scope: { chartId: '@', width: '@', height: '@', type: '@', data: '=', options: '=', cssClass: '@' },
        replace: true,
        link: function (scope, ele, attrs) {

        },
        controller: ['$scope', '$rootScope', '$parse', 'hub', '$timeout', async function ($scope, $rootScope, $parse, hub, $timeout) {
          var vm = this;
          var chart = null;
          vm.page = hub.page;
          vm.data = [];
          vm.chartOptions = null;

          await am4Init();

          $scope.$watch('data', function (value) {
            vm.data = value;
            if (vm.data) {
              if (!chart)
                vm.buildChart();
              else {
                chart.data = vm.data;
                chart.validateData();
              }
            }
          });
          $scope.$watch('options', function (value) {
            $timeout(function () {
              vm.chartOptions = value;
              if (vm.chartOptions) {
                if (vm.data)
                  vm.chartOptions.dataProvider = vm.data;

                if ($scope.type === 'serial') {
                  $timeout(function () {
                    var container = am4core.create($scope.chartId, am4core.Container);
                    container.width = am4core.percent(100);
                    container.height = am4core.percent(100);
                    container.contentAlign = "center";

                    chart = container.createChild(am4charts.XYChart);
                    chart.data = vm.data;
                    chart.width = am4core.percent(90);
                    chart.height = am4core.percent(100);
                    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                    dateAxis.renderer.minGridDistance = 50;
                    if (vm.chartOptions.legend) {
                      chart.legend = new am4charts.Legend();
                    }

                    vm.chartOptions.graphs.forEach(function (g) {
                      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                      valueAxis.min = 0;
                      var series = chart.series.push(new am4charts.LineSeries());
                      series.dataFields.valueY = g.valueField;
                      series.dataFields.dateX = vm.chartOptions.categoryField;
                      series.yAxis = valueAxis;
                      series.horizontalCenter = "middle";
                      series.verticalCenter = "middle";
                      series.name = g.name;
                      if (g.fillOpacity) {
                        series.fillOpacity = g.fillOpacity;
                      }
                      if (g.colors) {
                        var gradient = new am4core.LinearGradient();
                        for (let c of g.colors) {
                          gradient.addColor(am4core.color(c));
                        }
                        gradient.rotation = 270;
                        series.fill = gradient;
                      }
                      if (g.lineColor) {
                        series.stroke = am4core.color(g.lineColor);
                      }
                      valueAxis.renderer.opposite = g.opposite;
                      series.strokeWidth = 2;
                      if (g.dashed) {
                        series.strokeDasharray = "3,4";
                      } else {
                        series.minBulletDistance = 10;
                        if (g.valueField == 'spend')
                          series.tooltipText = "[bold]{date.formatDate()}:[/] ${" + g.valueField + ".formatNumber('###.##')}";
                        else if (g.valueField == 'ctr')
                          series.tooltipText = "[bold]{date.formatDate()}:[/] {" + g.valueField + ".formatNumber('###.##')}%";
                        else
                          series.tooltipText = "[bold]{date.formatDate()}:[/] {" + g.valueField + "}";
                        series.tooltip.pointerOrientation = "vertical";
                      }

                      chart.cursor = new am4charts.XYCursor();
                      chart.cursor.xAxis = dateAxis;
                    });
                  });
                } else if ($scope.type === 'bar') {
                  $timeout(function () {
                    chart = am4core.create($scope.chartId, am4charts.XYChart);
                    chart.data = vm.data;
                    chart.padding(0, 0, 0, 0);
                    chart.zoomOutButton.disabled = true;

                    //// Create axes
                    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "adGroupId";
                    categoryAxis.renderer.grid.template.opacity = 0;

                    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                    valueAxis.min = 0;
                    valueAxis.max = 100;
                    valueAxis.renderer.grid.template.opacity = 0;
                    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
                    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
                    valueAxis.renderer.ticks.template.length = 10;
                    valueAxis.renderer.line.strokeOpacity = 0;
                    valueAxis.renderer.baseGrid.disabled = true;
                    valueAxis.renderer.minGridDistance = 40;
                    valueAxis.cursorTooltipEnabled = false;

                    categoryAxis.renderer.grid.template.disabled = true;
                    categoryAxis.renderer.labels.template.disabled = true;
                    valueAxis.renderer.grid.template.disabled = true;
                    valueAxis.renderer.baseGrid.disabled = true;
                    valueAxis.renderer.labels.template.disabled = true;

                    // Create series
                    function createSeries(field, color) {
                      var series = chart.series.push(new am4charts.ColumnSeries());
                      series.dataFields.valueX = field;
                      series.dataFields.categoryY = "adGroupId";
                      series.stacked = true;
                      series.columns.template.stroke = am4core.color("#97A0AF"); // red outline
                      series.columns.template.fill = am4core.color(color);
                    }

                    createSeries("impressions", "#2598d5");
                    createSeries("impressionsShouldBeServed", "#97A0AF");
                    createSeries("totalImpressionsToBeServed", "#fff");
                  });
                }
              }
            });
          });

          vm.buildChart = function () {
            if ($scope.type === 'gauge') {
              chart = am4core.create($scope.chartId, {
                "type": "gauge",
                "theme": "light",
                "axes": [{
                  "axisAlpha": 0,
                  "tickAlpha": 0,
                  "labelsEnabled": false,
                  "startValue": 0,
                  "endValue": 100,
                  "startAngle": 0,
                  "endAngle": 360,
                  "bands": [{
                    "color": "#66d6c3",
                    "startValue": 0,
                    "endValue": 65,
                    "radius": "100%",
                    "innerRadius": "75%"
                  }, {
                    "color": "#eee",
                    "startValue": 65,
                    "endValue": 100,
                    "radius": "100%",
                    "innerRadius": "75%"
                  }]
                }],
                "allLabels": [{
                  "text": "",
                  "x": "49%",
                  "y": "5%",
                  "size": 15,
                  "bold": true,
                  "color": "#84b761",
                  "align": "right"
                }],
                "export": {
                  "enabled": true
                }
              });
            } else if ($scope.type === 'pie') {
              chart = am4core.create($scope.chartId, {
                "type": "pie",
                "theme": "light",
                "dataProvider": vm.data

                ,
                "titleField": "fullName",
                "valueField": "impressions",
                "labelRadius": 5,

                "radius": "42%",
                "innerRadius": "60%",

                "export": {
                  "enabled": true
                }
              });
            }
          };
          return vm;
        }],
        controllerAs: 'vm'
      };
    });

})();
