(function () {
    'use strict';
    angular.module('app')
        .directive('accountManager', function () {
            return {
                template: require('@views/admin/account.html').default,
                restrict: 'E',
                scope: { account: '=' },
                backdrop: 'static',
                controller: 'accountManagerController',
                controllerAs: 'vm'
            }
        });
})();