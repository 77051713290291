(function () {
    'use strict';
    angular.module('app')
        .directive('creativeRequest', ['$location', function () {
            return {
                template: require('@views/creative/editors/creative-request.html').default,
                restrict: 'E',
                controllerAs: 'vm',
                scope: { cancelRequest: '&cancelRequest', requestCreative: '=', isRequestPage: '=', campaign: '=', viewMode: '=' },
                controller: ['$scope', '$rootScope', 'campaignService', 'hub', 'authService', '$timeout', 'accountService', function ($scope, $rootScope, campaignService, hub, authService, $timeout, accountService) {
                    var vm = this;
                    var rootVM = $rootScope.rootVM;
                    vm.adGroup = $scope.adGroup;
                    vm.campaign = $scope.campaign;
                    vm.viewMode = $scope.viewMode;
                    vm.requestCreative = $scope.requestCreative;
                    vm.userAccountType = authService.userAccountType();
                    vm.account = accountService.get();

                    vm.isRequestPage = $scope.isRequestPage;
                    vm.channels = campaignService.getChannels();
                    vm.selectedChannel = null;
                    vm.selectedChannelChanged = function () {
                        if (vm.selectedChannel)
                            vm.requestCreative.channelId = vm.selectedChannel.id;
                        else
                            vm.requestCreative.channelId = '';

                        if (vm.campaign)
                            vm.requestCreative.channelId = vm.campaign.channelId;
                    }

                    vm.sizes = campaignService.getSizes();
                    vm.marketers = [];

                    if (vm.account.accountType == 2) {
                        vm.requestCreative.marketerId = vm.account.id;
                    }
                    else
                        vm.selectedMarketer = null;

                    vm.checkAll = function () {
                        vm.requestCreative.selectedSizes = vm.requestCreative.selectedSizes.length < vm.sizes.length ? _.map(vm.sizes, 'id') : [];
                    }

                    vm.sizeChecked = function (item) {
                        var checked = vm.requestCreative.selectedSizes.indexOf(item.id) > -1;
                        if (!checked)
                            vm.requestCreative.selectedSizes.push(item.id);
                        else
                            vm.requestCreative.selectedSizes.splice($.inArray(item.id, vm.requestCreative.selectedSizes), 1);
                    }

                    if (vm.requestCreative.id != 0) {
                        vm.selectedChannel = vm.channels.find(function (c) { return c.id == vm.requestCreative.channelId; });
                        campaignService.getAccounts(2).then(function () {
                            vm.advertisers = campaignService.advertisers;
                            vm.marketers = campaignService.marketers;
                            vm.selectedAdvertiser = vm.advertisers.find(function (b) { return b.id == vm.requestCreative.advertiserId; });
                            vm.selectedMarketer = vm.marketers.find(function (r) { return r.id == vm.requestCreative.marketerId; });
                            vm.selectedAdvertiserChanged();
                            vm.selectedMarketerChanged();
                            vm.selectedChannelChanged();
                            vm.requestCreativeAssetsExist = vm.requestCreative.assets && vm.requestCreative.assets.length > 0;
                        });
                    }
                    else if (!vm.isRequestPage) {
                        vm.requestCreative.advertiserId = vm.campaign.advertiserId ? vm.campaign.advertiserId : $rootScope.rootVM.accountId;

                        campaignService.getAccounts(1).then(function () {
                            vm.advertisers = campaignService.advertisers;
                            vm.requestCreative.advertiserName = vm.advertisers.find(function (b) { return b.id == vm.requestCreative.advertiserId; }).name;
                        });

                        vm.requestCreative.marketerId = vm.campaign.marketerId;
                        vm.requestCreative.assets = [];
                        vm.requestCreative.callToAction = 'Buy Now';
                        vm.requestCreative.advertiseringWebsite = '';
                        vm.requestCreative.destinationURL = '';
                        vm.requestCreative.selectedSizes = [1, 2, 3, 4, 5];
                    }
                    else {
                        vm.requestCreative.assets = [];
                        vm.requestCreative.callToAction = 'Buy Now';
                        vm.requestCreative.advertiseringWebsite = '';
                        vm.requestCreative.destinationURL = '';
                        vm.requestCreative.selectedSizes = [1, 2, 3, 4, 5];
                        campaignService.getAccounts(2).then(function () {
                            vm.advertisers = campaignService.advertisers;
                            if (authService.userAccountType() === "Advertiser") {
                                campaignService.getAccounts(1).then(function () {
                                    vm.advertisers = campaignService.advertisers;
                                    vm.selectedAdvertiser = vm.advertisers[0];
                                    if (vm.selectedAdvertiser) {
                                        vm.requestCreative.advertiserId = vm.selectedAdvertiser.id;
                                        vm.requestCreative.advertiserName = vm.selectedAdvertiser.name;
                                        vm.marketers = [];
                                        campaignService.marketers.forEach(function (r) {
                                            if (vm.selectedAdvertiser.marketers.indexOf(r.id) >= 0 && r.status == 0) {
                                                vm.marketers.push(r);
                                            }
                                        });
                                    }
                                });
                            }
                            if (vm.selectedMarketer) {
                                vm.requestCreative.marketerId = vm.selectedMarketer.id;
                            }
                        });
                    }

                    vm.selectedAdvertiserChanged = function () {
                        if (vm.selectedAdvertiser) {
                            vm.requestCreative.advertiserId = vm.selectedAdvertiser.id;
                            vm.requestCreative.advertiserName = vm.selectedAdvertiser.name;
                            vm.marketers = [];
                            campaignService.marketers.forEach(function (r) {
                                if (vm.selectedAdvertiser.marketers.indexOf(r.id) >= 0 && r.status == 0) {
                                    vm.marketers.push(r);
                                }
                                if (vm.account.accountType == 2) {
                                    vm.selectedMarketer = campaignService.marketers.find(function (r) { return r.id == vm.requestCreative.marketerId; });
                                    var allowedChannels = vm.selectedMarketer.options.find(function (o) { return o.optionId == 6; });
                                    if (allowedChannels) {
                                        var allowedChannelsIds = JSON.parse(allowedChannels.value);
                                        vm.filteredChannels = vm.channels.filter(function (ch) { return allowedChannelsIds.indexOf(ch.id) > -1; });
                                    }
                                    else {
                                        vm.filteredChannels = vm.channels;
                                    }
                                    vm.requestCreative.channelId = '';
                                }
                            });
                        }
                        else
                            vm.requestCreative.channelId = '';
                    }

                    vm.selectedMarketerChanged = function () {
                        if (vm.selectedMarketer) {
                            vm.requestCreative.marketerId = vm.selectedMarketer.id;
                            var allowedChannels = vm.selectedMarketer.options.find(function (o) { return o.optionId == 6; });
                            if (allowedChannels) {
                                var allowedChannelsIds = JSON.parse(allowedChannels.value);
                                vm.filteredChannels = vm.channels.filter(function (ch) { return allowedChannelsIds.indexOf(ch.id) > -1; });
                            }
                            else {
                                vm.filteredChannels = vm.channels;
                            }
                        }
                        vm.requestCreative.channelId = '';
                    }

                    vm.uploadImage = function (file, dimensions) {
                        return hub.webapicontext.uploadFile('Creative/ImageUpload', file).then(function (data) {
                            vm.requestCreative.assets.push(
                                {
                                    url: hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true",
                                    name: data.data.originalFileName
                                });
                            vm.advertiseringWebsiteAndAssetsValidate();
                        });
                    }

                    vm.removeAsset = function (asset) {
                        vm.requestCreative.assets.splice($.inArray(asset, vm.requestCreative.assets), 1);
                        vm.advertiseringWebsiteAndAssetsValidate();
                    }

                    vm.requestCreativeAssetsExist = vm.requestCreative.assets && vm.requestCreative.assets.length > 0;
                    vm.uploadInvalid = !vm.requestCreative.advertiseringWebsite && !vm.requestCreativeAssetsExist;
                    vm.advertiseringWebsiteAndAssetsValidate = function () {
                        vm.requestCreativeAssetsExist = vm.requestCreative.assets && vm.requestCreative.assets.length > 0;
                        vm.uploadInvalid = !vm.requestCreative.advertiseringWebsite && !vm.requestCreativeAssetsExist;
                        $timeout(function () {
                            $('#requestCreativeAssetsValidation').trigger('input');
                            $('#advertiseringWebsite').trigger('input');
                        });
                    }

                    vm.GetDateInCurrentTimezone = function (stringDate) {
                        var d = new Date(stringDate);
                        return d.toLocaleString();
                    }

                    vm.submitRequest = function () {
                        vm.advertiseringWebsiteAndAssetsValidate();
                        if (!creativeRequestForm.checkValidity()) {
                            $('#creativeRequestForm').validator('destroy');
                            $('#creativeRequestForm').validator('validate');
                            return;
                        }
                        vm.isInProgress = true;
                        if (vm.requestCreative.callToAction == 'Others')
                            vm.requestCreative.callToAction = vm.requestCreative.callToActionCustom;

                        if (vm.selectedChannel)
                            vm.requestCreative.channelId = vm.selectedChannel.id;
                        else if (vm.campaign && vm.campaign.channelId)
                            vm.requestCreative.channelId = vm.campaign.channelId;

                        if (authService.userAccountType() === "Admin")
                            vm.requestCreative.owner = 'TDMAM';
                        else
                            vm.requestCreative.owner = 'Advertiser';

                        hub.webapicontext.submitRequestCreative(vm.requestCreative).then(function (cr) {
                            vm.requestCreative.submitted = true;
                            $rootScope.$broadcast('requestCreativeSubmitted', vm.requestCreative);
                            vm.isInProgress = false;
                        });
                    }

                    vm.canSubmit = function () {
                        if ($scope.isRequestPage)
                            return vm.advertisers && vm.advertisers.length > 0 && $scope.$parent.creativeRequestForm.$valid;
                        else
                            return $scope.$parent.creativeRequestForm.$valid;
                    }

                    vm.cancelRequest = function () {
                        $scope.cancelRequest();
                        $rootScope.$broadcast('requestCreativeSubmitted', null);
                    }

                    return vm;
                }]
            }
        }]);
})();
