(function () {
  'use strict';

  angular.module('app')
    .directive('toggleNavCollapsedMin', ['$rootScope', toggleNavCollapsedMin])
    .directive('collapseNav', collapseNav)

  // swtich for mini style NAV, realted to 'collapseNav' directive
  function toggleNavCollapsedMin($rootScope) {
    var directive = {
      restrict: 'A',
      link: link
    };

    return directive;

    function link(scope, ele, attrs) {
      var app;

      app = $('#app');

      ele.on('click', function (e) {
        if (app.hasClass('nav-collapsed-min')) {
          app.removeClass('nav-collapsed-min');
        } else {
          app.addClass('nav-collapsed-min');
          $rootScope.$broadcast('nav:reset');
        }
        return e.preventDefault();
      });
    }
  }

  // for accordion/collapse style NAV
  function collapseNav() {
    var directive = {
      restrict: 'A',
      link: link
    };

    return directive;

    function link(scope, ele) {
      var $a, $span, $aRest, $spanRest, $app, $lists, $listsRest, $nav, $window, Timer, prevWidth, slideTime, updateClass;

      slideTime = 250;

      $window = $(window);

      $lists = ele.find('ul').parent('li');

      $lists.append('<i class="ti-angle-down icon-has-ul-h"></i><i class="ti-angle-right icon-has-ul"></i>');

      $a = $lists.children('a');

      $span = $lists.children('span');

      $listsRest = ele.children('li').not($lists);

      $aRest = $listsRest.children('a');

      $spanRest = $listsRest.children('span');

      $app = $('#app');

      $nav = $('#nav-container');

      scope.vm.sidebarClick = function (event, nameClick, selectedCampaign, currentCampaign, adgroup) {
        var $parent, $this;
        if ($app.hasClass('nav-collapsed-min') || ($nav.hasClass('nav-horizontal') && $window.width() >= 768)) {
          return false;
        }
        $this = $(event.currentTarget);
        var tagName = $this.prop('tagName');

        var noToggle = false;
        if (tagName == 'SPAN') {
          $parent = $this.parent('a').parent('li');
          if ($parent.hasClass('open') && selectedCampaign != currentCampaign || (selectedCampaign == currentCampaign && adgroup != null))
            noToggle = true;
        }


        if (!noToggle) {
          if ($this.find('i')[0]) {
            $this.find('i')[0].className = $this.find('i')[0].className == 'ti-angle-right' ? 'ti-angle-down' : 'ti-angle-right';
            $parent = $this.parent('a').parent('li');
          }
          else if ($this.parent().find('i')[0]) {
            $this.parent().find('i')[0].className = $this.parent().find('i')[0].className == 'ti-angle-right' ? 'ti-angle-down' : 'ti-angle-right';
            $parent = $this.parent('a').parent('li');
          }
          else {
            if ($this.find('i').context.className == 'ti-angle-right')
              $this.find('i').context.className = 'ti-angle-down';
            else if ($this.find('i').context.className == 'ti-angle-down')
              $this.find('i').context.className = 'ti-angle-right';

            $parent = $this.parent('a').parent('li');
          }
        }

        if (tagName == 'A')
          $parent = $this.parent('li');

        if (tagName == 'SPAN')
          $parent = $this.parent('a').parent('li');

        $lists.not($parent).removeClass('open').find('ul').slideUp(slideTime);
        if (!noToggle)
          $parent.toggleClass('open').find('ul').stop().slideToggle(slideTime);
        event.preventDefault();
      }

      $a.on('click', scope.vm.sidebarClick);
      $span.on('click', scope.vm.sidebarClick);
      $spanRest.on('click', scope.vm.sidebarClick);

      $aRest.on('click', function (event) {
        $lists.removeClass('open').find('ul').slideUp(slideTime);
      });

      scope.$on('nav:reset', function (event) {
        $lists.removeClass('open').find('ul').slideUp(slideTime);
      });

      prevWidth = $window.width();

      updateClass = function () {
        var currentWidth;
        currentWidth = $window.width();
        if (currentWidth < 768) {
          $app.removeClass('nav-collapsed-min');
        }
        if (prevWidth < 768 && currentWidth >= 768 && $nav.hasClass('nav-horizontal')) {
          $lists.removeClass('open').find('ul').slideUp(slideTime);
        }
        prevWidth = currentWidth;
      };

      $window.resize(function () {
        setTimeout(updateClass, 300);
      });

    }
  }

})();



