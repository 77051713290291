(function () {
    'use strict';

    angular.module('app')
      .directive('timeSchedule', function () {
          return {
              template: require('@views/app/time-schedule.html').default,
              restrict: 'E',
              scope: { model: '=', viewMode:'=' },
              controller: ['$scope', '$rootScope', 'campaignService', function ($scope, $rootScope, campaignService) {
                  var vm = this;
                  vm.schedule = $scope.model;
                  vm.viewMode = $scope.viewMode;
                  vm.weekDays = [{ name: 'Monday', key: 'mon', order: 1 }, { name: 'Tuesday', key: 'tue', order: 2 }, { name: 'Wednesday', key: 'wed', order: 3 }, { name: 'Thursday', key: 'thr', order: 4 }, { name: 'Friday', key: 'fri', order: 5 }, { name: 'Saturday', key: 'sat', order: 6 }, { name: 'Sunday', key: 'sun', order: 7 }];
                  vm.hours = [];
                  vm.hourHeaders = [];


                  //just all hour numbers [0-24]
                  var availableHours = [];

                  (function setHours() {
                      vm.hourHeaders = [];
                      var h = [];
                      for (var i = 0; i < 24; i++) {
                          var hour = { name: (i == 0 ? 12 : (i > 12 ? i - 12 : i)) + (i < 12 ? 'am' : 'pm'), hour: i, hourCaption: (i == 0 ? 12 : (i > 12 ? i - 12 : (i < 10 ? '0' + i : i))) };
                          h.push(hour);

                          availableHours.push(i);
                          if (i % 3 == 0)
                              vm.hourHeaders.push(hour);
                      }
                      vm.hours = h;

                  })();


                  vm.isSelected = function (weekday, hour) {
                      var selected = true;
                      if (!weekday && hour) {

                          for (var i = 0; i < vm.weekDays.length; i++) {

                              selected = selected && vm.isSelected(vm.weekDays[i], hour);

                          }
                          return selected;

                      }
                      else if (weekday && !hour) {
                          var selected = true;
                          for (var i = 0; i < 24; i++) {

                              selected = selected && vm.isSelected(weekday, { hour: i });

                          }
                          return selected;

                      }

                      if (!vm.schedule[weekday.key])
                          return false;
                      var hours = vm.schedule[weekday.key];

                      return hours.indexOf(hour.hour) >= 0;

                  }


                  vm.startSelection = function (weekday, hour) {
                      if (vm.viewMode === 'readonly')
                          return;
                      vm.action = vm.isSelected(weekday, hour) ? 'unselect' : 'select';
                      vm.startDay = weekday;
                      vm.startHour = hour;
                      fillSquare(weekday, hour);
                  }

                  vm.endSelection = function () {
                        if (vm.viewMode === 'readonly')
                          return;
                      vm.action = 'none';
                  }

                  vm.hover = function (weekday, hour) {
                      if (vm.viewMode === 'readonly')
                          return;

                      if (vm.action == 'select' || vm.action == 'unselect')
                          fillSquare(weekday, hour);
                  }

                  function fillSquare(weekday, hour) {
                      var days = [];
                      if (weekday == null)
                          days = vm.weekDays;
                      else {
                          var startDay = vm.startDay.order > weekday.order ? weekday : vm.startDay;
                          var endDay = vm.startDay.order < weekday.order ? weekday : vm.startDay;
                          days = vm.weekDays.filter(function (d) { return d.order >= startDay.order && d.order <= endDay.order });
                      }
                      var startHour = vm.startHour.hour > hour.hour ? hour : vm.startHour;
                      var endHour = vm.startHour.hour < hour.hour ? hour : vm.startHour;
                      var hours = vm.hours.filter(function (h) { return h.hour >= startHour.hour && h.hour <= endHour.hour });

                      days.forEach(function (weekday) {
                          if (!vm.schedule[weekday.key])
                              vm.schedule[weekday.key] = [];
                          hours.forEach(function (hour) {
                              if (vm.action == 'select') {
                                  var idx = vm.schedule[weekday.key].indexOf(hour.hour);
                                  if (idx < 0)
                                      vm.schedule[weekday.key].push(hour.hour);
                              }
                              else if (vm.action == 'unselect') {
                                  var idx = vm.schedule[weekday.key].indexOf(hour.hour);
                                  if (idx >= 0)
                                      vm.schedule[weekday.key].splice($.inArray(hour.hour, vm.schedule[weekday.key]), 1);
                              }
                          });
                      });

                  }

                  //sets or removes week-day hour to the object {mon: [1,2,3],tue:[1,2,3] ... }
                  vm.setTime = function (weekday, hour) {
                      if (vm.viewMode === 'readonly')
                          return;

                      if (!weekday && hour) {
                          var allSelected = true;
                          for (var i = 0; i < vm.weekDays.length; i++) {

                              if (!vm.isSelected(vm.weekDays[i], hour)) {

                                  vm.setTime(vm.weekDays[i], hour);
                                  allSelected = false;
                              }


                          }
                          //if any of the times have been selected already - remove column selection
                          if (allSelected) {
                              for (var i = 0; i < vm.weekDays.length; i++) {
                                  var idx = !vm.schedule[vm.weekDays[i].key] ? -1 : vm.schedule[vm.weekDays[i].key].indexOf(hour.hour);
                                  if (idx >= 0) {
                                      vm.schedule[vm.weekDays[i].key].splice(idx, 1);
                                  }
                              }
                          }



                      }
                      else if (!hour) //weekday only selected
                      {

                          for (var i = 0; i < 24; i++) {
                              if (!vm.isSelected(weekday, { hour: i })) {
                                  vm.schedule[weekday.key] = angular.copy(availableHours); //set all hours
                                  return;
                              }

                          }
                          delete vm.schedule[weekday.key];
                          return;

                      }
                      else {


                          if (!vm.schedule[weekday.key])
                              vm.schedule[weekday.key] = [hour.hour];
                          else {
                              var hours = vm.schedule[weekday.key];
                              var idx = hours.indexOf(hour.hour);
                              if (idx >= 0) {
                                  hours.splice(idx, 1);
                              }
                              else {

                                  hours.push(hour.hour);
                                  vm.schedule[weekday.key] = hours.sort();
                              }
                          }
                      }
                  }

                  $scope.$on('scheduleChanged', function (event, data) {
                      var selectedAdGroup = data === null ? campaignService.getSelectedAdGroup() : data;
                      vm.schedule = selectedAdGroup.scheduleModel;
                  });


                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });

})();
