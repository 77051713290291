(function () {
    'use strict';
    var serviceId = 'expiringAppCache';
    angular.module('app').factory(serviceId, ['appCache', 'api','$q', expiringAppCacheFactory]);


    function expiringAppCacheFactory(appCache, api, $q) {
        const registeredKeys = [];

        const _get = function (name) {
            const cached = appCache.get(name);
            if(!cached) {
                return null
            }
            const { value, expiresAt } = cached;
            const isExpired = moment(expiresAt) < moment();
            if(isExpired) {
                remove(name);
                return null;
            }
            return value;
        };
        const put = function (name, value, secondsToExpire) {
            const expValue = { 
                expiresAt: moment().add('seconds', secondsToExpire).toString(), 
                value: value
            };
            appCache.put(name, expValue);
        };
        const remove = function(name)
        {
            appCache.remove(name);
        };

      function get({url, secondsToExpire = 10 }) {
           return  api.getSingle(url);
        }

        function clear(){
            for (let key of registeredKeys){
                console.log('removing cache item', key);
                remove(key);
            }
        }

        var service = {
            get,
            clear,
        };
        return service;
    }
})();
