(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:dateRange
 * @dateRange directive
 * # <date-range  />
 * shows data range dropdown list (sample)
 */

    angular.module('app')
      .directive('dateRange', function () {
          return {
              template: require('@views/app/date-range.html').default,
              restrict: 'E',
              scope: {model:'='},
              controller: ['$scope', '$rootScope', '$timeout', 'localStorageService', '$uibModal', 'hub', 'authService', 'mtaDateRangeService', function ($scope, $rootScope, $timeout, localStorageService, $modal, hub,authService, mtaDateRangeService) {
                  var vm = this;
                  const defaultRange = 'ThisMonth';
                  vm.model = $scope.model;
                  if (!vm.model)
                      vm.filter = localStorageService.get('date-filter') ? localStorageService.get('date-filter') : defaultRange;
                  else
                  {
                      vm.filter = vm.model.dateFilter ? vm.model.dateFilter : defaultRange;
                  }



                  var oldFilter = vm.filter;

                  var specificDate = localStorageService.get('date-day');
                  if (vm.model && vm.model.dayRange)
                      specificDate = vm.model.dayRange;

                  var specificDateValue = specificDate && specificDate.date ? new Date(specificDate.date) : null;

                  if (specificDateValue && isNaN(specificDateValue.valueOf())) {
                      specificDate = { date: new Date().toLocaleDateString(), range:'D' };
                      specificDateValue = new Date();
                  }
                  //remove ExactRange filter
                  var daysFilter = _.filter(_.clone(hub.config.dateRanges), function (item) {
                    const notAvailableRanges = ['Last7Days', 'Last30Days', 'Last90Days'];

                     return !item.custom && (!item.feature || authService.hasFeature(item.feature)) && !notAvailableRanges.includes(item.key);
                  });


                  vm.daysFilter = daysFilter;
                  vm.filterText = daysFilter.find(function (d) { return d.key === vm.filter }).day;

                  function setSpecialDateCaption(r, d) {
                      switch (r) {
                          case 'M':
                              return 'Month ' + (d ? (d.getMonth() + 1) + '/' + d.getFullYear() : '');

                          case 'W':
                              return 'Week ' + (d  ? d.toLocaleDateString() : '');

                          case 'Q':
                              return 'Quarter ' + (d ? (d.getMonth() + 1) + '/' + d.getFullYear() : '');
                          case 'D':
                              return d
                                  ? 'Day ' + d.toLocaleDateString()
                                  : 'Specific range';


                      }
                  }
                  vm.dateFilterDisplayed = false;
                  vm.changeFilter = function (key) {

                      if (vm.filter == key)
                          return;

                      vm.filter = key;

                      $rootScope.filter = key;
                      vm.dateFilterDisplayed = false;

                      vm.filterText = daysFilter.find(function (d) { return d.key === vm.filter }).day;


                      if (vm.filter === 'ExactDay' || vm.filter==='ExactRange') {
                          if (!specificDate)
                              specificDate = {
                                  date: specificDateValue
                                      ? specificDateValue.toLocaleDateString()
                                      : (new Date().toLocaleDateString()),
                                  range: 'D'
                              };


                        var  modalInstance = $modal.open({
                              template: require('@views/app/date-range-popup.html').default,
                              animation: false,
                              controller: 'dateRangePopupController as vm',
                              resolve: {
                                  specificDateRange: function () {
                                      return specificDate;
                                  }
                              }
                          });
                          modalInstance.result.then((function (dateRange) {
                          }), function (dateRange) {
                              if(!dateRange)
                              {
                                  vm.filter = oldFilter;
                                  $rootScope.filter = oldFilter;
                                  return;
                              }

                              if (!vm.model) {
                                  if (dateRange.date && !isNaN(dateRange.date.valueOf())) {
                                      localStorageService.set('date-day', { date: dateRange.date, range: dateRange.range });
                                      localStorageService.set('date-filter', vm.filter);
                                      hub.getLog('Date Range Filter', 'warn')('Specific range filter has been set. Range: ' + setSpecialDateCaption(dateRange.range, dateRange.date) + '. Updating data...');
                                      $rootScope.$broadcast('dateRangeChanged', null);
                                  } else {
                                      vm.filter = oldFilter;
                                      $rootScope.filter = oldFilter;
                                      hub.getLog('Date Range Filter', 'err')('Invalid date');
                                      return;

                                  }
                              }
                              else
                              {
                                  vm.model.dateFilter = vm.filter;
                                  vm.model.dayRange = { date: dateRange.date, range: dateRange.range };
                                  var specialDateCaption = setSpecialDateCaption(vm.model.dayRange.range, vm.model.dayRange.date);
                                  vm.daysFilter.forEach(function (x) {
                                      if (x.key === 'ExactRange')
                                          x.day = specialDateCaption;
                                  });
                              }

                          });
                      }
                      else {
                          if (!vm.model) {
                              localStorageService.set('date-filter', vm.filter);
                              $rootScope.$broadcast('dateRangeChanged', null);
                              hub.getLog('Date Range Filter', 'warn')('Date Range has changed. Updating data...');
                          }
                          else
                              vm.model.dateFilter = vm.filter;

                      }
                  }

                  const unreg = $scope.$watch('vm.filter', function(){
                    mtaDateRangeService.dateRangeChanged(vm.filter);
                  });
                  $scope.$on('$routeChangeStart', function() {
                    unreg();
                  });

                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });

    //bind select list to ng-model values
    angular.module('app').directive('convertToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    });

})();
