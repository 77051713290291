(function () {
    'use strict';

    angular.module('app').controller('defaultModalController', ['$scope', '$uibModalInstance', 'modalOptions', function ($scope, $uibModalInstance, modalOptions) {
        $scope.modalOptions = modalOptions;
        $scope.modalOptions.ok = function (result) {
            $uibModalInstance.close(result);
        };
        $scope.modalOptions.close = function (result) {
            $uibModalInstance.dismiss('cancel');
        };
    }]);

    angular.module('app').service('dialogService', ['$uibModal',
    function ($uibModal) {

        var modalDefaults = {
            backdrop: true,
            keyboard: true,
            modalFade: true,
            templateUrl: 'views/app/dialog.html',
            openedClass: 'modal-confirm-dialog'
        };

        var modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Remove',
            message: 'Are you sure you want to remove?'
        };

        this.showModal = function (customModalDefaults, customModalOptions) {
            if (!customModalDefaults) customModalDefaults = {};
            customModalDefaults.backdrop = 'static';
            if (customModalOptions.templateUrl)
                customModalDefaults.templateUrl = customModalOptions.templateUrl;
            customModalDefaults.controller = customModalOptions.controller;
            customModalDefaults.resolve = customModalOptions.resolve;
            return this.show(customModalDefaults, customModalOptions);
        };

        this.show = function (customModalDefaults, customModalOptions) {
            //Create temp objects to work with since we're in a singleton service
            var tempModalDefaults = {};
            var tempModalOptions = {};

            //Map angular-ui modal custom defaults to modal defaults defined in service
            angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

            //Map modal.html $scope custom properties to defaults defined in service
            angular.extend(tempModalOptions, modalOptions, customModalOptions);

            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = 'defaultModalController';
                tempModalDefaults.resolve = {
                    modalOptions: function () {
                        return tempModalOptions;
                    }
                };
            }

            return $uibModal.open(tempModalDefaults).result;
        };

    }]);
})();