(function () {
    'use strict';

    angular.module('app')
        .controller('editKeywordListController', EditKeywordListController);

    EditKeywordListController.$inject = ['$scope', '$uibModalInstance', '$sce', '$timeout', 'data', 'hub', 'authService'];

    function EditKeywordListController($scope, $modalInstance, $sce, $timeout, data, hub, authService) {
        var vm = this;
        $scope.vm = vm;
        vm.justUploaded = data.justUploaded;
        vm.keywordList = vm.justUploaded ? data.keywordList : angular.copy(data.keywordList);
        vm.marketers = data.marketers;
        vm.advertisers = data.advertisers;
        vm.isAdmin = authService.userAccountType() === "Admin";
        vm.isAdvertiser = authService.userAccountType() === "Advertiser";
        vm.isAdvertiserGroup = authService.userAccountType() === "AdvertiserGroup";
        vm.canManage = authService.hasAccess('InventoryLists', 'Manage');
        $scope.cancel = cancel;
        $scope.canSubmit = canSubmit;
        $scope.ok = ok;

        vm.selectedMarketerChanged = function () {
            vm.filteredAdvertisers = vm.advertisers.filter(function (a) { return a.marketerId === vm.keywordList.marketerId; });
            if (!vm.keywordList.advertiserId) {
                vm.keywordList.advertiserId = '';
            }
        };
        vm.selectedMarketerChanged();

        function cancel(cancelClicked) {
            vm.cancelClicked = cancelClicked;
            $modalInstance.dismiss('cancel');
        }

        function canSubmit(form) {
            var keywordListValid = false;

            if (typeof form !== 'undefined')
                keywordListValid = form.$valid;

            return keywordListValid && (vm.keywordList.keywordsFileURL || vm.keywordList.keywords);
        }

        vm.download = function (id) {

            hub.downloadFile('api/keywordList/download/' + id);
        };

        function ok() {
            if (!canSubmit(this.keywordListForm)) {
                $('#keywordListForm').validator('destroy');
                $('#keywordListForm').validator('validate');
                $timeout(function () {
                    $('#urlValidation').trigger('input');
                });
                return;
            }
            vm.isInProgress = true;
            hub.webapicontext.saveKeywordList(vm.keywordList).then(function (d) {
                vm.isInProgress = false;
                $modalInstance.close({ 'keywordList': vm.keywordList, 'files': $scope.files });
            });
        }

        vm.uploadKeywordList = function (file, type) {
            return hub.webapicontext.uploadFile('keywordList/KeywordListUpload', file).then(function (data) {
                vm.keywordList.keywordsFileURL = hub.settings.keywordsUrl + data.data.filename;
                vm.keywordList.keywordsFileName = data.data.originalFileName;
                vm.keywordList.totalRecordsCount = data.data.totalRecordsCount;
                vm.keywordList.uniqueRecordsCount = data.data.uniqueRecordsCount;
                $timeout(function () {
                    $('#urlValidation').trigger('input');
                });
            });
        };

        vm.removeFile = function () {
            vm.keywordList.keywordsFileURL = null;
            vm.keywordList.keywordsFileName = null;
            $timeout(function () {
                $('#urlValidation').trigger('input');
            });
        };

        $scope.$on('modal.closing', function (event, reason, closed) {
            if (!vm.showCaution && (reason === 'cancel') && event.currentScope.keywordListForm.$dirty && vm.cancelClicked) {
                $timeout(function () {
                    vm.showCaution = true;
                });
                event.preventDefault();
            }
        });

        $scope.$on('modal.force.close', function (event) {
            $modalInstance.dismiss('cancel');
        });

        vm.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    }
})();
