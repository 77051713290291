(function () {
    'use strict';

    angular.module('app')
        .controller('editBlockingProfileController', EditBlockingProfileController);

    EditBlockingProfileController.$inject = ['$scope', '$uibModalInstance', '$sce', '$timeout', 'data', 'hub', 'authService'];

    function EditBlockingProfileController($scope, $modalInstance, $sce, $timeout, data, hub, authService) {
        var vm = this;
        $scope.vm = vm;
        vm.justUploaded = data.justUploaded;
        vm.blockingProfile = vm.justUploaded ? data.blockingProfile : angular.copy(data.blockingProfile);
        vm.marketers = data.marketers;
        vm.advertisers = data.advertisers;
        vm.isAdmin = authService.userAccountType() === "Admin";
        vm.isAdvertiser = authService.userAccountType() === "Advertiser";
        vm.isAdvertiserGroup = authService.userAccountType() === "AdvertiserGroup";
        vm.canManage = authService.hasAccess('InventoryLists', 'Manage');
        $scope.cancel = cancel;
        $scope.canSubmit = canSubmit;
        $scope.ok = ok;

        vm.selectedMarketerChanged = function () {
            vm.filteredAdvertisers = vm.advertisers.filter(function (a) { return a.marketerId === vm.blockingProfile.marketerId; });
            if (!vm.blockingProfile.advertiserId) {
                vm.blockingProfile.advertiserId = '';
            }
        };
        vm.selectedMarketerChanged();

        function cancel(cancelClicked) {
            vm.cancelClicked = cancelClicked;
            $modalInstance.dismiss('cancel');
        }

        function canSubmit(form) {
            var blockingProfileValid = false;

            if (typeof form !== 'undefined')
                blockingProfileValid = form.$valid;

            return blockingProfileValid && vm.blockingProfile.blockingProfileFileURL;
        }

        vm.download = function (id) {

            hub.downloadFile('api/blockingProfile/download/' + id);
        };

        function ok() {
            if (!canSubmit(this.blockingProfileForm)) {
                $('#blockingProfileForm').validator('destroy');
                $('#blockingProfileForm').validator('validate');
                $timeout(function () {
                    $('#urlValidation').trigger('input');
                });
                return;
            }
            vm.isInProgress = true;
            hub.webapicontext.saveBlockingProfile(vm.blockingProfile).then(function (d) {
                vm.isInProgress = false;
                $modalInstance.close({ 'blockingProfile': vm.blockingProfile, 'files': $scope.files });
            });
        }

        vm.uploadBlockingProfile = function (file) {
            return hub.webapicontext.uploadFile('blockingProfile/BlockingProfileUpload', file).then(function (data) {
                vm.blockingProfile.blockingProfileFileURL = hub.settings.keywordsUrl + data.data.filename;
                vm.blockingProfile.blockingProfileFileName = data.data.originalFileName;
                $timeout(function () {
                    $('#urlValidation').trigger('input');
                });
            });
        };

        vm.removeFile = function () {
            vm.blockingProfile.blockingProfileFileURL = null;
            vm.blockingProfile.blockingProfileFileName = null;
            $timeout(function () {
                $('#urlValidation').trigger('input');
            });
        };

        $scope.$on('modal.closing', function (event, reason, closed) {
            if (!vm.showCaution && (reason === 'cancel') && event.currentScope.blockingProfileForm.$dirty && vm.cancelClicked) {
                $timeout(function () {
                    vm.showCaution = true;
                });
                event.preventDefault();
            }
        });

        $scope.$on('modal.force.close', function (event) {
            $modalInstance.dismiss('cancel');
        });

        vm.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    }
})();
