(function () {
    'use strict';

    var serviceId = 'columnsService';
    angular.module('app').factory(serviceId, ['appCache', '$q', columnsServiceFactory]);

    function columnsServiceFactory(appCache, $q) {        
        var service = {
            getSectionedUserColumns: function () {
                var userColumns = appCache.get('userColumns');

                var sectionedUserColumns = {};
                if (userColumns)
                    for (var colKey in userColumns) {
                        if (!sectionedUserColumns[userColumns[colKey].section])
                            sectionedUserColumns[userColumns[colKey].section] = {};

                        sectionedUserColumns[userColumns[colKey].section][colKey] = userColumns[colKey];
                    }

                return sectionedUserColumns;
            },
            getUserColumns: function (newToAdvertiser) {
                if (!service.columnsLoaded)
                    return service.loadColumns().then(function (data) {                        
                        return service.userColumns;
                    });
                else {
                    var userColumns = appCache.get('userColumns');
                    return this.filter(userColumns, function (uc) { return newToAdvertiser || uc.type !== 'ntb' && uc.type !== 'sc'; });
                }
            },
            setUserColumns: function (userColumns) {
                if (appCache.get('userColumns') !== userColumns)
                    appCache.put("userColumns", userColumns);
                service.userColumns = userColumns;
            },
            loadColumns: function () {                
                service.columnsLoadingPromise = $q.defer();

                return $q.all([service.columnsLoadingPromise.promise]).then(function (data) {
                    service.columnsLoaded = true;
                    return service.columnsLoadingPromise;
                });
            },
            userColumns: null,
            columnsLoaded: null,
            columnsLoadingPromise: null,
            filter: function (obj, predicate) {
                var result = {}, key;
                for (key in obj) {
                    if (obj.hasOwnProperty(key) && predicate(obj[key])) {
                        result[key] = obj[key];
                    }
                }
                return result;
            }
        };
        return service;
    }
})();