import { showLoader, hideLoader } from '../utils/loader'
import { navigateToAppUrl } from '../utils/navigation';

(function () {
  'use strict';

  angular.module('app')
    .controller('AppCtrl', ['$scope', '$rootScope', '$location', '$injector', '$document', '$timeout', '$filter', 'hub', 'context', 'logger', 'authService', 'appCache', 'campaignService', 'localStorageService', '$window', 'api', 'userSettingsService', '$uibModal',  AppCtrl]);

  function AppCtrl($scope, $rootScope, $location, $injector, $document, $timeout, $filter, hub, context, logger, authService, appCache, campaignService, localStorageService, $window, api, userSettingsService, $uibModal) {
    var date = new Date();
    var year = date.getFullYear();

    $scope.logOut = function () {
      authService.logOut();
      navigateToAppUrl('app/auth')
    };

    function openAdGroupHistory(adGroupId, fromV2) {
      $uibModal.open({
        template: `<history agid="${adGroupId}"/>`,
        animation: false,
        size: 'lg',
        backdrop: 'static',
      }).closed.then(() => {
        if (fromV2) {
          $window.history.back();
        }
      });
    }

    $scope.$on('$routeChangeStart', function ($event, next) {
      if ($event.defaultPrevented) {
        return;
      }

      if (!next.originalPath) {
        return
      }

      if (next.$$route?.name === 'adGroupHistory') {
        $event.preventDefault();
        const { adGroupId, fromV2 } = next.params;

        openAdGroupHistory(+adGroupId, !!fromV2);
        return;
      }

      let ss = window.sessionStorage.getItem('profileData');
      if (ss) {
        ss = JSON.parse(ss);
        let ps = ss.gridSettings && ss.gridSettings.pageSize ? ss.gridSettings.pageSize : 25;
        ss = { ...ss, gridSettings: { ...ss.gridSettings, pageSize: ps } };
      } else {
        ss = { gridSettings: { pageSize: 25 } };
      }
      window.sessionStorage.setItem('profileData', JSON.stringify(ss));
    });

    $scope.authentication = authService.authentication;


    var vm = this;
    $scope.vm = vm;
    vm.hasAccess = authService.hasAccess;
    vm.userSettingsService = userSettingsService;
    vm.appSettings = $injector.get('appSettings');

    vm.isSignInPage = $location.url() == '/pages/signin';
    vm.signInPageStyle = {};
    if (vm.isSignInPage)
      vm.signInPageStyle = { 'margin-top': '0px' };

    $scope.main = {
      advertiser: hub.config.appName,
      logo: require("@images/CompanyLogo.png"),
      name: '[Login]',
      year: year,
      copyright: '&copy;' + year + ' <b><a target="_blank" href="https://www.iqviadigital.com">Media OS&trade;</a></b>' + ' Version: ' + hub.settings.version + ' ' + (hub.settings.environment != 'production' || hub.settings.environment != 'staging' ? ' (' + hub.settings.environment + ')' + ', ' + $filter('date')(new Date(hub.settings.buildDate), 'medium') : '')

    };

    vm.clearSession = function () {
      if (sessionStorage) {
        sessionStorage.removeItem("campaigns_filter");
        sessionStorage.removeItem("sort_campaigns");
        sessionStorage.removeItem("sort_adGroups");
        sessionStorage.removeItem("sort_segments");
        sessionStorage.removeItem("creatives_filter");
        sessionStorage.removeItem("adgroups_filter");
        appCache.remove('accounts');
        appCache.remove('advertisers');
      }
    }

    $scope.pageTransitionOpts = [
      {
        name: 'Fade up',
        "class": 'animate-fade-up'
      }, {
        name: 'Scale up',
        "class": 'ainmate-scale-up'
      }, {
        name: 'Slide in from right',
        "class": 'ainmate-slide-in-right'
      }, {
        name: 'Flip Y',
        "class": 'animate-flip-y'
      }
    ];

    $scope.admin = {
      layout: 'wide',                                 // 'boxed', 'wide'
      menu: 'vertical',                               // 'horizontal', 'vertical', 'collapsed'
      fixedHeader: true,                              // true, false
      fixedSidebar: true,                             // true, false
      pageTransition: $scope.pageTransitionOpts[0],   // unlimited
      skin: '22'                                      // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    };


    $scope.color = {
      primary: '#7992BF',
      success: '#A9DC8E',
      info: '#6BD5C3',
      infoAlt: '#A085E4',
      warning: '#ECD48B',
      danger: '#ED848F',
      gray: '#DCDCDC'
    };

    vm.log = hub.getLog('TDX');


    var rootVM = $rootScope.rootVM || {};
    rootVM.newCampaignMode = false;

    rootVM.avatar = hub.settings.imagesUrl + 'avatars/user-icon-gray.svg';
    rootVM.userName = '[no user]';
    rootVM.addNewCreativeMode = false;
    campaignService.setSelectedCampaign({});
    campaignService.setSelectedAdGroup({ selectedLocations: [] });
    rootVM.menu = [];
    vm.account = appCache.account;
    rootVM.isLoaded = false;

    showLoader('mf')

    $scope.$watch(function () {
      return rootVM.isLoaded;
    }, function () {
      if (rootVM.isLoaded) {
        hideLoader('mf')
      } else {
        showLoader('mf')
      }
    });

    rootVM.loadMarketersNames = function () {
      rootVM.marketersNames = [];
      context.webapi.getMarketersNames().then(function (rn) {
        rootVM.marketersNames = rn;
        $rootScope.$broadcast('marketersNamesLoaded', null);
      });
    }

    rootVM.getAPBScript = function (id) {
      context.webapi.getAPBScript(id).then(function (c) {
        var selectedAdGroup = campaignService.getSelectedAdGroup();
        if (selectedAdGroup) {
          campaignService.setSelectedAdGroup({ adGroupId: id });
        }
        selectedAdGroup = campaignService.getSelectedAdGroup();
        selectedAdGroup.APBScript = c;
        $rootScope.$broadcast('APBScriptLoaded', null);
      });

    };

    rootVM.reportingUpdates = null;

    rootVM.allProductControlEntities = [];

    vm.menuClickAction = function (item) {
      if (item.feature == "CAMPAIGN") {
        $location.path(item.url, true);
      } else
        $location.path(item.url, true);
    }

    vm.logMessages = function () {
      return logger.logMessages();
    }


    rootVM.cancelCampaign = function (reload) {
      campaignService.setSelectedCampaign({ type: 0, items: [] });
      rootVM.clearAdGroup();
      rootVM.campaignEditMode = false;
      rootVM.addNewCreativeMode = false;
      if (reload) {
        campaignService.getCampaignsStatistic(true).then(function () {
          $rootScope.$broadcast('campaignsLoaded', null);
        });
      }
    }
    rootVM.clearAdGroup = function () {
      var adGroup = {
        schedule: '{}',
        selectedLocations: [],
        creative: {},
        channel: '1',
        deviceType: '0',
        budgetCapSpendModeId: 2,
        pacingType: 1,
        pacingOption: 1
      };
      campaignService.setSelectedAdGroup(adGroup);
      return adGroup;
    }

    vm.rootVM = rootVM;

    $rootScope.rootVM = rootVM;

    rootVM.channels = [
      { id: 1, name: 'Banner' },
      { id: 2, name: 'Video' },
      { id: 3, name: 'Facebook' }
    ];

    rootVM.creativeStatuses = [
      { id: 0, name: 'Draft' },
      { id: 1, name: 'Pending' },
      { id: 2, name: 'Approved' },
      { id: 3, name: 'Rejected' }
    ];
    vm.rootVM = $rootScope.rootVM;
    $scope.rootVM = $rootScope.rootVM;


    $rootScope.$on('signalRupdate', function (event, status) {
      if (status.entity == 'announcement') {
        if (vm.rootVM.userName == '[no user]') {
          var announcement = status.value;
          if (announcement.RefreshPage) {
            vm.log("<div style='text-align: center'>" + announcement.Body + "</div>");
            $timeout(function () {
              $window.location.reload();
            }, 30000);
          }
        }

      }
    });

    vm.isSuperAdmin = authService.authentication.userRoles ? authService.authentication.userRoles.includes("Super Admin") : false;

    return vm;
  }

})();
