(function () {
    'use strict';

    angular.module('app')
      .directive('campaigns', ['$location', function () {
          return {
              template: require('@views/campaign/campaigns.html').default,
              restrict: 'E',
              controllerAs: 'vm'
          }
      }]);

})();
