(function () {
  'use strict';

  angular.module('app')
    .controller('privacyPolicyCtrl', ['$scope', '$http', '$uibModal', 'hub', privacyPolicyCtrl])
    .controller('changePasswordCtl', ['$scope', 'hub', '$location', '$timeout', 'authService', changePasswordCtl])

  function privacyPolicyCtrl($scope, $http, $modal, hub) {

    var vm = this;
    vm.optOutGetUrl = 'http://' + hub.settings.serverHostName + '/optOut/get';
    vm.optOutStatus = 'http://' + hub.settings.serverHostName + '/optOut/status';
    $('#ooStatus').load(function () {
      console.log($('#ooStatus').contentDocument);
    });

    vm.optOutSetUrl = '';
    hub.page.setSidebarMode('');
    hub.page.setTitle('Privacy policy');
    vm.hasOptOut = true;
    vm.loaded = false;
    vm.getOptOutStatus = function () {


      $http.get('http://' + hub.settings.serverHostName + '/optOut/status', { withCredentials: true }).then(function (d) {
        vm.loaded = true;
        if (d.data.status == 1) {
          vm.hasOptOut = true;
        } else {
          vm.hasOptOut = false;
        }
      });
    }

    vm.getOptOutStatus();


    vm.setOptOutCookie = function () {
      $http.get('http://' + hub.settings.serverHostName + '/optOut/set?x=' + Math.random() * 100000, { withCredentials: true }).then(function () {
        vm.hasOptOut = true;
        vm.optOutGetUrl = vm.optOutGetUrl + '?x=' + Math.random() * 100000;
      });


    }

    vm.removeOptOutCookie = function () {
      $http.get('http://' + hub.settings.serverHostName + '/optOut/remove?x=' + Math.random() * 100000, { withCredentials: true }).then(function () {
        vm.hasOptOut = false;
        vm.optOutGetUrl = vm.optOutGetUrl + '?x=' + Math.random() * 100000;
      });


    }

    return vm;
  }

  function changePasswordCtl($scope, hub, $location, $timeout, authService) {

    var vm = this;
    vm.enabled = true;
    vm.firstTimeUser = authService.authentication.firstTimeUser;
    vm.code = $location.search().code;
    vm.userId = $location.search().id;

    vm.code = vm.code == true ? null : vm.code;
    vm.userId = vm.userId == true ? null : vm.userId;

    vm.isResetPassword = function () {
      return vm.code && vm.userId;
    }

    if (vm.isResetPassword()) {
      hub.page.setTitle('Set new password');
    } else
      hub.page.setTitle('Change password');

    vm.logout = function () {
      authService.logOut();
    }

    vm.changePassword = function () {

      if (!vm.enabled)
        return;
      vm.enabled = false;
      if (!vm.isResetPassword()) {
        authService.changePassword(vm.currentPassword, vm.newPassword).then(function () {
          hub.getLog('Change Password', 'info')('Password has been changed.');
          authService.authentication.firstTimeUser = false;
          authService.refreshUserData()
        }, function () {
          vm.enabled = true;
        });
      } else {
        authService.setPassword(vm.userId, vm.code, vm.newPassword).then(function () {
          hub.getLog('Change Password', 'info')('Password has been set. Please login the site user your new password after page reload.');
          $timeout(function () {
            window.location.href = window.location.origin + window.location.pathname;
          }, 2000);

        }, function () {
          vm.enabled = true;
        });

      }

    }

    return vm;

  }

})();



