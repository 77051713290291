(function () {
    'use strict';
    var serviceId = 'appCache';
    angular.module('app').factory(serviceId, ['$cacheFactory','localStorageService', appCacheFactory]);



    function appCacheFactory($cacheFactory, localStorageService) {
        var cache = $cacheFactory('app');

        var service = {
            get: function (name) {
                const src = cache.get(name);
                return src;
            },
            put: function (name, value) {                
                cache.put(name, value);
            },
            putPermanent: function(name,value)
            {
                localStorageService.set(name, value);
            },
            getPermanent: function (name) {
                return localStorageService.get(name);
            },
            remove: function(name)
            {
                localStorageService.remove(name);
                cache.put(name, null);
            }

        };
        return service;


    }
})();