(function () {
    'use strict';

    var serviceId = 'statcontext';
    angular.module('app').factory(serviceId, ['$q', '$http', 'api','config','accountService','expiringAppCache', 'appCache', statcontext]);


    function statcontext($q, $http, api, config, accountService, expiringAppCache, appCache) {



        var service = {

            adTrends: getAdTrends,
            adSummary: getAdSummary,
            offerSummary: getOfferSummary,
            offerTrends: getOfferTrends,
            campaignSummary: getCampaignSummary,
            adGroupSummary: getAdGroupSummary,
            allAdGroupsSummary: getAllAdGroupsSummary,
            allAdGroupsSummaryTotals: getAllAdGroupsSummaryTotals,
            campaignsSummary: getCampaignsSummary,
            campaignsSummaryTotals: getCampaignsSummaryTotals,
            segmentsReport: getSegmentsReport,
            customReport: postCustomReport,
            customReportSummary: postCustomReportSummary,
            exportCustomReport: exportCustomReport,
            getAdGroupFlightsPerformance: getAdGroupFlightsPerformance

        };

        return service;


        function getAdTrends(a, b, filter) {

            var acc = accountService.get();
            return api.getCached('api/statistics/ad/trends/?a=' + a + '&b=' + b + '&filter=' + filter, 'ad_trend_' + (acc ? acc.id : 0) + a + b + filter);
        }
        function getOfferTrends(a, b) {

            var acc = accountService.get();
            return api.getCached('api/statistics/offer/trends/?a=' + a + '&b=' + b, 'offer_trend_' + (acc ? acc.id : 0) + a + b);
        }

        function getAdSummary(filter) {

            return getCall('api/statistics/ad/', filter);

        }

        function getAdGroupFlightsPerformance(adGroupId) {
            return api.get('api/statistics/adgroupflights?adGroupId=' + adGroupId);
        }

        function getSegmentsReport(type,id) {

            if(type==='account')
                return api.get('api/reports/topsegments/' );
            else if (type === 'campaign')
                return api.get('api/reports/topsegments/campaign/' + id);
            else {
                throw new Error("Invalid report type: " + type);
            }

        }

        function getOfferSummary(filter) {

            return getCall('api/statistics/offer/', filter);

        }

        function getReportRequest(url, filter, idSelector, pageNumber, pageSize, sortColumn, sortDescending, currentPageIds, isExport){
            if (!filter)
            filter = config.dateFilter;

            var reportRequest = {
                sortDescending: sortDescending,
                sortColumn: sortColumn,
                pageNumber: isExport ? 0 : pageNumber,
                pageSize: isExport ? 0 : pageSize,
                singlePage: true,
                parameters: idSelector,
                currentPageIds: currentPageIds
            };

            reportRequest.parameters.filter = filter;

            if (filter === 'ExactRange' || filter === 'ExactDay') {
                var day = config.dateFilterDay ? new Date(config.dateFilterDay.date) : '';
                if (!isNaN(day.valueOf())) {
                    reportRequest.parameters.date = day.toISOString().slice(0, 10);
                    reportRequest.parameters.range = config.dateFilterDay.range;
                }
            }
            return reportRequest;
        }

        function fromCachePostCall(url, filter, idSelector, pageNumber, pageSize, sortColumn, sortDescending, currentPageIds, isExport){
            var deferred = $q.defer();
            const reportRequest = getReportRequest(...arguments);
            let cachedArray = appCache.get(url) || [];
            const cacheItem = cachedArray.find(x => JSON.stringify(x.reportRequest) === JSON.stringify(reportRequest));
            if(cacheItem == null) {
                postCall(...arguments).then((data) => {
                    const item = { reportRequest, reportResponse: data };
                    cachedArray.push(item);
                    appCache.put(url, cachedArray);
                    deferred.resolve(data);
                }, (err) => {
                    deferred.reject(err);
                });
            } else {
                deferred.resolve(cacheItem.reportResponse);
            }
            return deferred.promise;
        }

        function postCall(url, filter, idSelector, pageNumber, pageSize, sortColumn, sortDescending, currentPageIds, isExport) {
            const reportRequest = getReportRequest(url, filter, idSelector, pageNumber, pageSize, sortColumn, sortDescending, currentPageIds, isExport);
            var acc = accountService.get();
            return api.postSingle(url, reportRequest);
        }


        function postCustomReport(reportRequest) {



            return api.post('api/reports/customreport', reportRequest);
        }
        function postCustomReportSummary(reportRequest) {



            return api.post('api/reports/customreportsummary', reportRequest);
        }

        function exportCustomReport(reportRequest) {



            return api.post('api/reports/customreport/export', reportRequest);
        }

        function getCall(url, filter)
        {
            var dayUrl = '';
            if (!filter)
                filter = config.dateFilter;
            if (filter === 'ExactRange' || filter==='ExactDay') {
                var day = config.dateFilterDay ? new Date(config.dateFilterDay.date) : '';
                dayUrl = (!isNaN(day.valueOf()) ? '/?date=' + day.toISOString().slice(0, 10) + '&range=' + config.dateFilterDay.range : '');
            }


            return api.get(url + filter + dayUrl);
        }

        function getCampaignSummary(campaignId, filter) {
            return fromCachePostCall('api/statistics/campaign', filter, {id: campaignId});
        }

        function getCampaignsSummary(campaignIds, filter, pageNumber, sortColumn, sortDescending, currentPageIds, isExport) {

            if (!sortColumn)
            {
                sortColumn = "campaignId";
                sortDescending = true;
            }
            pageNumber = 0;
            const pageSize = campaignIds.length;
            return fromCachePostCall('api/statistics/campaigns', filter, { ids: campaignIds }, pageNumber, pageSize, sortColumn, sortDescending, currentPageIds, isExport);
        }

        function getCampaignsSummaryTotals(campaignIds, filter, isExport) {
            return fromCachePostCall('api/statistics/campaignsTotals', filter, { ids: campaignIds }, isExport);
        }

        function getAdGroupSummary(parameters, filter) {
            return fromCachePostCall('api/statistics/adgroup', filter,  parameters);
        }

        function getAllAdGroupsSummary(adGroupIds, filter, pageNumber, sortColumn, sortDescending, currentPageIds, isExport) {
            if (!sortColumn) {
                sortColumn = "adGroupId";
                sortDescending = true;
            }
            pageNumber = 0;
            const pageSize = adGroupIds.length;
            return fromCachePostCall('api/statistics/adgroups', filter, { ids: adGroupIds }, pageNumber, pageSize, sortColumn, sortDescending, currentPageIds, isExport);
        }

        function getAllAdGroupsSummaryTotals(adGroupIds, filter, isExport) {
            return fromCachePostCall('api/statistics/adgroupsTotals', filter, { ids: adGroupIds }, isExport);
        }
    }

})();
