(function () {
    'use strict';

    var serviceId = 'featureService';
    angular.module('app').factory(serviceId, ['api','localStorageService', featureServiceFactory]);

    function featureServiceFactory(api, localStorageService) {

        var service = {
            userData: null,
            getPortalSections: function () { return api.getEnum('PortalSections'); },
            getFeatures: function () { return api.get('api/feature/'); },
            allowToUseSSO: function () { return api.get('api/feature/CanUseOAuth'); },
            disabledFeatures: null,
            
            isActive: function (feature) {
                if (!feature)
                    return true;
                if (!this.disabledFeatures) {
                    var userData = localStorageService.get('userInfo');
                    if (!userData)
                        return false;

                    //copy disabled features to local variable
                    this.disabledFeatures = userData.disabledFeatures;
                }
                return !this.disabledFeatures || this.disabledFeatures.length == 0 ? false : this.disabledFeatures.indexOf(feature) == -1;
            },
            availabilityStatuses: [{ id: 0, name: 'Not available (disabled for all)' }, { id: 2, name: 'Alpha (Developers Only)' }, { id: 10, name: 'Beta (Lasso Users Only)' }, { id: 1, name: 'General Availability' }]
        };
        return service;

    }
})();