(function () {
    'use strict';
    angular.module('app')
        .controller('editLocationController', ['$scope', '$uibModalInstance', '$filter', 'config', 'hub', 'authService', '$timeout', '$location', 'Enum', 'data', 'campaignService', 'accountService', function ($scope, $modalInstance, $filter, config, hub, authService, $timeout, $location, Enum, data, campaignService, accountService) {

            var vm = this;
            vm.isInProgres = false;
            vm.enabled = false;
            vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('locations', 'Manage');
            vm.saveActionText = 'Save';
            vm.location = angular.copy(data);
            vm.locationTypes = angular.copy(Enum.LocationTypes);
            vm.file = null;
            vm.fileName = '';
            vm.config = hub.config;
            vm.location.headerRowsNumber = 1;
            vm.account = accountService.get();
            vm.isAdvertiserGroup = authService.userAccountType() === "AdvertiserGroup";

            if (!vm.location.id && vm.account) {
                if (vm.account.accountType == 2)
                    vm.location.marketerId = vm.account.id;
                if (vm.account.accountType == 1)
                    vm.location.advertiserId = vm.account.id;
            }

            vm.isSuperAdmin = authService.isSuperAdmin();
            campaignService.getAccounts(2).then(function () {
                vm.marketers = campaignService.marketers;
                vm.advertisers = campaignService.advertisers;
                vm.selectedMarketer = vm.marketers.find(function (m) { return m.id == vm.location.marketerId; });
                vm.selectedAdvertiser = vm.advertisers.find(function (m) { return m.id == vm.location.advertiserId; });
            });            

            if (!vm.location.radiusUnit)
                vm.location.radiusUnit = "Miles";

            vm.csvFields = vm.config.csvLocationFields;

            vm.location.selectedCsvFields = vm.location.selectedCsvFieldsJSON ? JSON.parse(vm.location.selectedCsvFieldsJSON) : [];
            vm.filteredCsvFields = vm.csvFields.filter(function (f) {
                return !vm.location.selectedCsvFields.find(function (sf) { return sf.id == f.id; });
            });


            if (vm.location.fileDataJSON) {
                vm.csvUploaded = true;
                vm.location.fileData = JSON.parse(vm.location.fileDataJSON);
            }

            vm.selectedMarketerChanged = function () {
                if (vm.selectedMarketer) {
                    vm.location.marketerId = vm.selectedMarketer.id;
                    vm.advertisers = campaignService.advertisers.filter(function (a) { return a.marketerId == vm.location.marketerId; });
                }
            };

            vm.selectedAdvertiserChanged = function () {
                if (vm.selectedAdvertiser)
                    vm.location.advertiserId = vm.selectedAdvertiser.id;
            };

            $scope.$on('fieldsBag.over', function (e, el, source) {
                if (source[0].id == 'selectedFields') {
                    source[0].style = 'border: 1px dashed #ddd;';
                    $('#dropMessage').hide();
                }
            });

            $scope.$on('fieldsBag.out', function (e, el, source) {
                if (source[0].id == 'selectedFields') {
                    source[0].style = 'border: 1px solid #ddd;';
                    if (vm.location.selectedCsvFields.length == 0)
                        if (vm.location.selectedCsvFields.length == 0)
                            $('#dropMessage').show();
                }
            });

            vm.getDownloadUrl = function (id) {
                return hub.api.get('api/audience/download/' + id + '/true').then(function (r) {
                    console.log(r);

                    var a = document.createElement('a');
                    a.href = r.url;
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                });
            };

            function readCsv(data, extension) {
                if (data) {
                    var cells;
                    if (extension == 'txt')
                        cells = data.split("\n").filter(function (r) { return r.split('\t'); }).map(function (row) {
                            return row.split('\t').map($.trim);
                        });
                    else
                        cells = data.split("\n").filter(function (r) { return hub.page.csvToArray(r); }).map(function (row) {
                            return hub.page.csvToArray(row).map($.trim);
                        });

                    var headers = cells[0];
                    vm.location.headers = [];
                    var order = 1;
                    headers.forEach(function (h) {
                        var field = vm.csvFields.find(function (f) {
                            return f.headers.indexOf(h.toLowerCase()) > -1;
                        });
                        if (field) {
                            field.usedFieldName = field.headers.find(function (head) { return head === h.toLowerCase(); });
                            field.order = order;
                            vm.location.headers.push(field);
                            order++;
                        }
                    });


                    vm.filePreview = [];
                    vm.location.headerExists = false;
                    var limit = cells.length - 1 > 10 ? 10 : cells.length - 1;
                    for (var i = 1; i <= limit; i++) {
                        var res = {};
                        for (var j = 0; j < headers.length; j++) {
                            var field = vm.csvFields.find(function (f) {
                                return f.usedFieldName === headers[j].toLowerCase();
                            });

                            if (field) {
                                res[field.usedFieldName] = cells[i][j];
                                vm.location.headerExists = true;
                            }
                        }
                        vm.filePreview.push(res);
                    }

                    if (!vm.location.headerExists) {
                        vm.location.headers = [];
                        vm.filePreview = [];
                        var n = 0;
                        headers.forEach(function (h) {
                            var field = n++;
                            vm.location.headers.push({ name: field, usedFieldName: field });
                        });
                        for (var i = 0; i < limit; i++) {
                            var res = {};
                            for (var j = 0; j < headers.length; j++) {
                                res[j] = cells[i][j];
                            }
                            vm.filePreview.push(res);
                        }
                    }

                    $timeout(function () {
                        vm.csvUploaded = true;
                    });
                }
            }

            vm.uploadCSV = function (file) {
                return hub.webapicontext.uploadFile('locationGroup/FileUpload', file.file).then(function (data) {
                    if (data.data) {
                        vm.location.originalFileName = data.data.originalFileName;
                        vm.location.dataFileUrl = hub.settings.locationDataUrl + data.data.filename;
                        vm.location.file = file;
                        var extension = data.data.filename.split(',').pop();
                        readCsv(file.data, extension);
                    }
                });
            };

            $scope.save = function () {
                if (typeof this.locationForm !== 'undefined') {
                    if (!this.locationForm.$valid) {
                        $('#locationForm').validator('validate');
                        return;
                    }
                }
                else
                    return;

                if (!vm.location.file && !vm.location.dataFileUrl) {
                    hub.getLog('Location Group Management', 'error')('Upload CSV locations file and try again');
                    return;
                }
                if (vm.location.radiusUnit === 'Miles' && vm.location.radius > 55 || vm.location.radiusUnit === 'Feet' && vm.location.radius > 290400) {
                    hub.getLog('Location Group Management', 'error')('Radius should be less than ' + (vm.location.radiusUnit === 'Miles' ? 55 : 290400) + ' ' + vm.location.radiusUnit.toLowerCase());
                    return;
                }



                vm.isInProgres = true;
                vm.location.fileDataJSON = JSON.stringify(vm.location.fileData);
                vm.location.selectedCsvFieldsJSON = JSON.stringify(angular.copy(vm.location.selectedCsvFields));
                if (vm.location.headers)
                    vm.location.headers = _.map(vm.location.headers, 'column');                

                hub.api.post('api/locationGroup/save', vm.location).then(function (res) {
                    console.log(data);
                    $modalInstance.dismiss();
                });
            };

            vm.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.$on('modal.force.close', function (event) {
                $modalInstance.dismiss('cancel');
            });

            return vm;
        }]);

})();
