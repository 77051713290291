/**
 * Created by naomiokubo on 3/22/16.
 */
(function () {
    'use strict';

    /**
     * @ngdoc function
     * @name app.directive:audienceController
     * @reportingController controller
     * # audienceController
     * audience builder controller
     */

    angular.module('app')
        .controller('reportingController', ['$scope', '$rootScope', '$uibModal', '$filter', 'hub', 'statcontext', function ($scope, $rootScope, $modal, $filter, hub, statcontext) {
            var log = hub.getLog('reportingController');
            var vm = this;
            vm.log = log;
            vm.warn = hub.getLog('reportingController', 'warn');
            var init;

            hub.page.setTitle('Reporting');
          
          

            vm.pieData =              
            [{
                "domain": "New York",
                "impressions": 1800
            }, {
                "domain": "CNN",
                "impressions": 1700
            }, {
                "domain": "Yahoo",
                "impressions": 800
            }, {
                "domain": "AOL",
                "impressions": 1200
            }, {
                "domain": "Facebook",
                "impressions": 2500
            }];

            vm.adSizesData =
           [{
               "adSize": "728x90",
               "views": 62,
               "color": "#66d6c3"
           }, {
               "adSize": "300x250",
               "views": 55,
               "color": "#66d6c3"
           }, {
               "adSize": "160x600",
               "views": 68,
               "color": "#66d6c3"
           }, {
               "adSize": "320x50",
               "views": 74,
               "color": "#66d6c3"
           }];
            vm.config = hub.config;
            vm.message = "Hello from reporting controller";

            vm.tableData= [{name: 'Naomi'}, {name: 'Dave'}, {name: 'Peter'}];


            return vm;
        }]);


})();