(function () {
    'use strict';

    var serviceId = 'accountService';
    angular.module('app').factory(serviceId, ['appCache', '$rootScope', '$location', 'featureService', accountServiceFactory]);

    function accountServiceFactory(appCache, $rootScope, $location, featureService) {
        var MANUFACTURER_MENU_OPTION = 32, HIDE_MENU_OPTION = 10121, COLUMNS_MENU_OPTIONS = 128;
        var _accountCache = null;
        var _columnCache = {};
        var _advertisersCache = null;

        var service = {
            get: function () {
                if (this._accountCache)
                    return this._accountCache;

                var account = appCache.getPermanent('account');

                return account;
            },
            getAdvertisers: function () {
                if (this._advertisersCache)
                    return this._advertisersCache;

                var advertisers = appCache.getPermanent('advertisers');

                return advertisers;
            },
            put: function (value) {
                this._accountCache = value;
                this._columnCache = null;
                appCache.putPermanent('account', value);
            },
            putAdvertisers: function (value) {
                this._advertisersCache = value;
                appCache.putPermanent('advertisers', value);
            },
            remove: function()
            {
                appCache.remove('account');
            },
            removeAdvertisers: function () {
                appCache.remove('advertisers');
            },
            getMarketerOption: function(marketerId, optionId, advertiser)
            {
                var _id = parseInt(marketerId);
                if (!advertiser) {
                    return _.chain(this.get().marketersOptions).filter({ id: _id }).map(function (o) { return o.options }).head().find({ optionId: optionId }).value();
                }
                else {
                    return _.chain(advertiser.marketersOptions).filter({ id: _id }).map(function (o) { return o.options }).head().find({ optionId: optionId }).value();
                }
            },

            isHidden: function (path) {
                return false;
            },
            getColumnName: function (columnKey, defaultName) {
                var ci = this.getColumnInfo();
                if (ci.names[columnKey])
                    return ci.names[columnKey].name;
                return defaultName;
            },
            isColumnHidden: function (columnKey) {
                return (this.getColumnInfo().hidden.indexOf(columnKey) >= 0);
            }
            ,
            getColumnInfo:
                 function () {

                     if (!this._columnCache) {
                         var acc = this.get();
                         if (acc && acc.options && acc.options.length>0) {
                             var section = acc.options.filter(function (opt) {
                                 return opt.optionId == COLUMNS_MENU_OPTIONS;
                             });
                             if (section.length > 0) {
                                 var columns = JSON.parse(section[0].value);
                                 columns.names = {};
                                 columns.customNames.forEach(function (cn, idx) {
                                     columns.names[cn.key] = { name: cn.customName };
                                 })
                                 this._columnCache = columns;
                             }
                             else
                                 this._columnCache = { hidden: [], names: {} };
                         }
                         else if (acc && acc.accountType == 3 && acc.advertisers && acc.advertisers.length > 0) {
                             var hiddenColumns = [];
                             acc.advertisers.forEach(function (advertiser) {
                                 var section = advertiser.options.filter(function (opt) {
                                     return opt.optionId == COLUMNS_MENU_OPTIONS;
                                 });
                                 if (section.length > 0) {
                                     var columns = JSON.parse(section[0].value);
                                     if (acc.advertisers[0].id == advertiser.id)
                                         hiddenColumns = columns.hidden;
                                     else {
                                         var newHiddenColumns = [];
                                         hiddenColumns.forEach(function (c) {
                                             if (columns.hidden.indexOf(c) > -1)
                                                 newHiddenColumns.push(c);
                                         });
                                         hiddenColumns = newHiddenColumns;
                                     }
                                 }
                             });
                             this._columnCache = { hidden: hiddenColumns, names: {} };
                         }
                         else
                             this._columnCache = { hidden: [], names: {} };
                     }

                     var acc = this.get();
                     if (acc && (acc.accountType == 1 || acc.accountType == 3)) {
                         const columnKeys = ['VOPA', 'COPA', 'VOP', 'COP', 'VFPA', 'CFPA', 'VFP', 'CFP', 'VPA', 'CPA', 'VP', 'CP', 'VROAS', 'CROAS'];
                         for (let key of columnKeys) {
                             if (this._columnCache.hidden.indexOf(key) == -1) {
                                this._columnCache.hidden.push(key);
                             }
                         }
                     }

                     return this._columnCache;
                 },
            getHideMenuValue: function() {
                var acc = this.get();
                if(acc && acc.options) {
                    const option = acc.options.find(function (opt) {
                        return opt.optionId == HIDE_MENU_OPTION;
                    });
                    return option ?  JSON.parse(option.value) : [];
                }
                return [];
            },
            hideMenus: function (topMenuItems) {
                var menus =JSON.parse(JSON.stringify(topMenuItems ? topMenuItems : $rootScope.rootVM.menu));
                var path = $location.path();
                var sectionValues = this.getHideMenuValue();

                sectionValues.forEach(function (sectionUrl, i) {
                    var result = menus.find(function (menu) {
                        return menu.url === sectionUrl;
                    });
                    if (result) {
                        result.hidden = true;
                    }
                });
                menus.forEach(function (menu, idx) {
                    menus[idx].activeClass = '';
                    if (!menu.submenu) {
                        if (menu.pathPrefixes) {
                            let it = menu.pathPrefixes.find(function (item) {
                                return path.indexOf(item) >= 0;
                            });
                            if (it)
                                menus[idx].activeClass = 'active';
                        }
                        else {
                            if (menu.url === path)
                                menus[idx].activeClass = 'active';
                        }
                    }
                    else {
                        menu.submenu.forEach(function (sub, sdx) {
                            if (sub.pathPrefixes) {
                                let it = sub.pathPrefixes.find(function (item) {
                                    return path.indexOf(item) >= 0;
                                });
                                if (it){
                                    menus[idx].activeClass = 'active';
                                }
                            }
                            else{
                                if (sub.url === path)
                                {
                                    menus[idx].activeClass = 'active';
                                }
                            }
                        });
                    }
                });
                return menus;

            },
            hasOption: function(id, value) {
                const accountOptions = this.get()?.options;
                return accountOptions
                  ? accountOptions.some(opt => opt.optionId === id && opt.enabled === value)
                  : true;
            }
        };
        return service;

    }
})();
