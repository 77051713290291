(function () {
    'use strict';

    angular.module('app')
        .controller('editInventoryListController', EditInventoryListController);

    EditInventoryListController.$inject = ['$scope', '$uibModalInstance', '$sce', '$timeout', 'data', 'hub', 'validationService', 'authService'];

    function EditInventoryListController($scope, $modalInstance, $sce, $timeout, data, hub, validationService, authService) {
        var vm = this;
        $scope.vm = vm;
        vm.justUploaded = data.justUploaded;
        vm.inventoryList = vm.justUploaded ? data.inventoryList : angular.copy(data.inventoryList);
        if(vm.inventoryList.advertiserId === 0) {
            vm.inventoryList.advertiserId = '';
        }
        vm.advertisers = data.advertisers;
        vm.marketers = data.marketers;
        vm.isAdmin = authService.userAccountType() === "Admin";
        vm.isAdvertiser = authService.userAccountType() === "Advertiser";
        vm.isAdvertiserGroup = authService.userAccountType() === "AdvertiserGroup";
        vm.canManage = authService.hasAccess('InventoryLists', 'Manage');
        $scope.cancel = cancel;
        $scope.canSubmit = canSubmit;
        $scope.ok = ok;
        
        vm.selectedMarketerChanged = function () {
            vm.filteredAdvertisers = vm.advertisers.filter(function (a) { return a.marketerId === vm.inventoryList.marketerId; });
            if (!vm.inventoryList.advertiserId) {
                vm.inventoryList.advertiserId = '';
            }
        };
        vm.selectedMarketerChanged();


        function cancel(cancelClicked) {
            vm.cancelClicked = cancelClicked;
            $modalInstance.dismiss('cancel');
        }

        function canSubmit(form) {
            var inventoryListValid = false;

            if (typeof form !== 'undefined')
                inventoryListValid = form.$valid;


            return inventoryListValid && (vm.inventoryList.domainsFileURL || vm.inventoryList.appsFileURL);
        }

        function ok() {
            if (!canSubmit(this.inventoryListForm)) {
                $('#inventoryListForm').validator('destroy');
                $('#inventoryListForm').validator('validate');
                $timeout(function () {
                    $('#urlValidation').trigger('input');
                });
                return;
            }
            vm.isInProgress = true;
            hub.webapicontext.saveInventoryList(vm.inventoryList).then(function (d) {
                vm.isInProgress = false;
                $modalInstance.close({ 'inventoryList': vm.inventoryList, 'files': $scope.files });
            });
        };

        vm.download = function (url) {

            hub.downloadFile(url);

        };

        vm.internalChanged = function () {
            if (vm.inventoryList.internal)
                vm.inventoryList.advertiserId = 0;
        };

        vm.uploadInventoryList = function (file, type) {
            return hub.webapicontext.uploadFile('inventoryList/InventoryListUpload', file).then(function (data) {
                if (type === 'apps') {
                    vm.inventoryList.appsFileURL = hub.settings.keywordsUrl + data.data.filename;
                    vm.inventoryList.appsFileName = data.data.originalFileName;
                }
                else {
                    vm.inventoryList.domainsFileURL = hub.settings.keywordsUrl + data.data.filename;
                    vm.inventoryList.domainsFileName = data.data.originalFileName;
                }
                vm.inventoryList.totalRecordsCount = data.data.totalRecordsCount;
                vm.inventoryList.uniqueRecordsCount = data.data.uniqueRecordsCount;

                $timeout(function () {
                    $('#urlValidation').trigger('input');
                });
            });
        };

        vm.removeFile = function (type) {
            if (type === 'apps') {
                vm.inventoryList.appsFileURL = null;
                vm.inventoryList.appsFileName = null;
            }
            else {
                vm.inventoryList.domainsFileURL = null;
                vm.inventoryList.domainsFileName = null;
            }
            $timeout(function () {
                $('#urlValidation').trigger('input');
            });
        };

        $scope.$on('modal.closing', function (event, reason, closed) {
            if (!vm.showCaution && (reason === 'cancel') && event.currentScope.inventoryListForm.$dirty && vm.cancelClicked) {
                $timeout(function () {
                    vm.showCaution = true;
                });
                event.preventDefault();
            }
        });

        $scope.$on('modal.force.close', function (event) {
            $modalInstance.dismiss('cancel');
        });

        vm.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    }
})();
