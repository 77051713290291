(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:sortColumn
 * @sortColumn directive
 * # <sort-column  />
 * renders table show column with up/down sign
 */



    angular.module('app')
      .directive('updateDates', function () {
          return {
              template: require('@views/app/update-dates.html').default,
              restrict: 'E',
              scope: { model: '=',},
              controller: ['$scope', function ($scope) {
                  var vm = this;
                  vm.model = $scope.model;


                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });

})();
