(function () {
  'use strict';

  angular.module('app')
    .directive('chartOptions', function () {
      return {
        template: require('@views/app/chart-options.html').default,
        restrict: 'E',
        scope: { title: '@', metrics: '=', model: '=', optionsChanged: '&' },
        controller: ['$scope', '$uibModal', function ($scope, $modal) {
          var vm = this;
          vm.title = $scope.title;
          vm.metrics = $scope.metrics;
          vm.model = $scope.model;

          $scope.$watch('metrics', function (newVal) {
            vm.metrics = newVal;
          });


          vm.showSettingsPopup = function ($event) {
            if ($event.stopPropagation) $event.stopPropagation();
            if ($event.preventDefault) $event.preventDefault();
            $event.cancelBubble = true;
            $event.returnValue = false;

            var modalInstance = $modal.open({
              templateUrl: 'views/app/chart-options-popup.html',
              animation: false,
              controller: 'chartOptionsPopupCtrl',
              size: 'md',

              resolve: {
                title: function () {
                  return vm.title;
                },
                metrics: function () {
                  return vm.metrics;
                }

              }
            });

            modalInstance.result.then(function (result) {
              vm.model = result;
              if ($scope.optionsChanged)
                $scope.optionsChanged()(vm.model);
            }, function () {
            });
          };

          return vm;
        }]
        ,
        controllerAs: 'vm'
      }
    });

  angular.module('app')
    .controller('chartOptionsPopupCtrl', ['$scope', '$uibModalInstance', 'config', 'hub', 'title', 'metrics',
      function ($scope, $modalInstance, config, hub, title, metrics) {

        $scope.title = title;
        var settings = hub.storagecontext.getCached('chartSettings', metrics.code, {});
        $scope.metrics = metrics.values; // [{ key: 'OD', caption: 'Offers Delivered' }, { key: 'OC', caption: 'Offers Clipped' }, { key: 'UM', caption: 'Units Moved' }, { key: 'RR', caption: 'Redemption Rate' }, { key: 'COUM', caption: 'Cost / Units Moved' }, { key: 'CC', caption: 'Cost / Clip' }, { key: 'SPND', caption: 'Spend' }];
        $scope.durations = [{ key: 'D', caption: 'Daily' }]; //, { key: 'W', caption: 'Weekly' }, { key: 'M', caption: 'Monthly' }, { key: 'Q', caption: 'Quaterly' }];
        $scope.series1 = settings ? settings.series1 : '';
        $scope.series2 = settings ? settings.series2 : '';
        $scope.duration = settings ? settings.duration : 'D';

        $scope.canSave = function () {
          if (!$scope.series1 || !$scope.series2)
            return false;

          return $scope.series1 != $scope.series2;
        }

        $scope.ok = function () {
          var obj = {};
          obj[metrics.code] = { series1: $scope.series1, series2: $scope.series2, duration: $scope.duration };
          hub.storagecontext.saveProfile('chartSettings', obj).then(function () {
            $modalInstance.close(obj);
          });
        };

        $scope.cancel = function () {
          $modalInstance.dismiss('cancel');
        };

      }]);

})();
