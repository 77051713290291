(function () {
    'use strict';
    angular.module('app')
      .controller('featuresController', ['$scope', 'config', 'featureService', 'hub', '$timeout', 'authService', function ($scope, config, featureService, hub, $timeout, authService) {

          var vm = this;

          hub.page.setTitle('Features Availablility');
          vm.isInProgress = false;
          vm.hasFeaturesAccess = authService.hasAccess('features', 'List');
          vm.canEdit =  authService.hasAccess('features', 'Edit');
          vm.availabilityStatuses = featureService.availabilityStatuses;
          vm.sections = null;
          var sectionLoaded = false;
          var featuresLoaded = false;

          featureService.getPortalSections().then(function (sections) {

              vm.sections = sections;
              sectionLoaded = true;

              if(featuresLoaded)
              {
                  vm.prepare();
              }
          });
          vm.activeTab = 0;
          vm.prepare = function()
          {
              var result = [];
              vm.sections.forEach(function (sec) {
                  result.push({ id: sec.id, name: sec.name, groups: vm.getGroups(sec.id) });
              });
              vm.sectionFeatures = result;
              $timeout(function () { vm.activeTab = 0; }, 100);

          }

          vm.sectionFeatures = [];

          vm.getFeatures = function () {

              featureService.getFeatures().then(function (f) {
                  vm.features = f;
                  featuresLoaded = true;
                  if (sectionLoaded)
                  {
                      vm.prepare();
                  }
              });
          };

          vm.getFeatures();


          vm.getSectionFeatures = function (sectionID) {
              if(vm.features && vm.features.length>0)
              {
                var features=  vm.features.filter(function (f) {
                      return f.portalSectionId == sectionID;
                });
                return features;
              }
              return [];
          };

          vm.getGroups = function (sectionID) {
              var f = vm.getSectionFeatures(sectionID);
              if (f != null && f.length > 0) {
                  var groups = f.map(function (o) { return o.groupName == null ? '' : o.groupName; });

                  groups = groups.filter(function (v, i) { return groups.indexOf(v) == i; });

                  groups.sort();
                  var result = [];
                  groups.forEach(function (item) {

                      result.push({ name: item, features: f.filter(function (feat) { return (!feat.groupName && !item) || feat.groupName == item; }) });
                  });
                  return result;

              }
              return [];
          };


            vm.refreshBrowsers = false;
            vm.save = function () {
              vm.isInProgress = true;

              hub.api.post('api/feature/?refresh=' + vm.refreshBrowsers, vm.features)
               .then(function (f) {
                          vm.isInProgress = false;
                          if (f.changed) {
                              hub.getLog('Settings', 'info')("The following features have been updated: " + f.name + (vm.refreshBrowsers ? ". All client browsers will be refreshed automatically" : " Your Browser will be refreshed automatically"));
                              if (!vm.refreshBrowsers)
                                  $timeout(function () { window.location.reload(); }, 2000);
                          }

                      },
                function (err) {
                    vm.isInProgress = false;
                    if (!err.data.logged) {
                        hub.getLog('Settings', 'error')('Unable to save features');

                    }
                });


          }
          vm.cancelFeatures = function () {

              vm.getFeatures();
          };



          return vm;
      }]);

})();
