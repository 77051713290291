(function () {
    'use strict';

    var serviceId = 'roleService';
    angular.module('app').factory(serviceId, ['authService', roleServiceFactory]);

    var SUPER_ADMIN = "Super Admin";


    function roleServiceFactory(authService) {
        var hasInternalRole = function(roles) {
            var internalRoles = [SUPER_ADMIN, "Account Manager", "Observer", "Reporting", "Media Ops Manager", "Creative Editor"];
            return hasIntersection(roles, internalRoles);
        };
        var hasIntersection = function (array1, array2) {
            return _.intersection(array1, array2).length > 0;
        }
        var service = {
            isInternalUser: function (user) {
                return hasInternalRole(user.roles);
            },
            isCurrentInternal: function() {
                return hasInternalRole(authService.authentication.userRoles);
            },
            isAdGroupMarketerMarginEditor: function() {
                return hasIntersection([SUPER_ADMIN], authService.authentication.userRoles);
            }
        };
        return service;
    }
})();
