"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-nocheck
const signalr_1 = require("@microsoft/signalr");
(function () {
    'use strict';
    angular.module('app').factory('signalR', ['$rootScope', '$location', '$timeout', '$q', 'logger', 'hub', 'localStorageService', function ($rootScope, $location, $timeout, $q, logger, hub, localStorageService) {
            const signalrURL = `${process.env.API}/signalr`;
            const hubConnection = window.__globalState?.rpcConnection?.value ?? new signalr_1.HubConnectionBuilder()
                .withUrl(signalrURL, {
                accessTokenFactory: function () {
                    var authData = localStorageService.get('authorizationData');
                    return authData?.token ?? '';
                },
            })
                .withAutomaticReconnect()
                .configureLogging(signalr_1.LogLevel.Information)
                .build();
            if (!window.__globalState) {
                window.__globalState = {};
            }
            if (!window.__globalState.rpcConnection) {
                window.__globalState.rpcConnection = { value: hubConnection };
            }
            function entityUpdate(message, useTimeout) {
                const tmt = Math.ceil(Math.random() * (useTimeout ? 30000 : 0));
                setTimeout(() => {
                    $rootScope.$broadcast(message.notificationType, message.notification);
                }, tmt);
            }
            hubConnection.on('notification', (message) => {
                switch (message.notificationType) {
                    case 'ReportUpdate':
                    case 'campaignUpdate':
                    case 'inventoryListUpdate':
                        entityUpdate(message, true);
                        break;
                    case 'audienceUpdate':
                    case 'locationUpdate':
                        entityUpdate(message, false);
                        break;
                    default:
                        break;
                }
            });
            async function restart() {
                await stop();
                await start();
            }
            async function stop() {
                if (hubConnection.state !== 'Disconnected') {
                    await hubConnection.stop();
                }
            }
            async function start() {
                if (hubConnection.state === 'Disconnected') {
                    await hubConnection.start();
                }
            }
            function isConnected() {
                return hubConnection.state === 'Connected';
            }
            start().catch(() => { });
            return {
                restart,
                stop,
                start,
                isConnected,
            };
        }]);
})();
