angular.module('app').directive('history', function () {
    return {
        template: require('@views/campaign/history.html').default,
        restrict: 'E',
        controllerAs: 'vm',
        scope: { adGroupId: '<agid' },
        controller: [
            '$scope', '$timeout', 'campaignService', 'changeHistory', 'timezoneService', 

            function($scope, $timeout, campaignService, changeHistory, timezoneService) {
              const apply = () => $timeout(() => $scope.$apply());
              const adGroupId = $scope.adGroupId;
              const dismiss = $scope.$parent.$dismiss;

              const vm = this;

              async function loadAdGroup(adGroupId) {
                  let adGroup = campaignService.getSelectedAdGroup();
                  
                  const isValid = () => adGroup?.campaignId && adGroup?.adGroupId == adGroupId;

                  if (!isValid()) {
                      adGroup = campaignService.adGroups.find(ag => ag.adGroupId == adGroupId);
                  }

                  if (!isValid()) {
                      const { results: adGroups } = await campaignService.getPagedAdGroupsStatistic({
                          progress: 0,
                          campaignId: 0,
                          adGroupIds: [adGroupId],
                          dateRange: 100, // All times
                          filter: { 
                              channels: [], 
                              accountManagers: [], 
                              advertisers: [], 
                              marketers: []
                          },
                          isExport: false,
                      });

                      adGroup = adGroups.find(ag => ag.adGroupId == adGroupId);
                  }

                  if (!isValid()) {
                      throw new Error(`Failed to load adGroup for id ${adGroupId}`);
                  }
                  
                  vm.setAdGroup(adGroup);
              }

              async function loadHistoryRecord(adGroupId) {
                  const historyRecords = await changeHistory.getAdgroup(adGroupId, campaignService.getSourceTypes(), campaignService.getTriggerEngagements());
                  vm.setHistoryRecords(historyRecords);
              }

              async function load(adGroupId) {
                  try {
                      vm.setLoading(true);
                      vm.setError(false);
                      await Promise.all([loadAdGroup(adGroupId), loadHistoryRecord(adGroupId)]);
                  } catch(error) {
                      vm.setError(true);
                      console.log(error);
                  } finally {
                      vm.setLoading(false);
                  }
              }

              vm.formatDate = timezoneService.estFormat;
              vm.adGroup = { adGroupId, name: adGroupId, buggetTypeId: 0 };
              vm.historyRecords = [];
              vm.hasHistory = false;

              vm.setLoading = function(value) {
                  vm.loading = value;
                  apply();
              };

              vm.setError = function(value) {
                  vm.error = value;
                  apply();
              };

              vm.setAdGroup = function(adGroup) {
                vm.adGroup = adGroup;
                apply();
              };

              vm.setHistoryRecords = function(historyRecords) {
                  vm.historyRecords = historyRecords;
                  vm.hasHistory = historyRecords && historyRecords.length > 0;

                  if (vm.hasHistory) {
                      const competitiveSeparation = /-ZipCode-Audience-Competitive-Separation|-PlusCode-Audience/i;

                      historyRecords.forEach(record => (record.locations ?? []).forEach(location => {
                        location.visible = !competitiveSeparation.test(location?.name ?? '');
                      }));

                      vm.historyDates = historyRecords.map(h => ({
                          updatedDate: h.updatedDate, 
                          mtMsgId: h.mtMsgId 
                      }));
                      vm.selectedHistoryItem = vm.historyRecords[0];
                      vm.selectedHistory = vm.selectedHistoryItem.mtMsgId;
                      vm.selectedHistoryChanged();
                      apply();
                  }
              };

              vm.selectedHistoryChanged = function() {
                  const { $$hashKey, creatives, ...props } = vm.adGroup;
                  vm.creativesAdgroup = null;
                  vm.selectedHistoryItem = vm.historyRecords.find(x => x.mtMsgId == vm.selectedHistory);
                  vm.creativesAdgroup = {
                      ...props,
                      creativeIds: (vm.selectedHistoryItem?.creatives || []).map(c => c.id),
                      creatives: vm.selectedHistoryItem?.creatives
                  };
              };

              vm.cancel = function() { dismiss('cancel'); };

              load(adGroupId);
              
              return vm;
            }
        ],
    }
})
