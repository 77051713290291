(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:optionForm
 * @optionForm directive
 * # <option-form  />
 * shows account options
 */

    angular.module('app')
        .directive('optionForm', function () {
            return {
                template: require('@views/app/option-form.html').default,
                restrict: 'E',
                scope: { model: '=', settings: '=', mode: '=' },
                controller: ['$scope', '$rootScope', 'hub', '$timeout', 'featureService', function ($scope, $rootScope, hub, $timeout, featureService) {
                    var vm = this;
                    vm.model = $scope.model;
                    vm.viewMode = $scope.mode;
                    vm.lists = {};
                    vm.options = [];
                    vm.accountOptions = [];
                    vm.settings = [];
                    vm.excludedOptionIds = [1010, 1020, 1030, 2010, 2020, 2030];
                    vm.showCombinedMarginGoal = vm.model.marketerContract ? vm.model.marketerContract.platformFeeType == 3 : false;

                    vm.hiddenFeature = function(opt) {
                        if((opt.id === 1024 || opt.id === 2048) && !featureService.isActive('BROWSERSBUYERS'))
                            return true;
                        if(opt.id === 10100 && !featureService.isActive("MTA")){
                            return true;
                        }
                        return false;
                    }

                    $scope.$on("platformFeeTypeChanged", function (event, platformFeeType) {
                        vm.showCombinedMarginGoal = platformFeeType == 3;
                        $timeout(function () {
                            $('#CombinedMarginGoal').trigger('input');
                        });
                    });

                    var BANNER_KEYS_DEPENDENT = ['BannerFloorCPM', 'DefaultCPMBanner'];
                    var VIDEO_KEYS_DEPENDENT = ['VideoFloorCPM', 'DefaultCPMVideo'];
                    var FACEBOOK_KEYS_DEPENDENT = ['FacebookFloorCPM', 'FacebookBudgetFactor', 'DefaultCPMFacebook', 'ForecastUserFBAudienceFactor'];

                    $scope.$watch(function() {
                        const publisherEnabledOption = vm.options.find(function (option) {
                            return option.id === 10120
                        });

                        if (!publisherEnabledOption) {
                            return undefined
                        }
                        return publisherEnabledOption.value === "true"
                    }, function updateValues(newValue) {
                        vm.updatePublisherRelatedOptions(newValue, false);
                    });

                    vm.firstLoad = 0;
                    vm.updatePublisherRelatedOptions = function(publisherEnabledOptionValue, shouldDeselect) {
                        const mediaTypesOption = vm.options?.find(option => option.id === 6);
                        const pldOption = vm.options?.find(option => option.id === 10190);
                        if (pldOption) {
                            pldOption.locked = !publisherEnabledOptionValue;
                            if (!publisherEnabledOptionValue) {
                                pldOption.value = 'false';
                            }
                        }

                        if (!mediaTypesOption) return;                        

                        const mediaTypeIdsToChange = vm.firstLoad++ > 1 ? [16, 19, 25] : [19];
                        const mediaTypesOptions = mediaTypesOption?.values?.filter(val => mediaTypeIdsToChange.some(mtid => mtid === val.id));
                        if (!mediaTypesOptions || mediaTypesOptions.length < 1) return;

                        const actions = {
                          uncheck: 'uncheck',
                          disable: 'disable',
                          enable: 'enable',
                        }

                        const updateMediaTypesOptions = (valuesToUpdate, mediaTypesActions) => {
                            valuesToUpdate.forEach(v => {
                              const mediaTypeAction = mediaTypesActions.find(a => a.id === v.id);
                              if (!mediaTypeAction)
                                return;
                              switch (mediaTypeAction.action) {
                                case actions.disable:
                                  v.locked = true;
                                  break;
                                case actions.enable:
                                  v.locked = false;
                                  break;
                                case actions.uncheck:
                                  v.checked = false;
                                  break;
                              }

                              if (shouldDeselect) {
                                v.checked =  false;
                              }
                            });
                        };

                        const mediaTypesActions = [
                            { id: 16, action: publisherEnabledOptionValue ? actions.uncheck : actions.enable },
                            { id: 19, action: publisherEnabledOptionValue ? actions.enable : actions.disable },
                            { id: 25, action: publisherEnabledOptionValue ? actions.uncheck : actions.enable },
                        ];

                        updateMediaTypesOptions(mediaTypesOptions, mediaTypesActions);

                        var dgidEnabled = vm.accountOptions.find(o => o.id == 10170);
                        if (dgidEnabled){
                            vm.yesNoClicked(10170, dgidEnabled.value === "true");
                        }
                    }

                    vm.yesNoClicked = function(optionId, value){
                        if (optionId == 10170) {
                           var recepientEmails = vm.accountOptions.find(o => o.id == 10180);
                            if (recepientEmails) {
                                recepientEmails.disabled = !value;
                                recepientEmails.required = value;
                                recepientEmails.isInvalid = recepientEmails.disabled ? false : recepientEmails.isInvalid;
                                $timeout(function () {
                                  $('#DGIDMacroMappingRecipients').trigger('input');
                                });
                            }
                        }

                        if (optionId === 10120) {
                            vm.updatePublisherRelatedOptions(value, true);
                        }
                    }

                    vm.optionChecked = function (id, checked, optionId) {
                        var applyOptionChanges = function(keys, ids) {
                            var options = vm.accountOptions.filter(function (o) {
                                return  keys && keys.indexOf(o.key) > -1 || ids && ids.indexOf(o.id) > -1;
                            });
                            options.forEach(function(opt){
                                opt.required = checked;
                            });
                            $timeout(function () {
                                options.forEach(function(opt) {
                                    $('#' + opt.key).trigger('input');
                                });
                            });
                        };

                        if (id == "/campaign") {
                            $rootScope.$broadcast('toggleColumnsSettings', checked);
                        }
                        switch (id) {
                            case 1:
                                applyOptionChanges(BANNER_KEYS_DEPENDENT);
                                break;
                            case 2:
                                applyOptionChanges(VIDEO_KEYS_DEPENDENT);
                                break;
                            case 3:
                                applyOptionChanges(FACEBOOK_KEYS_DEPENDENT);
                                $timeout(function () {
                                    $('#ForecastUserOverlapFactor').trigger('input');
                                    $('#ForecastUserReachFactor').trigger('input');
                                    $('#ForecastFrequencyFactor').trigger('input');
                                    $('#ForecaseEstimateReachRange').trigger('input');
                                });
                                break;
                        }
                        if (optionId == 4) {
                            var objectives = vm.accountOptions.find(function (o) { return o.id == 4; });
                            if (objectives.values.filter(function (v) { return v.checked; }).length < 3) {
                                objectives.values.forEach(function (val) {
                                    objectives.locked = true;
                                });
                            }
                            else {
                                objectives.values.forEach(function (val) {
                                    objectives.locked = false;
                                });
                            }
                            objectives.validate = (objectives.values.filter(function (v) { return v.checked; }).length < 2) ? true : false;
                            objectives.valid = objectives.validate ? '' : 'valid';
                        }
                        if (optionId == 6)
                            vm.setShowPlacements();
                    }

                    vm.required = function (key) {
                        if (key.indexOf('FloorCPM') > -1 || key.indexOf('DefaultCPM') > -1) {
                            var channels = vm.accountOptions.find(function (o) { return o.key == 'Channels'; });
                            if (channels) {
                                var res = ( BANNER_KEYS_DEPENDENT.indexOf(key) > -1  && channels.values[0].checked ||
                                    VIDEO_KEYS_DEPENDENT.indexOf(key) > -1 && channels.values[1].checked ||
                                    (key == 'FacebookFloorCPM' || key == 'DefaultCPMFacebook')  && channels.values[2].checked);
                                var option = vm.accountOptions.find(function (o) { return o.key == key; });
                                if (option)
                                    option.required = res;
                                return res;
                            }
                            return false;
                        }
                        if (key.indexOf('FacebookBudgetFactor') > -1 || key.indexOf('Forecas') > -1) {
                            var channels = vm.accountOptions.find(function (o) { return o.key == 'Channels'; });
                            if (channels) {
                                var res = channels.values[2].checked;
                                var opt = vm.accountOptions.find(function (o) { return o.key == key && [9000, 9010, 9020, 9030, 9040, 9431].indexOf(o.id) > -1; })
                                if (opt)
                                    opt.required = res;
                                return res;
                            }
                            return false;
                        }
                        return false;
                    }

                    vm.setShowPlacements = function () {
                        vm.showPlacements = vm.accountOptions.find(function (o) { return o.id == 6 && o.values[2].checked; });
                    }

                    vm.save = function () {
                        vm.settings = [];
                        vm.accountOptions.forEach(function (item, idx) {
                            var opt = { id: item.id, enabled: true, value: '' };
                            switch(item.type) {
                                case 'yesNo':
                                    opt.enabled = item.value == 'true' ? true : false;
                                    break;
                                case 'list':
                                    opt.value = parseInt(item.value);
                                    break;
                                case 'textbox': {
                                    //validation failed
                                    var elem = document.getElementById(item.key);
                                    if (!elem) {
                                        opt.value = '';
                                    } else {
                                        if (elem.dataset.isValid == 'false') {
                                            opt.isInvalid = true;
                                        }
                                        if (item.validation == 'number' || item.validation == 'percent0+')
                                            opt.value = parseFloat(item.value);
                                        else
                                            opt.value = item.value;

                                        if (item.id == 10180) {
                                            if (elem.value) {
                                                var emails = elem.value.split(',');
                                                item.isInvalid = false;
                                                if (!item.disabled) {
                                                    emails.forEach(function (em) {
                                                        if (!validateEmail(em.trim())) {
                                                            opt.isInvalid = true;
                                                            elem.setCustomValidity('Please enter valid email(s)');
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    }
                                    break;
                                }
                                case 'multiselect': {
                                    var values = [];
                                    for (var i = 0; i < item.values.length; i++) {
                                        if (item.invert && !item.values[i].checked) {
                                            values.push(item.values[i].id);
                                        }
                                        else if (!item.invert && item.values[i].checked) {
                                            values.push(item.values[i].id);
                                        }
                                    }
                                    opt.value = values;
                                    break;
                                }
                                default:
                                    opt.value = item.value;
                            }
                            vm.settings.push(opt);
                        });
                    }
                    var FORMATS = {
                        102: '0.01+',
                        112: '0.01+',
                        122: '0.01+',
                        9250: '%',
                        9200: '%0+',
                        1030: '%',
                        2010: '%',
                        2020: '%',
                        2030: '%',
                        9000: '0-10.',
                        9010: '%1-100',
                        9020: '0-100.',
                        9030:'%0-100',
                        9040: '0-10.',
                        9420: '$0-4.',
                        9430:'%',
                        9431:'%',
                        9440: '$0-100',
                        9441: '%',
                    };

                    var regexp = /^([$,%]?)([\d])+\-([\d]+)(\.?)/;
                    var decimalReg =  /^((\d+)(\.[\d]{0,2})?)|(\.\d{0,2})$/;
                    var regexpAny = /.*/;
                    var getItemData = function (item) {
                        if(item.format == '%') {
                            return {
                                min: 0,
                                max: 100,
                                step: "any",
                                pattern: regexpAny
                            };
                        } else if (item.format == '%0+') {
                            return {
                                min: 1,
                                max: 999,
                                step: "any",
                                pattern: regexpAny
                            };
                        } else if (item.format == '$0-100') {
                            return {
                                min: 0,
                                max: 100,
                                step: "any",
                                pattern: regexpAny
                            };
                        }else if (item.format == '0.01+') {
                            return {
                                min: 0.01,
                                step: 0.01,
                                max: '',
                                pattern: decimalReg
                            };
                        } else if(regexp.test(item.format)){
                            var matches = item.format.match(regexp);
                            var isDecimal = matches[4].length > 0;
                            return {
                                min: parseInt(matches[2]),
                                max: parseInt(matches[3]),
                                step: isDecimal ? 0.01 : 1,
                                pattern: isDecimal ? decimalReg : /^\d+$/
                            }
                        } else {
                            return {
                                min: 0,
                                max: '',
                                step: 'any',
                                pattern: decimalReg
                            }
                        }
                    }

                    function setValidationProps(item) {
                        switch(item.type) {
                            case 'list':
                            case 'textbox': {
                                if (item.validation == 'number') {
                                    var meta = getItemData(item);
                                    item.min = meta.min;
                                    item.max = meta.max;
                                    item.step = meta.step;
                                    item.pattern = meta.pattern;
                                }
                                if (item.id == 10180)
                                    item.patternError = 'Please enter valid email(s)';
                                break;
                            }
                            case 'multiselect': {
                                if (item.id == 4) {
                                    item.locked = item.values.filter(function (v) { return v.checked; }).length < 3;
                                    item.validate = item.values.filter(function (v) { return v.checked; }).length < 2;
                                    item.valid = item.validate ? '' : 'valid';
                                    $timeout(function () {
                                        $('#CampaignObjective').trigger('input');
                                    });
                                }
                                break;
                            }
                        }
                    }

                    function validateEmail(mail) {
                        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
                    }

                    function setItemValue(item, savedItem) {
                       if (savedItem && savedItem.optionId == item.id) {
                            item.enabled = savedItem.enabled;
                            switch(item.type) {
                                case 'yesNo':
                                    item.value = savedItem.enabled && savedItem.value != "0" ? "true" : "false";
                                    break;
                                case 'list':
                                case 'textbox': {
                                    if (item.validation == 'number') {
                                        item.value = (!savedItem.value && item.defaultValue) ? parseFloat(item.defaultValue) : parseFloat(savedItem.value);
                                    }
                                    else
                                        item.value = (!savedItem.value && item.defaultValue) ? item.defaultValue: savedItem.value;
                                    break;
                                }
                                case 'multiselect': {
                                    var selectedIds = JSON.parse(savedItem.value);
                                    item.values.forEach(function (v, index) {
                                        item.values[index].checked = (selectedIds.indexOf(v.id) >= 0);
                                        if (item.invert)
                                            item.values[index].checked = !item.values[index].checked;
                                    });
                                    break;
                                }
                                default:
                                    item.value = savedItem.value;
                            }
                        } else {
                            // set value from default value
                            if ((item.type == 'list' || item.type == 'textbox') && (item.value == null || item.value === "") ) {
                                if (item.validation == 'number')
                                    item.value = item.defaultValue==null  ? null: parseFloat(item.defaultValue);
                                  else
                                    item.value = item.defaultValue;
                           }
                           if (item.type == 'yesNo')
                               item.value = item.defaultValue == null || item.defaultValue == "0" ? "false" : "true";
                        }
                    }

                    hub.datacontext.getLocalJson('lists').then(function (d) {
                        vm.lists = d;
                        hub.api.getCached('api/json/accountOptionTypes','accountOptionTypes').then(function (o) {
                            vm.options = angular.copy(o);
                            vm.options.forEach(function (item, idx) {
                                //filter options by type
                                if (!item.appliesToAccountType || item.appliesToAccountType == vm.model.accountType) {
                                    item.enabled = true;
                                    if (item.type == 'yesNo') {
                                        item.value =  !item.defaultValue || item.defaultValue == "0" ? "false" : "true";
                                    }
                                    if (item.type == 'multiselect') {
                                        //copy list values
                                        item.values = vm.lists[item.source.key].values;
                                        item.values.forEach(function (val) { val.checked = false; });
                                        if (vm.model.accountType == 2 && item.id == 4)
                                            item.values.forEach(function (val) { val.checked = true; });
                                    }

                                    //set validation format
                                    if (FORMATS[item.id])
                                        item.format = FORMATS[item.id];
                                    setValidationProps(item);

                                    var savedOption = _.find(vm.model.options, function (it) { return it.optionId == item.id; });
                                    setItemValue(item, savedOption);

                                    if (item.id == 10180){
                                        var macroEnabled = _.find(vm.model.options, function (it) { return it.optionId == 10170; });
                                        if (!macroEnabled || !macroEnabled.enabled) {
                                            item.disabled = true;
                                            item.required = false;
                                        } else {
                                            item.disabled = false;
                                            item.required = true;
                                        }
                                    }

                                    vm.accountOptions.push(item);
                                }
                            });
                            vm.setShowPlacements();
                        });
                    });

                    return vm;
                }]
                ,
                link: function (scope, element, attrs, controller) {
                    if (scope.settings) {
                        scope.settings.getData = function () {

                            controller.save();


                            return controller.settings;
                        };
                    }
                },
                controllerAs: 'vm'
            }
        });

})();
