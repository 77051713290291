(function () {
    'use strict';

    var serviceId = 'externalservices';
    angular.module('app').factory(serviceId, ['$q', '$http',  'api', externalservices]);


    function externalservices($q, $http,  api) {



        //service definition
        var service = {

         

            getZipAddress: _getZipAddress,
            getLocationInfo: getLocationInfo,
            uploadLocations: uploadLocations,
            getPopulationData: getPopulationData,
            getLocationByPostalCodes: getLocationByPostalCodes
          
        };

        return service;

        function getLocationInfo(countries, type, keyword, cached)
        {
                return api.getCached("api/location/"+ countries.join('-') + "/" + type + "/" + keyword, type,!cached);
        }

        function getLocationByPostalCodes(countries, codes) {
            return api.post("api/location/" + countries.join('-') + "/postalcodes/", codes);
        }

        function uploadLocations(countries, postalcodes) {
            return api.post("api/location/" + countries.join('-') + "/postalcode/upload/", postalcodes);
        }

        function getPopulationData(locations) {
            return api.post("api/location/population", locations);
        }

        function _getZipAddress(zip) {


            return api.getDirect(zip, 'https://ziptasticapi.com/');

        }



    }


})();