(function () {
    'use strict';


    angular.module('app')
        .controller('columnManagerPopupController', ['$scope', '$uibModalInstance', 'accountService', 'authService', 'campaignService', 'config', 'table', 'columns', 'allowReordering', 'title', 'storagecontext', 'featureService', 'dragulaService', '$routeParams',
            function ($scope, $modalInstance, accountService, authService, campaignService, config, table, columns, allowReordering, title, storagecontext, featureService, dragulaService, $routeParams) {

                $scope.table = table;
                $scope.accountService = accountService;
                $scope.title = title;
                $scope.allowReordering = allowReordering;
                $scope.config = config;
                $scope.columns = [];

                var columnFilter = function (col) {
                    var isObserver = authService.isObserver('campaigns');
                    var isAccountManagerColumn = col.key == 'ACCMAN';
                    return $scope.table != 'campaigns' ||
                        (!isObserver || !isAccountManagerColumn && isObserver)
                }
                var allColumns = (!columns ? config.tables.getOrderedColumns($scope.table) : columns);

                if (allowReordering) {
                    dragulaService.options($scope, 'columns', {
                        revertOnSpill: true
                    });
                }

                if (!$scope.allowReordering)
                    $scope.columns = allColumns;
                else {
                    $scope.columns = config.tables.getOrderedColumns($scope.table);

                }
                $scope.columns = $scope.columns.filter(columnFilter)

                $scope.$on('columns.drop', function (e, el, target, source) {
                    if (target[0].id === "done" && source[0].id !== "done") {

                    }
                });

                $scope.columns = $scope.columns.filter(function (col) {
                    if (col.key == 'COM')
                        return true;
                    else
                        return featureService.isActive(col.key);
                });

                $scope.activeColumns = config.tables.getAvailableColumns($scope.table, columns);
                $scope.hiddenColumns = config.tables.hiddenColumns($scope.table) || [];

                var EXCLUDE_COLUMNS = ['AGRM', 'AGRMP', 'BPV', 'CONV', 'VR', 'ACPM', 'COM', 'CRID', 'CRSZ', 'BIFEE', 'AGMMB', 'AGMMBC', 'AGMMP', 'AGRMMP', 'AGRMB', 'AGRCSD', 'RC', 'STR', 'VSTS', 'ACTBTD', 'ACTIMP', 'TIMPSRV', 'IMPSRV'];
                $scope.columns = $scope.columns.filter(function (c) { return EXCLUDE_COLUMNS.indexOf(c.key) === -1; });

                var EXCLUDE_MEDIA_COLUMNS = ['PFEE', 'BIFEE', 'DCST', 'AGMMB', 'AGMMBC', 'MACPM', 'AGPT', 'AGRMB', 'AGRMMP', 'RMP', 'COM', 'AGRM', 'MCST', 'AGRMP'];


                if (authService.userAccountType() == "Advertiser" || authService.userAccountType() == "Manufacturer") {

                    var campaignId = $routeParams.campaignId;
                    var account = accountService.get();

                    var canShowPlatformFees = campaignService.canShowPlatformFees(campaignId, account);
                    if (canShowPlatformFees)
                        $scope.columns = $scope.columns.filter(function (c) { return EXCLUDE_MEDIA_COLUMNS.indexOf(c.key) == -1 });
                }

                if ($scope.title == "Campaigns")
                    $scope.columns = $scope.columns.filter(function (col) {
                        return !col.levels || col.levels.indexOf('campaign') > -1;
                    });

                if ($scope.title.match(/groups/i))
                    $scope.columns = $scope.columns.filter(function (col) {
                        return !col.levels || col.levels.indexOf('adgroup') > -1;
                    });

                if ($scope.title == "Segments")
                    $scope.columns = $scope.columns.filter(function (col) {
                        return !col.levels || col.levels.indexOf('segment') > -1;
                    });



                $scope.toggleCheck = function ($event, col) {
                    if (!$scope.hiddenColumns)
                        $scope.hiddenColumns = [];

                    var checked = $event.target.checked;
                    var idx = $scope.hiddenColumns.indexOf(col.key);

                    if (!checked) {

                        if (idx == -1)
                            $scope.hiddenColumns.push(col.key); // -- add to hidden list
                    }
                    else {
                        if (idx > -1) {
                            $scope.hiddenColumns.splice(idx, 1); //remove from hidden list
                        }
                    }
                }

                $scope.isGrossMetric =  function(col) {
                    return ['TRX', 'NRX', 'PROVNTB', 'PATNTB'].indexOf(col.key) > -1;
                }

                $scope.ok = function () {
                    //manually reorder columns
                    var keys = [];
                    if ($scope.allowReordering) {
                        $("#columnsReorderPanel > div[column-key]").each(function (idx, el) {
                            var ck = el.attributes['column-key'];
                            if (ck)
                                keys.push(ck.value);
                        });


                    }
                    else {
                        keys = _.map($scope.columns, 'key');
                    }

                    var obj = {};
                    var profileSection = 'columns';

                    var mode = true;
                    //save all columns
                    if (mode) {
                        var settings = { columnOrder: keys, hiddenColumns: $scope.hiddenColumns };
                        $scope.config.tables.saveColumns($scope.table, settings);
                        obj[$scope.table] = settings;
                    }
                    else {
                        $scope.config.tables.save($scope.table, $scope.hiddenColumns);
                        obj[$scope.table] = $scope.hiddenColumns;
                    }



                    storagecontext.saveProfile(profileSection, obj).then(function (d) {
                        $modalInstance.close('OK');
                    });


                };

                $scope.cancel = function () {
                    $modalInstance.dismiss('cancel');
                };

            }]);


    angular.module('app')
        .controller('tableManagerController', ['$scope', '$uibModal', '$timeout', '$location', 'logger', 'page', 'config', '$document', '$filter', 'campaignService', 'hub', '$rootScope', 'authService',
            function ($scope, $modal, $timeout, $location, logger, page, config, $document, $filter, campaignService, hub, $rootScope, authService
            ) {

                var mod = this;
                var getLogFn = logger.getLogFn;
                var log = getLogFn('Table Settings', 'warn');
                mod.tn = $scope.tableName;
                mod.dateRangeDisplayed = $scope.dateRangeDisplayed;
                mod.hideColumnSelection = $scope.hideColumnSelection === 'true' ? true : false;

                var vm = $scope.vm;
                mod.isAdmin = authService.isSuperAdmin();

                mod.path = $location.path();
                mod.exportData = [1, 2, 3, 4, 5];

                mod.getTableData = function () {
                    var tableName = mod.tn || mod.table;

                    if (!tableName)
                        return null;


                    var v = $('#' + tableName);
                    if (!v || v.length !== 1)
                        return null;

                    var table = v[0];
                    var csvData = [];
                    var excluded_columns = [];
                    var r = [];
                    if (tableName !== 'campaigns') {
                        for (var i = 0; i < table.rows.length; i++) {
                            var rowData = table.rows[i].cells;
                            for (var j = 0; j < rowData.length; j++) {
                                if (i === 0 && rowData[j].attributes['no-export'])
                                    excluded_columns.push(j);

                                if (excluded_columns.indexOf(j) === -1)
                                    r.push(rowData[j].innerText.replace(/(\r\n|\n|\r)/gm, ""));
                            }
                            csvData.push(r);
                            r = [];
                        }
                    }
                    if (mod.addDates == 'true') {
                        if ($scope.dates) {
                            $scope.dates.forEach(function (d) {
                                csvData.push([d.label + $filter('date')(d.date, 'MM/dd/yyyy (EST)', 'UTC')]);
                            });

                        }
                        else
                            campaignService.addReportingDatesForExport(csvData);
                    }

                    return csvData;
                }

                function iterateObject(obj) {
                    var value = '', header = '';
                    for (name in obj) {
                        if (obj.hasOwnProperty(name)) {
                            if (isObject(obj[name]) && obj[name] != null) {
                                var out = iterateObject(obj[name]);
                                value += out.value;
                                header += out.header;
                            } else if (name != 'undefined') {
                                value += removeNewLine(obj[name]) + ',';
                                header += name + ';';
                            }
                        }
                    }
                    return value;
                }

                function isObject(obj) {
                    return (typeof obj === 'object');
                }
                const wrappedStringRegexp = /^".+"$/;
                function removeNewLine(item) {
                    if (item == undefined)
                        item = '';
                    let result = item.toString().replace(/(\r\n|\n|\r)/gm, "");
                    if(!wrappedStringRegexp.test(item)){
                        return result.replaceAll(",", "");
                    }
                    return result;

                }

                mod.filename = function () {
                    if ($scope.exportFileName)
                        return $scope.exportFileName + '.csv';
                    else
                        return 'data_' + $filter('date')(new Date(), 'MM.dd.yyyy_hh.mm') + '.csv';
                }

                $scope.isAdGroupPage = $location.$$url.indexOf('campaign/') > -1 && $location.$$url.indexOf('campaign/adgroup') == -1;

                mod.settingsExportFunction = $scope.settingsExportFunction;

                mod.getConceptsFunction = $scope.getConceptsFunction;

                mod.makeInlineExport = function(res) {
                    const contentType = 'application/vnd.ms-csv';
                    var csvContent = '';
                    res.forEach(function (infoArray, index) {
                        var dataString = iterateObject(infoArray);
                        csvContent += dataString.substring(0, dataString.length - 2) + "\n";

                    });

                    hub.webapicontext.uploadData('statistics/CsvUpload', csvContent, 'campaigns', contentType).then(function (data) {
                        var url = data.data.downloadUrl;
                        var downloadContainer = angular.element('<div data-tap-disabled="true"><a target="_self" download href="' + url + '"></a></div>');
                        var downloadLink = angular.element(downloadContainer.children()[0]);
                        $document.find('body').append(downloadContainer);
                        $timeout(function () {
                            downloadLink[0].click();
                            downloadLink.remove();
                            $timeout(function () {
                                hub.webapicontext.removeFile(data.data.filename);
                            }, 1000);
                        }, 500);
                    });
                }

                mod.getExportData = function () {
                    mod.exportData = mod.getTableData();
                    if ((!mod.exportData || mod.exportFn != null) && typeof mod.exportFn == 'function') {
                        var result = mod.exportFn();
                        if (mod.promise || typeof result.then == 'function') {
                            return result.then(mod.makeInlineExport);
                        }
                        else {
                            return mod.makeInlineExport(result);
                        }
                    }
                    else {
                        return mod.makeInlineExport();
                    }
                }
                mod.log = log;
                mod.table = $scope.table;
                mod.exportFn = $scope.exportFunction;
                mod.bulkAdGroupUpload = $scope.bulkAdGroupUpload;

                mod.addDates = $scope.addDates;
                mod.promise = $scope.isPromise;
                mod.download = function () {
                    mod.log('Download option');
                }
                mod.openModal = function () {

                    var modalInstance = $modal.open({
                        template: require('@views/app/column-popup.html').default,
                        animation: true,
                        controller: 'columnManagerPopupController',
                        size: 'md',

                        resolve: {
                            table: function () {
                                return mod.table;
                            },
                            title: function () {
                                return $scope.title ? $scope.title : '';
                            },
                            columns: function () { return null; },
                            allowReordering: function () {
                                return $scope.allowReordering ? true : false;
                            }
                        }
                    });

                    modalInstance.result.then(async function () {
                        mod.log('Columns display updated. Page will be reloaded in a moment...');
                        await authService.refreshUserData(false)
                        window.location.reload();
                    }, function () {});
                };

                return mod;

            }]);


    angular.module('app')
        .directive('tableManager', function () {
            return {
                template: require('@views/app/table-manager.html').default,
                restrict: 'E',
                scope: {
                    table: '@',
                    bulkAdGroupUpload: '&?',
                    exportFunction: '&?',
                    settingsExportFunction: '&?',
                    getConceptsFunction: '&?',
                    tableName: '@',
                    title: '@',
                    dateRangeDisplayed: '=',
                    hideColumnSelection: '@',
                    isPromise: '@',
                    addDates: '@',
                    allowReordering: '@',
                    exportFileName: '@',
                    dates: '=',
                    tableData: '=',
                    setKeyword: '&?',
                    vm: '='
                },
                transclude: true,
                controller: 'tableManagerController',
                controllerAs: 'mod'
            };
        });
})();
