import { navigateToAppUrl } from '../utils/navigation';

(function () {
    'use strict';

    angular.module('app')
    .filter('reportFilter', [function() {

        var reportFilter = function(arr,searchText){

          if(! searchText)
            return arr;

          return arr.filter(function(arrayItem){

              var match = false;
              function isMatching(item, propName, search) {
                var value = item[propName];
                return value && value.toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
              }

              for(var key in arrayItem) {
                  if(!arrayItem.hasOwnProperty(key) || key === '$$hashKey' ||
                    key == 'reportJSON' || key == 'reportTypeId' || key == 'logId' ||
                    key == 'isExpired' || key == 'scheduledReportUrl' || key == 'isScheduled')
                    continue;
                  else if (key == 'reportType') {
                    match = match || isMatching(arrayItem[key], 'name', searchText);
                  }
                  else {
                    match = match || isMatching(arrayItem, key, searchText);
                  }
               }
               return match;
           });
        };

        return reportFilter;

    }]);

    angular.module('app')
        .controller('reportListController', ['$scope', '$rootScope', '$uibModal', '$filter', 'hub', 'statcontext', 'customReportService', 'authService' , '$location', 'timezoneService', '$window', function ($scope, $rootScope, $modal, $filter, hub, statcontext, customReportService, authService, $location, timezoneService, $window) {
            var log = hub.getLog('reportListController');
            var MOMENT_EST_TZ = "America/New_York";
            var vm = this;
            vm.log = log;
            vm.warn = hub.getLog('reportListController', 'warn');
            vm.keyword = '';
            vm.hasAccess = authService.hasAccess;
            hub.page.setTitle('Reporting');
            vm.currentEastTimeAbbreviation = timezoneService.getEasternTimeZone(new Date());
            vm.hasSalesChannelAccess = authService.userAccountType() === "Advertiser" || authService.userAccountType() === "Manufacturer" || authService.userAccountType() === "Admin";
            vm.isPublisherUser = authService.isPublisherUser;

            vm.config = hub.config;
            vm.reports = [];
            vm.sort = {
                reports: { column: 'name', sortDesc: false },
                deliveries: { column: 'deliveredDate', sortDesc: true }
            };

            vm.activeTab = 0;

            switch ($location.search().tab) {
              case 'saved':
                vm.activeTab = 1;
                break;
              case 'delivered':
                vm.activeTab = 2;
                break;
            }

            $scope.$watch('vm.activeTab', function(newTab, oldTab) {
              switch (newTab) {
                case 0:
                  $location.search('tab', undefined);
                  break;
                case 1:
                  $location.search('tab', 'saved');
                  break;
                case 2:
                  $location.search('tab', 'delivered');
                  break;
              }

              if (oldTab && newTab === 0 && authService.hasFeature('REPORTING-2')) {
                navigateToAppUrl('app/reporting')
              }
            });

            vm.deliveredFilter = function(r) {
                return r.scheduledReportUrl != null;
            }

            vm.delete = function(report) {
                hub.api.delete('api/reports/customreport/' + report.id)
                    .then(function (data) {
                        log(report.name + ' has been removed');

                        load();
                    });
            }

            vm.clone = function (id) {
                hub.api.post('api/reports/customreport/copy/' + id)
                    .then(function (data) {
                        log(data.name + ' has been copied');
                        load();
                    });
            }

            vm.deleteExport = function(logId) {
                hub.api.post('api/reports/customreport/cleanup/' + logId)
                    .then(function (data) {
                        vm.deliveries = _.filter(vm.deliveries, function(delivery){
                            return delivery.logId != logId;
                        });
                        var listName = 'deliveries';
                        vm.pageSelect(vm['currentPage_' + listName], listName);
                    });
            }
            vm.deleteExpiredExports = function() {
                hub.api.post('api/reports/customreport/cleanupexpired')
                    .then(function (data) {
                       load();
                    });
            }

            vm.editReportLink = function(r, load) {
                var service = customReportService;
                var path = service.getReportUrl(r.reportTypeId) + r.id;
                var json, campaignId, adGroupId;

                if (service.isCampaignAdgroupType(r.reportTypeId)) {
                    json = JSON.parse(r.reportJSON);
                    campaignId = json.campaignID;
                    adGroupId = json.adGroupID;
                }
                if(load) {
                    path += '?load=1';
                }
                return path;
            }

            vm.goEditReport = function(r) {
                var path = vm.editReportLink(r, false)
                $location.path(path);
            }

            vm.ReportTypeEnum = {
                CampaignSummary: 0,
                CampaignPerformance: 1,
                SitePerformance: 2,
                CustomMatch: 3,
                SellerPerformance: 4,
            }

            vm.hasAccessToReport = function(reportType) {
                if (vm.isPublisherUser() && reportType !== vm.ReportTypeEnum.SellerPerformance) {
                    return false;
                }

                return true;
            }

            vm.hasDeliveredReports = false;
            vm.hasSavedReports = false;
            $scope.$watch('vm.deliveries', function(newDeliveries){
                vm.hasDeliveredReports = newDeliveries && newDeliveries.length > 0;
            });
            $scope.$watch('vm.reports', function(newReports){
                vm.hasSavedReports = newReports && newReports.length > 0;
            });

            vm.editSchedule = function(report) {
                var modalInstance = $modal.open({
                    templateUrl: 'views/reporting/schedule.html',
                    animation: true,
                    controller: ['$scope', '$uibModalInstance', 'report', 'api', '$q', 'timezoneService', '$timeout', 'authService', function ($scope, $modalInstance, report, api, $q, timezoneService, $timeout, authService) {
                        var vm = this;
                        $scope.$window = $window;
                        var DEFAULT_DAYS_DELAY = 0;
                        var DEFAULT_REPEAT_VALUE = 1;
                        var DEFAULT_ENDS_AFTER_OCCURENCES = 2;
                        var DEFAULT_ENDS_ON_OCCURENCES = 2;

                        var ScheduleOptionsEnum = {
                            oneTime: 0,
                            day: 1,
                            week: 2,
                            month: 4,
                            year: 16,
                        };

                        var EndsOnEnum = {
                            Never: 1,
                            On: 2,
                            After: 3
                        };
                        var DEFAULT_ENDS_ON = EndsOnEnum.Never;

                        var findScheduleTypeOption = function(value) {
                            return _.find(vm.scheduleOptions, function(o) {
                                return o.value == value;
                            });
                        }

                        vm.minEndsOnDate = null;

                        function setMonthlyOptions (scheduledDeliveryDate) {
                            var options = [];
                            var dt = scheduledDeliveryDate;
                            if (dt && dt.getDate) {
                                var monthDays = moment(dt).daysInMonth();
                                var daysFromCurrentMonthEnd = monthDays - dt.getDate();
                                if (daysFromCurrentMonthEnd <= 2 ) {
                                    var max = dt.getDate();
                                    if (daysFromCurrentMonthEnd == 0) {
                                        options.push({
                                            name: 'Last day of month', value : 256
                                        });
                                    } else if (daysFromCurrentMonthEnd == 1) {
                                        options.push({
                                            name: 'Second to last day of month', value : 512
                                        });
                                    } else if (daysFromCurrentMonthEnd == 2) {
                                        options.push({
                                            name: 'Third to last day of month', value : 1024
                                        });
                                    }
                                } else {
                                    options.push({
                                        name: 'Monthly on day ' + dt.getDate(), value : 128
                                    });
                                }
                                vm.monthlyOptions = options;
                                if(vm.repeatOnType == null) {
                                    vm.repeatOnType = options[0].value;
                                }
                            }
                        }

                        $scope.vm = vm;

                        var toJsonDate = function(dt) {
                            if(dt){
                                var now = moment().tz(MOMENT_EST_TZ);
                                var result = moment().year(dt.getFullYear()).month(dt.getMonth()).date(dt.getDate()).hours(now.hours()).minutes(now.minutes());
                                return result.format().substring(0, 19) + "Z";
                            }
                            return null;
                        }

                        function setEndsOnDate(startDate, repeatEvery, endsOnOccurences, scheduleType) {
                            if(vm.endsOn != EndsOnEnum.Never && !vm.isInProgress) {
                                var data = {
                                    endsOnOccurences: endsOnOccurences,
                                    endsOn: vm.endsOn,
                                    startDate: toJsonDate(startDate),
                                    scheduleType: scheduleType,
                                    repeatEvery: repeatEvery,
                                    repeatOnType: vm.repeatOnType || 0
                                };
                                api.post('api/reports/schedule/dates', data)
                                .then(
                                    function (result) {
                                        if(vm.endsOn != EndsOnEnum.Never && result.endsOnDate){
                                            vm.endsOnDate = timezoneService.dateInEastern(result.endsOnDate).toDate();
                                        }
                                        vm.minEndsOnDate = timezoneService.dateInEastern(result.minEndsOnDate).toDate();
                                    },
                                    function (err) {
                                        console.log(err);
                                    });
                            }
                        }

                        function setRepeatOnType (repeatOnOptions, value) {
                            var isValidRepeatOnType = function () {
                                var valueIndex = _.findIndex(repeatOnOptions, function(opt){
                                    return opt.value == vm.repeatOnType;
                                });
                                return valueIndex > -1;
                            }
                            if(!isValidRepeatOnType() && repeatOnOptions.length > 0) {
                                $timeout(function(){
                                    vm.repeatOnType = value || repeatOnOptions[0].value;
                                });
                            }
                        }

                        function getDefaultOccurences(endsOn){
                            return endsOn === EndsOnEnum.After ? DEFAULT_ENDS_AFTER_OCCURENCES:
                                (endsOn == EndsOnEnum.On ? DEFAULT_ENDS_ON_OCCURENCES : null);
                        }

                        var setWatchers = function () {
                            $scope.$watch('vm.repeatEvery', function(repeatEvery){
                                if(repeatEvery) {
                                    setEndsOnDate(vm.startDate, repeatEvery, vm.endsOnOccurences, vm.scheduleType);
                                }
                            });

                            $scope.$watch('vm.repeatOnType', function(repeatOnType){
                                if(vm.scheduleType == ScheduleOptionsEnum.week) {
                                    setEndsOnDate(vm.startDate, vm.repeatEvery, vm.endsOnOccurences, vm.scheduleType);
                                }
                            });

                            $scope.$watch('vm.endsOnOccurences', function(endsOnOccurences) {
                                if(endsOnOccurences) {
                                    setEndsOnDate(vm.startDate, vm.repeatEvery, endsOnOccurences, vm.scheduleType);
                                }
                            });

                            $scope.$watch('vm.scheduleType', function(scheduleType) {
                                if(scheduleType) {
                                    if (scheduleType == ScheduleOptionsEnum.week) {
                                        var dayNumber = getNextStartDayOption();
                                        if(!vm.isInProgress){
                                            setRepeatOnType(vm.dayOptions, dayNumber);
                                        }
                                        setEndsOnDate(vm.startDate, vm.repeatEvery, vm.endsOnOccurences, scheduleType);
                                    } else if (scheduleType == ScheduleOptionsEnum.month) {
                                        setMonthlyOptions(vm.startDate);
                                        setRepeatOnType(vm.monthlyOptions);
                                        setEndsOnDate(vm.startDate, vm.repeatEvery, vm.endsOnOccurences, scheduleType);
                                    } else {
                                        setEndsOnDate(vm.startDate, vm.repeatEvery, vm.endsOnOccurences, scheduleType);
                                    }
                                }
                            });

                            $scope.$watch('vm.endsOn', function(endsOn){
                                if(endsOn == EndsOnEnum.Never) {
                                    vm.endsOnDate = null;
                                    vm.endsOnOccurences = null;
                                } else if (!vm.isInProgress && (endsOn == EndsOnEnum.On || endsOn == EndsOnEnum.After)) {
                                    vm.endsOnOccurences = getDefaultOccurences(endsOn);
                                    setEndsOnDate(vm.startDate, vm.repeatEvery, vm.endsOnOccurences, vm.scheduleType);
                                }
                            });

                            $scope.$watch('vm.startDate', function(startDate) {
                                if(startDate && startDate.getDate) {
                                    if(vm.scheduleType == ScheduleOptionsEnum.month){
                                        setMonthlyOptions(startDate);
                                        setEndsOnDate(startDate, vm.repeatEvery, vm.endsOnOccurences, vm.scheduleType);
                                        setRepeatOnType(vm.monthlyOptions);
                                    } else if (vm.scheduleType == ScheduleOptionsEnum.week && !vm.isInProgress) {
                                        var dayNumber = getNextStartDayOption();
                                        setRepeatOnType(vm.dayOptions, dayNumber);
                                        setEndsOnDate(startDate, vm.repeatEvery, vm.endsOnOccurences, vm.scheduleType);
                                    }
                                }
                            });
                        }

                        vm.emailsRegexp = /^(\s?[^\s;]+@[^\s;]+\.[^\s,]+\s?;)*(\s?[^\s;]+@[^\s;]+\.[^\s;]+)(;)*$/;

                        vm.init = function() {
                            vm.deleteSchedule = false;
                            vm.currentEastTimeAbbreviation = timezoneService.getEasternTimeZone(new Date());
                            var now = moment().tz(MOMENT_EST_TZ);
                            var dateInEastern = new Date(now.clone().tz(MOMENT_EST_TZ).format().substring(0, 16));
                            vm.minDate = dateInEastern;
                            vm.showAdvancedSettings = false;
                            vm.startDate = vm.minDate;

                            vm.scheduleType = ScheduleOptionsEnum.day;
                            vm.isOneTime = true;
                            vm.datepickers = {
                                startDate: false,
                                endsOnDate: false
                            };
                            vm.scheduleOptionsEnum = ScheduleOptionsEnum;
                            vm.endsOnEnum = EndsOnEnum;
                            vm.repeatOnType = null;
                            vm.endsOn = DEFAULT_ENDS_ON;
                            vm.endsOnDate = moment();
                            vm.monthlyOptions = [];





                            vm.repeatEvery = DEFAULT_REPEAT_VALUE;

                            vm.endsOnOptions = [
                                { name: 'Never', value: EndsOnEnum.Never },
                                { name: 'On', value: EndsOnEnum.On },
                                { name: 'After', value: EndsOnEnum.After }
                            ];

                            vm.dayOptions = [
                                { name: 'Sun', value: 1 },
                                { name: 'Mon', value: 2 },
                                { name: 'Tue', value: 4 },
                                { name: 'Wed', value: 8 },
                                { name: 'Thu', value: 16 },
                                { name: 'Fri', value: 32 },
                                { name: 'Sat', value: 64 },
                            ];

                            vm.scheduleOptions = _.map(Object.keys(ScheduleOptionsEnum) ,function(key){
                                return  {name: key, value: ScheduleOptionsEnum[key]}
                            });

                        }

                        vm.page = hub.page;
                        vm.config = hub.config;




                        vm.init();

                        vm.openDatepicker = function ($event, which) {
                            $event.preventDefault();
                            $event.stopPropagation();
                            for (var prop in vm.datepickers) {
                                vm.datepickers[prop] = false;
                            }
                            vm.datepickers[which] = true;
                        };

                        vm.isOneTimeSelected = function() {
                            return  vm.isOneTime;
                        }
                        vm.hasSelectedScheduleType = function() {
                            return vm.scheduleType;
                        }

                        vm.toggleAdvancedSettings = function () {
                            vm.showAdvancedSettings = !vm.showAdvancedSettings;
                        }

                        vm.isScheduleTypeOf = function(scheduleOptionsEnum) {
                            return scheduleOptionsEnum == vm.scheduleType;
                        }

                        vm.toggleOneTime = function() {
                           vm.isOneTime = !vm.isOneTime;
                           vm.repeatOnType = 0;
                        }

                        $scope.filterOneTime = function(opt) {
                            return opt.value != ScheduleOptionsEnum.oneTime;
                        };

                        $scope.canSubmit = function () {
                            var scheduleValid = false;

                            if (typeof this.scheduleForm !== 'undefined') {
                                scheduleValid = this.scheduleForm.$valid;
                            }

                            return scheduleValid;
                        };

                        $scope.validate = function () {
                            if (this.scheduleForm.$invalid) {
                                $('#scheduleForm').validator('validate');
                                var errorClass = 'has-error has-danger';
                                if(this.scheduleForm.emails.$invalid) {
                                    $('#emailWrp').addClass(errorClass);
                                } else {
                                    $('#emailWrp').removeClass(errorClass);
                                }
                                if(this.scheduleForm.startDate.$invalid){
                                    $('#startDateWrp').addClass(errorClass);
                                } else {
                                    $('#startDateWrp').removeClass(errorClass);
                                }
                                return false;
                            }
                            return true;
                        }

                        $scope.save = function () {
                            var startTime = new Date();
                            vm.isValid = false;
                            if (typeof this.scheduleForm !== 'undefined') {
                                vm.isValid = $scope.validate();
                            }
                            if(!vm.isValid){
                                return;
                            }

                            if(vm.deleteSchedule) {
                                deleteReportSchedule({ id: report.id });
                            } else {


                                var obj = {
                                    id: report.id,
                                    scheduleType: vm.isOneTime ? 0 :  vm.scheduleType,
                                    startDate: toJsonDate(vm.startDate),
                                    repeatOnType: vm.isOneTime ? 0 :  vm.repeatOnType,
                                    endsOn: vm.endsOn,
                                    repeatEvery: vm.isOneTime ? 0 : vm.repeatEvery,
                                    endsOnDate: vm.isOneTime ? null: toJsonDate(vm.endsOnDate),
                                    endsOnOccurences: vm.endsOn == EndsOnEnum.After ? vm.endsOnOccurences: 0,
                                    emails: vm.emails
                                };
                                saveReportSchedule(obj);
                            }
                        }

                        vm.cancel = function () {
                            $modalInstance.dismiss('cancel');
                        }
                        vm.isChecked = function(d) {
                            var value = d.value;
                            return (vm.repeatOnType & value) == value;
                        }
                        vm.checkDayOption = function(d) {
                            var value = d.value;
                            if((vm.repeatOnType & value) == value) {
                                vm.repeatOnType = vm.repeatOnType ^ value;
                            } else {
                                vm.repeatOnType = vm.repeatOnType | value;
                            }
                        }


                        function getNextStartDayOption() {
                            var day = vm.startDate.getDay();
                            var now = moment().tz("UTC");
                            while(now.day() != day) {
                                now = now.add(1, 'days');
                            }
                            var date = new Date(now.year(), now.month(), now.date(), now.hours(), now.minutes());
                            return Math.pow(2, date.getDay());
                        }

                        function deleteReportSchedule(data) {
                            vm.isInProgress = true;
                            api.post('api/reports/schedule/remove', data)
                            .then(
                                function (f) {
                                    vm.isInProgress = false;
                                    vm.cancel();
                                    load();
                                },
                                function (err) {
                                    vm.isInProgress = false;
                                    if (!err.data.logged) {
                                        hub.getLog('Schedule', 'error')('Unable to delete report schedule');
                                    }
                                });
                        }

                        function saveReportSchedule(data) {
                            vm.isInProgress = true;
                            api.post('api/reports/schedule', data)
                            .then(
                                function (f) {
                                    vm.isInProgress = false;
                                    vm.cancel();
                                    load();
                                },
                                function (err) {
                                    vm.isInProgress = false;
                                    if (!err.data.logged) {
                                        hub.getLog('Schedule', 'error')('Unable to save report schedule');
                                    }
                                });
                        }

                        function loadSchedule() {
                            vm.isInProgress = true;
                            api.get('api/reports/schedule/'+ report.id)
                                .then(function(data) {
                                    if(data) {
                                        var option = findScheduleTypeOption(data.scheduleType) || findScheduleTypeOption(ScheduleOptionsEnum.day);
                                        if(data.scheduleType == ScheduleOptionsEnum.oneTime) {
                                            vm.isOneTime = true;
                                            vm.scheduleType = ScheduleOptionsEnum.day;
                                        } else {
                                            vm.isOneTime = false;
                                            vm.scheduleType = option.value;
                                        }
                                        vm.emails = data.emails;
                                        var st = data.startDate.substring(0, data.startDate.length -1);
                                        var e = data.endsOnDate ? data.endsOnDate.substring(0, data.endsOnDate.length -1) : null;
                                        vm.startDate = moment(st).toDate();
                                        vm.endsOnDate = data.endsOnDate ? moment(e).toDate() : null;
                                        vm.repeatOnType = data.repeatOnType;

                                        vm.repeatEvery = data.repeatEvery || 1;
                                        vm.endsOn = data.endsOnDate == null ? EndsOnEnum.Never:
                                            data.endsOnOccurences ? EndsOnEnum.After : EndsOnEnum.On;
                                        vm.endsOnOccurences = vm.endsOn == EndsOnEnum.After ? data.endsOnOccurences : getDefaultOccurences(vm.endsOn);
                                        vm.existingSchedule = true;
                                        setWatchers();
                                        $timeout(function(){
                                            vm.isInProgress = false;
                                        }, 1500);
                                    }
                                }, function (err){
                                    vm.existingSchedule = false;
                                    authService.getUserInfo().then(function(res){
                                        vm.emails = res.email;
                                    });
                                    setWatchers();
                                    vm.isInProgress = false;
                                });
                        }
                        loadSchedule()

                        return vm;
                    }],
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        report: function () {
                            return report;
                        }
                    }
                });

                modalInstance.result.then(function () {

                }, function () {

                });
            }

            $scope.$watch('vm.keyword', function(keyword){
                vm.pageSelect(1, 'reports');
                vm.pageSelect(1, 'deliveries');
            });

            vm.pageSize = vm.config.pageSize();

            var getStartEnd = function(page) {
                var start = (page - 1) * parseInt(vm.pageSize);
                var end = start + parseInt(vm.pageSize);
                return {
                    start: start,
                    end: end
                };
            };

            vm.pageSizeChanged = function(size) {
                vm.pageSize = size;
                vm.pageSelect(1, 'reports');
                vm.pageSelect(1, 'deliveries');
            };

            vm.pageSelect = function (page, listName) {
                let end, start, se;

                let list = vm[listName];
                let orderedList = $filter('orderBy')(list, vm.sort[listName].column, vm.sort[listName].sortDesc);
                let fList = [];
                if(vm.keyword.length){
                  fList = $filter('reportFilter')(orderedList, vm.keyword);
                }else{
                  fList = orderedList;
                }

                if(listName.match(/reports/)){
                  vm.filtered_reports = fList;
                }else{
                  vm.filtered_deliveries = fList;
                }

                if(listName === 'deliveries') {
                    vm.hasExpired = _.filter(fList, function(item){
                        return item.isExpired;
                    }).length > 0;
                }

                se = getStartEnd(page);
                start = se.start;
                end = se.end;

                if(fList && fList.length < start) {
                    se = getStartEnd(--page);
                    start = se.start;
                    end = se.end;
                }
                if (fList) {
                    if (listName.match(/reports/)) {
                        vm.paged_reports = fList.slice(start, end);
                        vm.currentPage_reports = page;
                    } else {
                        vm.paged_deliveries = fList.slice(start, end);
                        vm.currentPage_deliveries = page;
                    }
                }
            };

            vm.sortFn = function (column, list) {
                if (vm.sort[list].column == column)
                    vm.sort[list].sortDesc = !vm.sort[list].sortDesc;
                else {
                    vm.sort[list].column = column;
                    vm.sort[list].sortDesc = false;
                }

                vm.pageSelect(vm['currentPage_' + list],list);
            }

            vm.loading = false;

            function load() {
              vm.loading = true;
              hub.api.get('api/reports/names')
                    .then(function(data) {
                        vm.reports = data.reports;
                        vm.deliveries = data.deliveries;
                        var applyData  = function(items) {
                            items.forEach(function (item) {
                                item.reportType = customReportService.reportTypes.find(function (x) { return x.type == item.reportTypeId; });
                                if (item.hasOwnProperty('nextDeliveryDate')) {
                                    if(!item.nextDeliveryDate) {
                                        item.nextDeliveryDate = '';
                                    }
                                }
                                if (item.hasOwnProperty('deliveredDate')) {
                                    if (!item.deliveredDate) {
                                        item.deliveredDate = '';
                                    }
                                }
                            });
                        }
                        applyData(vm.reports);
                        applyData(vm.deliveries);
                        vm.pageSelect(1, 'reports');
                        vm.pageSelect(1, 'deliveries');

                    })
                    .finally(function(){
                      vm.loading = false;
                    });
            }

            load();

            return vm;
        }]);


})();
