(function () {
    'use strict';

    /**
* @ngdoc function
* @name app.directive:dateRange
* @dateRange directive
* # <loading-message model="[View Model]" collection="[Collection name to track]"  />
* shows "loading ..." message or "no records found" depending on collection state. Loading is displayed when collection is null. No data - when collection is empty.
*/

    angular.module('app')
      .directive('loadingMessage', function () {
          return {
              template: require('@views/app/loading-message.html').default,
              restrict: 'E',
              scope: {model:'=', collection:'@', error:'@'},
              controller: ['$scope', function ($scope) {
                  var vm = this;
                  var collection = $scope.collection;
                  var error = $scope.error;
                  vm.isEmpty = false;
                  vm.isNull = true;
                  vm.hasError = false;
                  vm.model = $scope.model;

                  $scope.$watch("vm.model." + collection,
                    function handleChange(newValue, oldValue) {

                        vm.isNull = newValue == null;
                        vm.isEmpty = newValue != null && newValue.length == 0;

                    }
                  );

                  $scope.$watch("vm.model." + error,
                function handleChange(newValue, oldValue) {
                    if (newValue != oldValue) {
                        vm.hasError = newValue ==true;

                    }
                }
              );


                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });



})();
