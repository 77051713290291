(function () {
    'use strict';

    var serviceId = 'creativeService';
    angular.module('app').factory(serviceId, [creativeServiceFactory]);

    function creativeServiceFactory() {

        var service = {
            setCreativeAuditData: function(creative) {
                var selectedAdvertiserAttrIds = (creative.channelId == 1) ? _.chain(creative.provisionalAuditData.technicalAttributes.banner).filter({ checked: true }).map(function (a) { return a.id; }).value() : [];
                var selectedVideoAttrIds = (creative.channelId == 2) ? _.chain(creative.provisionalAuditData.technicalAttributes.video).filter({ checked: true }).map(function (a) { return a.id; }).value() : [];
                var selectedAnimatedAttrIds = _.chain(creative.provisionalAuditData.technicalAttributes.animated).filter({ checked: true }).map(function (a) { return a.id; }).value();
                var selectedLoadAttrIds = _.chain(creative.provisionalAuditData.technicalAttributes.load).filter({ checked: true }).map(function (a) { return a.id; }).value();
                var selectedMobileAttrIds = _.chain(creative.provisionalAuditData.technicalAttributes.mobileOptimized).filter({ checked: true }).map(function (a) { return a.id; }).value();
                var selectedSoundAttrIds = _.chain(creative.provisionalAuditData.technicalAttributes.sound).filter({ checked: true }).map(function (a) { return a.id; }).value();
                var selectedNativeAttrIds = _.chain(creative.provisionalAuditData.technicalAttributes.native).filter({ checked: true }).map(function (a) { return a.id; }).value();
                var selectedSensitiveCats = (creative.sensitiveCategoriesApply) ? _.chain(creative.provisionalAuditData.sensitiveCategories).filter({ checked: true }).map(function (a) { return a.id; }).value() : [];

                creative.technicalAttributes = selectedAdvertiserAttrIds.concat(selectedVideoAttrIds, selectedAnimatedAttrIds, selectedLoadAttrIds, selectedMobileAttrIds, selectedSoundAttrIds, selectedNativeAttrIds);
                creative.sensitiveCategories = selectedSensitiveCats;

            },
            fillSetAuditData: function (creative)
            {
                if (creative.technicalAttributes && creative.technicalAttributes.length > 0)
                {
                    _.forEach(creative.technicalAttributes, function (val) {

                        var element = _.find(creative.provisionalAuditData.technicalAttributes.banner, { 'id': val });
                        if (element)
                        {
                            element.checked = true;
                            return;
                        }

                        element = _.find(creative.provisionalAuditData.technicalAttributes.video, { 'id': val });
                        if (element)
                        {
                            element.checked = true;
                            return;
                        }
                        element = _.find(creative.provisionalAuditData.technicalAttributes.animated, { 'id': val });
                        if (element)
                        {
                            element.checked = true;
                            return;
                        }
                        element = _.find(creative.provisionalAuditData.technicalAttributes.load, { 'id': val });
                        if (element)
                        {
                            element.checked = true;
                            return;
                        }
                        element = _.find(creative.provisionalAuditData.technicalAttributes.mobileOptimized, { 'id': val });
                        if (element)
                        {
                            element.checked = true;
                            return;
                        }
                        element = _.find(creative.provisionalAuditData.technicalAttributes.sound, { 'id': val });
                        if (element)
                        {
                            element.checked = true;
                            return;
                        }
                        element = _.find(creative.provisionalAuditData.technicalAttributes.native, { 'id': val });
                        if (element) {
                            element.checked = true;
                            return;
                        }
                              
                    });
                }

                if (creative.sensitiveCategoriesApply && creative.sensitiveCategories && creative.sensitiveCategories.length > 0)
                {
                    _.forEach(creative.sensitiveCategories, function (val) {
                        var element = _.find(creative.provisionalAuditData.sensitiveCategories, { 'id': val });
                        if (element) {
                            element.checked = true;
                        }
                    });
                }
            },
            getCreativeStatusById: function (creativeStatusId) {
                var name = '';

                if (creativeStatusId === 0)
                    return "Draft";
                if (creativeStatusId >= 10 && creativeStatusId < 20)
                    return "In Review";
                if (creativeStatusId === 30)
                    return "Approved";
                if (creativeStatusId === 40)
                    return "Rejected";
                if (creativeStatusId === 50)
                    return "Active";
                if (creativeStatusId === 60)
                    return "Inactive";
                if (creativeStatusId === 70)
                    return "Removed";

                return name;
            },
            getCreativeStatusIconById: function (creativeStatusId) {
                var name = '';

                if (creativeStatusId === 0)
                    return "ti-eraser";//"Draft";
                if (creativeStatusId >= 10 && creativeStatusId < 20)
                    return "ti-lock";//"In Review";
                if (creativeStatusId >= 20 && creativeStatusId < 30)
                    return "ti-check";//"TDM Approved";
                if (creativeStatusId === 30)
                    return "ti-check-box";//"Approved";
                if (creativeStatusId === 40)
                    return "ti-flag";//"Rejected";
                if (creativeStatusId === 50)
                    return "ti-signal";//"Active";
                if (creativeStatusId === 60)
                    return "ti-signal";//"Inactive";
                if (creativeStatusId === 70)
                    return "ti-archive";//"Removed";

                return name;
            },
            getFacebookAdFormats: function () {
                return [
                    { id: 1, name: 'Desktop News Feed' },
                    { id: 2, name: 'Mobile News Feed' },
                    { id: 3, name: 'Feature Phone' },
                    { id: 4, name: 'Instant Articles' }
                ];
            },
            getFacebookPreviewAdFormats: function () {
                return [
                    { id: 1, name: 'Desktop News Feed', value: 'DESKTOP_FEED_STANDARD' },
                    { id: 2, name: 'Mobile Feed', value: 'MOBILE_FEED_STANDARD' },
                    { id: 3, name: 'Mobile Native', value: 'MOBILE_NATIVE' },
                    { id: 4, name: 'Instant Article', value: 'INSTANT_ARTICLE_STANDARD' },
                    { id: 5, name: 'Mobile Messenger Inbox', value: 'MESSENGER_MOBILE_INBOX_MEDIA' }
                ];
            },
            getCallToActions: function () {
                return [
                    { id: 2, value: 'CONTACT_US', text: 'Contact Us' },
                    { id: 4, value: 'LEARN_MORE', text: 'Learn More' },
                    { id: 5, value: 'SHOP_NOW', text: 'Shop Now' },
                    { id: 6, value: 'SIGN_UP', text: 'Sign Up' },
                ];
            }

        };
        return service;

    }
})();