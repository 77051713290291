(function () {
    'use strict';
    var serviceId = 'changeHistory';
    angular.module('app').factory(serviceId, ['api', changeHistoryFactory]);
    function changeHistoryFactory(api) {
        const stateEnum = {
            added: 'added',
            removed: 'removed',
            changed: 'changed'
        };
        const service = {
            getAdgroup: function (adgroupId, sourceTypes, triggerEngagements) {
                return api.get(`api/adgroup/history/${adgroupId}`).then((data) => {
                    const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
                    const audiences = data.audiences;
                    const allCreatives = data.creatives;
                    const allFlights = data.flights;
                    var count = 0;
                    const firstIndex = data.historyRecords.length - 1;
                    for (let idx = firstIndex; idx > -1; idx--) {
                        const hr = data.historyRecords[idx];
                        if (hr.flights) {
                            hr.fflights = JSON.parse(hr.flights);
                            const curFlights = hr.fflights.map(f => {
                                const delta = (flight) => Math.abs(new Date(flight.updatedDate).valueOf() - new Date(hr.updatedDate).valueOf());
                                const minDelta = Math.min(...allFlights.filter(f1 => f1.id == f.id)
                                    .map(f1 => delta(f1)));
                                const budgetCap = allFlights.find(f1 => f1.id === f.id && minDelta == delta(f1)).budgetCap;
                                return ({
                                    id: f.id,
                                    adGroupId: adgroupId,
                                    budgetCap,
                                    budgetType: f.budget_type,
                                    startDate: f.start_date,
                                    endDate: f.end_date,
                                    statusId: !!f.start_date ? 0 : 1
                                });
                            });
                            if (idx === firstIndex) {
                                hr.flights = curFlights;
                            }
                            else {
                                const nextHr = data.historyRecords[idx + 1];
                                hr.flights = [...curFlights.filter(x => x.statusId == 0),
                                    ...nextHr
                                        .flights
                                        .filter(x => x.statusId == 0 &&
                                        curFlights.find(cf => cf.statusId === 1 && cf.id == x.id))
                                        .map(x => ({ ...x, statusId: 1 }))];
                            }
                        }
                    }
                    const historyRecords = data
                        .historyRecords
                        .map((hr, index) => {
                        let segmentGroups = null, brandSafetySegments = null, conversionPixels = null;
                        const isStatusChange = hr.budgetCap == null;
                        if (isStatusChange) {
                            const srcHr = data.historyRecords.slice(index).find(x => x.budgetCap != null);
                            hr = {
                                ...hr,
                                ...srcHr,
                                email: hr.email,
                                updatedDate: hr.updatedDate,
                                srcUpdatedDate: srcHr.updatedDate,
                                status: hr.status
                            };
                        }
                        let locations = [];
                        let geoTargets = null;
                        const segments = [];
                        let creatives = [];
                        if (hr.locationJson) {
                            locations = JSON.parse(hr.locationJson).map(x => ({
                                ...x,
                                excluded: !!+x.excluded || false
                            }));
                        }
                        let recency = '';
                        let flights = [];
                        if (hr.segmentGroups) {
                            segmentGroups = JSON.parse(hr.segmentGroups);
                            segmentGroups.forEach(sg => {
                                const { boolean_operator } = sg;
                                segments.push(...sg.segments.map((s, idx) => {
                                    const audience = audiences.find(a => a.id == s.audience_id);
                                    return {
                                        ...s,
                                        name: audience && audience.name || 'Unknown Segment',
                                        boolean_operator: idx > 0 ? 'AND' : boolean_operator
                                    };
                                }));
                            });
                        }
                        if (hr.brandSafetySegments) {
                            brandSafetySegments = JSON.parse(hr.brandSafetySegments)
                                .map(bss => {
                                const audience = audiences.find(x => x.id == bss.audience_id);
                                return {
                                    ...bss,
                                    name: audience && audience.name || 'Unknown Brand Safety'
                                };
                            });
                        }
                        if (hr.conversionPixels) {
                            conversionPixels = JSON.parse(hr.conversionPixels)
                                .map(cp => {
                                const audience = audiences.find(a => a.id == cp.audience_id);
                                return {
                                    ...cp,
                                    name: audience && audience.name || 'Unknown Segment',
                                };
                            });
                        }
                        let { frequencyCap } = hr;
                        if (frequencyCap) {
                            frequencyCap = JSON.parse(frequencyCap);
                            if (frequencyCap) {
                                const { min_minutes_per_imp } = frequencyCap;
                                if (min_minutes_per_imp > 0) {
                                    const monthMinutes = 30 * 24 * 60;
                                    const weeksMinutes = 7 * 24 * 60;
                                    const daysMinutes = 24 * 60;
                                    const hoursMinutes = 60;
                                    let period = '';
                                    let impPerPeriod = null;
                                    if (min_minutes_per_imp > monthMinutes && (min_minutes_per_imp / monthMinutes) % 1 === 0) {
                                        impPerPeriod = min_minutes_per_imp / monthMinutes;
                                        period = 'Months';
                                    }
                                    else if (min_minutes_per_imp > weeksMinutes && (min_minutes_per_imp / weeksMinutes) % 1 === 0) {
                                        impPerPeriod = min_minutes_per_imp / weeksMinutes;
                                        period = 'Weeks';
                                    }
                                    else if (min_minutes_per_imp > daysMinutes && (min_minutes_per_imp / daysMinutes) % 1 === 0) {
                                        impPerPeriod = min_minutes_per_imp / daysMinutes;
                                        period = 'Days';
                                    }
                                    else if (min_minutes_per_imp > hoursMinutes && (min_minutes_per_imp / hoursMinutes) % 1 === 0) {
                                        impPerPeriod = min_minutes_per_imp / hoursMinutes;
                                        period = 'Hours';
                                    }
                                    else {
                                        impPerPeriod = min_minutes_per_imp;
                                        period = 'Minutes';
                                    }
                                    recency = `1 Imp Per ${impPerPeriod} ${period}`;
                                }
                            }
                        }
                        let { valuation } = hr;
                        if (valuation) {
                            valuation = JSON.parse(valuation);
                            let { goal_type, goal_target } = valuation;
                            if (goal_target && goal_type) {
                                goal_target = parseFloat(goal_type == 'ctr' ? 100 * goal_target : goal_target).toFixed(2);
                                valuation = `Optimize to a ${(goal_type == 'ctr' ? '%' : '$')} ${goal_target} ${goal_type}`;
                            }
                            else {
                                valuation = 'none';
                            }
                        }
                        if (hr.creativeIds) {
                            hr.creativeIds = JSON.parse(hr.creativeIds);
                            creatives = allCreatives.filter(c => hr.creativeIds.includes(c.id));
                        }
                        if (hr.sourceTypeId) {
                            const sourceType = sourceTypes.find(st => st.id == hr.sourceTypeId);
                            if (sourceType)
                                hr.sourceType = sourceType.name;
                        }
                        if (hr.triggerEngagementId) {
                            const triggerEngagement = triggerEngagements.find(st => st.id == hr.triggerEngagementId);
                            if (triggerEngagement)
                                hr.triggerEngagement = triggerEngagement.name;
                        }
                        hr.lassoTrigger = hr.dynamicSequentialMessaging ? 'Active' : 'Inactive';
                        return {
                            ...hr,
                            status: _.capitalize(hr.status),
                            recency,
                            maxLifeTimeImps: frequencyCap.max_lifetime_imps_flag ? frequencyCap.max_lifetime_imps : 'Disabled',
                            maxWeekImps: frequencyCap.max_week_imps_flag ? frequencyCap.max_week_imps : 'Disabled',
                            maxDayImps: frequencyCap.max_day_imps_flag ? frequencyCap.max_day_imps : 'Disabled',
                            maxHourImps: frequencyCap.max_hour_imps_flag ? frequencyCap.max_hour_imps : 'Disabled',
                            maxMonthImps: frequencyCap.max_month_imps_flag ? frequencyCap.max_month_imps : 'Disabled',
                            valuation,
                            creatives: deepClone(creatives),
                            segmentGroups: deepClone(segmentGroups),
                            locations: deepClone(locations),
                            segments: deepClone(segments),
                            brandSafetySegments: deepClone(brandSafetySegments),
                            conversionPixels: deepClone(conversionPixels),
                            flights: deepClone(hr.flights)
                        };
                    });
                    for (let idx = historyRecords.length - 2; idx >= 0; idx--) {
                        if (!historyRecords[idx].flights || historyRecords[idx].flights.length == 0) {
                            historyRecords[idx].flights = deepClone(historyRecords[idx + 1].flights);
                        }
                    }
                    return this.compare(historyRecords);
                });
            },
            buildSegments: function (previous, current, key, id) {
                if (!previous[key]) {
                    previous[key] = [];
                }
                if (!current[key]) {
                    current[key] = [];
                }
                const prevSegments = previous[key].filter(x => x.historyStatus != stateEnum.removed);
                const curSegments = current[key];
                for (var index = 0; index < curSegments.length; index++) {
                    const segment = curSegments[index];
                    const prevSegment = prevSegments.find(x => x[id] == segment[id]);
                    if (prevSegment == null) {
                        segment.historyStatus = stateEnum.added; // added
                    }
                    else {
                        segment.prev = {
                            ...prevSegment,
                            is_internal: prevSegment.is_internal,
                            excluded: prevSegment.excluded || false,
                            historyStatus: stateEnum.changed
                        };
                    }
                }
                const ids = curSegments.map(s => s[id]);
                const removed = prevSegments.filter(x => ids.indexOf(x[id]) == -1);
                current[key].push(...removed.map(x => ({ ...x, historyStatus: stateEnum.removed })));
            },
            buildCreatives: function (previous, current) {
                const prevCreatives = (previous.creatives || []).filter(x => x.historyStatus != stateEnum.removed);
                const curCreatives = current.creatives || [];
                for (var index = 0; index < curCreatives.length; index++) {
                    const creative = curCreatives[index];
                    const prevCreative = prevCreatives.find(x => x.id == creative.id);
                    if (prevCreative == null) {
                        creative.historyStatus = stateEnum.added;
                    }
                }
                const ids = curCreatives.map(s => s.id);
                const removed = prevCreatives.filter(x => ids.indexOf(x.id) == -1);
                current.creatives.push(...removed.map(x => ({ ...x, historyStatus: stateEnum.removed })));
            },
            buildFlights: function (previous, current) {
                const prevFlights = (previous.flights || []).filter(x => x.historyStatus != stateEnum.removed);
                const curFlights = current.flights || [];
                for (var index = 0; index < curFlights.length; index++) {
                    const flight = curFlights[index];
                    if (flight.statusId == 1)
                        flight.historyStatus = stateEnum.removed;
                    const prevFlight = prevFlights.find(x => x.id == flight.id);
                    if (prevFlight == null) {
                        flight.historyStatus = stateEnum.added;
                    }
                    else {
                        flight.prev = prevFlight;
                    }
                }
            },
            buildLocations: function (previous, current) {
                const prevLocations = (previous.locations || []).filter(x => x.historyStatus != stateEnum.removed);
                const curLocations = current.locations || [];
                for (var index = 0; index < curLocations.length; index++) {
                    const loc = curLocations[index];
                    const prevLocation = prevLocations.find(x => x.id == loc.id);
                    if (prevLocation == null) {
                        loc.historyStatus = stateEnum.added;
                    }
                    else {
                        loc.prev = prevLocation;
                    }
                }
                const ids = curLocations.map(s => s.id);
                const removed = prevLocations.filter(x => ids.indexOf(x.id) == -1);
                current.locations.push(...removed.map(x => ({ ...x, historyStatus: stateEnum.removed })));
            },
            compare: function (historyRecords) {
                historyRecords.reverse();
                for (var index = 1; index < historyRecords.length; index++) {
                    const previous = historyRecords[index - 1];
                    const current = historyRecords[index];
                    this.buildSegments(previous, current, "brandSafetySegments", "audience_id");
                    this.buildSegments(previous, current, "segments", "audience_id");
                    this.buildSegments(previous, current, "conversionPixels", "audience_id");
                    this.buildLocations(previous, current);
                    this.buildCreatives(previous, current);
                    this.buildFlights(previous, current);
                    current.prev = previous;
                }
                historyRecords.reverse();
                return historyRecords;
            }
        };
        return service;
    }
})();
