(function () {
    'use strict';

    var serviceId = 'dateRangeService';
    angular.module('app').factory(serviceId, ['api', 'appCache','hub', '$timeout', '$window',  dateRangeService]);

    function dateRangeService(api, appCache, hub, $timeout, $window) {

        var thisLastPeriodReg = /(This|Last)([a-z,A-Z]+)/;
        var lastXDaysReg = /Last(\d\d?)Days/;
        var service = {
           
            getPeriodDelta: function(key) {
                var delta = 0;
                var period = null;
                var lastDays = false;
                if (thisLastPeriodReg.test(key)) {
                    var match = key.match(thisLastPeriodReg);
                    if(match[1] == 'This') {
                        delta = 0;

                    } else {
                        delta = -1;
                    }
                    period = match[2].toLowerCase();
                } else if (lastXDaysReg.test(key)) {
                    period = 'day'
                    delta = (-1)*parseInt(key.match(lastXDaysReg)[1]),
                    lastDays = true;
                }
                return { delta: delta, period: period, lastDays: lastDays };
            },
            getRange: function(key){
                var delta = 0;
                var period = null;
                var lastDays = false;
                if(/day$/i.test(key)) {
                    if(key == 'Yesterday') {
                        delta = -1;
                    } else {
                        delta = 0;
                    }
                    period = 'day'
                } else {
                    var tmp = this.getPeriodDelta(key);
                    delta = tmp.delta;
                    period = tmp.period;
                    lastDays = tmp.lastDays;
                }
                var end = null;
                var start = moment().add(delta, period).startOf(period);
                if (lastDays) {
                    end = moment().endOf(period);
                }
                else {
                    end = start.clone().endOf(period);
                }
                return {
                    start: start,
                    end: end
                }
            },
            isSubrange: function(range, subrange){
                var rs1 = range.start;
                var re1 = range.end;
                var rs2 = moment(subrange.start);
                var re2 = moment(subrange.end);
                return (re1.valueOf() - re2.valueOf() >= 0) && (rs2.valueOf() - rs1.valueOf() >= 0);
            }
        };
        return service;

    }
})();