(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:dateRange
 * @dateRange directive
 * # <date-range  />
 * shows data range dropdown list (sample)
 */

    angular.module('app')
      .directive('controlLabel', function () {
          return {
              template: require('@views/app/control-label.html').default,
              restrict: 'E',
              scope: {title: '@', description: '@', $class:'@', section: '@', key: '@', requiredField: '@'},
              controller: ['$scope', 'resourceService', function ($scope, resourceService) {
                  var vm = this;

                  vm.title = $scope.title;
                  vm.class = $scope.class;
                  vm.description = $scope.description;
                  vm.required = $scope.requiredField;
                  if ($scope.section && $scope.key) {
                    var resource = resourceService.get($scope.section, $scope.key);

                    vm.description = resource.description;
                    vm.title = resource.title;
                  }

                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });



})();
