(function () {
    'use strict';

    angular.module('app')
        .controller('editCreativeController', EditCreativeController);

    EditCreativeController.$inject = ['$scope', '$uibModalInstance', '$sce', '$timeout', 'data', 'hub', 'validationService', 'authService', 'viewMode', 'creativeService', '$location'];

    function EditCreativeController($scope, $modalInstance, $sce, $timeout, data, hub, validationService, authService, viewMode, creativeService, $location) {
        var vm = this;
        $scope.vm = vm;
        vm.justUploaded = data.justUploaded;
        vm.viewMode = viewMode;

        var clickURLPattern = /https?:\/\/.+/;
        vm.creative = vm.justUploaded ? data.creative : angular.copy(data.creative);
        vm.isCreativePage = true;
        vm.recreateCommandPosted = false;
        vm.resetURLValidation = resetURLValidation;
        vm.validateClickURL = validateClickURL;
        vm.actionsAvailable = vm.isAdmin || ((!vm.creative.owner && (vm.isCampaignManager || vm.isAdmin || vm.isCreativeEditor))
            || (vm.creative.owner === "Advertiser" && vm.isCampaignManager)
            || (vm.creative.owner === "CreativeTeam" && vm.isCreativeEditor));
        vm.hasAccess = authService.hasAccess;
        vm.isObserver = authService.isObserver;
        vm.userAccountType = authService.userAccountType;
        vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('creatives', 'Manage');

        $scope.cancel = cancel;
        $scope.canSubmit = canSubmit;
        $scope.ok = ok;
        $scope.next = next;
        vm.refreshThumbnail = refreshThumbnail;
        vm.removeJustUploaded = removeJustUploaded;

        function cancel(cancelClicked) {
            vm.cancelClicked = cancelClicked;
            $modalInstance.dismiss('cancel');
        };

        vm.allowSendToCreativeTeam = function () {
            return vm.creative.availableActions.indexOf('SendToCreativeTeam') > -1;
        }

        vm.allowTdmApprove = function () {
            return vm.creative.availableActions.indexOf('TDMApprove') > -1;
        }

        vm.allowSendBackToAdvertiser = function () {
            return vm.creative.notifiedCampaignManagers && vm.creative.availableActions.indexOf('SendBackToAdvertiser') > -1;
        }

        vm.allowRestore = function () {
            return vm.creative.creativeStatusId === 70;
        }

        vm.allowSendFeedback = function () {
            return vm.creative.availableActions.indexOf('SendFeedback') > -1;
        }

        vm.allowSendToAdvertiser = function () {
            return vm.creative.notifiedCampaignManagers && vm.creative.availableActions.indexOf('SendToAdvertiser') > -1;
        }

        vm.allowAdvertiserApprove = function () {
            return vm.creative.availableActions.indexOf('AdvertiserApprove') > -1;
        }

        vm.allowSendForReview = function () {
            return vm.creative.availableActions.indexOf('SendForReview') > -1;
        }

        vm.allowSendToAdmin = function () {
            return vm.creative.availableActions.indexOf('SendToAdmin') > -1;
        }

        vm.allowResubmit = function () {
            return vm.creative.availableActions.indexOf('Resubmit') > -1;
        }

        vm.allowSave = function () {
            return (!vm.creative.id || ([0, 30, 50, 60].indexOf(vm.creative.creativeStatusId) !== -1 && vm.hasAccess('creatives', 'Manage', vm.creative)));
        }

        function canSubmit() {
            var creativeValid = false;

            if (typeof this.$parent.creativeForm !== 'undefined')
                creativeValid = this.$parent.creativeForm.$valid && validationService.validateCreative(vm.creative);

            return creativeValid;
        };

        function next() {
            var clickURLValid = !vm.creative.destinationURL || clickURLPattern.test(vm.creative.destinationURL) && CheckUrl(vm.creative.destinationURL);

            var cardsValidationSummary = validationService.validateCreativeSlides(vm.creative);
            vm.creative.cardsInvalid = !cardsValidationSummary.valid;
            vm.creative.cardsInvalidMessage = cardsValidationSummary.errorMessage;

            validateClickURL(clickURLValid);

            if (typeof this.creativeForm !== 'undefined') {
                var creativeValid = this.creativeForm.$valid && validationService.validateCreative(vm.creative) && clickURLValid;
                if (!creativeValid) {
                    vm.creative.validation = true;
                    $('#creativeForm').validator('destroy');
                    $('#creativeForm').validator('validate');
                    $timeout(function () {
                        $('#bannerValidation').trigger('input');
                    });
                    return;
                }
            }
            else {
                vm.resetURLValidation();
                return;
            }

            vm.creative.canvasCollectionStep = 2;
        }

        function CheckUrl(url) {
            return url.startsWith("http://") || url.startsWith("https://");
        }

        function ok(newAction) {
            if (vm.creative.channelId != 6 || confirm("Saving this creative will incur an Email Template charge for your account. Would you like to proceed?")) {

                var startTime = new Date();
                var clickURLValid = !vm.creative.destinationURL || clickURLPattern.test(vm.creative.destinationURL) && CheckUrl(vm.creative.destinationURL);

                var cardsValidationSummary = validationService.validateCreativeSlides(vm.creative);
                vm.creative.cardsInvalid = !cardsValidationSummary.valid;
                vm.creative.cardsInvalidMessage = cardsValidationSummary.errorMessage;
                validateClickURL(clickURLValid);

                if (typeof this.creativeForm !== 'undefined') {
                    var creativeValid = this.creativeForm.$valid && validationService.validateCreative(vm.creative) && clickURLValid;
                    if (!creativeValid) {
                        vm.creative.validation = true;
                        $('#creativeForm').validator('destroy');
                        $('#creativeForm').validator('validate');
                        $timeout(function () {
                            $('#bannerValidation').trigger('input');
                        });
                        return;
                    }
                }
                else {
                    vm.resetURLValidation();
                    return;
                }

                if (newAction)
                    vm.creative.action = newAction;

                vm.isInProgress = true;
                if (!vm.creative.id) {
                    creativeService.setCreativeAuditData(vm.creative);
                    vm.creative.selectedItemsJSON = JSON.stringify(vm.creative.selectedItems);
                    hub.webapicontext.saveCreative(vm.creative).then(function (d) {
                        logEvent(vm.creative, startTime);
                        vm.isInProgress = false;
                        $modalInstance.close({ 'creative': d, 'files': $scope.files, 'refreshThumbnail': false });
                    });
                }
                else {
                    creativeService.setCreativeAuditData(vm.creative);
                    hub.webapicontext.saveCreative(vm.creative).then(function (d) {
                        logEvent(vm.creative, startTime);
                        vm.isInProgress = false;
                        $modalInstance.close({ 'creative': d, 'files': $scope.files, 'refreshThumbnail': false });
                    });
                }
            }
        };

        function logEvent(entity, startTime) {
            var data = { id: entity.id, name: entity.name };
            authService.trackEvent({ action: 'SaveCreative', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
        }

        function refreshThumbnail() {

            vm.recreateCommandPosted = true;
            hub.webapicontext.refreshThumbnail(vm.creative.id).then(function () {

                hub.getLog('Creatives', 'info')('Thumbnail refresh scheduled for creative ' + vm.creative.name, '', true, true);

                $modalInstance.dismiss('refresh-thumbnail');
            });


        };

        function removeJustUploaded() {
            data.removeJustUploaded(vm.creative);
            $modalInstance.dismiss('just-uploaded-removed');
        }

        function resetURLValidation() {
            $('.clickURL').removeClass('has-error has-danger');
            $('.invalidURL').hide();
        }

        function validateClickURL(clickURLValid) {
            if (!clickURLValid) {
                if (!vm.creative.destinationURL) {
                    $('.invalidURL').hide();
                } else {
                    $('.clickURL').addClass('has-error has-danger');
                    $('.invalidURL').show();
                }
            }
        }

        $scope.$on('modal.closing', function (event, reason, closed) {
            if (!vm.showCaution && reason == 'cancel' && event.currentScope.creativeForm.$dirty && vm.cancelClicked) {
                $timeout(function () {
                    vm.showCaution = true;
                });
                event.preventDefault();
            }
        });

        $scope.$on('modal.force.close', function (event) {
            $modalInstance.dismiss('cancel');
        });

        $scope.$watch('vm.creative.destinationURL', function () {
            vm.resetURLValidation();
        });
    };
})();
