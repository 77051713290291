(function () {
    'use strict';

    const roundNumber = function (_this) {
      return _this > 0.1 || _this < -0.1
        ? Math.round(_this * 100) / 100
        : Math.round(_this * 10000) / 10000;
    }

    const moneyFormat = function (_this, n, s) {
      var x = 3;
      var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
      return (
        (s ? s : "") +
        _this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,")
      );
    }

    /**
 * @ngdoc function
 * @name app.directive:datePoints
 * # <date-points  />
 * shows list of items with numeric values. Required configuration object - an array of name, field and format values and data object.
 */

    angular.module('app')
      .directive('datapoints', function () {
          return {
              template: require('@views/app/data-points.html').default,
              restrict: 'E',
              scope: {config:'=', data:'='},
              controller: ['$scope', function ($scope) {
                  var vm = this;

                  vm.config = $scope.config;
                  vm.data = $scope.data;

                  vm.hasData=function()
                  {
                      return vm.data != null;
                  }

                  vm.getData = function(point)
                  {
                      if (vm.data[point.field] == null)
                          return 'n/a';
                      else
                      {
                          var value = vm.data[point.field];
                          if (point.format === '%')
                              return roundNumber(value * 100) + '%';


                          if(value>1000000)
                          {
                              if (point.format === '$')
                                  return '$' + moneyFormat(value / 1000000) + 'MM';
                              return roundNumber(value / 1000000) + 'MM';
                          }
                          else if (value > 1000) {
                              if (point.format === '$')
                                  return '$' + moneyFormat(value / 1000) + 'K';
                              return roundNumber(value / 1000) + 'K';
                          }
                          if (point.format === '$')
                              return '$' + moneyFormat(value);

                          return roundNumber(value);
                      }
                  }



                  $scope.$watch('data', function (value) {
                      vm.data = value;
                  });


                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });


})();
