// Loads data from json files in angularjs/data folder
// TODO: just import this statically
angular.module('app').factory('datacontext', [
  '$q', '$http', 'appCache',
  ($q, $http, appCache) => {

    async function getLocalJson(name) {
      const data = appCache.get(name);

      if (data) {
        return data
      }

      const res = await $http.get(getJsonUrl(name))
      appCache.put(name, res.data);
      return res.data
    }

    function getJsonUrl(name) {
      const link = `${__webpack_public_path__}data/${name}.json?v=${process.env.HASH}`;
      return link.startsWith('/') ? link.slice(1) : link;
    }

    return {
      getLocalJson: getLocalJson,
    };
  }
]);
