(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:filter
 * @filter directive
 * # <filter  />
 */

    angular.module('app')
      .directive('filter', function () {
          return {
                template: require('@views/app/filter.html').default,
                restrict: 'E',
                scope: {
                    advertisers:    '=',
                    accountManagers:'=',
                    filterViewModel:'=',
                    channels:       '=',
                    liveOnlyFilter: '=',
                    marketers:      '=',
                    statuses:       '=',
                    type:           '@',
                    showFilter:     '=',
                    cancel:         '&',
                    apply:          '&',
                    filtersOn:      '&',
                    clearFilters:   '&',
                    checkAll:       '&',
                    filterChecked:  '&'
                },
                controller: ['$scope', function ($scope) {
                    var vm = this;
                    vm.type = $scope.type;
                    vm.showFilter = $scope.showFilter;
                    
                    vm.toggleLive = function() {
                        vm.liveOnlyFilter = !vm.liveOnlyFilter;
                        $scope.$root.$broadcast('liveOnlyChange', vm.liveOnlyFilter);
                    }

                    vm.checkAll = function(list, name) {
                        $scope.checkAll()(list, name);
                    }
                    vm.filterChecked = function(item, list, filterModelList) {
                        $scope.filterChecked()(item, list, filterModelList);
                    }

                    vm.cancel = function() {
                        vm.showFilter = false;
                        $scope.cancel();
                    }

                    vm.apply = function() {
                        vm.showFilter = false;
                        $scope.apply();
                    }

                    $scope.$watch('marketers', function(value){
                        vm.marketers = value;
                    });

                    $scope.$watch('advertisers', function(value){
                        vm.advertisers = value;
                    });

                    $scope.$watch('channels', function(value){
                        vm.channels = value;
                    });

                    $scope.$watch('filterViewModel', function(value){
                        vm.filterViewModel = value;
                    });

                    $scope.$watch('statuses', function(value){
                        vm.statuses = value;
                    });

                    $scope.$watch('accountManagers', function(value){
                        vm.accountManagers = value;
                    });

                    $scope.$watch('liveOnlyFilter', function(value){
                        vm.liveOnlyFilter = value;
                    });
                    $scope.$watch('showFilter', function(value){
                        vm.showFilter = value;
                    });
                    return vm;
                }]
                ,
                replace: true,
                controllerAs: 'vm'
          }
      });
})();