import { navigateToAppUrl } from '../utils/navigation';

(function () {
    'use strict';

    angular.module('app').run([
        '$rootScope',
        '$location',
        '$injector', 'authSettings', 'page', 'authService',
        function ($rootScope, $location, $injector, authSettings, page, authService) {
            $rootScope.$on('$routeChangeStart', async function (event, next) {
                var authorised;
                var serv = $injector.get('authService');
                var settings = $injector.get('appSettings');

                //redirect to change password if user is a first time
                if (serv.authentication && serv.authentication.firstTimeUser && next.originalPath != '/pages/change-password')
                {
                    $location.path('pages/change-password');
                    return;
                }

                //allow not logged in user to access change password page if he has code and userId parameters
                if (!serv.isLoggedIn() && next.originalPath && next.originalPath.indexOf('/pages/change-password') >= 0 && $location.search().code && $location.search().id)
                {
                    return;
                }

                var accountService = $injector.get('accountService');

                if (!next.originalPath || next.originalPath.indexOf('/activation/campaign') == -1)
                    page.setSidebarMode('');

                //no template yes - bypass
                if (!next.originalPath || next.originalPath == '' || next.originalPath == '/')
                    return;
                if ($rootScope.rootVM) {

                    //handle custom case: campaign wizard. It won't work when path has some custom additions
                    if ($rootScope.rootVM.newCampaignMode && settings.menus.paths.indexOf(next.originalPath.toLowerCase()))
                        $rootScope.rootVM.menu = settings.menus.newcampaign.values;
                    else {
                        var segment = next.originalPath.split('/')[1].toLowerCase();
                        //map menu to url paths
                        var menuMap = settings.menuMap.find(function (item) { return item.paths.indexOf(segment) >= 0; });
                        if (menuMap)
                        $rootScope.rootVM.menu = accountService.hideMenus(settings.menus[menuMap.menu].values);
                        else if (settings.menus[segment] != null) {
                            $rootScope.rootVM.menu = accountService.hideMenus(settings.menus[segment].values);

                        }
                    }
                    localStorage.setItem("appMenu", JSON.stringify($rootScope.rootVM.menu));

                }

                if (authSettings.security.authorize && serv.isLoggedIn() && next.originalPath == 'pages/signin') {
                    $location.path('');
                    return;
                }

                //bypass exclude
                if (authSettings.security.exclude.indexOf(next.originalPath.toLowerCase()) >= 0)
                    return;
                //send user to login is he is not logged
                if (authSettings.security.authorize && !serv.isLoggedIn()) {
                  authService.setReturnLink()
                  authService.logOut()
                  return;
                }

                var path = next.originalPath.substring(1);
                //remove leading and trailing /
                if (path.substring(path.length-1) == '/')
                    path = path.substring(0, path.length - 1);

                if(
                  serv.userAccountType()
                  && !path.startsWith('v2/')
                  && !path.startsWith('app/')
                  && !path.startsWith("pages/")
                  && path!=='settings/account-settings'
                  && !window.location.pathname.includes('/print.html')
                ) {
                    authorised = serv.hasAccess(authSettings.security.paths[path], 'List');
                    if (!authorised) {
                        $location.path('pages/403');
                        return;
                    }
                }

                if (path === 'reporting') {
                    const isV2ReportingEnabled = serv.hasFeature('REPORTING-2')
                    const tab = next.params.tab

                    if (isV2ReportingEnabled && !tab) {
                      navigateToAppUrl('app/reporting')
                    }
                }
            });
        }]);

})();
