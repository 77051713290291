(function () {
    'use strict';

    var serviceId = 'copyService';
    angular.module('app').factory(serviceId, [copyServiceFactory]);

    function copyServiceFactory() {

        var service = {
            copy: function(val) {
                var el = document.createElement('textarea');
                el.value = val;                    
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                return val;
            }
        };
        return service;

    }
})();