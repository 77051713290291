(function () {
    'use strict';

    var serviceId = 'userSettingsService';
    angular.module('app').factory(serviceId, ['api', 'appCache','logger', '$timeout', '$window', 'featureService', userSettingsServiceFactory]);

    function userSettingsServiceFactory(api, appCache, logger, $timeout, $window, featureService) {

        var service = {
            getFullBasket: function () {
                var fc = appCache.getPermanent('isFullBasket');

                return fc;
            },
            multiTouchMode: null,
            multiTouchModeName: 'Initializing',
            setMta: function(mtaMode) {
                this.multiTouchMode = mtaMode || false;
                this.multiTouchModeName = mtaMode ? 'Multi Touch' : 'Last Touch';
            },
            fullBasketColumns: function () {
                return ['FOP', 'FOPA', 'FFP', 'FFPA', 'FVOP', 'FVOPA', 'FVFP', 'FVFPA', 'FCOP', 'FCOPA', 'FCFP', 'FCFPA', 'FVP', 'FVPA', 'FCP', 'FCPA', 'FVROAS', 'FCROAS', 'FROAS', 'FTP', 'FTPA', 'FTAS', 'FTIS', 'FTROAS', 'FFAS', 'FFASIS', 'FINS','FIROAS'];}
            ,
            toggleIsFullBasket : function () {

                if (!featureService.isActive('FULL-BSKT-TGL'))
                    return;
                api.post('api/user/ToggleFullBasket').then(function (result) {
                    //save auth
                    appCache.putPermanent('isFullBasket', result.isFullBasket);
                    logger.getLogFn('fullBasket')('Full Basket mode: ' + (result.isFullBasket ? 'Enabled' : 'Disabled') + '. Page will be reloaded in 5 seconds');
                    $timeout(function () {
                        $window.location.reload();
                    }, 5000);
                });
            }


        };
        return service;

    }
})();
