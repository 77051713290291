(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:flight
 * @dateRange directive
 * # <date-range  />
 * shows ad group flight
 */

    angular.module('app')
        .directive('flight', function () {
            return {
                template: require('@views/campaign/editors/flight.html').default,
                controllerAs: 'vm',
                restrict: 'E',
                scope: { model: '=', adGroup: '=', campaign: '=', index: '=' },
                controller: ['$scope', '$rootScope', '$timeout', 'timezoneService', 'validationService', '$filter', function ($scope, $rootScope, $timeout, timezoneService, validationService, $filter) {
                    var vm = this;
                    vm.flight = $scope.model;
                    vm.adGroup = $scope.adGroup;
                    vm.campaign = $scope.campaign;

                    vm.flight.pacingPercent = vm.flight.pacingPercent ? vm.flight.pacingPercent : 100;

                    vm.min = 0;

                    vm.dateDiff = dateDiff;
                    vm.datepickers = { startDate: false, endDate: false };
                    vm.openDatepicker = openDatepicker;
                    vm.hours = ["1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00"];
                    vm.meridiems = ["AM", "PM"];
                    const processDatepickerDate = timezoneService.processDatepickerDate;

                    var m = moment.utc();
                    vm.currentEastTimeAbbreviation = m.tz('America/New_York').format('z');
                    var now = new Date();
                    var offset = -5.00;
                    if (vm.currentEastTimeAbbreviation === "EDT")
                        offset = -4.00;
                    var tzDifference = offset * 60 + now.getTimezoneOffset();
                    vm.minDate = now;
                    vm.minDate.setMinutes(vm.minDate.getMinutes() - 1);

                    updateDatesLimits();
                    $rootScope.$broadcast('flightDatesChanged', null);

                    vm.endCalendarDate = new Date(now.getTime() + tzDifference * 60 * 1000);

                    if (vm.flight.startDate)
                        vm.flight.startDate = new Date(vm.flight.startDate);

                    //setup datepicker in Eastern Time
                    if (vm.flight.startDate && (vm.flight.startDate.valueOf(Date) > 0)) {
                        if (vm.flight.startDate < now) {
                            vm.minDate = new Date(vm.flight.startDate);
                        }
                    }
                    else {
                        vm.flight.startDate = new Date((new Date()).getTime() + 60 * 60000);//add 60 minutes to current date time
                    }

                    vm.parseStartDate = function () {
                        var result = processDatepickerDate(vm.flight.startDate, vm.flight.UTCCalendarStartDate, vm.flight.startDateHour, vm.flight.startDateMinutes, vm.flight.startDateMeridiem, 'America/New_York');
                        vm.flight.startDate = result.date;
                        vm.flight.startDateHour = result.hours;
                        vm.flight.UTCCalendarStartDate = result.utcDate;
                    };

                    vm.parseEndDate = function () {
                        if (vm.flight.endDate) {
                            if (vm.flight.UTCCalendarEndDate) {
                                vm.flight.endDate = timezoneService.utcToEst(vm.flight.UTCCalendarEndDate, vm.flight.endDateHour, vm.flight.endDateMinutes);
                            }
                            var result = processDatepickerDate(vm.flight.endDate, vm.flight.UTCCalendarEndDate, vm.flight.endDateHour, vm.flight.endDateMinutes, vm.flight.endDateMeridiem, 'America/New_York');
                            vm.flight.endDate = result.date;
                            vm.flight.UTCCalendarEndDate = result.utcDate;
                            vm.flight.endDateHour = result.hours;
                            vm.maxStartCalendarDate = moment(vm.flight.endDate).tz('America/New_York').format('YYYY/MM/DD');
                        }
                        else {
                            vm.maxStartCalendarDate = null;
                        }
                        $scope.$broadcast('flightDatesChanged', null);
                    };

                    vm.formatHours = function (hourType, hourValue) {
                        if (hourValue.length === 1)
                            hourValue = "0" + hourValue;
                        else if (hourValue.length === 3)
                            hourValue = hourValue.substr(1);

                        var parsed = parseInt(hourValue)

                        if (isNaN(parsed) || parsed > 12 || parsed < 1) {
                            hourValue = "01";
                            vm.selectTime(hourType, 'hour');
                        }

                        if (hourType === "start")
                            vm.flight.startDateHour = hourValue;
                        else if (hourType === "end")
                            vm.flight.endDateHour = hourValue;
                    };

                    vm.formatMinutes = function (minutesType, minutesValue) {
                        var parsed = parseInt(minutesValue);

                        if (isNaN(parsed) || parsed > 59 || parsed < 0) {
                            minutesValue = "00";
                            vm.selectTime(minutesType, 'minute');
                        }

                        if (minutesValue.length === 1)
                            minutesValue = "0" + minutesValue;
                        else if (minutesValue.length === 3)
                            minutesValue = minutesValue.substr(1);

                        if (minutesType === "start")
                            vm.flight.startDateMinutes = minutesValue;
                        else if (minutesType === "end")
                            vm.flight.endDateMinutes = minutesValue;
                    };

                    vm.selectTime = function (type, part) {
                        if (part === 'hour') {
                            setTimeout(function () {
                                angular.element(type === 'start' ? '#startDateHour' : '#endDateHour')[0].setSelectionRange(0, 2);
                            }, 0);
                        } else if (part === 'minute') {
                            setTimeout(function () {
                                angular.element(type === 'start' ? '#startDateMinutes' : '#endDateMinutes')[0].setSelectionRange(0, 2);
                            }, 0);
                        } else if (part === 'meridiem') {
                            setTimeout(function () {
                                angular.element(type === 'start' ? '#startDateMeridiem' : '#endDateMeridiem')[0].setSelectionRange(0, 2);
                            }, 0);
                        }
                    };

                    vm.toggleHours = function (hoursType, $event) {
                        var intHours;
                        var newHours;

                        if ($event.keyCode === 38) //up arrow
                        {
                            if (hoursType === 'start') {
                                intHours = parseInt(vm.flight.startDateHour) + 1;
                                newHours = (intHours).toString();
                                newHours = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.flight.startDateHour = intHours === 13 ? "01" : newHours;
                                vm.parseStartDate()
                            }
                            else if (hoursType === 'end') {
                                intHours = parseInt(vm.flight.endDateHour) + 1;
                                newHours = (intHours).toString();
                                newHours = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.flight.endDateHour = intHours === 13 ? "01" : newHours;
                                vm.parseEndDate();
                            }
                            vm.selectTime(hoursType, 'hour');

                        }
                        else if ($event.keyCode === 40) //down arrow
                        {
                            if (hoursType === 'start') {
                                intHours = parseInt(vm.flight.startDateHour) - 1;
                                newHours = (intHours).toString();
                                newHours = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.flight.startDateHour = intHours === 0 ? "12" : newHours;
                                vm.parseStartDate();
                            }
                            else if (hoursType === 'end') {
                                intHours = parseInt(vm.flight.endDateHour) - 1;
                                newHours = (intHours).toString();
                                var newMinewHoursnutes = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.flight.endDateHour = intHours === 0 ? "12" : newHours;
                                vm.parseEndDate();
                            }
                            vm.selectTime(hoursType, 'hour');
                        }
                    };

                    vm.toggleMinutes = function (minutesType, $event) {
                        var intMinutes;
                        var newMinutes;
                        if ($event.keyCode === 38) //up arrow
                        {
                            if (minutesType === 'start') {
                                intMinutes = parseInt(vm.flight.startDateMinutes) + 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.flight.startDateMinutes = intMinutes === 60 ? "00" : newMinutes;
                                vm.parseStartDate();
                            }
                            else if (minutesType === 'end') {
                                intMinutes = parseInt(vm.flight.endDateMinutes) + 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.flight.endDateMinutes = intMinutes === 60 ? "00" : newMinutes;
                                vm.parseEndDate();
                            }
                            vm.selectTime(minutesType, 'minute');
                        }
                        else if ($event.keyCode === 40) //down arrow
                        {
                            if (minutesType === 'start') {
                                intMinutes = parseInt(vm.flight.startDateMinutes) - 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.flight.startDateMinutes = intMinutes === -1 ? "59" : newMinutes;
                                vm.parseStartDate();
                            }
                            else if (minutesType === 'end') {
                                intMinutes = parseInt(vm.flight.endDateMinutes) - 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.flight.endDateMinutes = intMinutes === -1 ? "59" : newMinutes;
                                vm.parseEndDate();
                            }
                            vm.selectTime(minutesType, 'minute');
                        }
                    };

                    vm.toggleMeridiem = function (meridiemType, $event) {
                        if ($event.keyCode === 38 || $event.keyCode === 40) {
                            if (meridiemType === 'start') {
                                vm.flight.startDateMeridiem = (vm.flight.startDateMeridiem === "AM") ? "PM" : "AM";
                                vm.parseStartDate();
                            }
                            else if (meridiemType === 'end') {
                                vm.flight.endDateMeridiem = (vm.flight.endDateMeridiem === "AM") ? "PM" : "AM";
                                vm.parseEndDate();
                            }
                        }
                        else if ($event.keyCode === 65) {
                            if (meridiemType === 'start' && vm.flight.startDateMeridiem === "PM") {
                                vm.flight.startDateMeridiem = "AM";
                                vm.parseStartDate();
                            }
                            else if (meridiemType === 'end' && vm.flight.endDateMeridiem === "PM") {
                                vm.flight.endDateMeridiem = "AM";
                                vm.parseEndDate();
                            }
                        }
                        else if ($event.keyCode === 80) {
                            if (meridiemType === 'start' && vm.flight.startDateMeridiem === "AM") {
                                vm.flight.startDateMeridiem = "PM";
                                vm.parseStartDate();
                            }
                            else if (meridiemType === 'end' && vm.flight.endDateMeridiem === "AM") {
                                vm.flight.endDateMeridiem = "PM";
                                vm.parseEndDate();
                            }
                        }

                        if ($event.keyCode != 9 && $event.keyCode != 16) {
                            $event.preventDefault();
                        }
                        vm.selectTime(meridiemType, 'meridiem');
                    };

                    vm.ongoingChanged = function () {
                        if (vm.isOngoing) {
                            vm.flight.endDate = null;
                            vm.flight.UTCCalendarEndDate = null;
                            vm.flight.budgetCapSpendModeId = 1;
                        }
                        else {
                            vm.flight.endDate = new Date(vm.flight.startDate.getTime());
                            vm.flight.endDate.setDate(vm.flight.endDate.getDate() + 1); //tomorrow
                            vm.flight.endDateTime = vm.flight.startDateTime;
                            vm.flight.endDateHour = "11";
                            vm.flight.endDateMinutes = "59";
                            vm.flight.endDateMeridiem = "PM";
                        }
                        vm.parseEndDate();
                    }

                    $scope.$on('flightAdded', function (event, flightIndex) {
                        vm.adGroup = $scope.adGroup;
                        if ($scope.index == flightIndex)
                            vm.ongoingChanged();
                        vm.formatEnd();
                        vm.isNotLast = getIsLast();
                        updateDatesLimits();

                    });

                    $scope.$on('flightRemoved', function (event, flightIndex) {
                        vm.adGroup = $scope.adGroup;
                        vm.budgetCapChanged();
                        vm.formatEnd();
                        vm.isNotLast = getIsLast();

                        updateDatesLimits();
                    });

                    $scope.$on('flightDatesChanged', function (event) {
                        updateDatesLimits();
                    });

                    $scope.$on('adGroupIsStructuredChanged', function (event) {
                        vm.startDateChanged(true);
                    });

                    $scope.$on('updateFlightDates', function (event) {
                        var result = processDatepickerDate(vm.flight.startDate, vm.flight.UTCCalendarStartDate, vm.flight.startDateHour, vm.flight.startDateMinutes, vm.flight.startDateMeridiem, 'America/New_York');
                        vm.flight.UTCCalendarStartDate = result.utcDate;
                        result = processDatepickerDate(vm.flight.endDate, vm.flight.UTCCalendarEndDate, vm.flight.endDateHour, vm.flight.endDateMinutes, vm.flight.endDateMeridiem, 'America/New_York');
                        vm.flight.UTCCalendarEndDate = result.utcDate;
                    });

                    function updateDatesLimits() {
                        var notRemovedFlights = vm.adGroup.flights.filter(f => f.statusId == 0);
                        if ($scope.index == 0 || vm.flight.likeFirstFlight)
                            vm.startCalendarDate = new Date(now.getTime() + tzDifference * 60 * 1000);
                        else
                            vm.startCalendarDate = moment(notRemovedFlights[$scope.index - 1].endDate).add(1, 'days').tz('America/New_York').format('YYYY/MM/DD');

                        var nextFlight = false;
                        vm.maxEndCalendarDate = null;
                        notRemovedFlights.forEach(function (f) {
                            if (nextFlight)
                                vm.maxEndCalendarDate = moment(f.startDate).add(-1, 'days').tz('America/New_York').format('YYYY/MM/DD');
                            if (f === vm.flight)
                                nextFlight = true;
                        });
                    }

                    vm.flight.startDateTime = moment(vm.flight.startDate).tz('America/New_York').format('h') + ':00';
                    vm.flight.startDateHour = moment(vm.flight.startDate).tz('America/New_York').format('hh');
                    vm.flight.startDateMinutes = moment(vm.flight.startDate).tz('America/New_York').format('mm');
                    vm.flight.startDateMeridiem = moment(vm.flight.startDate).tz('America/New_York').format('A');

                    if (vm.flight.endDate)
                        vm.flight.endDate = new Date(vm.flight.endDate);

                    if (vm.flight.endDate && (vm.flight.endDate.valueOf(Date) > 0)) {
                        if (vm.flight.endDate.getUTCFullYear() < 2037) {
                            vm.isOngoing = false;
                            vm.flight.endDateTime = moment(vm.flight.endDate).tz('America/New_York').format('h') + ':00';
                            vm.flight.endDateHour = moment(vm.flight.endDate).tz('America/New_York').format('hh');
                            vm.flight.endDateMinutes = moment(vm.flight.endDate).tz('America/New_York').format('mm');
                            vm.flight.endDateMeridiem = moment(vm.flight.endDate).tz('America/New_York').format('A');
                        }
                        else if (vm.flight.endDate.getUTCFullYear() >= 2037) {
                            vm.flight.endDate = null;
                            vm.flight.UTCCalendarEndDate = null;
                            vm.isOngoing = true;
                        }
                    }
                    else {
                        vm.flight.endDate = null;
                        vm.flight.UTCCalendarEndDate = null;
                        vm.isOngoing = true;
                    }

                    vm.parseStartDate();
                    vm.parseEndDate();

                    vm.getEasternTimeZone = timezoneService.getEasternTimeZone;

                    vm.getEasternDateTime = function (date) {
                        return moment(date).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss ZZ');
                    };

                    vm.flight.startDateIsInThePast = vm.flight.startDate !== undefined && vm.flight.startDate !== null && vm.flight.startDate.getTime() <= (now.getTime() + 10 * 60 * 1000);
                    vm.flight.endDateIsInThePast = vm.flight.endDate !== undefined && vm.flight.endDate !== null && vm.flight.endDate < now;

                    if (!vm.adGroup.adGroupId && !(vm.adGroup.channelId == 6 && vm.adGroup.emailDeploymentType == 1)) {
                        vm.isOngoing = false;
                        vm.ongoingChanged();
                    }

                    const { dateRangeValid, endDateValid } = validationService.validateFlight(vm.flight, vm.adGroup, vm.campaign);
                    vm.dateRangeValid = dateRangeValid;
                    vm.endDateValid = endDateValid;

                    vm.startDateValid = true;
                    if (vm.adGroup.adGroupId && (!vm.dateRangeValid || !vm.endDateValid || vm.startDateValid == false))
                        vm.adGroup.datesInvalid = true;

                    function dateDiff() {
                        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                        var firstDate = new Date(vm.flight.startDate);
                        var secondDate = new Date(vm.flight.endDate);
                        var diffDays = Math.ceil(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
                        return diffDays;
                    }

                    function openDatepicker($event, which) {
                        $event.preventDefault();
                        $event.stopPropagation();
                        for (var prop in vm.datepickers) {
                            if (prop !== which)
                                vm.datepickers[prop] = false;
                        }
                        if (vm.datepickers[which])
                            vm.datepickers[which] = false;
                        else
                            vm.datepickers[which] = true;
                    };

                    $scope.$watch('vm.flight.startDate', function (newVal) {
                        if (typeof newVal !== 'undefined') {
                            const { dateRangeValid, startDateValid, endDateValid, inBounds } = validationService.validateFlight(vm.flight, vm.adGroup, vm.campaign);
                            vm.startDateValid = startDateValid;
                            vm.dateRangeValid = dateRangeValid;
                            vm.endDateValid = endDateValid;
                            vm.inBounds = inBounds;
                            vm.adGroup.datesInvalid = !vm.dateRangeValid || !vm.endDateValid || !vm.startDateValid || !vm.inBounds;
                            $timeout(function () {
                                $('#startDateValidation').trigger('input');
                                $('#endDateValidation').trigger('input');
                                $('#dateRangeValidation').trigger('input');
                            });
                        }
                    });

                    $scope.$watch('vm.flight.endDate', function (newVal) {
                        if (typeof newVal !== 'undefined') {
                            const { dateRangeValid, endDateValid, inBounds } = validationService.validateFlight(vm.flight, vm.adGroup, vm.campaign);
                            vm.dateRangeValid = dateRangeValid;
                            vm.endDateValid = endDateValid;
                            vm.inBounds = inBounds;
                            vm.adGroup.datesInvalid = !vm.dateRangeValid || !vm.endDateValid || !vm.startDateValid || !vm.inBounds;
                            $timeout(function () {
                                $('#endDateValidation').trigger('input');
                                $('#dateRangeValidation').trigger('input');
                            });
                        }
                    });

                    vm.startDateChanged = function (added) {
                        var newDate = moment(timezoneService.utcToEst(vm.flight.UTCCalendarStartDate)).tz('America/New_York').format('YYYY/MM/DD');
                        var currentDate = moment(Date.now()).tz('America/New_York').format('YYYY/MM/DD');
                        if (currentDate === newDate) {
                            vm.flight.startDateHour = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('hh');
                            vm.flight.startDateMinutes = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('mm');
                            vm.flight.startDateMeridiem = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('A');
                        }
                        else {
                            vm.flight.startDateHour = "12";
                            vm.flight.startDateMinutes = "00";
                            vm.flight.startDateMeridiem = "AM";
                        }
                        vm.flight.startDate = timezoneService.utcToEst(vm.flight.UTCCalendarStartDate);
                        vm.parseStartDate();

                        if (vm.adGroup.channelId === 9 && vm.adGroup.isStructured) {
                            const flights = vm.adGroup.flights.filter(x => x.statusId !== 1 && vm.adGroup.flights.indexOf(x) !== 0);
                            var currentFlight = this.flight;
                            currentFlight.startDate = timezoneService.getMonthStart(currentFlight.startDate);
                            currentFlight.endDate = timezoneService.getMonthEnd(currentFlight.startDate);
                            if (!added) {
                                flights.forEach(function (f) {
                                    f.startDate = timezoneService.getNextMonth(currentFlight.startDate);
                                    f.endDate = timezoneService.getMonthEnd(f.startDate, true);
                                    currentFlight = f;
                                });
                            }
                            $rootScope.$broadcast('updateFlightDates', null);
                        }

                        $rootScope.$broadcast('flightDatesChanged', null);
                    };

                    $scope.slider = {
                        options: {
                            floor: 50,
                            ceil: 150,
                            step: 1,
                            translate: function (value) {
                                return value + '%';
                            }
                        }
                    };

                    $scope.$watch('vm.flight.pacingPercent', function (newVal, oldVal) {
                        if (vm.flight.pacingPercent > 150)
                            vm.flight.pacingPercent = 150;
                        if (!(vm.flight.pacingPercent >= 50 && vm.flight.pacingPercent <= 150))
                            vm.flight.pacingPercent = 100;
                    });

                    const onChannelChanged = () => {
                        const doagBudgetCapImp = 35000000;
                        vm.adGroup.flightBudgetCap = vm.adGroup.budgetTypeId == 2 ? 1000000: (vm.adGroup.channelId === 9 ? doagBudgetCapImp : 10000000);

                        const format = vm.adGroup.budgetTypeId == 1 ? 'number': 'currency';
                        vm.adGroup.flightBudgetCapText = $filter(format)(vm.adGroup.flightBudgetCap);
                    };

                    $scope.$watch('adGroup.channelId', function (newVal, oldVal) {
                        onChannelChanged();
                    });

                    vm.budgetCapChanged = function () {
                        var bc = _.map(vm.adGroup.flights.filter(x => x.statusId !== 1), 'budgetCap').reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
                        $scope.adGroup.budgetCap = Math.round(bc * 100) / 100;
                    }

                    const getIsLast = () => {
                        const flights = vm.adGroup.flights.filter(x => x.statusId !== 1);
                        const idx = flights.indexOf(this.flight);
                        return idx <= flights.length - 2;
                    }

                    vm.formatEnd = () => {
                        if(vm.flight.endDate.getUTCFullYear() < 2037) {
                            vm.flight.endDateTime = moment(vm.flight.endDate).tz('America/New_York').format('h') + ':00';
                            vm.flight.endDateHour = moment(vm.flight.endDate).tz('America/New_York').format('hh');
                            vm.flight.endDateMinutes = moment(vm.flight.endDate).tz('America/New_York').format('mm');
                            vm.flight.endDateMeridiem = moment(vm.flight.endDate).tz('America/New_York').format('A');
                        }
                    }

                    vm.isNotLast = getIsLast();

                    const getIsFirst = () => {
                        const flights = vm.adGroup.flights.filter(x => x.statusId !== 1);
                        const idx = flights.indexOf(this.flight);
                        return idx === 0;
                    }
                    
                    vm.isFirst = getIsFirst();

                    if (vm.adGroup.channelId == 9 && vm.adGroup.isStructured)
                        vm.startDateChanged(true);

                    onChannelChanged();
                    return vm;
                }]
            };
        });
})();
