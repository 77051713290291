(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:tracking-code-datepicker
 * @dateRange directive
 * # <date-range  />
 * shows data range dropdown list (sample)
 */

    angular.module('app')
        .directive('trackingCodeDatepicker', function () {
            return {
                template: require('@views/app/tracking-code-datepicker.html').default,
                controllerAs: 'vm',
                restrict: 'E',
                scope: { model: '=' },
                controller: ['$scope', '$timeout', function($scope, $timeout) {
                    var vm = this;
                    vm.modes = ['Lifetime', 'Custom'];

                    $scope.model = $scope.model ? $scope.model : {};
                    vm.model = $scope.model;                    

                    if (new Date($scope.model.startDate).getFullYear() < 2000)
                        $scope.model.startDate = new Date();

                    if (new Date($scope.model.endDate).getFullYear() < 2000)
                        $scope.model.endDate = new Date();

                    vm.model.trackingCodeMode = vm.model.trackingCodeMode ? vm.model.trackingCodeMode : vm.modes[0];
                    vm.model.startDateTimeZone = vm.model.startDateTimeZone ? vm.model.startDateTimeZone : 'PST';
                    vm.model.endDateTimeZone = vm.model.endDateTimeZone ? vm.model.endDateTimeZone : 'PST';

                    vm.baseModel = angular.copy($scope.model);

                    function disabled() {
                        return vm.model.trackingCodeMode === 'Lifetime';
                    }

                    $scope.dateOptions = {
                        dateDisabled: disabled,
                        showWeeks: false
                    };

                    $scope.$on("cleanTrackingCodeValidation", function () {
                        if (vm.model.trackingCodeMode === 'Lifetime')
                            vm.cleanValidation = true;
                    });


                    $scope.$watch('vm.model.trackingCodeMode', function (newVal, oldVal) {
                        if (vm.model.trackingCodeMode === 'Lifetime') {
                            vm.trackingCodeStartDate = null;
                            vm.trackingCodeEndDate = null;
                        }
                        else {
                            vm.trackingCodeStartDate = vm.model.startDate;
                            vm.trackingCodeEndDate = vm.model.endDate;
                        }
                    });

                    $scope.$watch('vm.trackingCodeStartDate', function (newVal, oldVal) {
                        if (vm.model.trackingCodeMode === 'Custom')
                            vm.model.startDate = vm.trackingCodeStartDate;
                    });

                    $scope.$watch('vm.trackingCodeEndDate', function (newVal, oldVal) {
                        if (vm.model.trackingCodeMode === 'Custom')
                            vm.model.endDate = vm.trackingCodeEndDate;
                    });

                    vm.timeZones = ['HST', 'AKST', 'PST', 'MST', 'CST', 'EST'];

                    $scope.formatDate = function (date) {
                        return moment(date).format('MMM DD YYYY hh:mm');
                    };

                    vm.save = function () {
                        vm.baseModel = angular.copy(vm.model)
                        vm.popupIsOpen = false;
                    }

                    vm.cancel = function () {
                        $scope.model = angular.copy(vm.baseModel);
                        vm.model = angular.copy(vm.baseModel);
                        vm.popupIsOpen = false;
                    }

                    return vm;
                }]
            };
      });
})();