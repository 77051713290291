(function () {
    'use strict';

    angular.module('app')
        .config(['$routeProvider','$injector', function ($routeProvider, $injector) {
            var routes, setRoutes;

            var ROUTE_PREFIX = 'activation';
            const prefix = (route) => {
                const result = `/${ROUTE_PREFIX}`;
                if (!route) return result;
                const withoutClient = ['reporting'];
                return withoutClient.some((section) => route.toLowerCase().indexOf(section) === 0) ? '' : result;
            }

            routes = [
                'campaign/campaign-wizard', 'campaign', 'creative', 'creative-request', 'audience', 'retargeting-audience', 'admin-only', 'pixel', 'location',
                'reporting', 'reporting/campaign-summary', 'reporting/custom', 'reporting/match','reporting/site', 'reporting/sell',
                'pages/404', 'pages/403', 'pages/500', 'pages/change-password',
                'admin/users', 'admin/search', 'admin/accounts', 'admin/advertisers', 'admin/advertisergroups', 'admin/account-management', 'admin/tags', 'admin/taxonomy', 'admin/features', 'admin/roles', 'admin/deals', 'admin/web-events/deals',
                'settings/account-settings',
                'brand-safety/inventory-list', 'brand-safety/keyword-list', 'brand-safety/blocking-profile', 'brand-safety',
                'web-events', 'web-events/pixel', 'web-events/retargeting-audience',
                'v2'
            ];

            var paths = ['campaign', 'creative', 'creative-request', 'audience', 'retargeting-audience', 'admin-only', 'pixel', 'location', 'reporting', 'brand-safety/inventory-list', 'brand-safety/keyword-list', 'brand-safety/blocking-profile', 'brand-safety'];
            setRoutes = function (route) {
                var settings = $injector.get('appSettings');

                var config, url;
                url = '/' + route;

                if (route.indexOf('reporting')!=-1)
                {
                    url =prefix(route)+ '/' + route;
                }

                //TODO: do something
                if (route === 'campaign/campaign-wizard')
                    url =prefix(route)+ '/' + route;

                //convert path to views/$1/$1.html
                if (paths.indexOf(route) >= 0)
                {
                    config = {
                        templateUrl: 'views/' + route + '/' + route + '.html'
                    };
                    url =prefix(route)+ '/' + route;
                }
                else
                config = {
                    templateUrl: 'views/' + route + '.html'
                };
                if(route === 'reporting') {
                    config = {
                        templateUrl: 'views/' + route + '/' + 'list.html'
                    };
                    url =prefix(route)+ '/' + route;
                }
                if (route === 'web-events') {
                    config = {
                        template: require('@views/audience/audience.html').default
                    };
                    url =prefix(route)+ '/' + route;
                }
                if (route === 'web-events/pixel') {
                    config = {
                        template: require('@views/audience/audience.html').default
                    };
                    url =prefix(route)+ '/' + route;
                }
                if (route === 'web-events/retargeting-audience') {
                    config = {
                        template: require('@views/audience/audience.html').default
                    };
                    url =prefix(route)+ '/' + route;
                }
                if (route === 'admin/web-events/deals') {
                    config = {
                        template: require('@views/audience/audience.html').default
                    };
                    url = '/' + route;
                }
                if (route === 'pixel') {
                    config = {
                        template: require('@views/audience/audience.html').default
                    };
                    url =prefix(route)+ '/' + route;
                }
                if (route === 'retargeting-audience') {
                    config = {
                        template: require('@views/audience/audience.html').default
                    };
                    url =prefix(route)+ '/' + route;
                }
                if (route === 'admin-only') {
                    config = {
                        template: require('@views/audience/audience.html').default
                    };
                    url =prefix(route)+ '/' + route;
                }
                config.menu = settings.menus.manufacturer;

                if (['campaign/campaign-wizard', 'reporting'].includes(route))
                    config.reloadOnSearch = false;

                $routeProvider.when(url, config);
                return $routeProvider;
            };
            routes.forEach(function(route) {
                return setRoutes(route);
            });


            $routeProvider
                .when('/', { redirectTo: '/activation/campaign' })
                .when('/' + ROUTE_PREFIX + '', { redirectTo: '/activation/campaign' })
                .when('/pages/404', { template: require('@views/pages/404.html').default })
                .when('/' + ROUTE_PREFIX + '/campaign/adgroup/:adGroupId/:mode', {
                    template: require('@views/campaign/campaign.html').default
                })
                .when('/' + ROUTE_PREFIX + '/campaign/:campaignId/new', {
                    template: require('@views/campaign/campaign.html').default
                })
                .when('/dashboard', { redirectTo: '/activation/campaign' })
                .when('/' + ROUTE_PREFIX + '/location/new', {
                    template: require('@views/location/location.html').default
                })
                .when('/' + ROUTE_PREFIX + '/location/:locationId/edit', {
                    template: require('@views/location/location.html').default
                })
                .when('/' + ROUTE_PREFIX + '/campaign/:campaignId', {
                    template: require('@views/campaign/campaign.html').default
                })
                .when('/' + ROUTE_PREFIX + '/campaign/:campaignId/edit', {
                    template: require('@views/campaign/campaign.html').default
                })
                .when('/' + ROUTE_PREFIX + '/campaign/campaign-wizard/:campaignId', {
                    template: require('@views/campaign/campaign-wizard.html').default
                })
                .when('/' + ROUTE_PREFIX + '/campaign/adgroup/:adGroupId', {
                     template: require('@views/campaign/campaign.html').default
                })
                .when(`/${ROUTE_PREFIX}/adgroup-history/:adGroupId/:fromV2?`, {
                    name: 'adGroupHistory',
                    redirectTo: () => { throw new Error("Stop processing"); }, // prevent loading
                })
                .when('/' + ROUTE_PREFIX + '/creative/:creativeId/:mode', {
                     template: require('@views/creative/creative.html').default
                })
                .when('/' + ROUTE_PREFIX + '/creative/new', {
                    template: require('@views/creative/creative.html').default
                })
                .when('/' + ROUTE_PREFIX + '/creative/bulk', {
                    template: require('@views/creative/creative.html').default
                })
                .when('/' + ROUTE_PREFIX + '/audience/:audienceId/edit', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/audience/new', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/admin-only/:audienceId/edit', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/web-events', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/retargeting-audience/:audienceId/edit', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/retargeting-audience/new', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/web-events/retargeting-audience/:audienceId/edit', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/web-events/retargeting-audience/new', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/admin-only/new', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/pixel/:audienceId/edit', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/pixel/new', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/web-events/pixel/:audienceId/edit', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/web-events/pixel/new', {
                    template: require('@views/audience/audience.html').default
                })
                .when('/' + ROUTE_PREFIX + '/creative-request/:requestId', {
                     template: require('@views/creative-request/creative-request.html').default
                 })
                .when('/admin/account-management/:accountId/edit',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/advertisers',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/advertisers/:accountId/edit',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/advertisers/new',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/marketers',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/marketers/:accountId/edit',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/marketers/new',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/publishers',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/publishers/:accountId/edit',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/publishers/new',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/advertisergroups',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/advertisergroups/:accountId/edit',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/accounts/advertisergroups/new',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/holding-companies',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/holding-companies/:accountId/edit',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/holding-companies/new',
                {
                    template: require('@views/admin/account-management.html').default
                })
                .when('/admin/users/:userId/edit',
                {
                    template: require('@views/admin/users.html').default
                })
                .when('/admin/users/new',
                {
                    template: require('@views/admin/users.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/inventory-list',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/inventory-list/new',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/inventory-list/:inventoryListId/:mode',
                {
                    template: require('@views/brand-safety/brand-safety.html').default,
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/keyword-list',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/keyword-list/new',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/keyword-list/:keywordListId/:mode',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/blocking-profile',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/blocking-profile/new',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/' + ROUTE_PREFIX + '/brand-safety/blocking-profile/:blockingProfileId/:mode',
                {
                    template: require('@views/brand-safety/brand-safety.html').default
                })
                .when('/reporting/custom/:id', {
                    template: require('@views/reporting/custom.html').default
                })
                .when('/reporting/site',
                {
                    template: require('@views/reporting/custom.html').default
                })
                .when('/reporting/sell',
                {
                    template: require('@views/reporting/custom.html').default
                })
                .when( '/reporting/site/:id',
                {
                    template: require('@views/reporting/custom.html').default
                })
                .when('/reporting/sell/:id',
                {
                    template: require('@views/reporting/custom.html').default
                })
                .when('/reporting/match',
                {
                    template: require('@views/reporting/match.html').default
                })
                .when('/reporting/campaign-summary',
                {
                    template: require('@views/reporting/campaign-summary.html').default
                })
                .when('/reporting/campaign-summary/:id',
                {
                    template: require('@views/reporting/campaign-summary.html').default
                })
                .when('/admin/users/:accountId', {
                    template: require('@views/admin/users.html').default,
                    controller: 'usersController'
                })
                .when('/app/:path*', {
                  template: require('@views/v2.html').default,
                })
                .when('/v2/:path*', {
                  template: require('@views/v2.html').default,
                })
                .otherwise({ redirectTo: '/pages/404'});
        }]
    );

})();
