(function () {
    'use strict';

    angular.module('app')
      .directive('historyItem', function () {
          return {
              template: require('@views/app/history-item.html').default,
              restrict: 'E',
              scope: {model: '=', key: '@', date: '@', number: '@', bool: '@' },
              controller: ['$scope', '$filter', 'timezoneService', function ($scope, $filter, timezoneService) {
                  var vm = this;
                  var key =$scope.key;
                  var dateFormat = $scope.date;
                  var number = $scope.number;
                  var bool = $scope.bool;
                  vm.model = $scope.model;
                  vm.estFormat = timezoneService.estFormat;
                  const FormatsEnum = {
                      date: 'date',
                      number: 'number',
                      bool: 'bool'
                  };
                  const handleNoDate = function (date) {
                    return !date || date.indexOf('2040') > -1  || date.indexOf('2037') > -1 ? 'No End Date' : vm.estFormat(date);
                  };

                  const formated = ({model, isPrev, key, format, extra}) => {
                      let val = isPrev ? model && model.prev && model.prev[key] : model && model[key];
                      if (val != null) {
                        if (format === FormatsEnum.date) {
                            return handleNoDate(val);
                        }
                        else if (format ===  FormatsEnum.number) {
                            return  $filter('number')(val, extra);
                        }
                        else if (format === FormatsEnum.bool) {
                            return val.toString() === 'true' ? 'Yes' : 'No';
                        } else {
                            return val;
                        }
                      }
                      return null;
                  }
                  var loadData = function(model){
                    vm.model = model;
                    let  curArg = { model, key,};
                    let  prevArg = { model, key, isPrev: true };
                    if (dateFormat){
                        curArg.format = prevArg.format = FormatsEnum.date;
                    } else if (number) {
                        curArg.format = prevArg.format = FormatsEnum.number;
                        curArg.extra = prevArg.extra = number;
                    } else if (bool) {
                        curArg.format = prevArg.format = FormatsEnum.bool;
                    }
                    const cur = formated(curArg);
                    const prev = formated(prevArg);
                    vm.value = cur;
                    vm.hasChanges = cur != prev;
                    vm.prev = vm.hasChanges ? prev : null;
                  }

                  $scope.$watch('model', function(newModel){
                        loadData(newModel);
                    });
                    return vm;
                }],
                controllerAs: 'vm'
            }
        });
})();
